class DMCNewSource extends React.Component {
  constructor(props) {
    super(props);
    this.onNewSourceClick = this.onNewSourceClick.bind(this);
    this.onDropSchemaObjectToSource = this.onDropSchemaObjectToSource.bind(this);
    this.restrictAddSource = this.restrictAddSource.bind(this);
  }

  componentDidMount() {
    $j(this.el).droppable({
      scope: 'omnifind',
      tolerance: 'touch',
      hoverClass: 'notice',
      drop: (_event, ui) => {
        const termId = ui.helper.data('dcb-term_id');

        const {
          onDropTermToSource,
          lineage,
          sourceParams
        } = this.props;

        this.restrictAddSource(() => onDropTermToSource(termId, lineage, sourceParams));
      }
    });
  }

  onNewSourceClick(e) {
    e.preventDefault();

    const { sourceParams, openNewSourceForm } = this.props;

    this.restrictAddSource(() => openNewSourceForm(sourceParams));
  }

  restrictAddSource(callback) {
    const { canAddSource, cannotAddSourceMsg } = this.props;
    if (canAddSource)
      callback();
    else
      jAlert('Error', cannotAddSourceMsg);
  }

  onDropSchemaObjectToSource(e) {
    e.preventDefault();
    DCB.html5droppableHighlight.drop();

    const {
      lineage,
      sourceParams,
      onDropSchemaObjectToSource
    } = this.props;

    this.restrictAddSource(() => onDropSchemaObjectToSource(e, lineage, sourceParams));
  }

  render() {
    const { sourceErpSystemName, sourceErpSystemDescription } = this.props;

    const {
      plusImageSrc
    } = this.props;

    return (
      <div
        onDragOver={e => e.preventDefault()}
        onDrop={this.onDropSchemaObjectToSource}
        className="dmc-new-source html5-droppable-highlight"
        ref={el => this.el = el}
      >
        <a
          href="#"
          onClick={this.onNewSourceClick}
          className="dmc-new-source__link"
        >
          <div style={{ display: 'inline-flex' }}>
            <div>
              <img
                src={plusImageSrc}
                className="dmc-new-source__plus-icon"
                alt="Add a new source"
              />
            </div>
            <div>
              <span>
                Add a source:
                <br />
                {
                  sourceErpSystemName &&
                  <div
                    className="dmc-source__system_name"
                    title={sourceErpSystemDescription || sourceErpSystemName}
                  >
                    {sourceErpSystemName}
                  </div>
                }
              </span>
            </div>
          </div>
        </a>
      </div>
    );
  }
}

export default DMCNewSource;
