import {
  render,
  fireEvent
} from '@testing-library/react';
import ImportOptions from './ImportOptions';

describe('<ImportOptions />', () => {
  describe('when object type is Report', () => {
    it('should render correct options with permissions', async () => {
      const component = exerciseReportTypeWithPermission();
      const autoApproveInfo = await component.findByText(messages.autoApproveInfo);
      const autoApproveCheckbox = await component.findByText(messages.autoApproveCheckbox);
      const updateExistingCheckboxName = await component.findByText(messages.updateExistingCheckboxName);
      const updateExistingInfo = await component.findByText(messages.updateExistingInfo);
      const reviewInfo = await component.findByText(messages.reviewInfo);
      const importButton = await component.findByText(messages.importButton);
      expect(autoApproveInfo).toBeVisible();
      expect(autoApproveCheckbox).toBeVisible();
      expect(updateExistingCheckboxName).toBeVisible();
      expect(updateExistingInfo).toBeVisible();
      expect(reviewInfo).toBeVisible();
      expect(importButton).toBeVisible();
    });

    it('should show errors an warnings', async () => {
      const component = exerciseReportTypeWithPermission();
      const headerError = await component.findByText('headerError1');
      const headerWarning = await component.findByText('headerWarning1');
      expect(headerError).toBeVisible();
      expect(headerWarning).toBeVisible();
    });

    it('should extend options on auto approve click', async () => {
      const component = exerciseReportTypeWithPermission();
      const autoApproveCheckbox = await component.findByTitle('auto approve');
      fireEvent.click(autoApproveCheckbox);
      const willApproveCount = await component.findByText(messages.willApproveCount);
      const willNotApproveCount = await component.findByText(messages.willNotApproveCount);
      expect(willApproveCount).toBeVisible();
      expect(willNotApproveCount).toBeVisible();
    });

    it('should render correct options without permissions', async () => {
      const component = exerciseReportTypeWithoutPermission();
      const autoApproveCheckbox = await component.findByText(messages.autoApproveCheckbox);
      const optionsNotAvailable = await component.findByText(messages.optionsNotAvailable.message);
      const updateExistingCheckboxName = await component.findByText(messages.updateExistingCheckboxName);
      const updateExistingInfo = await component.findByText(messages.updateExistingInfo);
      const reviewInfo = await component.findByText(messages.reviewInfo);
      const importButton = await component.findByText(messages.importButton);
      expect(autoApproveCheckbox).toBeVisible();
      expect(optionsNotAvailable).toBeVisible();
      expect(updateExistingCheckboxName).toBeVisible();
      expect(updateExistingInfo).toBeVisible();
      expect(reviewInfo).toBeVisible();
      expect(importButton).toBeVisible();
    });
  });

  describe('when object type is Term', () => {
    it('should render correct options with permissions', async () => {
      const component = exerciseTermTypeWithPermission();
      const autoApproveInfo = await component.findByText(messages.autoApproveInfo);
      const autoApproveCheckbox = await component.findByText(messages.autoApproveCheckbox);
      const updateExistingCheckboxName = await component.findByText(messages.updateExistingCheckboxName);
      const updateExistingInfo = await component.findByText(messages.updateExistingInfo);
      const reviewInfo = await component.findByText(messages.reviewInfo);
      const importButton = await component.findByText(messages.importButton);
      expect(autoApproveInfo).toBeVisible();
      expect(autoApproveCheckbox).toBeVisible();
      expect(updateExistingCheckboxName).toBeVisible();
      expect(updateExistingInfo).toBeVisible();
      expect(reviewInfo).toBeVisible();
      expect(importButton).toBeVisible();
    });

    it('should show errors an warnings', async () => {
      const component = exerciseTermTypeWithPermission();
      const headerError = await component.findByText('headerError1');
      const headerWarning = await component.findByText('headerWarning1');
      expect(headerError).toBeVisible();
      expect(headerWarning).toBeVisible();
    });

    it('should extend options on auto approve click', async () => {
      const component = exerciseTermTypeWithPermission();
      const autoApproveCheckbox = await component.findByTitle('auto approve');
      fireEvent.click(autoApproveCheckbox);
      const willApproveCount = await component.findByText(messages.willApproveCount);
      const willNotApproveCount = await component.findByText(messages.willNotApproveCount);
      expect(willApproveCount).toBeVisible();
      expect(willNotApproveCount).toBeVisible();
    });

    it('should render correct options without permissions', async () => {
      const component = exerciseTermTypeWithoutPermission();
      const autoApproveCheckbox = await component.findByText(messages.autoApproveCheckbox);
      const optionsNotAvailable = await component.findByText(messages.optionsNotAvailable.message);
      const updateExistingCheckboxName = await component.findByText(messages.updateExistingCheckboxName);
      const updateExistingInfo = await component.findByText(messages.updateExistingInfo);
      const reviewInfo = await component.findByText(messages.reviewInfo);
      const importButton = await component.findByText(messages.importButton);
      expect(autoApproveCheckbox).toBeVisible();
      expect(optionsNotAvailable).toBeVisible();
      expect(updateExistingCheckboxName).toBeVisible();
      expect(updateExistingInfo).toBeVisible();
      expect(reviewInfo).toBeVisible();
      expect(importButton).toBeVisible();
    });
  });
});

const messages = {
  autoApproveInfo: 'auto_approve_info',
  autoApproveCheckbox: 'auto_approve_checkbox',
  willApproveCount: 'will_approve_count',
  willNotApproveCount: 'will_not_approve_count',
  optionsNotAvailable: { message: 'options_not_available', link: 'link' },
  updateExistingCheckboxName: 'update_existing_checkbox_name',
  updateExistingInfo: 'update_existing_info',
  reviewInfo: 'review_info',
  importButton: 'import_button'
};

const exerciseReportTypeWithPermission = () => {
  const component = render(<ImportOptions
    helpPoints={{ point1: '', point2: '', point3: '', point4: '', point5: '', point6: '' }}
    objectType="specification"
    headerErrors={['headerError1']}
    headerWarnings={['headerWarning1']}
    unrecognizedErrors={['unrecognizedError1']}
    userHasManagerPermission
    importOptionsMessages={messages}
    anyExistingObjects
    noValidObjects={false}
    forceAutoApprove={false}
    invalidForAutoApprove={false}
    onCheckAutoApprove={() => {}}
    objects={[{ objectValues: { name: 'SpecName1', customFields: [] }, warnings: ['warning1'], errors: ['error1'], hasWarnings: true, hasErrors: true, statusMessage: 'successMessage', actionMessage: 'createNew', customFieldsHeaders: [], customFieldsValues: [] }]}
  />);

  return {
    ...component
  };
};

const exerciseReportTypeWithoutPermission = () => {
  const component = render(<ImportOptions
    helpPoints={{ point1: '', point2: '', point3: '', point4: '', point5: '', point6: '' }}
    objectType="specification"
    headerErrors={[]}
    headerWarnings={[]}
    unrecognizedErrors={[]}
    userHasManagerPermission={false}
    importOptionsMessages={messages}
    anyExistingObjects
    noValidObjects={false}
    forceAutoApprove={false}
    invalidForAutoApprove={false}
    onCheckAutoApprove={() => {}}
    objects={[{ objectValues: { name: 'SpecName1', customFields: [] }, warnings: ['warning1'], errors: ['error1'], hasWarnings: true, hasErrors: true, statusMessage: 'successMessage', actionMessage: 'createNew', customFieldsHeaders: [], customFieldsValues: [], customFieldsApprovalWarnings: [] }]}
  />);

  return {
    ...component
  };
};

const exerciseTermTypeWithPermission = () => {
  const component = render(<ImportOptions
    helpPoints={{ point1: '', point2: '', point3: '', point4: '', point5: '', point6: '' }}
    objectType="definition"
    headerErrors={['headerError1']}
    headerWarnings={['headerWarning1']}
    unrecognizedErrors={['unrecognizedError1']}
    userHasManagerPermission
    importOptionsMessages={messages}
    anyExistingObjects
    noValidObjects={false}
    forceAutoApprove={false}
    invalidForAutoApprove={false}
    onCheckAutoApprove={() => {}}
    objects={[{ objectValues: { name: 'SpecName1', customFields: [] }, warnings: ['warning1'], errors: ['error1'], hasWarnings: true, hasErrors: true, statusMessage: 'successMessage', actionMessage: 'createNew', customFieldsHeaders: [], customFieldsValues: [], customFieldsApprovalWarnings: [] }]}
  />);

  return {
    ...component
  };
};

const exerciseTermTypeWithoutPermission = () => {
  const component = render(<ImportOptions
    helpPoints={{ point1: '', point2: '', point3: '', point4: '', point5: '', point6: '' }}
    objectType="definition"
    headerErrors={[]}
    headerWarnings={[]}
    unrecognizedErrors={[]}
    userHasManagerPermission={false}
    importOptionsMessages={messages}
    anyExistingObjects
    noValidObjects={false}
    forceAutoApprove={false}
    invalidForAutoApprove={false}
    onCheckAutoApprove={() => {}}
    objects={[{ objectValues: { name: 'SpecName1', customFields: [] }, warnings: ['warning1'], errors: ['error1'], hasWarnings: true, hasErrors: true, statusMessage: 'successMessage', actionMessage: 'createNew', customFieldsHeaders: [], customFieldsValues: [], customFieldsApprovalWarnings: [] }]}
  />);

  return {
    ...component
  };
};
