const LineageTooltip = function(rootSelector) {
  this.div = d3.select(rootSelector)
    .append('div')
    .attr('class', 'tooltip')
    .style('opacity', 0);

  $j(document).on('mousemove', (e) => {
    this.div.style('left', `${e.pageX + 10}px`)
      .style('top', `${e.pageY}px`);
  });
};

LineageTooltip.prototype.show = function(html) {
  const { div } = this;
  div.transition().duration(100).style('opacity', 1.0);
  div.html(html);
  return this.div;
};

LineageTooltip.prototype.hide = function() {
  return this.div.transition().duration(100).style('opacity', 0);
};

LineageTooltip.prototype.destroy = function() {
  return this.div.remove();
};

export default LineageTooltip;
