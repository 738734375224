import HelpPoint from "../Helpers/HelpPoint";

export default class TabSubjectDetailsDescription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      tabSubjectDetailsDescriptionContent: this.tabSubjectDetailsDescriptionContent.bind(this),
      errorNoticeContent: this.errorNoticeContent.bind(this),
      browseDataSystemButton: this.browseDataSystemButton.bind(this),
      getHelpPoint: this.getHelpPoint.bind(this),
      tabSubjectDetailsDescriptionElement: props.tabSubjectDetailsDescription,
      onTabSubjectDetailsDescriptionSave: this.onTabSubjectDetailsDescriptionSave.bind(this),
      onTabSubjectDetailsDescriptionCancel: this.onTabSubjectDetailsDescriptionCancel.bind(this),
      toggleTabSubjectDetailsDescriptionEditMode: this.toggleTabSubjectDetailsDescriptionEditMode.bind(this),
      onTabSubjectDetailsDescriptionChange: this.onTabSubjectDetailsDescriptionChange.bind(this),
      tabSubjectDetailsDescriptionEditMode: false,
      reportUpdateErrorMessage: '',
      tabSubjectDetailsEditDescription: props.tabSubjectDetailsEditDescription || props.tabSubjectDetailsDescription
    };
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.onUnload);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload);
  }

  onUnload = e => {
    const displayDescriptionUpdated =
      this.state.tabSubjectDetailsEditDescription.toString() !== this.state.tabSubjectDetailsDescription.toString()

    if (this.state.tabSubjectDetailsDescriptionEditMode && displayDescriptionUpdated) {
      e.preventDefault();
      e.returnValue = '';
    }
  }

  componentDidUpdate() {
    const { tabSubjectDetailsDescriptionEditMode } = this.state;
    if (tabSubjectDetailsDescriptionEditMode) {
      const { tabSubjectDetailsDescriptionId, body } = this.state;
      if (!CKEDITOR.instances[tabSubjectDetailsDescriptionId]) {
        CKEDITOR.replace(tabSubjectDetailsDescriptionId, { toolbar: 'mini' });

        const instance = CKEDITOR.instances[tabSubjectDetailsDescriptionId];
        instance.setData(body);
        instance.on('change', () => {
          const target = {
            name: `${tabSubjectDetailsDescriptionId}`,
            value: instance.getData()
          };

          this.state.onTabSubjectDetailsDescriptionChange({ target });
        });
      }
      initDataSchemaSearch();
    }
  }

  onTabSubjectDetailsDescriptionSave(e) {
    e.preventDefault();

    const {
      reportVersion,
      tabSubjectDetailsDescription,
      tabSubjectDetailsEditDescription,
      tabSubjectDetailsDescriptionField
    } = this.state;

    const data = {
      report_version: {
        [this.state.tabSubjectDetailsDescriptionField]: tabSubjectDetailsEditDescription,
        source_erp_system_ids: this.state.reportVersionErpSystemIds,
        data_tool_ids: this.state.dataToolIds
      }
    };

    $j.ajax({
      url: `/institution/reports/${reportVersion.report_id}` +
      `/versions/${reportVersion.id}`,
      method: 'PATCH',
      data,
      success: (response) => {
        CKEDITOR.instances[this.state.tabSubjectDetailsDescriptionId].destroy();
        this.setState({
          tabSubjectDetailsDescriptionEditMode: false,
          tabSubjectDetailsDescriptionElement: response[tabSubjectDetailsDescriptionField],
          tabSubjectDetailsDescription: response[tabSubjectDetailsDescriptionField]
        });
      },
      error: (jqXHR, textStatus, errorThrown) => {
        this.setState({ reportUpdateErrorMessage: errorThrown });
      }
    });
  }

  onTabSubjectDetailsDescriptionCancel(e) {
    e.preventDefault();
    CKEDITOR.instances[this.state.tabSubjectDetailsDescriptionId].destroy();
    this.setState({
      tabSubjectDetailsEditDescription: this.state.tabSubjectDetailsDescriptionElement,
      tabSubjectDetailsDescriptionEditMode: false
    });
  }

  toggleTabSubjectDetailsDescriptionEditMode(e) {
    e.preventDefault();
    const instanceEditor = CKEDITOR.instances[this.state.tabSubjectDetailsDescriptionId];
    const { tabSubjectDetailsDescriptionEditMode } = this.state;
    if (instanceEditor)
      instanceEditor.destroy();

    this.setState({ tabSubjectDetailsDescriptionEditMode: !tabSubjectDetailsDescriptionEditMode });
  }

  onTabSubjectDetailsDescriptionChange(obj) {
    this.setState({ tabSubjectDetailsEditDescription: obj.target.value });
  }

  tabSubjectDetailsDescriptionContent() {
    const {
      reportVersion,
      tabSubjectDetailsDescription,
      tabSubjectDetailsEditDescription,
      tabSubjectDetailsDescriptionEditMode
    } = this.state;

    let el;
    if (tabSubjectDetailsDescriptionEditMode) {
      el = (
        <div id="tab-subject-details-desc-editable-area" className="schema-droppable">
          <textarea
            onChange={this.state.onTabSubjectDetailsDescriptionChange}
            value={tabSubjectDetailsEditDescription}
            id={this.state.tabSubjectDetailsDescriptionId}
          />

          <br />
          <div style={{ height: '50px' }}>
            <button
              onClick={this.state.onTabSubjectDetailsDescriptionCancel}
              aria-expanded={tabSubjectDetailsDescriptionEditMode}
              aria-controls="tab-subject-details-desc-editable-area"
            >
              Cancel
            </button>

            <button
              onClick={this.state.onTabSubjectDetailsDescriptionSave}
            >
              Save
            </button>
          </div>
        </div>
      );
    } else {
      el = (
        <p
          dangerouslySetInnerHTML={{
            __html: tabSubjectDetailsDescription || 'Not specified.'
          }}
        />
      );
    }
    return el;
  }

  allowBrowse() {
    return ['Selection Description', 'Sort Description'].includes(this.props.detailsLabel);
  }

  browseDataSystemButton() {
    const { reportVersion } = { ...this.state };
    const url = `/institution/data_systems/search?report_version_id=${reportVersion.id}`;
    if (this.state.tabSubjectDetailsDescriptionEditMode && this.allowBrowse()) {
      return (
        <a
          className="data-model-schema data-model-schema-report-details pretty-button med"
          href={url}
        >
          Browse Data System
        </a>
      );
    }
    return null;
  }

  getHelpPoint() {
    const { props } = this;
    if (this.state.tabSubjectDetailsDescriptionEditMode) {
      return (
        <HelpPoint helpPoint={props.tabSubjectHelpPointEdit || props.tabSubjectHelpPoint} />
      );
    }
    return (
      <HelpPoint helpPoint={props.tabSubjectHelpPoint} />
    );
  }

  errorNoticeContent() {
    if (this.state.reportUpdateErrorMessage)
      return <div className="error">{this.state.reportUpdateErrorMessage}</div>;

    return null;
  }

  render() {
    const { props } = this;
    const { tabSubjectDetailsDescriptionEditMode } = this.state;

    return (
      <div className="tab-subject-details-description preserve-line-breaks">
        <br />
        <div>
          <label
            style={{
              display: 'inline-block'
            }}
          >
            {props.detailsLabel}
          </label>
          {props.editable ? (<img
            src={props.imageEdit}
            style={{
              verticalAlign: 'middle',
              marginLeft: '5px'
            }}
            alt="Edit mapping description icon"
            role="button"
            aria-expanded={tabSubjectDetailsDescriptionEditMode}
            aria-controls="tab-subject-details-desc-editable-area"
            onClick={this.state.toggleTabSubjectDetailsDescriptionEditMode}
          />) : ''}
          {this.getHelpPoint()}
          {this.browseDataSystemButton()}
        </div>
        {this.errorNoticeContent()}
        {this.tabSubjectDetailsDescriptionContent()}
      </div>
    );
  }
}
