function DMCOrdering(props) {
  const order = props.lineage.newOrder || (props.lineage.treeJson.reportItem && props.lineage.treeJson.reportItem.position) || '';

  const handleKeyPress = (event) => {
    if (event.key === 'Enter')
      props.onOrderSubmit(event, props.lineage);
  };

  const imageStyle = {
    position: 'relative',
    top: '3px'
  };

  return (
    <div className="dmc-lineage__ordering">
      <input
        className="span-1"
        value={order}
        onChange={e => props.onOrderChange(e, props.lineage)}
        onKeyPress={handleKeyPress}
      />

      <a
        href="#"
        onClick={e => props.onOrderSubmit(e, props.lineage)}
      >
        <img
          style={imageStyle}
          src={props.submitOrderingImage}
          role="button"
          alt="Submit new ordering"
        />
      </a>
    </div>
  );
}

export default DMCOrdering;
