import OptimizedSelect from '../shared/OptimizedSelect';

class DMCTargetErpSystemSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTargetErpSystem: props.targetErpSystem ? {
        value: props.targetErpSystem.id,
        label: props.targetErpSystem.name,
        type: 'name'
      } : null
    };
  }

    dataSystemsOptionRenderer = ({ focusedOption, focusOption, key, option, selectValue, style, valueArray }) => {
      const classNames = ['nameOption'];

      if (option.type === 'header') {
        classNames.push('nameHeader');

        return (
          <div
            className={classNames.join(' ')}
            key={key}
            style={style}
          >
            {option.label}
          </div>
        );
      }

      classNames.push('nameField');
      if (option === focusedOption) classNames.push('nameOptionFocused');
      if (valueArray.indexOf(option) >= 0) classNames.push('nameOptionSelected');

      return (
        <div
          className={classNames.join(' ')}
          key={key}
          onClick={() => selectValue(option)}
          onMouseEnter={() => focusOption(option)}
          style={style}
        >
          {option.label}
        </div>
      );
    };

    updateTargetErpSystem(selectedTargetErpSystem) {
      if (!selectedTargetErpSystem) return;

      this.setState({ selectedTargetErpSystem });
    }

    removeTargetErpSystem(e) {
      e.preventDefault();

      this.setState({ selectedTargetErpSystem: null });
    }

    renderSelectedField = () => {
      const { selectedTargetErpSystem } = this.state;
      const name = this.props.reportCreation ? 'report[version_attributes][target_erp_system_id]' : 'report_version[target_erp_system_id]';

      return (
        <input type="hidden" className="item-value" name={name} data-testid="target-data-system-input" value={selectedTargetErpSystem ? selectedTargetErpSystem.value : ''} />
      );
    }

    render() {
      const { selectedTargetErpSystem } = this.state;
      let el;
      const optionsForDataSystemsSelect = this.props.erpSystems.map((erpSystem) => {
        if (erpSystem.groupFlag) {
          const header = {
            label: erpSystem.name,
            value: '',
            isHeader: true,
            isDisabled: true
          };

          const systems = erpSystem.childSystems.filter(s => s.hideFromDropdown == false).map(system => ({
            value: system.id,
            label: system.name
          }));
          return [header].concat(systems);
        }

        return {
          value: erpSystem.id,
          label: erpSystem.name
        };
      }).reduce((firstEl, secondEl) => firstEl.concat(secondEl), []);

      el = (
        <div className="virtualized-custom-wrap">
          <div className="virtualized-custom-style">
            <OptimizedSelect
              options={optionsForDataSystemsSelect}
              value={selectedTargetErpSystem}
              onChange={selectedTargetErpSystem => this.updateTargetErpSystem(selectedTargetErpSystem)}
              isClearable={false}
            />
            {selectedTargetErpSystem &&
            <button
              onClick={e => this.removeTargetErpSystem(e)}
              className="btn-done"
            >
              Remove
            </button>}
          </div>
        </div>
      );
      return (
        <div
          className="dmc-header__target-erp-system"
          style={{ float: 'right' }}
        >
          {el}
          {this.renderSelectedField()}
        </div>
      );
    }
}

export default DMCTargetErpSystemSelector;
