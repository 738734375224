import SpecReportDisplayItems from './SpecReportDisplayItems'
import SpecReportDisplayImageComponent from './SpecReportDisplayImageComponent'
import DisplayDetailsAttachmentUploadForm from './DisplayDetailsAttachmentUploadForm'
import SpecSelectDisplayDataItemForm from './SpecSelectDisplayDataItemForm'
import PropTypes from "prop-types";

export default class DDTCanvasUIControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      displayUploadForm: false,
      showSpecSelectDisplayDataItemForm: false,
      isDisplayDataItemsExpanded: false,
      toggleDisplayDataItemExpandMode: this.toggleDisplayDataItemExpandMode.bind(this),
    };
    this.showUploadAttachmentForm = this.showUploadAttachmentForm.bind(this);
    this.hideUploadAttachmentForm = this.hideUploadAttachmentForm.bind(this);
    this.onUploadAttachmentSuccess = this.onUploadAttachmentSuccess.bind(this);
    this.showSpecSelectDisplayDataItemForm = this.showSpecSelectDisplayDataItemForm.bind(this);
    this.hideSpecSelectDisplayDataItemForm = this.hideSpecSelectDisplayDataItemForm.bind(this);
    this.createMarker = this.createMarker.bind(this);
    this.highlightItem = this.highlightItem.bind(this);
    this.updateReportDisplayItem = this.updateReportDisplayItem.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      reportDisplayItems: nextProps.reportDisplayItems,
    });
  }

  onUploadAttachmentSuccess(attachmentId) {
    const self = this;

    const url = this.getControllerUrl();
    $j.ajax({
      url: url + '/' + attachmentId + '/show_link',
      type: 'GET',
      dataType: 'JSON',
      success: (data) => {
        self.showMockupImage(`${data.img_src}?${new Date().getTime()}`, data.description); // Invalidates the browser cache.
      },
      error: () => {
        // If an error happens, the image will not be displayed
      },
    });
  }

  getControllerUrl() {
    let url = window.location.href;
    if (url.indexOf('/versions') === -1) {
      url = url.substr(0, url.indexOf('/display_details')) + '/versions/' + this.props.reportVersion.id + '/display_details_attachment';
    } else {
      url = 'display_details_attachment';
    }
    return url;
  }

  showUploadAttachmentForm() {
    this.setState({
      displayUploadForm: true,
    });
  }

  hideUploadAttachmentForm() {
    this.setState({
      displayUploadForm: false,
    });
  }

  showMockupImage(path, description) {
    this.setState({
      mockupImagePath: path,
      mockupImageDescription: description,
      displayUploadForm: false,
    });
  }

  showSpecSelectDisplayDataItemForm(leftPos, topPos, item) {
    this.setState({
      displaySpecSelectDataItemForm: true,
      reportDisplayItem: item,
      newMarker: {
        leftPos,
        topPos,
      },
    });
  }

  hideSpecSelectDisplayDataItemForm() {
    this.setState({
      displaySpecSelectDataItemForm: false,
      newMarker: null,
    });
  }

  createMarker(reportDisplayItem) {
    this.updateReportDisplayItem(reportDisplayItem);
  }

  updateReportDisplayItem(reportDisplayItem) {
    const { report, reportVersion } = this.props;
    const { reportItem } = reportDisplayItem;
    const data = {
      report_display_item: {
        id: reportDisplayItem.id,
        report_version_id: reportDisplayItem.reportVersionId,
        notes: reportDisplayItem.notes,
        location: reportDisplayItem.location,
        aggregate_notes: reportDisplayItem.aggregateNotes,
        report_item_id: reportDisplayItem.reportItemId,
        report_item: {
          id: reportItem.id,
          term_id: reportItem.termId,
          erp_system_id: reportItem.erpSystemId,
          object_label: reportItem.objectLabel,
        },
        left_pos: reportDisplayItem.leftPos,
        top_pos: reportDisplayItem.topPos,
      },
    };

    const url = `/institution/reports/${report.id}/versions/${reportVersion.id}/report_display_items/${reportDisplayItem.id}`;

    $j.ajax({
      url,
      method: 'PATCH',
      data,
      success: response => {
        const { reportDisplayItems } = this.state;
        const newRDIs = reportDisplayItems.map((rdi) => {
          if (rdi.id !== reportDisplayItem.id) {
            return Object.assign({}, rdi, { highlighted: false });
          }
          return reportDisplayItem;
        });
        this.setState({
          reportDisplayItems: newRDIs,
          moving: false,
        });
        this.hideSpecSelectDisplayDataItemForm();
      },
    }).error((xhr) => {
      if (xhr.responseJSON.errors) {
        this.setState({ errors: xhr.responseJSON.errors });
      } else {
        window.location.reload();
      }
    });
  }

  highlightItem(reportDisplayItem) {
    const { reportDisplayItems } = this.state;
    const newItems = reportDisplayItems.map((item) => {
      const itm = Object.assign({}, item, { highlighted: false });
      itm.highlighted = item.id === reportDisplayItem.id;
      return itm;
    });
    this.setState({
      reportDisplayItems: newItems,
    });
  }

  toggleDisplayDataItemExpandMode(e) {
    const { isDisplayDataItemsExpanded } = this.state;

    this.setState({
      isDisplayDataItemsExpanded: !isDisplayDataItemsExpanded,
    });
  }

  render() {
    const { displayUploadForm } = this.state;
    const { mockupImagePath } = this.state;
    const { mockupImageDescription } = this.state;
    const controllerUrl = this.getControllerUrl();

    return (
      <div className="display-data-items-container">
        <SpecReportDisplayItems
          onRemoveDisplayDataItem={this.state.setDisplayDataItems}
          onUpdateDisplayDataItem={this.state.setDisplayDataItems}
          expandable
          {...this.state}
        />
        <SpecReportDisplayImageComponent
          {...this.state}
          onImageAdd={this.showUploadAttachmentForm}
          imageUrl={mockupImagePath}
          imageDescription={mockupImageDescription}
          showSpecSelectDisplayDataItemForm={this.showSpecSelectDisplayDataItemForm}
          onSelectMarker={this.highlightItem}
          onDragMarker={this.createMarker}
        />
        <DisplayDetailsAttachmentUploadForm
          {...this.state}
          display={displayUploadForm}
          onUploadSuccess={this.onUploadAttachmentSuccess}
          onCancel={this.hideUploadAttachmentForm}
          controllerUrl={controllerUrl}
        />
        <SpecSelectDisplayDataItemForm
          onCancel={this.hideSpecSelectDisplayDataItemForm}
          onCreate={this.createMarker}
          {...this.state}
        />
      </div>
    );
  }
}

DDTCanvasUIControl.propTypes = {
  report: PropTypes.object.isRequired,
};
