import React from 'react';
//import PropTypes from 'prop-types';
import { dropdownRender } from '../FunctionalAreas/FunctionalAreasUtils.js';

export default class SingleFunctionalAreaSelector extends React.Component {
  constructor(props) {
    super(props);
    const options = this.createOptions(this.props.functionalAreas),
          flatOptions = this.createFlattenOptions(this.props.functionalAreas);
    this.state = {
      options,
      flatOptions,
      searchString: '',
      selectedFunctionalArea: undefined,
      defaultSelected: this.getDefaultSelectedValue(options),
      visible: false,
    };
  }

  /**
   * returns an array of values which is required to autoexpanding the first item in the list
   * @param options
   * @returns {Array}
   */
   getDefaultSelectedValue(options) {
    if (_.isEmpty(options))
      return [];


    /**
     * options[0] is institution-wide Functional Area,
     * root node of all functional areas
     */

    return [_.first(options).value];
  }

  preselectedFunctionalArea() {
    return this.state.selectedFunctionalArea ? this.state.selectedFunctionalArea.label : this.props.preselectedFunctionalArea?.[0]?.label
  }

  createOptions = functionalAreas =>
    functionalAreas.map(fa => this.parseFunctionalArea(fa));

  parseFunctionalArea = (fa, path) => {
    const itemPath = [path, fa.id];
    if (_.isEmpty(fa.children)) {
      return {
        value: fa.id,
        label: fa.name,
        description: fa.description,
        path: itemPath,
        disabled: !fa.availableToDrilldown,
        faAvailable: fa.available,
      };
    }

    return {
      value: fa.id,
      label: fa.name,
      description: fa.description,
      path: itemPath,
      faAvailable: fa.available,
      children: fa.children.map(childArea =>
        this.parseFunctionalArea(childArea, itemPath)),
    };
  };

  parseFlatFunctionalArea = (fa, path) => {
    let result = [];
    path = path || [];
    const itemPath = [...path, fa.id];

    if (fa.available === true) {
      result.push({
        value: fa.id, label: fa.name, description: fa.description, path: itemPath, faAvailable: fa.available,
      });
    }


    if (!_.isEmpty(fa.children)) {
      for (let i = 0; i < fa.children.length; i += 1) {
        result = _.concat(
          result,
          this.parseFlatFunctionalArea(fa.children[parseInt(i)], itemPath),
        );
      }
    }
    return result;
  };

  createFlattenOptions = (functionalAreas) => {
    let result = [];
    for (let i = 0; i < functionalAreas.length; i += 1) {
      const fa = functionalAreas[i];
      result = _.concat(result, this.parseFlatFunctionalArea(fa));
    }
    return _.uniqBy(result, 'value');
  };

  onFunctionalAreaChange = (value, selectedOptions) => {
    const selectedOne = selectedOptions.last();

    if (selectedOne.faAvailable === true) {
      this.setState({
        searchString: '',
        selectedFunctionalArea: selectedOne,
      });
      this.props.selectedValueHandler([selectedOne])
    } else {
      alert('Your user does not have access to this functional area.');
      return null;
    }
  };

  onSearchStringChange = (e) => {
    const searchString = e.target.value;
    this.setState({
      searchString,
      visible: true,
    });
  };

  onSearchKeyDown = (e) => {
    // Prevents the closing of the parent element (Cascader) by Backspase button
    switch (e.keyCode) {
      case KeyCode.BACKSPACE:
        e.stopPropagation();
        break;
      case KeyCode.ESC:
        this.setState({
          visible: false,
        });
        break;
      default:
        break;
    }
  };

  preventPageScroll = (e) => {
    const { searchInput } = this;
    switch (e.keyCode) {
      case KeyCode.UP:
      case KeyCode.DOWN:
      case KeyCode.LEFT:
      case KeyCode.RIGHT:
        e.preventDefault();
        break;
      case KeyCode.ENTER:
        break;
      case KeyCode.BACKSPACE:
      case KeyCode.ESC:
      default:
        searchInput.focus();
        break;
    }
  };

  togglePopupVisibility = () => {
    const { visible } = this.state;
    this.setState({
      visible: !visible,
    });
  };

  onPopupVisibleChange = (visible) => {
    this.setState({
      visible,
    });
  };


  getOptions = () => {
    const {
      searchString,
      selectedFunctionalArea,
      options,
      flatOptions,
    } = this.state;
    if (searchString && !selectedFunctionalArea) {
      const searchLowCase = searchString.toLowerCase();
      return flatOptions.filter(option =>
        option.label.toLowerCase().include(searchLowCase));
    }
    return options;
  };

  render() {
    const {
        searchString,
        selectedFunctionalArea,
        defaultSelected,
        visible,
      } = this.state,
      { editable, labelTarget } = this.props,
      opts = this.getOptions();

    return (
      <div className="cascader-container">
        <Cascader
          options={opts}
          expandIcon=""
          expandTrigger="hover"
          changeOnSelect
          onChange={this.onFunctionalAreaChange}
          onPopupVisibleChange={this.onPopupVisibleChange}
          popupVisible={visible}
          value={defaultSelected}
          dropdownRender={menus => dropdownRender(menus, this.props.functionalAreas)}
        >
          <span className="ui-combobox">
            <input
              className="ui-widget-content ui-combobox-input ui-widget ui-corner-left"
              placeholder="Select..."
              autoComplete="off"
              onClick={this.togglePopupVisibility}
              onKeyDown={this.onSearchKeyDown}
              readOnly={true}
              value={this.preselectedFunctionalArea()}
              aria-label="Type or Select Functional Area"
              id={`${this.props.parentObjectType}_functional_areas`}
            />

            <a
              tabIndex="-1"
              title="Show All Functional Areas"
              className={`ui-button ui-widget ui-state-default
                          ui-button-icon-only ui-corner-right
                          ui-combobox-toggle`}
              role="button"
            >
              <span className="ui-button-icon-primary ui-icon ui-icon-triangle-1-s"
                    role="img"
                    aria-label="Show all Functional Areas" />
              <span className="ui-button-text" />
            </a>
          </span>
        </Cascader>
      </div>
    );
  }
}
