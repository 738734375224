import React from "react";
import PropTypes from "prop-types";
import { dropdownRender } from "./FunctionalAreasUtils.js";

export default class FunctionalAreasSidebarDrillDown extends React.Component {
  constructor(props) {
    super(props);
    const {
      selectedFunctionalAreas,
      functionalAreaMembershipsPath
    } = this.props;
    const options = this.createOptions(this.props.functionalAreas);
    const flatOptions = this.createFlattenOptions(this.props.functionalAreas);
    this.state = {
      searchString: "",
      options,
      flatOptions,
      selectedFunctionalArea: null,
      defaultSelected: this.getDefaultSelectedValue(options),
      selectedFunctionalAreas: this.getSortedFunctionalAreasImmutableList(selectedFunctionalAreas),
      functionalAreaMembershipsPath,
      visible: false,
      showChangingWorkflowWarning: false,
      showChangingFaOnParentWarning: false,
      action: null,
      functionalAreaToRemove: null,
      parentWfdv: false,
      tempResponse: null,
    };
  }

  /**
   * returns an array of values which is required to autoexpanding the first item in the list
   * @param options
   * @returns {Array}
   */
  getDefaultSelectedValue(options) {
    if (_.isEmpty(options)) {
      return [];
    }

    /**
     * options[0] is institution-wide Functional Area,
     * root node of all functional areas
     */
    return [_.first(options).value];
  }

  getSortedFunctionalAreasImmutableList(functionalAreas) {
    return new Immutable.List(functionalAreas.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0)))
  }

  createOptions = functionalAreas => functionalAreas.map(fa => (this.parseFunctionalArea(fa)));

  parseFunctionalArea = (fa, path) => {
    path = path || [];
    const itemPath = [...path, fa.id];
    if (_.isEmpty(fa.children)) {
      return {
        value: fa.id,
        label: fa.name,
        description: fa.description,
        path: itemPath,
        disabled: !fa.availableToDrilldown,
        faAvailable: fa.available
      };
    }
    return {
      value: fa.id,
      label: fa.name,
      description: fa.description,
      path: itemPath,
      faAvailable: fa.available,
      children: fa.children.map(childArea => this.parseFunctionalArea(childArea, itemPath)),
    };
  };

  parseFlatFunctionalArea = (fa, path) => {
    let result = [];
    path = path || [];
    const itemPath = [...path, fa.id];

    if (fa.available === true) {
      result.push({ value: fa.id, label: fa.name, path: itemPath, description: fa.description, faAvailable: fa.available });
    }

    if (!_.isEmpty(fa.children)) {
      for (let i = 0; i < fa.children.length; i += 1) {
        result = _.concat(result, this.parseFlatFunctionalArea(fa.children[i], itemPath));
      }
    }
    return result;
  };

  createFlattenOptions = (functionalAreas) => {
    let result = [];
    for (let i = 0; i < functionalAreas.length; i += 1) {
      const fa = functionalAreas[i];
      result = _.concat(result, this.parseFlatFunctionalArea(fa));
    }
    return _.uniqBy(result, 'value');
  };

  getFunctionalAreaPropName = (parentObjectType) => {
    if (parentObjectType === 'term') {
      return 'functional_area[id]';
    } else if (parentObjectType === 'report') {
      return 'report_functional_area_membership[functional_area_id]';
    } else if (parentObjectType === 'quality_rule') {
      return 'functional_area[id]';
    } else if (parentObjectType === 'quality_issue') {
      return 'functional_area[id]';
    } else if (parentObjectType === 'collection') {
      return 'functional_area[id]';
    } else if (parentObjectType === 'valid_value_list_group') {
      return 'functional_area[id]';
    }
    console.error(`Undefined field name for: ${parentObjectType}`);
    return '';
  };

  handleObjectTypeAction = (action, functionalArea) => {
    const { embeddedQualityRule } = this.props
    const { selectedFunctionalArea } = this.state

    if (embeddedQualityRule && (selectedFunctionalArea || functionalArea)) {
      this.setState({
        showChangingFaOnParentWarning: true,
        action: action,
        functionalAreaToRemove: functionalArea || null
      });
    } else if (action === 'add') {
      this.addSelectedFunctionalArea()
    } else if (action === 'remove') {
      this.removeFunctionalAreaFromSelected(functionalArea)
    }
  }

  addSelectedFunctionalArea = (changeWDV) => {
    const {
      selectedFunctionalArea,
      selectedFunctionalAreas,
      functionalAreaMembershipsPath,
      options,
    } = this.state;

    const { parentObjectType } = this.props;

    if (!selectedFunctionalArea) {
      this.setState({
        searchString: '',
        defaultSelected: this.getDefaultSelectedValue(options),
      });
      return;
    }

    if (!selectedFunctionalAreas.find(fa => (fa.value === selectedFunctionalArea.value))) {
      const data = {};
      data[`${this.getFunctionalAreaPropName(parentObjectType)}`] = selectedFunctionalArea.value;

      if (changeWDV) data.change_wdv = true;
      $j.ajax({
        url: functionalAreaMembershipsPath,
        method: 'POST',
        data,
        success: (response) => {
          if (response.parent_wfdv_changed) {
            this.setState({
              showChangingWorkflowWarning: true,
              action: 'add',
              response: response.parent_wfdv_changed,
              tempResponse: response.wfdv_changed,
              parentWfdv: true,
            });
            return;
          } else if (response.workflow_definition_version_was) {
            this.setState({
              showChangingWorkflowWarning: true,
              action: 'add',
              response,
            });
            return;
          }

          if (changeWDV) {
            window.location.reload();
            $j('.ui-dialog').hide();
          }
          selectedFunctionalArea.functionalAreaMembershipId = response.functional_area_membership_id || response.functional_areaMembership_id;

          this.setState({
            searchString: '',
            selectedFunctionalAreas: selectedFunctionalAreas.push(selectedFunctionalArea).sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0)),
          });
        },
      }).error((xhr) => {
        console.error(xhr);
      });
    }
  };

  handleChangingWorkflowWarningConfirm = () => {
    const { action, functionalAreaToRemove, parentWfdv, tempResponse } = this.state;
    if (parentWfdv && tempResponse) {
      this.setState({
        response: tempResponse,
        parentWfdv: false
      });
    } else if (action === 'add') {
      this.addSelectedFunctionalArea(true);
    } else if (action === 'remove') {
      this.removeFunctionalAreaFromSelected(functionalAreaToRemove, true);
    }
  }

  handleChangingWorkflowWarningCancel = () => {
    this.setState({
      showChangingWorkflowWarning: false,
      selectedFunctionalArea: null,
      searchString: '',
      functionalAreaToRemove: null,
      parentWfdv: false,
      tempResponse: null,
    });
  }

  handleChangingFaOnParentObjectWarningCancel = () => {
    this.setState({
      showChangingFaOnParentWarning: false,
      action: null,
      functionalAreaToRemove: null,
    });
  }

  handleChangingFaOnParentObjectWarningConfirm = () => {
    const { action, functionalAreaToRemove } = this.state;
    this.setState({
      showChangingFaOnParentWarning: false,
    }, () => {
      if (action === 'add') {
        this.addSelectedFunctionalArea();
      } else if (action === 'remove') {
        this.removeFunctionalAreaFromSelected(functionalAreaToRemove);
      }
    });
  }

  onFunctionalAreaChange = (value, selectedOptions) => {
    const {
      selectedFunctionalAreas,
      options,
      searchString,
    } = this.state;
    const selectedFunctionalArea = selectedOptions.last();

    const selectedValue = _.isEmpty(searchString) ? value : this.getDefaultSelectedValue(options);
    if (!selectedFunctionalAreas.find(fa => (fa.value === selectedFunctionalArea.value))) {
      if (selectedFunctionalArea.faAvailable === true) {
        this.setState({
          searchString: selectedFunctionalArea.label,
          selectedFunctionalArea,
          defaultSelected: selectedValue, // HACK: auto expand for the first level of the menu
        });
      } else {
        alert("Your user does not have access to this functional area.");
        return null;
      }
    }
  };

  onSearchStringChange = (e) => {
    const searchString = e.target.value;
    this.setState({
      searchString,
      selectedFunctionalArea: null,
      visible: true,
    });
  };

  onSearchKeyDown = (e) => {
    // Prevents the closing of the parent element (Cascader) by Backspase button
    switch (e.keyCode) {
      case KeyCode.BACKSPACE:
        e.stopPropagation();
        break;
      case KeyCode.ESC:
        this.setState({
          visible: false,
        });
        break;
      default:
        break;
    }
  };

  preventPageScroll = (e) => {
    const { searchInput } = this;
    switch (e.keyCode) {
      case KeyCode.UP:
      case KeyCode.DOWN:
      case KeyCode.LEFT:
      case KeyCode.RIGHT:
        e.preventDefault();
        break;
      case KeyCode.ENTER:
        break;
      case KeyCode.BACKSPACE:
      case KeyCode.ESC:
      default:
        searchInput.focus();
        break;
    }
  };

  togglePopupVisibility = () => {
    const { visible } = this.state;
    this.setState({
      visible: !visible,
    });
  };

  onPopupVisibleChange = (visible) => {
    this.setState({
      visible,
    });
  };


  removeFunctionalAreaFromSelected = (functionalArea, changeWDV) => {
    const {
      selectedFunctionalAreas,
      functionalAreaMembershipsPath,
    } = this.state;
    const data = changeWDV ? { change_wdv: true } : null;
    $j.ajax({
      url: `${functionalAreaMembershipsPath}/${functionalArea.functionalAreaMembershipId}`,
      method: 'DELETE',
      data,
      success: (response) => {
        if (response.parent_wfdv_changed) {
          this.setState({
            showChangingWorkflowWarning: true,
            action: 'remove',
            response: response.parent_wfdv_changed,
            tempResponse: response.wfdv_changed,
            parentWfdv: true,
          });
          return;
        } else if (response.workflow_definition_version_was) {
          this.setState({
            showChangingWorkflowWarning: true,
            action: 'remove',
            functionalAreaToRemove: functionalArea,
            response,
          });
          return;
        }

        if (changeWDV) {
          window.location.reload();
          $j('.ui-dialog').hide();
        }

        if (response.flash_error) {
          this.setState({
            flashMessage: response.flash_error,
          });
          this.flashHolder.style.display = 'block';
          this.flashHolder.setAttribute('role', 'error');
          setTimeout(() => {
            $j(this.flashHolder).fadeOut(2500, () => {
              this.setState({
                flashMessage: '',
              });
              this.flashHolder.removeAttribute('role');
            });
          }, 1000);
        } else {
          const faIndex = selectedFunctionalAreas
            .findIndex(fa => fa.value === functionalArea.value);
          if (faIndex > -1) {
            this.setState({
              selectedFunctionalAreas: selectedFunctionalAreas.delete(faIndex),
            });
          }
        }
      },
    }).error((xhr) => {
      console.error(xhr);
    });
  };

  getFunctionalAreaLi = functionalArea => (
    <li
      key={`${functionalArea.label}:${functionalArea.value}`}
      id={`functional_area_${functionalArea.value}`}
      className="functional_area"
    >
      <a
        title={functionalArea.description || "No description available"}
        href={`/institution/functional_areas/${functionalArea.value}`}
      >
        {functionalArea.label}
      </a>
      <input
        type="button"
        className="remove remove_functional_area_button"
        title="Remove"
        aria-label="Remove Functional Area"
        onClick={() => this.handleObjectTypeAction('remove', functionalArea)}
      />
    </li>
  );

  getOptions = () => {
    const {
      searchString,
      selectedFunctionalArea,
      options,
      flatOptions,
    } = this.state;
    if (searchString && !selectedFunctionalArea) {
      const searchLowCase = searchString.toLowerCase();
      return flatOptions.filter(option => option.label.toLowerCase().include(searchLowCase));
    }
    return options;
  };

  getChangingWorkflowWarningMessage = () => {
    const { response, action, parentWfdv } = this.state;
    if (!response) return null;

    const workflowDefinitionVersionWas = JSON.parse(response.workflow_definition_version_was);
    const workflowDefinitionVersion = JSON.parse(response.workflow_definition_version);
    const workflowDefinitionChanged =
      workflowDefinitionVersion.workflow_definition_id ===
      workflowDefinitionVersionWas.workflow_definition_id;
      const parentMessage = parentWfdv ? 'of the parent definition' : ''
    return (
      <div>
        {_.capitalize(action)} this functional area and restart the workflow?
        <hr />
        This change affects which workflow applies.
        If you {action} this functional area, the workflow {parentMessage} will be changed from
        <a
          href={response.workflow_definition_version_was_path}
          title={workflowDefinitionVersionWas.workflow_definition.description}
          target="_blank"
        >
          {workflowDefinitionVersionWas.name}
          {workflowDefinitionChanged && ` (version ${workflowDefinitionVersionWas.version})`}
        </a>
        to
        <a
          href={response.workflow_definition_version_path}
          title={workflowDefinitionVersion.workflow_definition.description}
          target="_blank"
        >
          {workflowDefinitionVersion.name}
          {workflowDefinitionChanged && ` (version ${workflowDefinitionVersion.version})`}
        </a>
        and will be started from the beginning.
      </div>
    );
  };

  getChangingFaOnParentWarningMessage = () => {
    const { action } = this.state;
    return (
      <div>
        {_.capitalize(action)} this functional area?
        <hr />
        If you {action} this functional area of this quality attribute, it will also {action} this functional area on the parent definition. Do you wish to proceed?
      </div>
    );
  };

  render() {
    const {
      searchString,
      defaultSelected,
      selectedFunctionalAreas,
      flashMessage,
      visible,
      showChangingWorkflowWarning,
      showChangingFaOnParentWarning,
      parentWfdv,
    } = this.state;

    const opts = this.getOptions();
    const parentWfdvTitle = parentWfdv ? 'on Parent Definition' : ''

    return (
      <div
        className="cascader-container"
      >
        <ul id="functional_area_list" className="editable">
          { selectedFunctionalAreas.map(fa => (this.getFunctionalAreaLi(fa))) }
        </ul>
        <Cascader
          options={opts}
          expandIcon=''
          expandTrigger="hover"
          changeOnSelect
          onChange={this.onFunctionalAreaChange}
          onPopupVisibleChange={this.onPopupVisibleChange}
          popupVisible={visible}
          value={defaultSelected}
          dropdownRender={menus => dropdownRender(menus, this.props.functionalAreas)}
        >
          <span
            className="ui-combobox workflow-sidebar-section--hide-controls"
            ref={r => this.faDropdown = r}
          >
            <input
              className="ui-widget-content ui-combobox-input ui-widget ui-corner-left"
              placeholder="Type or Select..."
              autoComplete="off"
              value={searchString}
              onChange={this.onSearchStringChange}
              onClick={this.togglePopupVisibility}
              onKeyDown={this.onSearchKeyDown}
              ref={el => this.searchInput = el}
              aria-label='Type or Select Functional Area'
            />
            <a
              tabIndex="-1"
              title="Show All Items"
              className="ui-button ui-widget ui-state-default ui-button-icon-only ui-corner-right ui-combobox-toggle"
              role="button"
            >
              <span className="ui-button-icon-primary ui-icon ui-icon-triangle-1-s" role="img" />
              <span className="ui-button-text" />
            </a>
          </span>
        </Cascader>
        <input
          type="image"
          name="Add"
          value=""
          className="create_report_functional_area_membership"
          aria-label="Add Functional Area"
          onClick={() => this.handleObjectTypeAction('add')}
          src={this.props.iconAddUrl}
          alt="Add Functional Area"
        />
        <div
          style={{ display: 'none' }}
          ref={flashHolder => this.flashHolder = flashHolder}
          className="error notice-functional-area"
          dangerouslySetInnerHTML={{ __html: flashMessage }}
        />
        <ConfirmationPopup
          opened={showChangingWorkflowWarning}
          title={`Warning: Changing Workflow ${parentWfdvTitle}`}
          width={500}
          confirmButtonText="Yes"
          cancelButtonText="No"
          onConfirm={this.handleChangingWorkflowWarningConfirm}
          onCancel={this.handleChangingWorkflowWarningCancel}
        >
          {this.getChangingWorkflowWarningMessage()}
        </ConfirmationPopup>
        <ConfirmationPopup
          opened={showChangingFaOnParentWarning}
          title="Warning: Changing Functional Area on Parent Definition"
          width={500}
          confirmButtonText="Yes"
          cancelButtonText="No"
          onConfirm={this.handleChangingFaOnParentObjectWarningConfirm}
          onCancel={this.handleChangingFaOnParentObjectWarningCancel}
        >
          {this.getChangingFaOnParentWarningMessage()}
        </ConfirmationPopup>
      </div>
    );
  }

  componentDidMount() {
    /* The text input and dropdown sections of this component receive keyboard focus separately, and
    * needs two tab presses to navigate past it. Excluding this from the tab order lets the keyboard focus
    * only on the text input area unless the dropdown is opened with arrow keys, space, etc. */
    this.faDropdown.setAttribute("tabindex", "-1");
  }
}

FunctionalAreasSidebarDrillDown.propTypes = {
  functionalAreas: PropTypes.arrayOf(Object).isRequired,
  selectedFunctionalAreas: PropTypes.arrayOf(Object).isRequired,
  functionalAreaMembershipsPath: PropTypes.string.isRequired,
  parentObjectType: PropTypes.string.isRequired,
  embeddedQualityRule: PropTypes.bool
};
