class DMCProcessingStepForm extends React.Component {
  componentDidUpdate(_prevProps, _prevState) {
    let opened;
    try {
      opened = $j(this.el).dialog('isOpen');
    } catch (_) {}

    if (this.props.processingStep && !opened) {
      $j(this.el).dialog({
        resizable: false,
        height: 280,
        width: 470,
        close: this.props.onCancel
      });
    }
  }

  UNSAFE_componentWillUpdate(nextProps, _nextState) {
    if (!nextProps.processingStep)
      $j(this.el).dialog('destroy');
  }

  render() {
    const {
      onChange,
      processingStep,
      processingTypes,
      onCancel,
      onSave
    } = this.props;

    if (!processingStep)
      return null;

    return (
      <div
        className="dmc-new-processing-step"
        title={processingStep.id ? 'Edit a processing step' : 'Add a processing step'}
        ref={el => this.el = el}
      >
        <label className="dmc-new-processing-step__label">
          Type
          <select
            className="span-10 dmc-new-processing-step__type"
            name="processing_type_id"
            onChange={onChange}
            value={processingStep.processing_type_id}
          >
            <option value="" />
            {processingTypes.map(processingType =>
              (<option
                key={processingType.id}
                value={processingType.id}
              >
                {processingType.name}
               </option>))}
          </select>
        </label>

        <label className="dmc-new-processing-step__label">
          Note
          <textarea
            className="span-10 dmc-new-processing-step__note"
            name="note"
            rows="6"
            onChange={onChange}
            value={processingStep.note}
          />
        </label>

        <ul className="dmc-new-processing-step__actions">
          <li>
            <button onClick={onCancel}>
              Cancel
            </button>
          </li>

          <li>
            <button onClick={onSave}>
              Save
            </button>
          </li>
        </ul>
      </div>
    );
  }
}

export default DMCProcessingStepForm;
