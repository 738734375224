import {
  render,
  fireEvent,
  screen,
} from '@testing-library/react';
import selectEvent from 'react-select-event';
import RelatedToolsForm from './RelatedToolsForm';

mockSpecName();

describe('<RelatedToolsForm />', () => {
  describe('when form is rendered on report creation', () => {
    it('renders the component with version attributes form fields', async () => {
      const component = exercise(true, true);
      const extIdInput = await component.getPrimaryToolExtIdInput();
      expect(extIdInput.name).toEqual('report[version_attributes][report_version_tools_associations_attributes][0][spec_tool_ref_id]');
    });
  });

  it('renders the component with report_version form fields', async () => {
    const component = exercise(true, false);
    const extIdInput = await component.getPrimaryToolExtIdInput();
    expect(extIdInput.name).toEqual('report_version[report_version_tools_associations_attributes][0][spec_tool_ref_id]');
  });

  it('should render correct primary tool form', async () => {
    const component = exercise(true);
    const selector = await component.getPrimaryToolSelect();
    const extIdInput = await component.getPrimaryToolExtIdInput();
    const notes = await component.getPrimaryToolNotesInput();
    const copyBtn = await component.getPrimaryCopyBtn();
    const removeBtn = await component.getPrimaryRemoveBtn();
    expect(selector).toBeVisible();
    expect(extIdInput).toBeVisible();
    expect(notes).toBeVisible();
    expect(copyBtn).toBeVisible();
    expect(removeBtn).toBeVisible();
  });

  it('should render primary tool selected values', async () => {
    const component = exercise(true);
    const extIdInput = await component.getPrimaryToolExtIdInput();
    const notesInput = await component.getPrimaryToolNotesInput();
    const selectedPrimaryTool = component.getByText('primaryTool-1');
    expect(selectedPrimaryTool).toBeVisible();
    expect(extIdInput).toHaveValue('primaryExtId');
    expect(notesInput).toHaveValue('primaryToolUsageNoteText');
  });

  it('should have working copy button', async () => {
    const component = exercise(true);
    const remove = await component.getPrimaryRemoveBtn();
    const copy = await component.getPrimaryCopyBtn();
    expect(copy).toBeDisabled();
    fireEvent.click(remove);
    expect(copy).not.toBeDisabled();
    fireEvent.click(copy);
    const extIdInput = await component.getPrimaryToolExtIdInput();
    expect(extIdInput).toHaveValue('testReport');
  });

  it('should have working remove button', async () => {
    const component = exercise(true);
    const remove = await component.getPrimaryRemoveBtn();
    fireEvent.click(remove);
    const extIdInput = await component.getPrimaryToolExtIdInput();
    expect(extIdInput).toHaveValue('');
  });

  it('should render correct related tools form', async () => {
    const component = exercise(true);
    const selector = await component.getRelatedToolsSelect();
    const noteInput = await component.getRelatedToolsNotesInput();
    expect(selector).toBeVisible();
    expect(noteInput).toBeVisible();
  });

  it('should render correct related tools values', async () => {
    const component = exercise(true);
    const selected = await component.getRelatedToolsSelectedItems();
    const editBtns = await component.getRelatedToolsEditBtns();
    const removeBtns = await component.getRelatedToolsRemoveBtns();
    expect(selected.length).toEqual(2);
    expect(editBtns.length).toEqual(2);
    expect(removeBtns.length).toEqual(2);
    expect(selected[0]).toHaveValue('1');
    expect(selected[1]).toHaveValue('2');
  });

  it('should have correct related tools menu interaction', async () => {
    const component = exercise(true);
    const dropdownIcon = component.getByTitle('Show All Multiselect Options');
    fireEvent.click(dropdownIcon);
    const menuItems = screen.queryAllByRole('menuitem');
    expect(menuItems.length).toBeGreaterThan(0);
  });

  it('should add new related tool', async () => {
    const component = exercise(true);
    const dropdownIcon = component.getByTitle('Show All Multiselect Options');
    fireEvent.click(dropdownIcon);
    const menuItems = screen.queryAllByRole('menuitem');
    expect(menuItems.length).toBeGreaterThan(0);
    fireEvent.click(menuItems[0]);
    const selected = await component.getRelatedToolsSelectedItems();
    expect(selected.length).toEqual(3);
  });

  it('should remove related tool', async () => {
    const component = exercise(true);
    const removeBtns = await component.getRelatedToolsRemoveBtns();
    fireEvent.click(removeBtns[0]);
    const selected = await component.getRelatedToolsSelectedItems();
    expect(selected.length).toEqual(1);
  });

  it('should be able to edit related tool', async () => {
    const component = exercise(true);
    const editBtns = await component.getRelatedToolsEditBtns();
    fireEvent.click(editBtns[0]);
    const updateBtn = await component.getRelatedToolsUpdateBtn();
    const select = await component.getRelatedToolsUpdateSelect();
    const note = await component.getRelatedToolsNotesUpdateInput();
    expect(updateBtn).toBeVisible();
    expect(select).toBeVisible();
    expect(note).toBeVisible();
    fireEvent.click(updateBtn);
    const selected = await component.getRelatedToolsSelectedItems();
    expect(selected.length).toEqual(2);
  });

  it('should not render primary tool form if hidden', async () => {
    const component = exercise();
    const selector = await component.queryPrimaryToolSelect();
    const extIdInput = await component.queryPrimaryToolExtIdInput();
    const notes = await component.queryPrimaryToolNotesInput();
    const copyBtn = await component.queryPrimaryCopyBtn();
    const removeBtn = await component.queryPrimaryRemoveBtn();
    expect(selector).toBeNull();
    expect(extIdInput).toBeNull();
    expect(notes).toBeNull();
    expect(copyBtn).toBeNull();
    expect(removeBtn).toBeNull();
  });

  it('should not render related tools form if hidden', async () => {
    const component = exercise();
    const selector = await component.queryRelatedToolsSelect();
    const noteInput = await component.queryRelatedToolsNotesInput();
    expect(selector).toBeNull();
    expect(noteInput).toBeNull();
  });

  it('should not render selected dataTools', async () => {
    const component = exercise(true);
    const dropdownIcon = component.getByTitle('Show All Multiselect Options');
    fireEvent.click(dropdownIcon);
    const menuItems = screen.queryAllByRole('menuitem');
    expect(menuItems.length).toEqual(4);
    expect(menuItems.map(item => item.title)).toEqual(['tool3', 'test-name', 'Argos', 'Exel']);
  });

  it('should remove selected primary tool from related tools menu', async () => {
    const component = exercise(true);
    const dropdownIcon = component.getByTitle('Show All Multiselect Options');
    fireEvent.click(dropdownIcon);
    let menuItems = screen.queryAllByRole('menuitem');
    expect(menuItems.length).toEqual(4);
    expect(menuItems.map(item => item.title)).toEqual(['tool3', 'test-name', 'Argos', 'Exel']);
    await selectEvent.select(component.getByLabelText('Primary Tool'), 'tool3');
    fireEvent.click(dropdownIcon);
    menuItems = screen.queryAllByRole('menuitem');
    expect(menuItems.length).toEqual(4);
    expect(menuItems[0].title === 'tool3').toBeFalsy();
  });

  it('should add unselected primary tool to related tools menu', async () => {
    const component = exercise(true);
    const dropdownIcon = component.getByTitle('Show All Multiselect Options');
    fireEvent.click(dropdownIcon);
    let menuItems = screen.queryAllByRole('menuitem');
    expect(menuItems.length).toEqual(4);
    expect(menuItems.map(item => item.title)).toEqual(['tool3', 'test-name', 'Argos', 'Exel']);
    await selectEvent.select(component.getByLabelText('Primary Tool'), 'tool3');
    fireEvent.click(dropdownIcon);
    menuItems = screen.queryAllByRole('menuitem');
    expect(menuItems.length).toEqual(4);
    expect(menuItems[0].title === 'primaryTool-1').toBeTruthy();
  });

  it('should remove selected related tools from primary tool menu', async () => {
    const component = exercise(true);
    const dropdownIcon = component.getByTitle('Show All Multiselect Options');
    fireEvent.click(dropdownIcon);
    selectEvent.openMenu(component.getByLabelText('Primary Tool'));
    expect(component.getAllByText('relatedTool1').length).toEqual(1);
    expect(component.getAllByText('relatedTool2').length).toEqual(1);
  });
});

const exercise = (visible, reportCreation = false) => {
  const selectedPrimaryTool = { specToolRefId: 'primaryExtId', specToolNote: 'primaryToolUsageNoteText', dataTool: { id: 4, name: 'primaryTool-1' } };
  const dataTools = [
    { id: 1, name: 'relatedTool1' },
    { id: 2, name: 'relatedTool2' },
    { id: 3, name: 'tool3' },
    { id: 4, name: 'primaryTool-1' },
    { id: 5, name: 'test-name' },
    { id: 6, name: 'Argos' },
    { id: 7, name: 'Exel' }
  ];
  const selectedRelatedTools = [
    { specToolRefId: '', specToolNote: 'relatedNoteText1', dataTool: { id: 1, name: 'relatedTool1' } },
    { specToolRefId: '', specToolNote: 'relatedNoteText2', dataTool: { id: 2, name: 'relatedTool2' } }
  ];
  const component = render(<RelatedToolsForm
    dataTools={dataTools}
    selectedPrimaryTool={selectedPrimaryTool}
    selectedRelatedTools={selectedRelatedTools}
    relatedToolsVisible={visible}
    removeIconSrc="/remove_button"
    specName="testReport"
    reportCreation={reportCreation}
    helpPoints={{ point1: '', point2: '' }}
  />);

  return {
    ...component,

    async getPrimaryToolSelect() {
      return component.findByTestId('primarySelect');
    },

    async queryPrimaryToolSelect() {
      return component.queryByTestId('primarySelect');
    },

    async getPrimaryCopyBtn() {
      return component.findByTestId('copyBtn');
    },

    async queryPrimaryCopyBtn() {
      return component.queryByTestId('copyBtn');
    },

    async getPrimaryRemoveBtn() {
      return component.findByTestId('removeBtn');
    },

    async queryPrimaryRemoveBtn() {
      return component.queryByTestId('removeBtn');
    },

    async getPrimaryToolExtIdInput() {
      return component.findByTestId('externalIdInput');
    },

    async queryPrimaryToolExtIdInput() {
      return component.queryByTestId('externalIdInput');
    },

    async getPrimaryToolNotesInput() {
      return component.findByTestId('usageNote');
    },

    async queryPrimaryToolNotesInput() {
      return component.queryByTestId('usageNote');
    },

    async getRelatedToolsUpdateSelect() {
      return component.findByTestId('relatedUpdateSelect');
    },

    async getRelatedToolsUpdateBtn() {
      return component.findByTestId('updateBtn');
    },

    async getRelatedToolsSelectedItems() {
      return component.findAllByTestId('relatedToolSelected');
    },

    async getRelatedToolsRemoveBtns() {
      return component.findAllByTestId('relatedToolDelete');
    },

    async getRelatedToolsEditBtns() {
      return component.findAllByTestId('editBtn');
    },

    async getRelatedToolsSelect() {
      return component.findByTestId('relatedSelect');
    },

    async queryRelatedToolsSelect() {
      return component.queryByTestId('relatedSelect');
    },

    async getRelatedToolsNotesInput() {
      return component.findByTestId('relatedNoteInput');
    },

    async queryRelatedToolsNotesInput() {
      return component.queryByTestId('relatedNoteInput');
    },

    async getRelatedToolsNotesUpdateInput() {
      return component.findByTestId('updateNoteInput');
    },

    async getDropdownButton() {
      return component.findByTestId('primarySelectIcon');
    }
  };
};

function mockSpecName() {
  const nameField = document.createElement('input');
  nameField.setAttribute('id', 'report_version_name');
  nameField.setAttribute('value', 'testReport');
  document.body.appendChild(nameField);
}
