import OrderComponent from "../shared/OrderComponent";

export default class DraggableDataSystem extends React.Component {
  render() {
    const { item } = this.props;
    return (
      <div className="draggable-data-system special-gray-box">
        <div
          className="draggable-data-system_title"
          data-testid="order.listItem"
        >
          {item.title}
        </div>
        <div>
          <OrderComponent
            toggleOrderFieldMode={this.toggleOrderFieldMode}
            changePosition={this.props.sharedProps.changePosition}
            item={item}
            onRemove={this.props.sharedProps.onRemove}
            ariaLabel={"Data System position"}
            trashcanIconPath={this.props.sharedProps.trashcanIconPath}
            moveIconPath={this.props.sharedProps.moveIconPath}
            controlPlayIconPath={this.props.sharedProps.controlPlayIconPath}
            value={item.position}
          />
        </div>
      </div>
    );
  }
}
