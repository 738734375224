import React from 'react';
import PropTypes from 'prop-types';
import { dropdownRender } from './FunctionalAreasUtils.js';

export default class RelatedFunctionalAreaDrillDown extends React.Component {
  constructor(props) {
    super(props);
    const options = this.createOptions(this.props.functionalAreas),
      flatOptions = this.createFlattenOptions(this.props.functionalAreas),
      systemOfRecordsCheckedData = {};
    this.props.selectedFunctionalAreas.map(fa => systemOfRecordsCheckedData[fa.value] = !!fa.systemOfRecordId);
    this.state = {
      options,
      flatOptions,
      searchString: '',
      selectedFunctionalAreas: new Immutable.List(this.props.selectedFunctionalAreas),
      defaultSelected: this.getDefaultSelectedValue(options),
      visible: false,
      systemOfRecordsCheckedData,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const faIds = this.state.selectedFunctionalAreas
        .toArray()
        .map(fa => fa.value),
      prevFaIds = prevState.selectedFunctionalAreas
        .toArray()
        .map(fa => fa.value);

    if (!_.isEqual(faIds, prevFaIds))
      $j('.workflow-entry-points-form').submit();
  }

  /**
   * returns an array of values which is required to autoexpanding the first item in the list
   * @param options
   * @returns {Array}
   */
  getDefaultSelectedValue(options) {
    if (_.isEmpty(options))
      return [];


    /**
     * options[0] is institution-wide Functional Area,
     * root node of all functional areas
     */

    return [_.first(options).value];
  }

  createOptions = functionalAreas =>
    functionalAreas.map(fa => this.parseFunctionalArea(fa));

  parseFunctionalArea = (fa, path) => {
    const itemPath = [path, fa.id];
    if (_.isEmpty(fa.children)) {
      return {
        value: fa.id,
        label: fa.name,
        description: fa.description,
        path: itemPath,
        disabled: !fa.availableToDrilldown,
        faAvailable: fa.available,
      };
    }
    return {
      value: fa.id,
      label: fa.name,
      description: fa.description,
      path: itemPath,
      faAvailable: fa.available,
      children: fa.children.map(childArea =>
        this.parseFunctionalArea(childArea, itemPath)),
    };
  };

  parseFlatFunctionalArea = (fa, path) => {
    let result = [];
    path = path || [];
    const itemPath = [...path, fa.id];

    if (fa.available === true) {
      result.push({
        value: fa.id, label: fa.name, description: fa.description, path: itemPath,
      });
    }


    if (!_.isEmpty(fa.children)) {
      for (let i = 0; i < fa.children.length; i += 1) {
        result = _.concat(
          result,
          this.parseFlatFunctionalArea(fa.children[parseInt(i)], itemPath),
        );
      }
    }
    return result;
  };

  createFlattenOptions = (functionalAreas) => {
    let result = [];
    for (let i = 0; i < functionalAreas.length; i += 1) {
      const fa = functionalAreas[i];
      result = _.concat(result, this.parseFlatFunctionalArea(fa));
    }
    return _.uniqBy(result, 'value');
  };

  onFunctionalAreaChange = (value, selectedOptions) => {
    const { selectedFunctionalAreas, searchString, options } = this.state,
      selectedFunctionalArea = selectedOptions.last();

    let defaultSelected = null;

    if (!_.isEmpty(searchString))
      defaultSelected = this.getDefaultSelectedValue(options);
    else
      defaultSelected = value;

    if (
      !selectedFunctionalAreas.find(fa => fa.value === selectedFunctionalArea.value)
    ) {
      if (selectedFunctionalArea.faAvailable === true) {
        this.setState({
          searchString: '',
          selectedFunctionalAreas: selectedFunctionalAreas.push(selectedFunctionalArea),
          defaultSelected,
        });
      } else {
        alert('Your user does not have access to this functional area.');
        return null;
      }
    }
  };

  onSearchStringChange = (e) => {
    const searchString = e.target.value;
    this.setState({
      searchString,
      visible: true,
    });
  };

  onSystemOfRecordCheckboxChange = (e, idx) => {
    const { systemOfRecordsCheckedData } = this.state;
    systemOfRecordsCheckedData[idx] = e.target.checked;
    const newSystemOfRecordsCheckedData = systemOfRecordsCheckedData;
    this.setState({ systemOfRecordsCheckedData: newSystemOfRecordsCheckedData });
  }

  onRemoveClickHandler = (faIndex) => {
    const { selectedFunctionalAreas } = this.state;
    this.setState({
      selectedFunctionalAreas: selectedFunctionalAreas.delete(faIndex),
    });
  };

  onSearchKeyDown = (e) => {
    // Prevents the closing of the parent element (Cascader) by Backspase button
    switch (e.keyCode) {
      case KeyCode.BACKSPACE:
        e.stopPropagation();
        break;
      case KeyCode.ESC:
        this.setState({
          visible: false,
        });
        break;
      default:
        break;
    }
  };

  togglePopupVisibility = () => {
    const { visible } = this.state;
    this.setState({
      visible: !visible,
    });
  };

  onPopupVisibleChange = (visible) => {
    this.setState({
      visible,
    });
  };

  getHiddenFieldName = (parentObjectType, idx) => {
    if (parentObjectType === 'system_of_record')
      return `erp_system[system_of_record_memberships_attributes][${idx}][functional_area_id]`;
    else if (parentObjectType === 'related_functional_area')
      return `erp_system[related_functional_area_memberships_attributes][${idx}][functional_area_id]`;

    console.error(`Undefined field name for: ${parentObjectType}`);
    return '';
  };

  getHiddenFieldId = (parentObjectType, idx) => {
    if (parentObjectType === 'system_of_record')
      return `erp_system[system_of_record_memberships_attributes][${idx}][id]`;

    if (parentObjectType === 'related_functional_area')
      return `erp_system[related_functional_area_memberships_attributes][${idx}][id]`;

    return '';
  }

  getHiddenFieldDestroy = (parentObjectType, idx) => {
    if (parentObjectType === 'system_of_record')
      return `erp_system[system_of_record_memberships_attributes][${idx}][_destroy]`;

    if (parentObjectType === 'related_functional_area')
      return `erp_system[related_functional_area_memberships_attributes][${idx}][_destroy]`;

    return '';
  }

  getItemContainer = (fa, idx) => {
    const { parentObjectType, relatedObjectType } = this.props,
      { systemOfRecordsCheckedData } = this.state;
    return (
      <div className="item-container flex-box justify-between" key={`${fa.label}:${fa.value}`}>
        <div className="item-name width_30">
          <a href={`/institution/functional_areas/${fa.value}`} target="blank" title={`${fa.description || 'No description available'}`}>
            {fa.label}
          </a>
        </div>
        <div className="item-name text-align-center width_35_p">
          <input type="checkbox" onChange={e => this.onSystemOfRecordCheckboxChange(e, fa.value)} checked={systemOfRecordsCheckedData[fa.value]} />
        </div>
        <div className="btn-remove-item-container" style={{ borderRight: 0 }}>
          <input
            type="hidden"
            className="item-value"
            name={this.getHiddenFieldName(parentObjectType, idx)}
            value={fa.value}
          />
          <input
            type="hidden"
            className="item-value"
            name={this.getHiddenFieldId(parentObjectType, idx)}
            value={fa.relationId}
          />
          { systemOfRecordsCheckedData[fa.value] && (
            <div>
              <input
                type="hidden"
                className="item-value"
                name={this.getHiddenFieldName(relatedObjectType, idx)}
                value={fa.value}
              />
              { fa.systemOfRecordId && (
                <input
                  type="hidden"
                  className="item-value"
                  name={this.getHiddenFieldId(relatedObjectType, idx)}
                  value={fa.systemOfRecordId}
                />
              )}
            </div>
          )}
          { fa.systemOfRecordId && !systemOfRecordsCheckedData[fa.value] && (
            <div>
              <input
                type="hidden"
                className="item-value"
                name={this.getHiddenFieldId(relatedObjectType, idx)}
                value={fa.systemOfRecordId}
              />
              <input
                type="hidden"
                className="item-value"
                name={this.getHiddenFieldDestroy(relatedObjectType, idx)}
                value="1"
              />
            </div>
          )}
          {this.props.editable && (
            <button
              name="button"
              type="button"
              className="btn-remove-item"
              aria-label="remove item"
              onClick={() => this.onRemoveClickHandler(idx)}
            >
              <img
                src={this.props.removeIconSrc}
                alt="Remove field name"
                tabIndex="0"
              />
            </button>
          )}
        </div>
        <br />
      </div>
    );
  }

  getDeletedItemContainer = (fa, idx) => {
    const { parentObjectType, relatedObjectType } = this.props,
      index = idx + this.state.selectedFunctionalAreas.size;
    return (
      <div className="item-container" key={`${fa.label}:${fa.value}`}>
        <div className="span-2 btn-remove-item-container">
          <input
            type="hidden"
            className="item-value"
            name={this.getHiddenFieldId(parentObjectType, index)}
            value={fa.relationId}
          />
          <input
            type="hidden"
            className="item-value"
            name={this.getHiddenFieldDestroy(parentObjectType, index)}
            value="1"
          />
          <input
            type="hidden"
            className="item-value"
            name={this.getHiddenFieldId(relatedObjectType, index)}
            value={fa.systemOfRecordId}
          />
          <input
            type="hidden"
            className="item-value"
            name={this.getHiddenFieldDestroy(relatedObjectType, index)}
            value="1"
          />
        </div>
      </div>
    );
  };

  getOptions = () => {
    const {
      searchString,
      selectedFunctionalArea,
      options,
      flatOptions,
    } = this.state;
    if (searchString && !selectedFunctionalArea) {
      const searchLowCase = searchString.toLowerCase();
      return flatOptions.filter(option =>
        option.label.toLowerCase().include(searchLowCase));
    }
    return options;
  };

  render() {
    const {
        searchString,
        selectedFunctionalAreas,
        defaultSelected,
        visible,
      } = this.state,
      { editable, labelTarget } = this.props,
      opts = this.getOptions();

    return (
      <div className="cascader-container">
        <div className="selected-items-container">
          {selectedFunctionalAreas.map((fa, idx) =>
            this.getItemContainer(fa, idx))}
          {['settings', 'related_functional_area'].includes(this.props.parentObjectType) &&
          this.props.selectedFunctionalAreas &&
          this.props.selectedFunctionalAreas
            .filter(x => selectedFunctionalAreas.indexOf(x) < 0)
            .map((fa, idx) => this.getDeletedItemContainer(fa, idx))}
        </div>
        {editable && (
          <Cascader
            options={opts}
            expandIcon=""
            expandTrigger="hover"
            changeOnSelect
            onChange={this.onFunctionalAreaChange}
            onPopupVisibleChange={this.onPopupVisibleChange}
            popupVisible={visible}
            value={defaultSelected}
            dropdownRender={menus => dropdownRender(menus, this.props.functionalAreas)}
          >
            <span className="ui-combobox">
              <input
                className="ui-widget-content ui-combobox-input ui-widget ui-corner-left"
                placeholder="Type or Select..."
                autoComplete="off"
                id={labelTarget || null}
                value={searchString}
                onChange={this.onSearchStringChange}
                onClick={this.togglePopupVisibility}
                onKeyDown={this.onSearchKeyDown}
                ref={el => (this.searchInput = el)}
                aria-label="Type or Select Functional Area"
                id={`${this.props.parentObjectType}_functional_areas`}
              />
              <a
                tabIndex="-1"
                title="Show All Functional Areas"
                className="ui-button ui-widget ui-state-default ui-button-icon-only ui-corner-right ui-combobox-toggle"
                role="button"
              >
                <span className="ui-button-icon-primary ui-icon ui-icon-triangle-1-s" role="img" aria-label="Show all Functional Areas" />
                <span className="ui-button-text" />
              </a>
            </span>
          </Cascader>
        )}
      </div>
    );
  }
}

FunctionalAreasDrillDown.propTypes = {
  functionalAreas: PropTypes.arrayOf(Object).isRequired,
  parentObjectType: PropTypes.string.isRequired,
  relatedObjectType: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  rollUp: PropTypes.bool,
};

FunctionalAreasDrillDown.defaultProps = {
  editable: false,
  rollUp: false,
};
