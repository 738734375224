import DMCNewSource from './DMCNewSource';
import DMCOrdering from './DMCOrdering';
import LineageTooltip from './LineageTooltip';

class LinioComponent extends React.Component {
  constructor(props) {
    super(props);
    this.repaintLineage = this.repaintLineage.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.repaintLineage();
    }, 500);
    $j('#sidebar-toggle-area').on('click', e => this.handleClickSidebar(e, this));
  }

  handleClickSidebar(_event, linioComponent) {
    setTimeout(() => {
      linioComponent.repaintLineage();
    }, 500);
  }

  shouldComponentUpdate(nextProps) {
    return (
      JSON.stringify(this.props.lineage) !==
        JSON.stringify(nextProps.lineage) ||
      this.props.editable !== nextProps.editable
    );
  }

  componentDidUpdate() {
    this.repaintLineage();
  }

  getImagesForSourceOrTarget(node) {
    const { databaseImageSrc, textImageSrc } = this.props;

    const objectLabel =
      node.type === 'LinioSource' ? node.objectLabel : node.name;

    const erpSystemId =
      node.type === 'LinioSource'
        ? this.props.lineage.treeJson.erpSystemId
        : this.props.targetErpSystem
          ? this.props.targetErpSystem.id
          : null;

    const objectType = node.relatedObjectType || '';

    const images = [];
    const imageTypes = [];
    const lines = [];

    if (objectType.startsWith('DataModel')) {
      if (node.relatedObject) {
        lines.push(node.relatedObject.name);
        images.push({
          src: databaseImageSrc,
          alt: 'Data source icon'
        });
        imageTypes.push('DataModel');
      }
    }

    if (objectLabel && lines.length > 0 && (lines.length === 2 || !lines.includes(objectLabel))) {
      images.unshift({
        src: textImageSrc,
        alt: 'Text icon'
      });
      imageTypes.unshift('Label');
    }

    return [images, imageTypes];
  }

  repaintLineage() {
    const { plusImageSrc, plusImageHoverSrc, lineage, editable } = this.props;
    const { container } = this;
    const sourceDiv = this.sourceColumn;
    const sourceDivWidth = sourceDiv.offsetWidth;
    const linioDiv = this.linioColumn;
    const plusSize = 15;

    const data = lineage.treeJson;
    if (!data) return;
    data.isRoot = true;

    const fullWidth = editable
      ? container.offsetWidth * 0.99
      : container.offsetWidth;
    const rectWidth = 100;
    const largeRectWidth = 213;
    const rectHeight = 30;
    const padding = 6;
    const oneProcWidth = rectWidth + (padding * 2) + plusSize;

    const linesColor = '#444444';
    const linesWidth = 1.5;
    const targetErpSystemId =
      (this.props.targetErpSystem && this.props.targetErpSystem.id) || null;
    const sourceErpSystemId = this.props.lineage.treeJson.erpSystemId;

    const getTreeHeight = function(node) {
      let maxChildHeight = 0;
      if (node.children && node.children.length > 0) {
        maxChildHeight = Math.max.apply(
          null,
          node.children.map(child => getTreeHeight(child))
        );
      }
      return maxChildHeight + 1;
    };

    const [images, _] = this.getImagesForSourceOrTarget(data);

    const getSourcesCount = function(node) {
      let sourcesCount = 0;
      if (node.children) {
        node.children.forEach((child) => {
          sourcesCount += getSourcesCount(child);
        });
      } else
        sourcesCount = 1;

      node.sourcesCount = sourcesCount;
      return Math.max(sourcesCount, images.length);
    };

    const sourcesCount = getSourcesCount(data);

    let treeHeight = getTreeHeight(data);
    if (data.sourcesCount === 0) treeHeight++;
    const procsCount = treeHeight - 2;

    const isSidebarCollapsed = $j('#main-wrapper').hasClass('span-23');
    const fullWidthWithSideBar = isSidebarCollapsed ? fullWidth : 590;
    const horizontalSpaceBetweenColumns =
      (fullWidthWithSideBar / (treeHeight - 1) - rectWidth) / 4;
    const height = sourcesCount * (rectHeight + 35) || rectHeight + 35;

    d3.select(sourceDiv)
      .select('svg')
      .remove();
    d3.select(linioDiv)
      .select('svg')
      .remove();

    if (sourcesCount === 0)
      sourceDiv.style.height = '16px';
    else
      sourceDiv.style.height = '';

    const sourceContainer = d3
      .select(sourceDiv)
      .append('svg')
      .attr('width', sourceDivWidth)
      .attr('height', sourcesCount > 0 ? height : 1);
    const tooltip = new LineageTooltip(container);

    const recalcProcsWidth = (linioDivWidth) => {
      const minProcWidth = oneProcWidth * procsCount;
      if (procsCount > 3 && minProcWidth > linioDivWidth)
        return minProcWidth;

      return linioDivWidth;
    };

    const allProcsWidth = recalcProcsWidth(linioDiv.offsetWidth);
    const linioContainer = d3
      .select(linioDiv)
      .append('svg')
      .attr('width', allProcsWidth)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${-sourceDivWidth} , 0)`);

    const calculateNodesPositions = function(
      node,
      nodeLevel,
      yBegin,
      yEnd,
      root
    ) {
      let x = 0;
      if (nodeLevel === 0) {
        x =
          (fullWidth / treeHeight) * nodeLevel -
          largeRectWidth / 2 -
          horizontalSpaceBetweenColumns;
      } else if (root)
        x = fullWidth;
      else {
        const procStart = (allProcsWidth / procsCount) * (nodeLevel - 2);
        x = 225 + procStart + (allProcsWidth / procsCount / 2);
      }
      const y = (yBegin + yEnd) / 2;

      if (!node.children)
        x = padding + largeRectWidth / 2;

      node.x = x;
      node.y = y;

      if (node.children) {
        let y1 = yBegin;
        node.children.forEach((child) => {
          const part =
            node.sourcesCount === 0
              ? 1
              : child.sourcesCount / node.sourcesCount;
          const y2 = y1 + (yEnd - yBegin) * part;
          calculateNodesPositions(child, nodeLevel - 1, y1, y2, false);
          y1 = y2;
        });
      }

      if (node.type === 'LinioSource')
        node.canBeDeleted = node.canBeDeleted || data.sourcesCount === 1;
    };

    calculateNodesPositions(data, treeHeight, 0, height, true);

    const getTooltipContentForLineageTarget = (node) => {
      let html = '';
      const { reportItem } = node;
      const { dataModelObject } = reportItem;
      if (dataModelObject) {
        html += `<b>${dataModelObject.parentObject.dataSchemaVersion.name}.`;
        html += `${dataModelObject.parentObject.name}.`;
        html += `${dataModelObject.name}</b><br>`;
      }
      return html;
    };

    const getTooltipContentForLinioSource = (node) => {
      let html = '';
      const { relatedObject } = node;
      if (relatedObject) {
        html += `<b>${relatedObject.parentObject.dataSchemaVersion.name}.`;
        html += `${relatedObject.parentObject.name}.`;
        html += `${relatedObject.name}</b><br>`;
      }
      return html;
    };

    const getTooltipContent = (node) => {
      let html;
      if (node.type === 'LineageTarget')
        html = getTooltipContentForLineageTarget(node);
      else if (node.type === 'LinioSource')
        html = getTooltipContentForLinioSource(node);
      else {
        html = `<b>${node.name}</b>`;
        if (node.note)
          html += `<br>${node.note}`;
      }
      return html;
    };

    const getTextLines = (node, sourceErpSystemId, targetErpSystemId) => {
      if (node.type === 'LinioProcessing') return [node.name || ''];

      const objectLabel =
        node.type === 'LinioSource' ? node.objectLabel : node.name;
      const lines = [];

      if (node.relatedObject)
        lines.push(node.relatedObject.name || '');

      if (node.relatedObject && node.relatedObject.technicalRelationships) {
        let technicalRelationship;
        if (node.type === 'LineageTarget')
          technicalRelationship = node.relatedObject.technicalRelationships.find(techRel => techRel.dataSchema.erpSystemId === targetErpSystemId);
        else if (node.type === 'LinioSource')
          technicalRelationship = node.relatedObject.technicalRelationships.find(techRel => techRel.dataSchema.erpSystemId === sourceErpSystemId);

        if (technicalRelationship)
          lines.push(technicalRelationship.objectName || '');
      }
      if (objectLabel && (lines.length === 2 || !lines.includes(objectLabel)))
        lines.unshift(node.name);

      return lines;
    };

    const drawTextContent = (
      container,
      node,
      width,
      height,
      sourceErpSystemId,
      targetErpSystemId
    ) => {
      const lines = getTextLines(node, sourceErpSystemId, targetErpSystemId);
      const textLength = node.type === 'LinioProcessing' ? 8 : 14;

      lines.forEach((line, i) => {
        const text =
          line.substring(0, textLength) +
          (line.length > textLength ? '...' : '');
        container
          .append('text')
          .attr('x', node.x - width / 2 + 8)
          .attr('y', node.y - ((lines.length - 1) * 15) / 2 + i * 15)
          .attr('dy', '0.33em')
          .attr('dx', '1em')
          .attr('class', 'dmc-lineage__clickable dmc-lineage__text')
          .text(text)
          .on('mouseover', () => {
            tooltip.show(getTooltipContent(node));
          })
          .on('mouseout', () => {
            tooltip.hide();
          })
          .on('click', () => {
            const { onEditNode } = this.props;
            onEditNode(node, lineage);
          });
      });
    };

    const drawNode = (node, sourceErpSystemId, targetErpSystemId) => {
      if (!node) return;
      let container = sourceContainer;
      if (node.type === 'LinioProcessing')
        container = linioContainer;

      const width =
        node.type === 'LinioProcessing' ? rectWidth : largeRectWidth;
      let height = rectHeight;
      const textLines =
        getTextLines(node, sourceErpSystemId, targetErpSystemId).length - 1;
      const [images, _] = this.getImagesForSourceOrTarget(node);
      height += Math.max(textLines, images.length - 1) * 15;

      container
        .append('rect')
        .attr('x', node.x - width / 2)
        .attr('y', node.y - height / 2)
        .attr('width', width)
        .attr('height', height)
        .style('fill', '#ffffff')
        .style('stroke', linesColor)
        .style('stroke-width', linesWidth)
        .attr('class', 'dmc-lineage__clickable')
        .on('mouseover', () => {
          tooltip.show(getTooltipContent(node));
        })
        .on('mouseout', () => {
          tooltip.hide();
        })
        .on('click', () => {
          const { onEditNode } = this.props;
          onEditNode(node, lineage);
        });

      drawTextContent(
        container,
        node,
        width,
        height,
        sourceErpSystemId,
        targetErpSystemId
      );

      if (['LinioProcessing', 'LinioSource'].includes(node.type) && editable)
        drawDeleteIcon(node, container, width, height);

      if (['LinioSource', 'LineageTarget'].includes(node.type))
        drawNodeTypeIconForSourceOrTarget(node, container, width);
      else
        drawNodeTypeIcon(node, container, width);
    };

    const getNodeImage = (node) => {
      const { processingImageSrc, databaseImageSrc, textImageSrc } = this.props;

      const objectType = node.relatedObjectType || '';

      let image = {
        src: processingImageSrc,
        alt: 'Processing step icon'
      };
      if (node.type !== 'LinioProcessing') {
        if (objectType.startsWith('DataModel')) {
          image = {
            src: databaseImageSrc,
            alt: 'Database image icon'
          };
        } else {
          image = {
            src: textImageSrc,
            alt: 'Text image icon'
          };
        }
      }

      return image;
    };

    const drawNodeTypeIcon = (node, container, width) => {
      const imageSize = 15;
      const margin = 5;
      const image = getNodeImage(node);

      container
        .append('image')
        .attr('x', node.x - width / 2 + margin)
        .attr('y', node.y - imageSize / 2)
        .attr('width', imageSize)
        .attr('height', imageSize)
        .attr('xlink:href', image.src)
        .attr('alt', image.alt)
        .attr('class', 'dmc-lineage__clickable')
        .on('mouseover', () => {
          tooltip.show(getTooltipContent(node));
        })
        .on('mouseout', () => {
          tooltip.hide();
        })
        .on('click', () => {
          const { onEditNode } = this.props;
          onEditNode(node, lineage);
        });
    };

    const getPaddingSize = (imageSize, imagesCount, imageTypes, counter) => {
      let paddingY = 0;
      switch (imagesCount) {
        case 1:
          paddingY = imageSize / 2;
          break;
        case 2:
          paddingY = 15 - counter * 15;
          break;
        case 3:
          paddingY = 23 - counter * 15;
          break;
      }
      return paddingY;
    };

    const drawNodeTypeIconForSourceOrTarget = (node, container, width) => {
      const imageSize = 15;
      const margin = 5;
      const [images, imageTypes] = this.getImagesForSourceOrTarget(node);
      const imagesCount = images.length;

      images.forEach(function(image, i) {
        container
          .append('image')
          .attr('x', node.x - width / 2 + margin)
          .attr(
            'y',
            node.y - getPaddingSize(imageSize, imagesCount, imageTypes, i)
          )
          .attr('width', imageSize)
          .attr('height', imageSize)
          .attr('xlink:href', image.src)
          .attr('alt', image.alt)
          .attr('class', 'dmc-lineage__clickable')
          .on('mouseover', () => {
            tooltip.show(getTooltipContent(node));
          })
          .on('mouseout', () => {
            tooltip.hide();
          })
          .on('click', () => {
            const { onEditNode } = this.props;
            onEditNode(node, lineage);
          });
      });
    };

    const drawDeleteIcon = (node, container, width, height) => {
      const imageSize = 15;
      const margin = 2;

      let deleteIconSrc;
      if (node.canBeDeleted)
        deleteIconSrc = this.props.deleteImageSrc;
      else
        deleteIconSrc = this.props.deleteDisabledImageSrc;

      container
        .append('image')
        .attr('x', node.x + width / 2 - imageSize - margin)
        .attr('y', node.y - height / 2 + margin)
        .attr('width', imageSize)
        .attr('height', imageSize)
        .attr('xlink:href', deleteIconSrc)
        .attr('alt', 'Delete processing step')
        .attr('class', 'dmc-lineage__control')
        .on('mouseover', function() {
          d3.select(this).attr('xlink:href', deleteIconSrc);
        })
        .on('mouseout', function() {
          d3.select(this).attr('xlink:href', deleteIconSrc);
        })
        .on('click', () => {
          const { onDelete, lineage } = this.props;
          onDelete(node, lineage);
        });
    };

    const drawLine = function(x1, y1, x2, y2, container, dashed) {
      const line = container
        .append('line')
        .style('stroke', linesColor)
        .style('stroke-width', linesWidth)
        .attr('x1', x1)
        .attr('y1', y1)
        .attr('x2', x2)
        .attr('y2', y2);
      if (dashed)
        line.style('stroke-dasharray', '4, 4').style('stroke-width', 1);
    };

    const drawEdge = function(node1, node2, dashed) {
      const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
      const containers = isIE11
        ? [linioContainer]
        : [sourceContainer, linioContainer];

      const firstNodeWidth =
        node1.type === 'LinioSource' ? largeRectWidth : rectWidth;
      const secondNodeWidth = rectWidth;

      const x1 = node1.x + firstNodeWidth / 2;
      const y1 = node1.y;
      const x2 = node2.x - secondNodeWidth / 2;
      let y2 = node2.y;

      if (y1 !== y2)
        y2 = y1 < y2 ? y2 - 5 : y2 + 5;

      containers.forEach((container) => {
        drawLine(
          x1,
          y1,
          x2 - horizontalSpaceBetweenColumns,
          y1,
          container,
          dashed
        );
        drawLine(
          x2 - horizontalSpaceBetweenColumns,
          y1,
          x2 - horizontalSpaceBetweenColumns,
          y2,
          container,
          dashed
        );
        drawLine(
          x2 - horizontalSpaceBetweenColumns,
          y2,
          x2,
          y2,
          container,
          dashed
        );
      });
    };

    const drawPlus = (node, parentNode) => {
      let container = sourceContainer;
      if (node.type === 'LinioProcessing' || node.type === 'LinioSource')
        container = linioContainer;

      const margin = node.children
        ? horizontalSpaceBetweenColumns / 2
        : horizontalSpaceBetweenColumns * 1.5;
      if (lineage.id) {
        container
          .append('image')
          .attr('x', node.x + rectWidth / 2 + margin - plusSize / 2)
          .attr('y', node.y - plusSize / 2)
          .attr('width', plusSize)
          .attr('height', plusSize)
          .attr('xlink:href', plusImageSrc)
          .attr('class', 'dmc-lineage__control')
          .attr('alt', 'Add a processing step icon')
          .on('mouseover', function() {
            d3.select(this).attr('xlink:href', plusImageHoverSrc);
          })
          .on('mouseout', function() {
            d3.select(this).attr('xlink:href', plusImageSrc);
          })
          .on('click', () => {
            d3.event.stopPropagation();
            const { openNewProcessingStepForm, lineage } = this.props;
            openNewProcessingStepForm(node, parentNode, lineage);
          });
      }
    };

    const drawProcessingWithoutSourceControl = (node) => {
      if (!node.children || node.children.length > 0) return;
      const fakeSourceNode = {
        type: 'LinioSource',
        x: padding + largeRectWidth / 2,
        y: node.y
      };
      drawEdge(fakeSourceNode, node, true);
      !node.isRoot && editable && drawPlus(fakeSourceNode, node);
    };

    const drawSubtree = function(node, parentNode) {
      drawNode(node, sourceErpSystemId, targetErpSystemId);

      if (parentNode) {
        if (parentNode.type !== 'LineageTarget')
          drawEdge(node, parentNode);

        editable && drawPlus(node, parentNode);
      }

      drawProcessingWithoutSourceControl(node);

      if (node.children)
        node.children.forEach(child => drawSubtree(child, node));
    };

    drawSubtree(data);
  }

  findLastChildProcessing(node) {
    if (!node.children) return null;
    const lastChildren = node.children[node.children.length - 1];
    if (lastChildren && lastChildren.type === 'LinioProcessing')
      return lastChildren;

    return null;
  }

  findProcessingForNewSource(node) {
    if (!node || !node.children) return null;
    let lastChildProcessing = this.findLastChildProcessing(node);
    while (
      lastChildProcessing &&
      this.findLastChildProcessing(lastChildProcessing)
    )
      lastChildProcessing = this.findLastChildProcessing(lastChildProcessing);

    return lastChildProcessing;
  }

  getSourceErpSystem(erpSystemId) {
    const { sourceErpSystems } = this.props;
    if (!_.isEmpty(sourceErpSystems)) {
      if (sourceErpSystems.length === 1)
        return sourceErpSystems[0];

      return sourceErpSystems.find(erpSystem => erpSystem.id === erpSystemId);
    }
    return null;
  }

  render() {
    const { lineage, editable } = this.props;
    const target = lineage.treeJson;
    const { erpSystemId } = lineage ? lineage.treeJson : null;
    const firstProcessing = this.findProcessingForNewSource(target);
    const targetObject = firstProcessing || target;
    const canAddSource =
      firstProcessing || (target.children || []).length === 0;
    const sourceParams = {
      targetObjectId: targetObject.id,
      targetObjectType: targetObject.type,
      lineageId: lineage.id
    };
    const sourceErpSystem = this.getSourceErpSystem(erpSystemId);
    let sourceErpSystemName = null;
    let sourceErpSystemDescription = null;
    if (sourceErpSystem) {
      sourceErpSystemName = sourceErpSystem.name;
      sourceErpSystemDescription =
        sourceErpSystem.system_type || sourceErpSystem.systemType;
    }
    return (
      <div
        className="dmc-lineage"
        ref={el => (this.container = el)}
        data-id={lineage.id}
      >
        {editable && <div className="dmc__sortable-handle" />}

        <div style={{ display: 'table-cell' }}>
          <div className="dmc-lineage__source-column">
            <div ref={el => (this.sourceColumn = el)} />
            {editable && (
              <div className="dmc-lineage__new-source">
                <DMCNewSource
                  sourceParams={sourceParams}
                  canAddSource={canAddSource}
                  sourceErpSystemName={sourceErpSystemName}
                  sourceErpSystemDescription={sourceErpSystemDescription}
                  {...this.props}
                />
              </div>
            )}
            {!editable && sourceErpSystemName && (
              <div
                className="dmc-source__system_name"
                style={{ margin: 'auto' }}
                title={sourceErpSystemDescription}
              >
                {sourceErpSystemName}
              </div>
            )}
          </div>

          <div
            className="dmc-lineage__linio-column"
            ref={el => (this.linioColumn = el)}
          />

          {editable && (
            <div>
              <div className="dmc-lineage__general-control">
                <DMCOrdering lineage={lineage} {...this.props} />
                {lineage.id && (
                  <div className="dmc-lineage__delete-btn">
                    <a
                      href="#"
                      onClick={e => this.props.onLineageDelete(e, lineage)}
                    >
                      Delete
                    </a>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default LinioComponent;
