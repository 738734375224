import DMCSourceErpSystems from './DMCSourceErpSystems';
import DMCTargetErpSystem from './DMCTargetErpSystem';

function DataMappingControlHeader(props) {
  const browseErpSystem = (e, erpSystem, selectedElement) => {
    e.preventDefault();
    if (erpSystem['hasSchema?']) {
      const allowableObjectTypes = ['column'];
      const additional_data = {
        erpSystemId: erpSystem.id,
        button: { href: '/institution/data_systems/search' },
        allowableObjectTypes,
        erpSystemChangeAllowed: false,
        selectedElement
      };

      SchemaSearch.search(additional_data);
    }
  };

  const browseLink = (erpSystem, selectedElement) => {
    if (props.onlyLinio && !props.technicalDefinitionView)
      return '';

    const classString = erpSystem['hasSchema?'] ? 'pretty-button mini'
      : 'pretty-button mini disabled';
    const titleString = erpSystem['hasSchema?'] ? '' : 'There are no data model objects to browse for the selected data system';
    return (
      <a
        href="#"
        onClick={e => browseErpSystem(e, erpSystem, selectedElement)}
        className={classString}
        title={titleString}
      >
        Browse
      </a>
    );
  };
  const { visibleFields } = props;
  const showSourceErpsystem = visibleFields.includes('Source Data Systems') &&
    ((!props.dataModelObjectView && props.withSourceErpSystem) ||
      (props.technicalDefinitionView && props.editable));

  const showTargetErpSystem = visibleFields.includes('Target Data System') &&
    (!props.dataModelObjectView && props.withTargetErpSystem);

  return (
    <div className="dmc-header">
      { props.onlyLinio && props.technicalDefinitionView &&
        <div>
          <label style={{ display: 'inline-block' }}>Linio: </label>
          <HelpPoint helpPoint={props.helpPoints.point4} />
        </div>
      }
      { props.editableChanged &&
        <img
          src={props.imageEdit}
          style={{ verticalAlign: 'middle' }}
          onClick={props.toggleEditable}
        />
      }
      <br />
      { showSourceErpsystem &&
        <DMCSourceErpSystems
          browseLink={browseLink}
          {...props}
        />
      }

      { showTargetErpSystem &&
        <DMCTargetErpSystem
          browseLink={browseLink}
          {...props}
        />
      }
      {
        props.children
      }
      <div style={{ clear: 'both' }} />
    </div>
  );
}

export default DataMappingControlHeader;
