import ShowValidValueListTableForm from "./ShowValidValueListTableForm";
import ShowNonMasterForms from "./ShowNonMasterForms";
import OptimizedSelect from '../shared/OptimizedSelect';

export default class NewEmbeddedRuleValidValueForm extends React.Component {
  // TODO: Need to refactor this component with using camelCase for props
  constructor(props) {
    super(props)

    this.getSelectInputName = this.getSelectInputName.bind(this)
    this.getGroupCopyForEditing = this.getGroupCopyForEditing.bind(this)
    this.setNewLists = this.setNewLists.bind(this)
    this.getTechnicalRelationshipData = this.getTechnicalRelationshipData.bind(this)
    this.getListIdByValueId = this.getListIdByValueId.bind(this);

    this.state = {
      selectedId: props.selected_id || 'new',
      groups: props.groups,
      disableSelect: props.disable_select,
      erpSystems: props.erp_systems,
      erpSystemsTechInfoAccesible: props.erp_systems_tech_info_accesible,
      dataApplications: props.data_applications,
      limitValidValues: true,
      groupCopyForEditing: this.getGroupCopyForEditing(props.selected_id),
      showWorkflowBar: false,
      visibleTechnical: [],
      showForm: this.props.show_form,
      visibleValidValueSection: [],

      getSelectedGroup: this.getSelectedGroup.bind(this),
      getLists: this.getLists.bind(this),
      getMasterList: this.getMasterList.bind(this),
      getNonMasterLists: this.getNonMasterLists.bind(this),
      getMasterValues: this.getMasterValues.bind(this),
      getNonMasterValues: this.getNonMasterValues.bind(this),
      isMasterListPresent: this.isMasterListPresent.bind(this),
      removeList: this.removeList.bind(this),
      changeErpSystem: this.changeErpSystem.bind(this),
      changeListAttributes: this.changeListAttributes.bind(this),
      changeTechnicalInformationMode: this.changeTechnicalInformationMode.bind(this),
      addList: this.addList.bind(this),
      addValue: this.addValue.bind(this),
      addValues: this.addValues.bind(this),
      removeValue: this.removeValue.bind(this),
      onValueChange: this.onValueChange.bind(this),
      changeValueAttrs: this.changeValueAttrs.bind(this),
      onKeyPress: this.onKeyPress.bind(this),
      onPaste: this.onPaste.bind(this),
      setTechnicalRelationshipData: this.setTechnicalRelationshipData.bind(this),
      showAllMasterValidValues: this.showAllMasterValidValues.bind(this),
      toggleTechnical: this.toggleTechnical.bind(this),
      showTechnicalSection: this.showTechnicalSection.bind(this),
      getErpNameFromId: this.getErpNameFromId.bind(this),
      showValidValueSectionFromList: this.showValidValueSectionFromList.bind(this),
      toogleValidValueSection: this.toogleValidValueSection.bind(this)
    }
  }

  getErpNameFromId(id) {
    return this.state.erpSystems.find(s => s.id === id).name
  }

  showTechnicalSection(id) {
    return this.state.visibleTechnical.includes(id)
  }

  showValidValueSectionFromList(id) {
    return !this.state.visibleValidValueSection.includes(id)
  }

  toogleValidValueSection(e, id){
    e.preventDefault();

    const VisibleValidValueSection = [...this.state.visibleValidValueSection];
    const presentIndex = VisibleValidValueSection.indexOf(id);

    if (presentIndex >= 0) {
      VisibleValidValueSection.splice(presentIndex, 1);
    } else {
      VisibleValidValueSection.push(id);
    }

    this.setState({visibleValidValueSection: VisibleValidValueSection});
  }

  toggleTechnical(e, id) {
    e.preventDefault();

    const visibleTechnical = [...this.state.visibleTechnical];
    const presentIndex = visibleTechnical.indexOf(id);

    if (presentIndex >= 0) {
      visibleTechnical.splice(presentIndex, 1);
    } else {
      visibleTechnical.push(id);
    }

    this.setState({visibleTechnical: visibleTechnical});
  }

  showAllMasterValidValues(e) {
    e.preventDefault()
    this.setState({
      limitValidValues: !this.state.limitValidValues
    })
  }

  changeFormType = (selected) => {
    const selectedId = selected.value;

    this.setState({
      selectedId,
      groupCopyForEditing: this.getGroupCopyForEditing(selectedId),
    });
  };

  getGroupCopyForEditing(id) {
    if (+id) {
      return this.props.groups.find(gv =>
        gv.id === +id
      )
    } else {
      return {
        current_version: {
          valid_value_lists: [{
            id: Math.random(),
            name: 'Master',
            master: true,
            valid_values: [],
            technical_relationships: []
          }]
        }
      }
    }
  }

  getSelectedGroup() {
    return this.state.groupCopyForEditing
  }

  getSelectedGroupVersion() {
    if (this.props.valid_value_list_version) {
      return this.props.valid_value_list_version
    } else {
      return this.getSelectedGroup().current_version
    }
  }

  getLists() {
    return this.getSelectedGroupVersion().valid_value_lists
  }

  getMasterList() {
    return this.getLists().find(list => list.master)
  }

  getNonMasterLists() {
    return this.getLists().filter(list => !list.master)
  }

  isMasterListPresent() {
    return !!this.getMasterList()
  }

  getMasterValues() {
    return this.getMasterList().valid_values
  }

  getNonMasterValues() {
    return this.getNonMasterLists().map(l => l.valid_values)
  }

  getSelectInputName() {
    if (this.state.selectedId) {
      return `${this.props.name_param}[valid_value_list_group_id]`;
    }
  }

  removeList(id) {
    const newLists = this.
                      getLists().
                      filter(vvl => vvl.id !== +id)

    this.setNewLists(newLists)
  }

  setNewLists(lists) {
    const newGroup = {
      ...this.getSelectedGroup(),
      current_version: {
        ...this.getSelectedGroupVersion(),
        valid_value_lists: lists
      }
    }

    this.setState({
      groupCopyForEditing: newGroup
    })
  }

  changeErpSystem(e, listId) {
    const newLists = this.getLists().map(list => {
      if (list.id === listId) {
        return {...list, erp_system_id: +e.target.value}
      } else {
        return list
      }
    })

    this.setNewLists(newLists)
  }

  changeListAttributes(e, listId, attribute, value) {
    attribute = attribute || e.target.name;
    value = value === undefined ? e.target.value : value;

    const newLists = this.getLists().map(list => {
      if (list.id === listId) {
        return {...list, [attribute]: value}
      } else {
        return list
      }
    })

    this.setNewLists(newLists)
  }

  getTechnicalRelationshipData(codeColumn) {
    return {
      data_schema_id: codeColumn.parent_object.data_schema_version.data_schema.id,
      parent_name: codeColumn.parent_object.name,
      parent_type: 'DataModelTable',
      object_value: codeColumn.name,
      object_type: 'DataModelColumn',
    }
  }

  setTechnicalRelationshipData(list, params) {
    if (list.technical_relationships.length > 0 && params.codeColumn) {
      destroyRel = list.technical_relationships[0];
      if (list.technical_relationships[0].id) {
        list.destroyed_technical_relationships = list.technical_relationships;
      }
      list.technical_relationships = [this.getTechnicalRelationshipData(params.codeColumn)];
    } else {
      let column = (params.descColumn || params.codeColumn);
      let data_schema_id = column.parent_object.data_schema_version.data_schema.id;
      let same_parent = (list.technical_relationships.length > 0 && data_schema_id === list.technical_relationships[0].data_schema_id && column.parent_object.name === list.technical_relationships[0].parent_name);
      if (params.codeColumn || same_parent) {
        list.technical_relationships.push(this.getTechnicalRelationshipData(column));
      }
    }
  }

  changeTechnicalInformationMode(listId) {
    const newLists = this.getLists().map(list => {
      if (list.id === listId) {
        if (list.query_mode === 'query') {
          return {...list, query_mode: 'table_column', retrieve_values_query: null}
        } else {
          return {...list, query_mode: 'query'}
        }
      } else {
        return list
      }
    });
    this.setNewLists(newLists)
  }

  showValidValueListGVWorkflowBar(e, validValueGroupVersion){
    e.preventDefault();
    $j('.validvaluelistgroup_version-' + validValueGroupVersion.id ).toggle();

    this.setState({
      showWorkflowBar: !this.state.showWorkflowBar
    })
  }

  addList() {
    const newLists = this.getLists().concat({
      id: Math.random(),
      valid_value_list_group_version_id: this.getSelectedGroupVersion().id,
      valid_values: [],
      erp_system_id: this.state.erpSystems[0].id,
      query_mode: 'table_column'
    })

    this.setNewLists(newLists)
  }

  addValue(listId) {
    const newLists = this.getLists().map(list => {
      if (list.id === listId) {
        const newValues = list.valid_values.concat({
          id: Math.random(),
          valid_value_list_id: list.id,
          value: '',
          description: ''
        })

        return {
          ...list,
          valid_values: newValues
        }
      } else {
        return list
      }
    })

    this.setNewLists(newLists)
  }

  addValues(listId, values) {
    const newLists = this.getLists().map(list => {
      if (list.id === listId) {
        const newValues = list.valid_values.concat(values.map((val) => {
          return {
            id: Math.random(),
            valid_value_list_id: list.id,
            value: val.value || '',
            description: val.description || ''
          }
        }))

        return {
          ...list,
          valid_values: newValues
        }
      } else {
        return list
      }
    })

    this.setNewLists(newLists)
  }

  removeValue(e, valueId) {
    const newLists = this.getLists().map(list => {
      const value = list.valid_values.find(v => v.id === +valueId)
      if (value) {
        const newValidValues = list.valid_values.filter(value => (
          value.id !== +valueId
        ))

        return {
          ...list,
          valid_values: newValidValues
        }
      } else {
        return list
      }
    })

    this.setNewLists(newLists)
  }

  changeValueAttrs(valueId, attrs) {
    const newLists = this.getLists().map(list => {
      const value = list.valid_values.find(v => v.id === +valueId)
      if (value) {
        const newValidValues = list.valid_values.map(value => {
          if (value.id === +valueId) {
            return {
              ...value,
              ...attrs
            }
          } else {
            return value
          }
        })

        return {
          ...list,
          valid_values: newValidValues
        }
      } else {
        return list
      }
    })

    this.setNewLists(newLists)
  }

  getListIdByValueId(valueId) {
    let listId = null;
    this.getLists().forEach(list => {
      const value = list.valid_values.find(v => v.id === +valueId);
      if (value) {
        listId = list.id;
      }
    });
    return listId
  }

  onValueChange(e, valueId) {
    this.changeValueAttrs(valueId, {[e.target.name]: e.target.value});
  }

  onKeyPress(e, valueId) {
    if (e.key === 'Enter') {
      e.preventDefault();
      let listId = this.getListIdByValueId(valueId)
      this.addValue(listId);
    }
  }

  onPaste(e, valueId) {
    let clipboardData = e.clipboardData || e.originalEvent.clipboardData || window.clipboardData;
    let pastedData = clipboardData.getData('text');

    let clipRows1 = pastedData.split("\r");
    let clipRows2 = pastedData.split("\n");
    let clipRows = clipRows1.length > clipRows2.length ? clipRows1 : clipRows2;

    for (let i = 0; i < clipRows.length; i++) {
      clipRows[i] = clipRows[i].split("\t");
    }

    const firstRow = clipRows[0];
    if (firstRow.length > 1 || clipRows.length > 1) {
      e.preventDefault();
      this.changeValueAttrs(+valueId, {value: firstRow[0], description: firstRow[1]});
      const listId = this.getListIdByValueId(+valueId);
      setTimeout(() => this.addValues(listId, clipRows.slice(1).map((r) => { return {value: r[0], description: r[1]}})), 0);
    }
  }

  handleCreateNew = () => {
    this.setState({
      showForm: true,
      selectedId: '',
      groupCopyForEditing: this.getGroupCopyForEditing(),
    });
  };

  handleCancelCreation = () => {
    this.setState({ showForm: false });
  };

  getValidValueListGroupForm = () => (
    <ValidValueListGroupForm
      nameParam={`${this.props.name_param}[valid_value_list_group_attributes]`}
      validValueListGroup={this.props.valid_value_list_group}
      validValueLists={this.props.valid_value_lists}
      displayLabel={this.props.valid_value_list_group_display_label}
      erpSelect={this.props.erp_select}
      disabledErpSystems={this.props.disabled_erp_systems}
      scheduleSelect={this.props.schedule_select}
      allValidValueLists={this.props.all_valid_value_lists}
      getValidValuesPath={this.props.get_valid_values_path}
      assetPaths={this.props.asset_paths}
      handleCancel={this.handleCancelCreation}
      currentUserId={this.props.current_user_id}
      usersForDesignatedRequesterSelect={this.props.users_for_designated_requester_select}
      vvlgf_help_points={this.props.vvlgf_help_points}
      currentDateFormat={this.props.currentDateFormat}
    />
  );

  render() {
    const { selectedId, disableSelect, erpSystemsTechInfoAccesible } = this.state;

    const masterList = this.getMasterList();
    let masterForm;
    if (masterList) {
      masterForm = (
        <ShowValidValueListTableForm
          key="masterform"
          {...this.state}
          validValueGroupVersion={this.getSelectedGroupVersion()}
          validValueList={masterList}
          master
          limitValidValues={this.state.limitValidValues}
          valid_value_list_show_page={this.props.valid_value_list_show_page}
          quality_rule_show_page={this.props.quality_rule_show_page}
          embedded_qr_page={this.props.embedded_qr_page}
          has_igs_module={this.props.has_igs_module}
          svvltf_help_points={this.props.svvltf_help_points}
          erpSystemsTechInfoAccesible={erpSystemsTechInfoAccesible}
          allLists={this.props.all_valid_value_lists}
        />
      );
    } else {
      masterForm = (
        <div
          className="span-15"
          key="masterform"
        >
          <h3>No master list defined</h3>
        </div>
      );
    }

    let nonMasterForms = '';
    if (this.props.valid_value_list_show_page) {
      nonMasterForms = (
        <ShowNonMasterForms
          key="nonmasterforms"
          {...this.state}
          valid_value_list_show_page={this.props.valid_value_list_show_page}
          validValueGroupVersion={this.getSelectedGroupVersion()}
          has_igs_module={this.props.has_igs_module}
          svvltf_help_points={this.props.svvltf_help_points}
          allLists={this.props.all_valid_value_lists}
        />
      );
    }

    let select
    if (!disableSelect) {
      const options = this.state.groups.map(g => ({ value: g.id, label: g.name }));
      const selectedOption = options.filter(option => option.value === selectedId);

      select = (
        <div>
          <div className="span-4">
            Reference Data:
            <HelpPoint helpPoint={this.props.svvltf_help_points.point8} />
          </div>
          { !this.state.showForm &&
            <div>
              <div className="span-7 f-l">
                <OptimizedSelect
                  options={options}
                  value={selectedOption}
                  onChange={this.changeFormType}
                  name={this.getSelectInputName()}
                  aria-label="reference data"
                />
              </div>
              { !this.props.from_embedded_quality &&
                <div className="span-3">
                  <button
                    onClick={this.handleCreateNew}
                    className="pretty-button med"
                  >
                    Create New
                  </button>
                </div>
              }
            </div>
          }
        </div>
      )
    }

    let label;
    const validValueGroupVersion = this.getSelectedGroupVersion();
    let phaseName = '';
    if (validValueGroupVersion.phase) {
      phaseName = validValueGroupVersion.phase.status.charAt(0).toUpperCase() + validValueGroupVersion.phase.status.slice(1)
    }
    if (disableSelect) {
      if (this.props.embedded_qr_page) {
        label = (
          <div>
            <h2 className='inline '>
              <a href={`/institution/valid_value_list_groups/${this.getSelectedGroup().id}`}>
                Reference Data: {validValueGroupVersion.name} {masterList ? '- Master List' : ''}
              </a>

              {validValueGroupVersion.phase &&
              <span
                className="quality-item-phase-label f-r"
                style={{color: 'white', backgroundColor: validValueGroupVersion.phase.styles.background}}
              >
                {phaseName}
              </span>
              }
            </h2>
          </div>
        )
      } else {
        label = (
          <div className="span-10">
            {validValueGroupVersion.phase && this.props.valid_value_lists_url &&
            <div className="workflow-bar span-6">
              <span
                className="workflow-bar__phase-box"
                style={{color: 'white', backgroundColor: validValueGroupVersion.phase.styles.background}}
              >
                {phaseName}
              </span>
              <a href={this.props.valid_value_lists_url + '/' + this.getSelectedGroup().id}>
                View/Edit
              </a>
            </div>
            }
          </div>
        )
      }
    }

    return (
      <div>
        {label}
        {select}
        {
          this.state.showForm && !this.props.from_embedded_quality ?
            this.getValidValueListGroupForm() :
            (masterList && this.getLists().length > 1 && this.props.embedded_qr_page ?
              [masterForm] : [masterForm, nonMasterForms])
        }
      </div>
    );
  }
}
