export default class DisplayModeSwitchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
    };
    this.switchDisplayMode = this.switchDisplayMode.bind(this);
  }

  componentDidUpdate(_prevProps, _prevState) {
    let opened = false;
    try {
      opened = $j(this.el).hasClass("ui-dialog-content") && $j(this.el).dialog('isOpen');
    } catch (e) {
      console.error(e);
    }

    if (!opened) {
      $j(this.el).dialog({
        modal: true,
        resizable: false,
        height: 250,
        width: 280,
        close: this.props.onCancel,
      });
    }
  }

  UNSAFE_componentWillUpdate(nextProps, _nextState) {
    const { visible } = nextProps;
    if (!visible) {
      $j(this.el).dialog('destroy');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      visible: nextProps.visible,
      mode: nextProps.mode,
    });
  }

  switchDisplayMode() {
    const { mode } = this.state;
    this.props.onSwitch(mode);
  }

  selectDisplayMode(e) {
    this.setState({
      mode: e.target.value,
    });
  }

  render() {
    const title = 'Select display mode';
    const { visible } = this.state;
    if (!visible) {
      return null;
    }
    const { mode } = this.state;

    return (
      <div
        title={title}
        ref={el => this.el = el}
      >
        <div>
          <p>Change Display Mode</p>
          <label>
            <input
              type="radio"
              name="mode"
              value="mockup"
              onChange={e => this.selectDisplayMode(e)}
              checked={mode === 'mockup'}
            />
            Image mock-up with pin drop
          </label>
          <label>
            <input
              type="radio"
              name="mode"
              value="attachment"
              onChange={e => this.selectDisplayMode(e)}
              checked={mode === 'attachment'}
            />
            Sample attachment
          </label>
          <label>
            <input
              type="radio"
              name="mode"
              value="text"
              onChange={e => this.selectDisplayMode(e)}
              checked={mode === 'text'}
            />
            Full text descriptions
          </label>
          <br />
          <input
            type="button"
            value="Switch"
            onClick={this.switchDisplayMode}
          />
        </div>
      </div>
    );
  }
}
