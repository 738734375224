import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import ReportPreviewTable from './ReportPreviewTable';
import TermPreviewTable from './TermPreviewTable';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';

const ObjectCollapsedPreview = (props) => {
  const { chevronIcons, 
          objectType, 
          hasErrors, 
          hasWarnings, 
          errors, 
          warnings, 
          statusMessage, 
          approvalStatusMessage, 
          actionMessage, 
          emptyRowMessage, 
          customFieldsHeaders, 
          customFieldsValues, 
          objectValues, 
          autoAproveActive, 
          approvalWarnings, 
          helpPoints,
          objectIndex,
          objectCustomFieldWarnings
        } = props;

  const [isExpanded, setIsExpanded] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [warningsPresent, setWarningsPresent] = useState(hasWarnings);
  const [warningsArr, setWarningsArr] = useState(warnings);

  useEffect(() => {
    if (autoAproveActive && (approvalWarnings.length > 0 || objectCustomFieldWarnings?.length > 0)) {
      setWarningsPresent(true);
      setWarningsArr(warnings.concat(approvalWarnings));
    } else {
      setWarningsPresent(hasWarnings);
      setWarningsArr(warnings);
    }
  }, [autoAproveActive]);

  const tables = {
    specification: { table: ReportPreviewTable, width: 2275, name: 'ReportPreviewTable' },
    definition: { table: TermPreviewTable, width: 840, name: 'TermPreviewTable' }
  };
  const Table = tables[objectType].table;
  const tableName = tables[objectType].name;
  const additionalColumnsWidth = 75 * customFieldsHeaders.length;
  const scrollContainerWidth = tables[objectType].width + additionalColumnsWidth;

  const onExpandableClick = () => {
    setIsExpanded(!isExpanded);
    setShowErrors(!isExpanded);
  };

  const onStatusLinkClick = () => {
    setShowErrors(!showErrors);
  };

  const getErrors = () => (
    <div className="error-recap round-border error">
      {errors.map((error, index) => <div key={`error-${index}`}>{parse(error)}<br /></div>)}
    </div>
  );

  const getWarnings = () => (
    <div className="notice">
      {warningsArr.map((warning, index) => <div key={`warning-${index}`}>{parse(warning)}<br /></div>)}
      {autoAproveActive && objectCustomFieldWarnings.map((warning, index) => <div key={`warning-${warningsArr.length + index}`}>{parse(warning)}<br /></div>)}
    </div>
  );

  const getErrorsAndWarnings = () => (
    <div>
      {hasErrors && getErrors()}
      {warningsPresent && getWarnings()}
    </div>
  );

  const handleTopScroll = (scroll) => {
    if(document.getElementById(`${tableName}-${objectIndex}`)) {
      document.getElementById(`${tableName}-${objectIndex}`).scrollLeft = scroll.target.scrollLeft;
    }
  };

  const handleBottomScroll = (scroll) => {
    if(document.getElementById(`topScrollContainer-${objectIndex}`)) {
      document.getElementById(`topScrollContainer-${objectIndex}`).scrollLeft = scroll.target.scrollLeft;
    }
  };

  return (
    <Accordion data-testid="collapsePreview">
      <AccordionItem dangerouslySetExpanded={isExpanded}>
        <AccordionItemHeading onClick={onExpandableClick}>
          <AccordionItemButton>
            <div className="accordion-header-container">
              <img className="chevron-img" src={isExpanded ? chevronIcons[0] : chevronIcons[1]} />
              <h2 className="accordion-header-text">{objectValues.name}</h2>
            </div>
          </AccordionItemButton>
        </AccordionItemHeading>
        <div>
          <div className="info-container">
            <div className="status-container">
              <div>Status: {autoAproveActive ? approvalStatusMessage : statusMessage}</div>
              {(hasErrors || warningsPresent) && <a className="status-link" onClick={onStatusLinkClick}>{showErrors ? 'hide' : 'show'}</a>}
            </div>
            <div>Action: {actionMessage}</div>
          </div>
          {(hasErrors || warningsPresent) && showErrors && getErrorsAndWarnings()}
        </div>
        {!objectValues.unrecognizedReportError
          ? (
            <AccordionItemPanel>
              <div className="topScrollContainer" key={`topScrollContainer-${objectIndex}`} id={`topScrollContainer-${objectIndex}`} onScroll={handleTopScroll} >
                <div className="topScroll" style={{ width: scrollContainerWidth }} />
              </div>
              <Table
                customFieldsHeaders={customFieldsHeaders}
                customFieldsValues={customFieldsValues}
                objectValues={objectValues}
                hasWarningsOrErrors={warningsArr.size > 0 || errors.size > 0}
                emptyRowMessage={emptyRowMessage}
                helpPoints={helpPoints}
                objectIndex={objectIndex}
                handleScroll={handleBottomScroll}
              />
            </AccordionItemPanel>
            )
          : null}
      </AccordionItem>
    </Accordion>
  );
};

export default ObjectCollapsedPreview;
