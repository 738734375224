import React from 'react';
import Select, { components } from 'react-select';
import HelpPoint from '../../Helpers/HelpPoint';
import Immutable from 'immutable';
import Cascader from 'rc-cascader';

const DropdownIndicator = () => (
  <a
    tabIndex="-1"
    title="Show All Related Tools Options"
    className="ui-button ui-widget ui-state-default ui-button-icon-only ui-corner-right optimized-toggle"
    role="button"
    data-testid="primarySelectIcon"
  >
    <span className="ui-button-icon-primary ui-icon ui-icon-triangle-1-s" role="img" aria-label="Show All Related Tools Options" />
    <span className="ui-button-text" />
  </a>
);

class RelatedToolsSelect extends React.Component {
  constructor(props) {
    super(props);
    const { options, selected } = this.createOptions(this.props.dataTools, this.props.selectedDataTools);
    const flatOptions = options;
    this.state = {
      options,
      flatOptions,
      searchString: '',
      selectedDataTools: new Immutable.List(selected),
      defaultSelected: this.getDefaultSelectedValue(options),
      currentNote: '',
      visible: false,
      editMode: null,
      editedNote: '',
      editSearchString: '',
      editedDataTool: null,
      editVisible: false
    };
  }

  componentDidUpdate(_prevProps, _prevState) {
    if (_prevProps.dataTools != this.props.dataTools) {
      const { options } = this.createOptions(this.props.dataTools, this.props.selectedDataTools);
      this.setState({
        options,
        flatOptions: options
      });
    }
  }

  /**
   * @param options
   * @returns {Array}
   */
  getDefaultSelectedValue(options) {
    if (_.isEmpty(options))
      return [];

    return [_.first(options).value];
  }

  createOptions = (dataTools, selectedDataTools) => {
    const prevOptions = dataTools.map(dt => this.parseData(dt));
    let options = [];
    let selected = [];
    if (selectedDataTools && selectedDataTools != undefined) {
      selected = this.getSelectedFromProps(selectedDataTools, prevOptions);
      options = prevOptions.filter(opt => !selected.includes(opt));
    } else
      options = prevOptions;

    return { options, selected };
  }

  getSelectedFromProps = (selectedDataTools, options) => {
    const filteredSelectedValues = [];
    selectedDataTools.map((tool) => {
      let selectedTool = tool.dataTool;
      selectedTool.note = tool.specToolNote;
      filteredSelectedValues.push(this.parseData(selectedTool));
    });
    return filteredSelectedValues;
  }

  parseData = dataTool => ({
    value: dataTool.id,
    label: dataTool.name,
    note: ''
  });

  onSelectChange = (value, selectedOptions) => {
    const { selectedDataTools, searchString, options, currentNote } = this.state;
    const { onChangeCallback } = this.props;
    const selectedTool = selectedOptions[selectedOptions.length - 1];

    let defaultSelected = null;

    if (!_.isEmpty(searchString))
      defaultSelected = this.getDefaultSelectedValue(options);
    else
      defaultSelected = value;

    if (
      !selectedDataTools.find(scfdv => scfdv.value === selectedTool.value)
    ) {
      if (selectedTool) {
        selectedTool.note = currentNote;
        onChangeCallback(selectedTool);

        this.setState({
          searchString: '',
          selectedDataTools: selectedDataTools.push(selectedTool),
          options: options.filter(opt => opt.value !== selectedTool.value),
          defaultSelected,
          currentNote: ''
        });
      }
    }
  };

  onSelectChangeEdit = (selectedDataTool, scfdvIndex) => {
    const { selectedDataTools, options, editedNote, editedDataTool } = this.state;
    const { onChangeCallback } = this.props;

    options.push(editedDataTool);
    selectedDataTool.note = editedNote;
    selectedDataTools[scfdvIndex] = selectedDataTool;

    if (selectedDataTool) {
      onChangeCallback([selectedDataTool, editedDataTool], true);
      this.setState({
        selectedDataTools,
        options: options.sort((a, b) => ((a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))),
        editSearchString: selectedDataTool.label,
        editedDataTool: selectedDataTool,
        editedNote: selectedDataTool.note
      });
    }
  };

  onSearchStringChange = (e, edit = false) => {
    const searchString = e.target.value;
    if (edit) {
      this.setState({
        editSearchString: searchString,
        editVisible: true
      });
    } else {
      this.setState({
        searchString,
        visible: true
      });
    }
  };

  onRemoveClickHandler = (scfdvIndex, option) => {
    const { selectedDataTools, options } = this.state;
    const { onChangeCallback } = this.props;
    onChangeCallback(option, true);
    options.push(selectedDataTools.get(scfdvIndex));
    selectedDataTools.delete(scfdvIndex);
    this.setState({
      selectedDataTools: selectedDataTools.delete(scfdvIndex),
      options: options.sort((a, b) => ((a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))),
      editMode: null
    });
  };

  onSearchKeyDown = (e) => {
    switch (e.keyCode) {
      case KeyCode.BACKSPACE:
        e.stopPropagation();
        break;
      case KeyCode.ESC:
        this.setState({
          visible: false,
          editVisible: false
        });
        break;
      default:
        break;
    }
  };

  preventPageScroll = (e) => {
    const { searchInput } = this;
    switch (e.keyCode) {
      case KeyCode.UP:
      case KeyCode.DOWN:
      case KeyCode.LEFT:
      case KeyCode.RIGHT:
        e.preventDefault();
        break;
      case KeyCode.ENTER:
        break;
      case KeyCode.BACKSPACE:
      case KeyCode.ESC:
      default:
        searchInput.focus();
        break;
    }
  };

  togglePopupVisibility = (edit = false) => {
    const { visible, editVisible } = this.state;
    if (edit) {
      this.setState({
        editVisible: !editVisible,
        visible: false
      });
    } else {
      this.setState({
        visible: !visible,
        editVisible: false
      });
    }
  };

  onPopupVisibleChange = (visible, edit = false) => {
    if (edit) {
      this.setState({
        editVisible: visible,
        visible: false
      });
    } else {
      this.setState({
        visible,
        editVisible: false
      });
    }
  };

  onEditClickHandler = (dataTool) => {
    this.setState({
      editMode: dataTool.value,
      editedNote: dataTool.note,
      editedDataTool: dataTool,
      editSearchString: dataTool.label
    });
  }

  onUpdateClickHandler = (dataTool) => {
    const { editedNote, editedDataTool } = this.state;
    dataTool.note = editedNote;
    dataTool.value = editedDataTool.value;
    dataTool.label = editedDataTool.label;
    this.setState({
      editMode: null,
      editedNote: '',
      editedDataTool: null,
      editSearchString: ''
    });
  }

  filterOption = (option, searchText) => {
    const { label } = option;

    if (
      label.toLowerCase().includes(searchText.toLowerCase())
    ) return true;

    return false;
  };

  getItemContainer = (sdt, idx, selectedDataTools) => {
    const { formObjectName } = this.props;
    const { editedNote, editMode, editedDataTool, flatOptions } = this.state;
    const controlStyle = {
      control: base => ({
        ...base, height: 32, minHeight: 32, maxHeight: 32
      }),
      menu: base => ({
        ...base, minWidth: 213, width: 'max-content'
      }),
      option: (base) => ({
        ...base, height: '32px', lineHeight: '32px', padding: '0 16px',
      })
    };

    return (
      <div className="multi-item-container related-tools-item" key={`${sdt.label}:${sdt.value}`}>
        {editMode === sdt.value
          ? <div className="related-tools-labels-container">
              <div className="related-tools-name-cell tools-optimizes-select">
                <div className="optimizedSelectWrap related-tool-edit-select" data-testid="relatedUpdateSelect">
                  <Select
                    value={editedDataTool}
                    onChange={value => this.onSelectChangeEdit(value, idx)}
                    maxMenuHeight={250}
                    options={flatOptions}
                    filterOption={this.filterOption}
                    components={{ DropdownIndicator }}
                    placeholder="Type or Select"
                    styles={controlStyle}
                    className="OptimizedSelect"
                    classNamePrefix="OptimizedSelect"
                  />
                </div>
              </div>
              <div className="related-tools-note-cell">
                <input className="tool-note-input-container" data-testid="updateNoteInput" type="text" value={editedNote} onChange={value => this.onChangeNote(value, true)} />
              </div>
              <div className="related-tools-note-cell">
                <button
                  data-testid="updateBtn"
                  name="button"
                  type="button"
                  className="btn-remove-item"
                  aria-label="remove item multiselect item"
                  onClick={() => this.onUpdateClickHandler(sdt)}
                >
                  Update
                </button>
              </div>
              <div className="btn-remove-item-container related-tools-note-cell">
                <input
                  data-testid="relatedToolSelected"
                  type="hidden"
                  className="item-value"
                  name={`${formObjectName}[value]`}
                  value={(selectedDataTools.map(sdt => sdt.value))}
                />
                <button
                  data-testid="relatedToolDelete"
                  name="button"
                  type="button"
                  className="btn-remove-item"
                  aria-label="remove item multiselect item"
                  onClick={() => this.onRemoveClickHandler(idx, sdt)}
                >
                  <img
                    src={this.props.removeIconSrc}
                    alt="Remove field name"
                    tabIndex="0"
                  />
                </button>
              </div>
            </div>
          : <div className="related-tools-labels-container">
              <div className="related-tools-name-cell item-name">
                <a href={`/institution/tools/${sdt.value}`} target="_blank">{sdt.label}</a>
              </div>
              <div className="related-tools-note-cell tool-note-input-container">
                <p className="item-description tool-note" target="blank">
                  {sdt.note}
                </p>
              </div>
              <div className="related-tools-note-cell">
                <button
                  data-testid="editBtn"
                  name="button"
                  type="button"
                  className="btn-tool-edit-item"
                  aria-label="remove item multiselect item"
                  onClick={() => this.onEditClickHandler(sdt)}
                >
                  Edit
                </button>
              </div>
              <div className="btn-remove-item-container related-tools-note-cell">
                <input
                  data-testid="relatedToolSelected"
                  type="hidden"
                  className="item-value"
                  name={`${formObjectName}[report_version_tools_associations_attributes][${idx + 1}][data_tool_id]`}
                  value={sdt.value}
                />
                <input
                  type="hidden"
                  className="item-value"
                  name={`${formObjectName}[report_version_tools_associations_attributes][${idx + 1}][spec_tool_note]`}
                  value={sdt.note}
                />
                <input
                  type="hidden"
                  className="item-value"
                  name={`${formObjectName}[report_version_tools_associations_attributes][${idx + 1}][primary_tool]`}
                  value={false}
                />
                <button
                  data-testid="relatedToolDelete"
                  name="button"
                  type="button"
                  className="btn-remove-item"
                  aria-label="remove item multiselect item"
                  onClick={() => this.onRemoveClickHandler(idx, sdt)}
                >
                  <img
                    src={this.props.removeIconSrc}
                    alt="Remove field name"
                    tabIndex="0"
                  />
                </button>
              </div>
            </div>
          }
      </div>
    );
  }

  getEmptyContainer = () => {
    const { formObjectName } = this.props;
    return (
      <div className="empty-item-container">
        <div>{this.state.selectedDataTools}</div>
        <input
          type="hidden"
          className="item-value"
          name=""
          value=""
        />
      </div>
    );
  }

  getOptions = (edit = false) => {
    const {
      searchString,
      editSearchString,
      selectedTool,
      options,
      flatOptions
    } = this.state;
    const searchQuery = edit ? editSearchString : searchString;

    if (searchQuery && !selectedTool) {
      const searchLowCase = searchQuery.toLowerCase();
      return flatOptions.filter(option =>
        option.label.toLowerCase().include(searchLowCase));
    }
    return options;
  };

  onChangeNote = (e, edit = false) => {
    if (edit) {
      this.setState({
        editedNote: e.target.value
      });
    } else {
      this.setState({
        currentNote: e.target.value
      });
    }
  }

  render() {
    const {
      searchString,
      selectedDataTools,
      defaultSelected,
      visible,
      currentNote
    } = this.state;
    const { helpPoints } = this.props;
    const opts = this.getOptions();
    const selectorsContainerStyle = selectedDataTools.size > 0 ? { paddingTop: '15px' } : {};

    return (
      <div className="edit_related_tools_wrap cascader-container">
        <div className="related-tools-labels-container">
          <div className="related-tools-name-cell">
            <h4>Additional Tools<HelpPoint helpPoint={helpPoints.point4} /></h4>
          </div>
          <div className="related-tools-note-cell">
            <h4>Usage Note<HelpPoint helpPoint={helpPoints.point5} /></h4>
          </div>
        </div>
        <div className="selected-items-container">
          {selectedDataTools.size > 0 ? selectedDataTools.map((scfdv, idx) =>
            this.getItemContainer(scfdv, idx, selectedDataTools)) : this.getEmptyContainer()}
        </div>
        <div className="related-tools-labels-container" style={selectorsContainerStyle}>
          <div className="related-tools-name-cell user-select" data-testid="relatedSelect">
            <Cascader
              options={opts}
              expandIcon=""
              expandTrigger="hover"
              changeOnSelect
              onChange={this.onSelectChange}
              onPopupVisibleChange={this.onPopupVisibleChange}
              popupVisible={visible}
              value={defaultSelected}
              classNamePrefix="UserSelector"
              dropdownMenuColumnStyle={{ minWidth: '210px' }}
            >
              <span className="ui-combobox">
                <input
                  className="ui-widget-content ui-combobox-input ui-widget ui-corner-left ui-content-reated-tools"
                  placeholder="Type or Select..."
                  autoComplete="off"
                  value={searchString}
                  onChange={this.onSearchStringChange}
                  onClick={this.togglePopupVisibility}
                  onKeyDown={this.onSearchKeyDown}
                  ref={el => (this.searchInput = el)}
                  aria-label="Type or Select Multiselect Options"
                  id={`${this.props.parentObjectType}_multiselect`}
                />
                <a
                  tabIndex="-1"
                  title="Show All Multiselect Options"
                  className="ui-button ui-widget ui-state-default ui-button-icon-only ui-corner-right ui-combobox-toggle ui-content-reated-tools"
                  role="button"
                >
                  <span className="ui-button-icon-primary ui-icon ui-icon-triangle-1-s" role="img" aria-label="Show all Multiselect Options" />
                  <span className="ui-button-text" />
                </a>
              </span>
            </Cascader>
          </div>
          <div className="related-tools-note-cell">
            <textarea className="tool-note-input-container" data-testid="relatedNoteInput" type="text" value={currentNote} onChange={this.onChangeNote} rows={1} />
          </div>
        </div>
      </div>
    );
  }
}

export default RelatedToolsSelect;
