class OrderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.toggleMode = this.toggleMode.bind(this);
    this.inputValueChange = this.inputValueChange.bind(this);
    this.keyDown = this.keyDown.bind(this);
    this.blur = this.blur.bind(this);
    this.state = {
      ...props
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.value
    });
  }

  toggleMode(e) {
    e.preventDefault();
    this.setState({
      focused: true
    });
    this.props.toggleOrderFieldMode();
    return false;
  }

  inputValueChange(e) {
    const { value } = e.target;
    if (value !== '0' && /^-{0,1}\d+$/.test(value)) { // replacement for !isNaN function
      this.setState({
        value
      });
    } else if (value === '') {
      this.setState({
        value: ''
      });
    }
  }

  blur() {
    const { dataItemId, value } = this.state;
    if (value !== '0' && /^-{0,1}\d+$/.test(value)) { // replacement for !isNaN function
      this.props.changePosition(dataItemId, parseInt(value, 10));
    } else if (value === '')
      this.props.changePosition(dataItemId, '');

    this.setState({
      focused: false
    });
  }

  keyDown(e) {
    if (e.key === 'Enter') {
      this.blur();
      this.props.valueChanged();
    } else if (e.key === 'Escape')
      this.props.toggleOrderFieldMode(true);
  }

  render() {
    const { value, focused } = this.state;
    const { orderFieldVisible } = this.props;
    const invalidValueClass = parseInt(value, 10) >= 0 ? '' : 'invalid-value';
    return (
      orderFieldVisible
        ? <input
          className={invalidValueClass}
          ref={input => input && focused && input.focus()}
          style={{ width: '50px' }}
          type="text"
          value={value}
          name="order"
          onChange={this.inputValueChange}
          onKeyDown={this.keyDown}
          onBlur={this.blur}
          aria-label={this.props.ariaLabel}
        />
        : <a
          style={{ width: '50px' }}
          href=""
          onClick={(e) => { this.toggleMode(e); }}
        >
          order
        </a>
    );
  }
}

export default OrderComponent;
