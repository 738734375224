import { GlobalHotKeys, configure } from "react-hotkeys";
import React from "react";

function getModalTrigger(elementInFocusIsInsideModal, modal, elementInFocusIsInsideList, listContainer) {
  if (elementInFocusIsInsideModal) {
    return document.querySelector(`[data-trigger-id='${modal.dataset.triggerButtonId}']`);
  }
  if (elementInFocusIsInsideList) {
    return listContainer.querySelector("[data-browse-data-systems-modal-trigger='true']");
  }
  return document.querySelector(
    "[data-browse-data-systems-modal-trigger='true']:not(.disabled)"
  );
}

export default class ReferenceDataKeyShortcuts extends React.Component {
  constructor(props) {
    super(props);
    this.allowHotKeysInAllTags();
  }

  allowHotKeysInAllTags() {
    configure({ ignoreTags: [] });
  }

  switchModalFocus(e) {
    const elementInFocus = e.target;
    const modal = document.querySelector("[data-browse-data-systems-modal='true']");
    const elementInFocusIsInsideModal = !!elementInFocus.closest(".ui-dialog");
    const modalIsCreatedAndOpen = !!(modal && modal.textContent.length > 0);
    const firstModalElement = modal && modal.parentElement.querySelector(".ui-button");
    const listContainer = elementInFocus.closest("[data-list-container='true']");
    const elementInFocusIsInsideList = !!listContainer;
    const modalTrigger = getModalTrigger(elementInFocusIsInsideModal, modal, elementInFocusIsInsideList, listContainer);

    if (modalIsCreatedAndOpen && elementInFocusIsInsideModal) return modalTrigger.focus();
    if (!elementInFocus.dataset.allowsReactHotkeys) return;
    if (modalIsCreatedAndOpen) return firstModalElement.focus();
    modalTrigger && modalTrigger.click();
  }

  pasteGrabbedData = (e) => {
    const elementInFocus = e.target;
    if (!elementInFocus.dataset.allowsReactHotkeys) return;

    const parentList = elementInFocus.closest("[data-list-id]");
    if (!parentList) return;

    const listId = parentList.dataset.listId;
    this.props.handleGrabbedData(elementInFocus, listId);
  }

  render() {
    const keyMap = {
      shortcutToSwitchModalFocus: ["ctrl+option+f"],
      shortcutToPasteGrabbedData: ["ctrl+option+p"]
    };
    const handlers = {
      shortcutToSwitchModalFocus: this.switchModalFocus,
      shortcutToPasteGrabbedData: this.pasteGrabbedData
    };
    return(
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
    );
  }
}
