export default class DisplayDetailsAttachmentUploadForm extends React.Component {
  constructor(props) {
    super(props);
    this.uploadAttachment = this.uploadAttachment.bind(this);
    this.isFileInputEmpty = this.isFileInputEmpty.bind(this);
  }

  componentDidUpdate(_prevProps, _prevState) {
    let opened;
    try {
      opened = $j(this.el).hasClass("ui-dialog-content") && $j(this.el).dialog('isOpen');
    } catch (e) {
      console.error(e);
    }

    if (!opened) {
      $j(this.el).dialog({
        modal: true,
        resizable: false,
        height: 180,
        width: 350,
        close: this.props.onCancel
      });
    }
  }


  onUploadSuccess(data) {
    $j(this.el).hasClass("ui-dialog-content") && $j(this.el).dialog('close');
    this.props.onUploadSuccess(data);
  }

  isFileInputEmpty() {
    return document.getElementById('attachment_file').files.length === 0;
  }

  uploadAttachment(e) {
    e.preventDefault();
    if (this.isFileInputEmpty()) {
      return false;
    }

    const url = this.props.controllerUrl;
    const frm = $j('#display-details-attachment-form')[0];
    const data = new FormData(frm);
    const self = this;

    $j.ajax({
      url: url,
      type: 'POST',
      enctype: 'multipart/form-data',
      processData: false,
      contentType: false,
      data,
      dataType: 'json',
      success: (data) => {
        const flashErrorContainer = $j('#flash-error');
        if (flashErrorContainer && flashErrorContainer[0]) {
          flashErrorContainer[0].remove();
        }
        self.onUploadSuccess(data);
      },
      error: (e) => {
        console.error(e);
        location.reload();
      }
    });

    return false;
  }

  render() {
    if (!this.props.display) {
      return (<div style={{ display: 'none' }}></div>);
    }

    return (
      <div id="display-details-attachment-form-container" ref={el => this.el = el} title="Add attachment">
        <form name="attachment" encType="multipart/form-data" method="POST" action="display_details_attachment"
              acceptCharset="UTF-8"
              id="display-details-attachment-form"
          onSubmit={this.uploadAttachment}
        >
          <div>
            <label htmlFor="attachment_file">Select a File to Upload</label>
            <input type="file" name="attachment[file]" id="attachment_file" />
          </div>
          <div>
            <label htmlFor="attachment_description">Description</label>
            <input type="text" name="attachment[description]" id="attachmentdescription" />
            <input type="submit" name="commit" value="Attach File" />
          </div>
        </form>
      </div>
    );
  }
}
