import { render, screen } from '@testing-library/react';
import DMCTargetErpSystemSelector from './DMCTargetErpSystemSelector';

describe('<DMCTargetErpSystemSelector />', () => {
  const component = (overrideProps) => {
    render(<DMCTargetErpSystemSelector targetErpSystem={null} erpSystems={[]} reportCreation={false} {...overrideProps} />);
  };
  describe('input name', () => {
    describe('when reportCreation is true', () => {
      it('renders input with version attributes name', () => {
        component({ reportCreation: true });

        expect(screen.getByTestId('target-data-system-input').name).toEqual('report[version_attributes][target_erp_system_id]');
      });
    });

    describe('when reportCreation is false', () => {
      it('renders input with report version name', () => {
        component({ reportCreation: false });

        expect(screen.getByTestId('target-data-system-input').name).toEqual('report_version[target_erp_system_id]');
      });
    });
  });
});
