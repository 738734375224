import DataItem from './DataItem';

class DraggableDataItem extends React.Component {
  render() {
    const { item } = this.props;
    return (
      <DataItem
        key={`di-${item.id}`}
        {...this.props}
        {...this.props.sharedProps}
        dataItem={item}
      />
    );
  }
}

DraggableDataItem.propTypes = {
  item: PropTypes.object.isRequired,
  sharedProps: PropTypes.object.isRequired
};

export default DraggableDataItem;
