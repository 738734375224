class DataItemNewComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    $j(this.el).droppable({
      scope: 'omnifind',
      tolerance: 'touch',
      hoverClass: 'notice',
      drop: (_event, ui) => {
        const termId = ui.helper.data('dcb-term_id');

        const {
          onDropTermToDataItem
        } = this.props;

        onDropTermToDataItem(termId);
      }
    });
  }

  render() {
    const {
      onDropSchemaObjectToDataItem,
      openNewDataItemForm,
      plusImageSrc
    } = this.props;

    return (
      <div
        className="html5-droppable-highlight"
        onDragOver={e => e.preventDefault()}
        onDrop={onDropSchemaObjectToDataItem}
        ref={el => this.el = el}
      >
        <a
          href=""
          onClick={openNewDataItemForm}
          className="data-item__new-data-item-link"
        >
          <img
            src={plusImageSrc}
            className="dmc-new-lineage__plus-icon"
            style={{ margin: '0 5px 0 0' }}
          />
          <span style={{ verticalAlign: 'super' }}>Existing Definition</span>
        </a>
        <span
          className="dmc-new-lineage__label"
        />
      </div>
    );
  }
}

export default DataItemNewComponent;
