import React from "react";
import PropTypes from "prop-types";

export default class AutomaticNamedRoleSelector extends React.Component {
    // START Constructor block
    constructor(props) {
        super(props);
        const { namedRoleOptions, selectedNamedRoleOptions, namedRoleOptionsGlobalIds, selectedNamedRoleParentIds, userMappingOptions, selectedMappingOptions, isAdmin } = this.props
        const { roleOptions, roleSelected } = this.createRoleOptions(namedRoleOptions, selectedNamedRoleOptions, namedRoleOptionsGlobalIds, selectedNamedRoleParentIds);
        const { mappingOptions, mappingSelected } = this.createMappingOptions(userMappingOptions, selectedMappingOptions);
        const flatOptions = roleOptions;
        const flatMappingOptions = mappingOptions;
        this.state = {
            roleOptions,
            mappingOptions,
            flatOptions,
            flatMappingOptions,
            searchRoleString: "",
            searchMappingString: "",
            searchRoleStringEdit: "",
            searchMappingStringEdit: "",
            selectedNamedRoleOptions: new Immutable.List(
                roleSelected
            ),
            selectedMappingOptions: new Immutable.List(
                mappingSelected
            ),
            tempSelectedNamedRoleOption: "",
            tempSelectedMappingOption: "",
            defaultRoleSelected: this.getDefaultSelectedValue(roleOptions),
            defaultMappingSelected: this.getDefaultSelectedValue(mappingOptions),
            visibleRoleSelector: false,
            visibleRoleSelectorEdit: false,
            visibleMappingSelector: false,
            visibleMappingSelectorEdit: false,
            editMode: false,
            editedId: '',
            deletedItems: [],
            prevRole: '',
            prevMapping: '',
            isAdmin: isAdmin || false,
        };
    }
    // END Constructor block

    // START Options block
    /**
    * @param roleOptions
    * @returns {Array}
    */

    getDefaultSelectedValue(roleOptions) {
        if (_.isEmpty(roleOptions)) {
            return [];
        }
        return [_.first(roleOptions).value];
    };

    createRoleOptions = (namedRoleOptions, selectedNamedRoleOptions, globalIds, assignableIds) => {
        let prevOptions = namedRoleOptions.map((nro, index) => this.parseRoleData(nro, index, globalIds));
        let roleOptions = []
        let roleSelected = []
        if (selectedNamedRoleOptions && selectedNamedRoleOptions != undefined) {
            roleSelected = this.getSelectedFromProps(selectedNamedRoleOptions, prevOptions, assignableIds)
            roleOptions = prevOptions.filter(opt => !roleSelected.includes(opt))
        }
        else {
            roleOptions = prevOptions
        }
        roleOptions.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
        return { roleOptions, roleSelected }
    };

    createMappingOptions = (mOptions, selectedMappingOptions) => {
        let prevOptions = mOptions.map((mo, index) => this.parseMappingData(mo, index));
        let mappingOptions = []
        let mappingSelected = []
        if (selectedMappingOptions && selectedMappingOptions != undefined) {
            mappingSelected = this.getSelectedFromProps(selectedMappingOptions, prevOptions, [])
            mappingOptions = prevOptions.filter(opt => !mappingSelected.includes(opt))
        }
        else {
            mappingOptions = prevOptions
        }
        return { mappingOptions, mappingSelected }
    };

    getSelectedFromProps = (selectedNamedRoleOptions, roleOptions, assignableIds) => {
        let selectedValues = selectedNamedRoleOptions
        let filteredSelectedValues = []
        selectedValues.map((el, index) => {
            roleOptions.find((opt) => {
                if (opt.label === el.name) {
                    if (assignableIds[index] != undefined) {
                        opt.assignable_id = assignableIds[index]
                    }
                    filteredSelectedValues.push(opt)
                }
            })
        })
        return filteredSelectedValues
    };

    parseRoleData = (nro, index, globalIds) => {
        return {
            value: nro.id,
            label: nro.name,
            position: index,
            role_gid: globalIds[index],
            assignable_id: ''
        };
    };

    parseMappingData = (mo, index) => {
        return {
            value: mo.id,
            label: mo.name,
            position: index,
        };
    };

    getOptions = () => {
        const {
            searchRoleString,
            selectedNamedRoleOption,
            roleOptions,
            flatOptions,
        } = this.state;
        if (searchRoleString && !selectedNamedRoleOption) {
            const searchLowCase = searchRoleString.toLowerCase();
            return flatOptions.filter((option) =>
                option.label.toLowerCase().include(searchLowCase)
            );
        }
        return roleOptions;
    };

    getMappingOptions = () => {
        const {
            searchMappingString,
            selectedMappingOption,
            mappingOptions,
            flatMappingOptions,
        } = this.state;
        if (searchMappingString && !selectedMappingOption) {
            const searchLowCase = searchMappingString.toLowerCase();
            return flatMappingOptions.filter((option) =>
                option.label.toLowerCase().include(searchLowCase)
            );
        }
        return mappingOptions;
    };
    // END Options block

    // START Change handlers block
    onSelectRoleChange = (value, selectedOptions) => {
        const { selectedNamedRoleOptions, searchRoleString, roleOptions, deletedItems } = this.state;
        const selectedNamedRoleOption = selectedOptions.last();
        deletedItems.find((item) => {
            if (item == selectedNamedRoleOption.assignable_id) {
                selectedNamedRoleOption.assignable_id = ''
            }
        });

        let defaultRoleSelected = null;

        if (!_.isEmpty(searchRoleString)) {
            defaultRoleSelected = this.getDefaultSelectedValue(roleOptions);
        } else {
            defaultRoleSelected = value;
        }
        if (
            !selectedNamedRoleOptions.find(
                (snro) => snro.value === selectedNamedRoleOption.value
            )
        ) {
            if (selectedNamedRoleOption) {
                this.setState({
                    searchRoleString: selectedNamedRoleOption.label,
                    tempSelectedNamedRoleOption: selectedNamedRoleOption,
                    defaultRoleSelected,
                });
            }
        }
    };

    onSelectMappingChange = (value, selectedOptions) => {
        const { selectedMappingOptions, searchMappingString, mappingOptions } = this.state;
        const selectedMappingOption = selectedOptions.last();

        let defaultMappingSelected = null;

        if (!_.isEmpty(searchMappingString)) {
            defaultMappingSelected = this.getDefaultSelectedValue(mappingOptions);
        } else {
            defaultMappingSelected = value;
        }
        if (
            !selectedMappingOptions.find(
                (smo) => smo.value === selectedMappingOption.value
            )
        ) {
            if (selectedMappingOption) {
                this.setState({
                    searchMappingString: selectedMappingOption.label,
                    tempSelectedMappingOption: selectedMappingOption,
                    defaultMappingSelected,
                });
            }
        }
    };

    onSelectRoleChangeEdit = (value, selectedOptions) => {
        const { selectedNamedRoleOptions, searchRoleStringEdit, roleOptions, deletedItems } = this.state;
        const selectedNamedRoleOption = selectedOptions.last();
        deletedItems.find((item) => {
            if (item == selectedNamedRoleOption.assignable_id) {
                selectedNamedRoleOption.assignable_id = ''
            }
        });

        let defaultRoleSelected = null;

        if (!_.isEmpty(searchRoleStringEdit)) {
            defaultRoleSelected = this.getDefaultSelectedValue(roleOptions);
        } else {
            defaultRoleSelected = value;
        }
        if (
            !selectedNamedRoleOptions.find(
                (snro) => snro.value === selectedNamedRoleOption.value
            )
        ) {
            if (selectedNamedRoleOption) {
                this.setState({
                    searchRoleStringEdit: selectedNamedRoleOption.label,
                    tempSelectedNamedRoleOption: selectedNamedRoleOption,
                    defaultRoleSelected,
                });
            }
        } else {
            this.setState({
                searchRoleStringEdit: selectedNamedRoleOption.label,
            });
        }
    };

    onSelectMappingChangeEdit = (value, selectedOptions) => {
        const { selectedMappingOptions, searchMappingStringEdit, mappingOptions } = this.state;
        const selectedMappingOption = selectedOptions.last();

        let defaultMappingSelected = null;

        if (!_.isEmpty(searchMappingStringEdit)) {
            defaultMappingSelected = this.getDefaultSelectedValue(mappingOptions);
        } else {
            defaultMappingSelected = value;
        }
        if (
            !selectedMappingOptions.find(
                (scfdv) => scfdv.value === selectedMappingOption.value
            )
        ) {
            if (selectedMappingOption) {
                this.setState({
                    searchMappingStringEdit: selectedMappingOption.label,
                    tempSelectedMappingOption: selectedMappingOption,
                    defaultMappingSelected,
                });
            }
        } else {
            this.setState({
                searchMappingStringEdit: selectedMappingOption.label,
            });
        }
    };

    onSearchRoleStringChangeEdit = (e) => {
        const searchRoleStringEdit = e.target.value;
        this.setState({
            searchRoleStringEdit,
            visibleRoleSelectorEdit: true,
        });
    };

    onSearchMappingStringChangeEdit = (e) => {
        const searchMappingStringEdit = e.target.value;
        this.setState({
            searchMappingStringEdit,
            visibleMappingSelectorEdit: true,
        });
    };

    onSearchRoleStringChange = (e) => {
        const searchRoleString = e.target.value;
        this.setState({
            searchRoleString,
            visibleRoleSelector: true,
        });
    };

    onSearchMappingStringChange = (e) => {
        const searchMappingString = e.target.value;
        this.setState({
            searchMappingString,
            visibleMappingSelector: true,
        });
    };
    // END Change handlers block

    // START Accessability block
    onSearchKeyDown = (e) => {
        switch (e.keyCode) {
            case KeyCode.BACKSPACE:
                e.stopPropagation();
                break;
            case KeyCode.ESC:
                this.setState({
                    visibleRoleSelector: false,
                    visibleRoleSelectorEdit: false,
                });
                break;
            default:
                break;
        }
    };

    preventPageScroll = (e) => {
        const { searchInput } = this;
        switch (e.keyCode) {
            case KeyCode.UP:
            case KeyCode.DOWN:
            case KeyCode.LEFT:
            case KeyCode.RIGHT:
                e.preventDefault();
                break;
            case KeyCode.ENTER:
                break;
            case KeyCode.BACKSPACE:
            case KeyCode.ESC:
            default:
                searchInput.focus();
                break;
        }
    };
    // END Accessability block

    // START Toggle components visability block
    removeSelectedFromOptions = () => {
        const { prevRole, prevMapping, roleOptions, mappingOptions } = this.state;
        if (prevRole != '' || prevMapping != '') {
            roleOptions.pop()
            mappingOptions.pop()
        }

        this.setState({
            prevRole: '',
            prevMapping: '',
            roleOptions: roleOptions,
            mappingOptions: mappingOptions,
        });
    }

    toggleRolePopupVisibility = () => {
        const { visibleRoleSelector } = this.state;
        this.removeSelectedFromOptions()

        this.setState({
            visibleRoleSelector: !visibleRoleSelector,
            editMode: false,
            searchRoleString: ''
        });
    };

    toggleMappingPopupVisibility = () => {
        const { visibleMappingSelector } = this.state;
        this.removeSelectedFromOptions()

        this.setState({
            visibleMappingSelector: !visibleMappingSelector,
            editMode: false,
            searchMappingString: ''
        });
    };

    toggleRolePopupVisibilityEdit = () => {
        const { visibleRoleSelectorEdit } = this.state;
        this.setState({
            visibleRoleSelectorEdit: !visibleRoleSelectorEdit,
            visibleRoleSelector: false
        });
    };

    toggleMappingPopupVisibilityEdit = () => {
        const { visibleMappingSelectorEdit } = this.state;
        this.setState({
            visibleMappingSelectorEdit: !visibleMappingSelectorEdit,
            visibleMappingSelector: false,
        });
    };

    onRolePopupVisibleChange = (visibleRoleSelector) => {
        this.setState({
            visibleRoleSelector,
        });
    };

    onMappingPopupVisibleChange = (visibleMappingSelector) => {
        this.setState({
            visibleMappingSelector,
        });
    };

    onRolePopupVisibleChangeEdit = (visibleRoleSelectorEdit) => {
        this.setState({
            visibleRoleSelectorEdit,
        });
    };

    onMappingPopupVisibleChangeEdit = (visibleMappingSelectorEdit) => {
        this.setState({
            visibleMappingSelectorEdit,
        });
    };
    // END Toggle components visability block

    // START Update state block
    updateOptionsStateAdd = () => {
        const { selectedNamedRoleOptions,
            selectedMappingOptions,
            tempSelectedNamedRoleOption,
            tempSelectedMappingOption,
            roleOptions,
            mappingOptions
        } = this.state;

        if (tempSelectedNamedRoleOption.assignable_id != '') {
            tempSelectedNamedRoleOption.assignable_id = ''
        }

        this.setState({
            selectedNamedRoleOptions: selectedNamedRoleOptions.push(
                tempSelectedNamedRoleOption
            ),
            selectedMappingOptions: selectedMappingOptions.push(
                tempSelectedMappingOption
            ),
            roleOptions: roleOptions.filter(opt => opt.value !== tempSelectedNamedRoleOption.value),
            mappingOptions: mappingOptions.filter(opt => opt.value !== tempSelectedMappingOption.value),
            searchMappingString: '',
            searchRoleString: '',
            tempSelectedNamedRoleOption: '',
            tempSelectedMappingOption: '',
        });
    }

    updateOptionsStateRemove = (idx, snro) => {
        const { selectedNamedRoleOptions, roleOptions, selectedMappingOptions, mappingOptions, deletedItems } = this.state;

        if (snro.assignable_id != '' && snro.assignable_id != undefined) {
            deletedItems.push(snro.assignable_id);
        };

        roleOptions.push(selectedNamedRoleOptions.get(idx))
        mappingOptions.push(selectedMappingOptions.get(idx))
        selectedNamedRoleOptions.delete(idx);
        selectedMappingOptions.delete(idx);

        this.setState({
            selectedNamedRoleOptions: selectedNamedRoleOptions.delete(idx),
            selectedMappingOptions: selectedMappingOptions.delete(idx),
            roleOptions: roleOptions.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0)),
            mappingOptions: mappingOptions.sort((a, b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0)),
            deletedItems: deletedItems,
        });
    }

    updateOptionsStateEdit = (snro, idx) => {
        const { selectedNamedRoleOptions,
            selectedMappingOptions,
            tempSelectedNamedRoleOption,
            tempSelectedMappingOption,
            roleOptions,
            mappingOptions
        } = this.state;

        if (tempSelectedNamedRoleOption) {
            tempSelectedNamedRoleOption.assignable_id = snro.assignable_id
            roleOptions.push(selectedNamedRoleOptions.get(idx))
        };

        if (tempSelectedMappingOption) {
            mappingOptions.push(selectedMappingOptions.get(idx))
        };

        this.setState({
            selectedMappingOptions: tempSelectedMappingOption ? selectedMappingOptions.set(idx, tempSelectedMappingOption) : selectedMappingOptions,
            selectedNamedRoleOptions: tempSelectedNamedRoleOption ? selectedNamedRoleOptions.set(idx, tempSelectedNamedRoleOption) : selectedNamedRoleOptions,
            roleOptions: roleOptions.filter(opt => (opt.value !== tempSelectedNamedRoleOption.value)),
            mappingOptions: mappingOptions.filter(opt => (opt.value !== tempSelectedMappingOption.value)),
            searchMappingStringEdit: '',
            searchRoleStringEdit: '',
            editMode: false,
            tempSelectedNamedRoleOption: '',
            tempSelectedMappingOption: '',
            prevRole: '',
            prevMapping: ''
        });
    }
    // END Update state block

    // START Ajax requests block
    sendCreateRequest = () => {
        const { tempSelectedNamedRoleOption, tempSelectedMappingOption } = this.state;
        const { requestUrl, parentObjectType } = this.props
        const assignable_named_roles_attributes = {
            "0": {
                id: '',
                role_gid: tempSelectedNamedRoleOption.role_gid,
                user_mapping_id: tempSelectedMappingOption.value,
            }
        }
        const workflowData = {
            workflow_definition: {
                assignable_named_roles_attributes: assignable_named_roles_attributes
            }
        }
        const phaseData = {
            phase: {
                assignable_named_roles_attributes: assignable_named_roles_attributes
            }
        }
        const data = parentObjectType == 'phase' ? phaseData : workflowData

        $j.ajax({
            type: 'PATCH',
            url: requestUrl,
            contentType: 'application/json; charset=utf-8',
            data: JSON.stringify(data),
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
            dataType: 'json',
            success: () => this.updateOptionsStateAdd()
        });
    }

    sendDeleteRequest = (idx, snro) => {
        const { selectedNamedRoleOptions } = this.state
        const { requestUrl, parentObjectType } = this.props
        const namedRole = selectedNamedRoleOptions.get(idx)
        const assignable_named_roles_attributes = {
            "0": {
                id: namedRole.assignable_id,
                "_destroy": 1
            }
        }
        const workflowData = {
            workflow_definition: {
                assignable_named_roles_attributes: assignable_named_roles_attributes
            }
        }
        const phaseData = {
            phase: {
                assignable_named_roles_attributes: assignable_named_roles_attributes
            }
        }

        const data = parentObjectType == 'phase' ? phaseData : workflowData

        $j.ajax({
            url: requestUrl,
            type: "PATCH",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(data),
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
            dataType: 'json',
            success: () => this.updateOptionsStateRemove(idx, snro)
        });
    }

    sendUpdateRequest = (snro, idx) => {
        const { tempSelectedNamedRoleOption, tempSelectedMappingOption, selectedMappingOptions } = this.state;
        const { requestUrl, parentObjectType } = this.props
        const assignable_named_roles_attributes = {
            "0": {
                id: snro.assignable_id,
                role_gid: tempSelectedNamedRoleOption ? tempSelectedNamedRoleOption.role_gid : snro.role_gid,
                user_mapping_id: tempSelectedMappingOption ? tempSelectedMappingOption.value : selectedMappingOptions.get(idx).value,
            }
        }
        const workflowData = {
            workflow_definition: {
                assignable_named_roles_attributes: assignable_named_roles_attributes
            }
        }
        const phaseData = {
            phase: {
                assignable_named_roles_attributes: assignable_named_roles_attributes
            }
        }

        const data = parentObjectType == 'phase' ? phaseData : workflowData

        $j.ajax({
            type: 'PATCH',
            url: requestUrl,
            contentType: 'application/json; charset=utf-8',
            data: JSON.stringify(data),
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
            dataType: 'json',
            success: () => this.updateOptionsStateEdit(snro, idx)
        });
    }
    // END Ajax requests block

    // START Buttons block
    toggleEdit = (idx, selRole, selMapping) => {
        const { roleOptions, mappingOptions } = this.state
        this.removeSelectedFromOptions()
        roleOptions.push(selRole)
        mappingOptions.push(selMapping)

        this.setState({
            editMode: true,
            editedId: idx,
            searchRoleString: "",
            searchMappingString: "",
            searchRoleStringEdit: selRole.label,
            searchMappingStringEdit: selMapping.label,
            prevRole: selRole,
            prevMapping: selMapping,
            roleOptions: roleOptions,
            mappingOptions: mappingOptions,
        });
    };

    cancelButton = () => {
        const { roleOptions, mappingOptions, prevRole, prevMapping } = this.state
        this.setState({
            editMode: false,
            editedId: '',
            searchRoleStringEdit: "",
            searchMappingStringEdit: "",
            roleOptions: roleOptions.filter(opt => opt.value !== prevRole.value),
            mappingOptions: mappingOptions.filter(mOpt => mOpt.value !== prevMapping.value),
            prevRole: '',
            prevMapping: '',
        });
    };

    setSelectedOptions = () => {
        const { requestUrl } = this.props

        if (requestUrl) {
            this.sendCreateRequest()
        } else {
            this.updateOptionsStateAdd()
        }
    }

    updateSelectedOptions = (snro, idx) => {
        const { tempSelectedNamedRoleOption, tempSelectedMappingOption, roleOptions, mappingOptions } = this.state;
        const { requestUrl } = this.props
        roleOptions.pop()
        mappingOptions.pop()

        if (tempSelectedNamedRoleOption || tempSelectedMappingOption) {
            if (requestUrl) {
                this.sendUpdateRequest(snro, idx)
            } else {
                this.updateOptionsStateEdit(snro, idx)
            }
        } else {
            this.setState({
                searchMappingStringEdit: '',
                searchRoleStringEdit: '',
                editMode: false,
                tempSelectedNamedRoleOption: '',
                tempSelectedMappingOption: '',
                prevRole: '',
                prevMapping: ''
            });
        }
    };

    onRemoveClickHandler = (idx, snro) => {
        const { requestUrl } = this.props

        if (requestUrl) {
            this.sendDeleteRequest(idx, snro)
        } else {
            this.updateOptionsStateRemove(idx, snro)
        }
    };
    // END Buttons block

    // START Item containers block
    getItemContainer = (snro, idx, selectedMappingOptions) => {
        const {
            searchRoleStringEdit,
            searchMappingStringEdit,
            defaultRoleSelected,
            defaultMappingSelected,
            visibleRoleSelectorEdit,
            visibleMappingSelectorEdit,
            isAdmin,
        } = this.state;
        const opts = this.getOptions();
        const mOpts = this.getMappingOptions()

        const { parentObjectType } = this.props;
        return (
            <div className="custom-item-container flex-box" key={`${snro.label}:${snro.value}`}>
                {(this.state.editMode && this.state.editedId === idx) ? (
                    <>
                        <div className='span-7 named-role-item-name'>
                            {this.roleSelector(
                                opts,
                                this.onSelectRoleChangeEdit,
                                this.onRolePopupVisibleChangeEdit,
                                visibleRoleSelectorEdit,
                                defaultRoleSelected,
                                searchRoleStringEdit,
                                this.onSearchRoleStringChangeEdit,
                                this.toggleRolePopupVisibilityEdit,
                                'Role',
                                isAdmin,
                            )}
                        </div>
                        <div className="span-10">
                            {this.roleSelector(
                                mOpts,
                                this.onSelectMappingChangeEdit,
                                this.onMappingPopupVisibleChangeEdit,
                                visibleMappingSelectorEdit,
                                defaultMappingSelected,
                                searchMappingStringEdit,
                                this.onSearchMappingStringChangeEdit,
                                this.toggleMappingPopupVisibilityEdit,
                                'Mapping',
                                isAdmin,
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <div className="span-7 named-role-item-name">
                            {snro.label}
                        </div>
                        <div className="span-10">
                            <a href={`/institution/user_mappings/${selectedMappingOptions.get(idx).value}`} target="blank" title={`${selectedMappingOptions.get(idx).value}`}>
                                {selectedMappingOptions.get(idx).label}
                            </a>
                        </div>
                    </>
                )}
                { this.props.isAdmin &&
                    <div className="span-1 btn-remove-item-container named-role-remove-container">
                        <input
                            type="hidden"
                            className="item-value"
                            name={`${parentObjectType}[assignable_named_roles_attributes][${idx}][id]`}
                            value={snro.assignable_id}
                        />
                        <input
                            type="hidden"
                            className="item-value"
                            name={`${parentObjectType}[assignable_named_roles_attributes][${idx}][role_gid]`}
                            value={(snro.role_gid)}
                        />
                        <input
                            type="hidden"
                            className="item-value"
                            name={`${parentObjectType}[assignable_named_roles_attributes][${idx}][user_mapping_id]`}
                            value={(selectedMappingOptions.get(idx).value)}
                        />
                        {(this.state.editMode && this.state.editedId === idx) ? (
                            <div>
                                <button className="pretty-button mini named-role-update" type="button"
                                    onClick={() => this.updateSelectedOptions(snro, idx)}
                                >
                                    Update
                                </button>
                                <div className='buttons-separator'> or </div>
                                <a className='named-role-cancel' type="button"
                                    onClick={() => this.cancelButton()}
                                >
                                    Cancel
                                </a>
                            </div>
                        ) : (
                            <>
                                <button className="pretty-button mini named-role-edit" type="button"
                                    onClick={() => this.toggleEdit(idx, snro, selectedMappingOptions.get(idx))}
                                >
                                    Edit
                                </button>
                                <button
                                    name="button"
                                    type="button"
                                    className="btn-remove-item named-role-remove"
                                    aria-label="remove item selected item"
                                    onClick={() => this.onRemoveClickHandler(idx, snro)}
                                >
                                    <img
                                        src={this.props.removeIconSrc}
                                        alt="Remove field name"
                                        tabIndex="0"
                                    />
                                </button>
                            </>
                        )}
                    </div>
                }
                <br />
            </div>
        );
    }

    getDeletedItemContainer = (item, idx) => {
        const { parentObjectType } = this.props;
        const index = idx + this.state.selectedNamedRoleOptions.size;

        return (
            <div className="item-container named-role-item" key={`${item}:${idx}`}>
                <div className="span-2 btn-remove-item-container">
                    <input
                        type="hidden"
                        className="item-value"
                        name={`${parentObjectType}[assignable_named_roles_attributes][${index}][id]`}
                        value={item}
                    />
                    <input
                        type="hidden"
                        className="item-value"
                        name={`${parentObjectType}[assignable_named_roles_attributes][${index}][_destroy]`}
                        value="1"
                    />
                </div>
            </div>
        );
    };

    getEmptyContainer = () => {
        return (
            <div className="empty-item-container">
                <input
                    type="hidden"
                    className="item-value"
                    name={`workflow_definition[assignable_named_roles_attributes][0]`}
                    value={[]}
                />
            </div>
        );
    }
    // END Item containers block

    // START Selector block
    roleSelector = (opts, onChange, onPopupVisibleChange, popupVisible, defaultSelected, searchString, onChangeSearch, onClick, selectorsName, isAdmin) => {
      return (
        isAdmin ? (
          <Cascader
            options={opts}
            expandIcon=''
            expandTrigger="hover"
            changeOnSelect
            onChange={onChange}
            onPopupVisibleChange={onPopupVisibleChange}
            popupVisible={popupVisible}
            value={defaultSelected}
          >
            <span className="ui-combobox">
              <input
                className="ui-widget-content ui-combobox-input ui-widget ui-corner-left named-role-widget"
                placeholder={"Type or Select a " + selectorsName}
                autoComplete="off"
                value={searchString}
                onChange={onChangeSearch}
                onClick={onClick}
                onKeyDown={this.onSearchKeyDown}
                ref={(el) => (this.searchInput = el)}
                aria-label={"Type or Select a " + selectorsName}
                id={this.props.parentObjectType + "_" + selectorsName}
              />
              <a
                tabIndex="-1"
                title={"Show All " + selectorsName + " Options"}
                className="ui-button ui-widget ui-state-default ui-button-icon-only ui-corner-right ui-combobox-toggle"
                role="button"
                onClick={onClick}
              >
                  <span className="ui-button-icon-primary ui-icon ui-icon-triangle-1-s" role="img"
                        aria-label={"Show all " + "Type or Select a " + selectorsName + " Options"}/>
                  <span className="ui-button-text"/>
              </a>
            </span>
          </Cascader>
        ) : null
      )
    }
    // END Selector block

    render() {
        const {
            searchRoleString,
            searchMappingString,
            selectedNamedRoleOptions,
            selectedMappingOptions,
            defaultRoleSelected,
            defaultMappingSelected,
            visibleRoleSelector,
            visibleMappingSelector,
            deletedItems,
            tempSelectedNamedRoleOption,
            tempSelectedMappingOption,
            isAdmin,
        } = this.state;
        const opts = this.getOptions();
        const mOpts = this.getMappingOptions()

        return (
            <div className="cascader-container">
                <div className="selected-role-items-container named-role-items">
                    {selectedNamedRoleOptions.size > 0 ? selectedNamedRoleOptions.map((snro, idx) =>
                        this.getItemContainer(snro, idx, selectedMappingOptions)
                    ) : this.getEmptyContainer()}
                    {deletedItems.size() > 0 ? deletedItems.map((item, idx) =>
                        this.getDeletedItemContainer(item, idx)
                    ) : null}
                </div>
                <div className='span-7'>
                    {this.roleSelector(
                        opts,
                        this.onSelectRoleChange,
                        this.onRolePopupVisibleChange,
                        visibleRoleSelector,
                        defaultRoleSelected,
                        searchRoleString,
                        this.onSearchRoleStringChange,
                        this.toggleRolePopupVisibility,
                        "Role",
                        isAdmin
                    )}
                </div>
                <div className='span-10'>
                    {this.roleSelector(
                        mOpts,
                        this.onSelectMappingChange,
                        this.onMappingPopupVisibleChange,
                        visibleMappingSelector,
                        defaultMappingSelected,
                        searchMappingString,
                        this.onSearchMappingStringChange,
                        this.toggleMappingPopupVisibility,
                        "Mapping",
                        isAdmin
                    )}
                </div>
                <div className='span-1'>
                    {((searchRoleString == tempSelectedNamedRoleOption.label) && (searchMappingString == tempSelectedMappingOption.label)) && (
                        <button className="pretty-button mini named-role-add" type="button"
                            onClick={() => this.setSelectedOptions()}
                        >
                            Add
                        </button>
                    )}
                </div>
            </div>
        );
    }
}

AutomaticNamedRoleSelector.propTypes = {
    namedRoleOptions: PropTypes.arrayOf(Object).isRequired,
    namedRoleOptionsGlobalIds: PropTypes.arrayOf(PropTypes.string),
    selectedNamedRoleOptions: PropTypes.arrayOf(Object),
    selectedNamedRoleParentIds: PropTypes.arrayOf(PropTypes.number),
    userMappingOptions: PropTypes.arrayOf(Object).isRequired,
    selectedMappingOptions: PropTypes.arrayOf(Object),
    parentObjectType: PropTypes.string.isRequired,
    requestUrl: PropTypes.string
};
