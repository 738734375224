import React from 'react';
import PropTypes from 'prop-types';
import Cascader from 'rc-cascader';

export default class SingleSelect extends React.Component {
  constructor(props) {
    super(props);
    const { options, selectedOption } = this.createOptions(props.customFieldDefinitionValues, props.selectedCustomFieldDefinitionValue);
    const currentSelected = selectedOption || { label: '', value: '' };
    this.state = {
      searchString: '',
      currentSelected,
      defaultSelected: this.getDefaultSelectedValue(options),
      visible: false,
      formChecker: props.formChecker || false
    };
  }

  componentDidMount() {
    this.state.formChecker && window.addEventListener("beforeunload", this.onUnload);
  }

  onUnload = e => {
    if (this.state.currentSelected.value !== '') {
      e.preventDefault();
      e.returnValue = '';
    }
  }

  onChange = (value, selectedOptions) => {
    const selectedCustomFieldDefinitionValue = selectedOptions.last();
    this.updateDefaultSelected(value);

    this.setState({
      searchString: '',
      currentSelected: selectedCustomFieldDefinitionValue
    });
  };

  onSearchStringChange = (e) => {
    const searchString = e.target.value;
    this.setState({
      searchString,
      visible: true
    });
  };

  onPopupVisibleChange = (visible) => {
    this.setState({ visible });
  };

  onSearchKeyDown = (e) => {
    switch (e.keyCode) {
      case KeyCode.BACKSPACE:
        e.stopPropagation();
        break;
      case KeyCode.ESC:
        this.setState({
          visible: false
        });
        break;
      default:
        break;
    }
  };

  getDefaultSelectedValue = (options) => {
    if (_.isEmpty(options))
      return [];
    return [_.first(options).value];
  };

  getOptions = () => {
    const { customFieldDefinitionValues } = this.props;
    const { searchString } = this.state;
    const { options } = this.createOptions(customFieldDefinitionValues);
    if (searchString) {
      const searchLowCase = searchString.toLowerCase();
      return options.filter(option => option.label.toLowerCase().includes(searchLowCase));
    }

    return options;
  };

  updateDefaultSelected = (value) => {
    const { customFieldDefinitionValues } = this.props;
    const { options } = this.createOptions(customFieldDefinitionValues);
    const { searchString } = this.state;

    if (!_.isEmpty(searchString))
      this.setState({ defaultSelected: this.getDefaultSelectedValue(options) });
    else
      this.setState({ defaultSelected: value });
  };

  createOptions = (customFieldDefinitionValues, selectedCustomFieldDefinitionValue) => {
    const prevOptions = customFieldDefinitionValues.map(cfdv => this.parseData(cfdv));
    let options = [];
    let selectedOption = null;
    if (selectedCustomFieldDefinitionValue) {
      selectedOption = prevOptions.find(opt => opt.value === selectedCustomFieldDefinitionValue);
      options = prevOptions.filter(opt => selectedOption === opt);
    } else
      options = prevOptions;

    options.unshift({ label: '', value: '' });
    return { options, selectedOption };
  };

  parseData = customFieldDefinitionValue => ({
    value: customFieldDefinitionValue.value,
    label: customFieldDefinitionValue.fieldName,
    description: customFieldDefinitionValue.description,
    position: customFieldDefinitionValue.position
  });

  togglePopupVisibility = () => {
    const { visible } = this.state;
    this.setState({
      visible: !visible
    });
  };

  render() {
    const {
      searchString, currentSelected, defaultSelected, visible
    } = this.state;
    const { fieldIndex, formObjectName } = this.props;
    const opts = this.getOptions();

    return (
      <div className="cascader-container">
        <Cascader
          options={opts}
          expandIcon=""
          expandTrigger="hover"
          changeOnSelect
          onChange={this.onChange}
          onPopupVisibleChange={this.onPopupVisibleChange}
          popupVisible={visible}
          value={defaultSelected}
        >
          <span className="ui-combobox fa-selector-container">
            <input
              className="ui-widget-content ui-combobox-input ui-widget ui-corner-left"
              placeholder="Type or Select..."
              aria-label="Type or Select"
              autoComplete="off"
              title={currentSelected.description}
              value={searchString || currentSelected.label}
              onChange={this.onSearchStringChange}
              onClick={this.togglePopupVisibility}
              onKeyDown={this.onSearchKeyDown}
            />

            <input
              name={`${formObjectName}[value]`}
              type="hidden"
              id={`cf_${fieldIndex}`}
              value={currentSelected.value}
            />
            <a
              tabIndex="-1"
              title="Show All"
              className="ui-button ui-widget ui-state-default ui-button-icon-only ui-corner-right ui-combobox-toggle fa-selector-btn"
              role="button"
            >
              <span className="ui-button-icon-primary ui-icon ui-icon-triangle-1-s" role="img" aria-label="Show all" />
              <span className="ui-button-text" />
            </a>
          </span>
        </Cascader>
      </div>
    );
  }
}

SingleSelect.propTypes = {
  customFieldDefinitionValues: PropTypes.arrayOf(Object).isRequired,
  selectedCustomFieldDefinitionValue: PropTypes.string,
  formObjectName: PropTypes.string.isRequired,
  fieldIndex: PropTypes.number.isRequired
};

SingleSelect.defaultProps = {
  selectedCustomFieldDefinitionValue: ''
};
