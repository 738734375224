import HelpPoint from "../../Helpers/HelpPoint";

export default class DDTDisplayLayout extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ...props,
      displayLayoutContent: this.displayLayoutContent.bind(this)
    }
  }

  displayLayoutContent(props) {
    const {reportVersion, displayLayout, displayLayoutEditMode} = props;

    let editLink, doneLink, el;
    if (displayLayoutEditMode) {
      const reportVersionId = (reportVersion &&
        reportVersion.id) || '';

      el = (
        <div>
        <textarea
          className="small_text_eighty_percent is-droppable"
          cols={132}
          rows={10}
          onChange={props.onDisplayLayoutChange}
          value={displayLayout}
          id="displayLayout"
        >
        </textarea>

          <br />
          <button
            onClick={props.onDisplayLayoutCancel}
            style={{float: 'initial', display: 'inline-block'}}
          >
            Cancel
          </button>

          <button
            onClick={props.onDisplayLayoutSave}
            style={{float: 'initial', display: 'inline-block'}}
          >
            Save
          </button>
        </div>
      )
    } else {
      el = (
        <div>
          {
            displayLayout ?
              <pre
                className="reportDisplayDetail small_text_eighty_percent"
              >
                {displayLayout}
              </pre> : <p> Not specified.</p>
          }
        </div>
      )
    }
    return el
  }

  render() {
    const props = this.props;
    return (
      <div className="span-24">
        <div>
          <label style={{display: 'inline-block'}} >Display layout</label>
          {props.editable ? (<img
            src={props.imageEdit}
            style={{verticalAlign: 'middle', marginLeft: '5px'}}
            alt="Edit"
            onClick={this.props.toggleDisplayLayoutEditMode}
          /> ): ''}
          <HelpPoint helpPoint={this.props.helpPoints.point7} />
        </div>
        {this.displayLayoutContent(props)}
      </div>
    )
  }
}
