import React from 'react';

const ImportFileForm = (props) => {
  const {
    modelType,
    userId,
    institutionId,
    importUrl,
    previewButton
  } = props;

  const modelTypes = {
    specification: 'Report',
    definition: 'Term'
  };

  return (
    <div className="span-24 import selectFileForm">
      <form className="new_import_file" encType="multipart/form-data" id="new_import_file" role="form" acceptCharset="UTF-8" action={importUrl} method="post">
        <input type="hidden" name="authenticity_token" />
        <input type="file" aria-label="import file" name="import_file[file]" id="import_file_file" role="input" />
        <input value={userId} type="hidden" name="import_file[user_id]" id="import_file_user_id" />
        <input value={institutionId} type="hidden" name="import_file[institution_id]" id="import_file_institution_id" />
        <input value={modelTypes[modelType]} type="hidden" name="import_file[model_type]" id="import_file_model_type" />
        <input type="submit" name="commit" value={previewButton} />
      </form>
    </div>
  );
};

export default ImportFileForm;
