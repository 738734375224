import React from 'react';
import SingleFunctionalAreaSelector from './SingleFunctionalAreaSelector';
import MultiSelector from './MultiSelector';
import Select, { components } from 'react-select';

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <div className="Select-arrow"></div>
    </components.DropdownIndicator>
  );
};

const singleSelectorCustomStyles = {
  control: (provided, state) => ({
    ...provided,
    height: '26px',
    minHeight: '26px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  }),

  container: (provided, state) => ({
    ...provided,
    width: '224px',
    height: '23px',
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    backgroundColor: '#fff',
    borderColor: '#d9d9d9 #ccc #b3b3b3',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    color: '#333',
    cursor: 'default',
    verticalAlign: 'middle',
    height: '100%',
    position: 'unset',
    width: '85%'
  }),

  input: (provided, state) => ({
    ...provided,
    margin: 0,
    paddingTop: 0,
    boxShadow: 'none'
  }),

  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '100%',
    borderRadius: '2px',
  }),

  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: '9px',
    borderRadius: '3px',
    backgroundColor: '#4b8ec6',
  }),

  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none'
  }),

  singleValue: (provided, state) => ({
    ...provided,
    width: '60%'
  })
};

export default class WorkflowConditionForm extends React.Component {
  constructor(props) {
    super(props);

    this.selectedObjectTypeValuesHandler = this.selectedObjectTypeValuesHandler.bind(this)

    this.state = {
      objectTypeOptions: this.getObjectTypeOptions(),
      selectedObjectType: this.getObjectTypeOptions().find(type => (type.value.selected)),
      selectedOperand: this.getSelectedOperand(),
      selectedObjectTypeValues: this.getPreselectedObjectTypeValues(this.findSelectedType()),
      conditionType: this.findSelectedType()?.conditionType,
      operandOptions: this.getOperandsOptions(this.findSelectedType()),
      objectTypeValueOptions: this.getObjectTypeValues(this.findSelectedType()),
      isFormVisible: true,
      formErrors: null
    };
  }

  getObjectTypeOptions() {
    return this.props.objectTypes.map(type => ({value: type, label: type.humanizedTypeName }))
  }

  getSelectedObjectType() {
    const selectedType = this.findSelectedType()
    if(selectedType){
      return({value: selectedType, label: selectedType.humanizedTypeName})
    }
  }

  getSelectedOperand() {
    const selectedType = this.findSelectedType()
    if(selectedType){
      const selectedOperand = selectedType.operands.find(operand => (operand.selected))
      return({value: selectedOperand.internalOperandName, label: selectedOperand.humanizedOperandName})
    }else{
      return null
    }
  }

  findSelectedType() {
    return this.props.objectTypes.find(type => (type.selected))
  }

  selectedObjectTypeValuesHandler(selectedValues) {
    this.setState({
      selectedObjectTypeValues: selectedValues
    })
  }

  objectMap(object) {
    return Object.keys(object).reduce(function(result, key) {
      var opts = object[key].map((value) => ({label: value.name, value: {value: value.id, name: value.name } }))
      result.push({label: key, options: opts })
      return result
    }, [])
  }

  parseErpSystemValues(values) {
    return values.map((value) => ({label: value.name, value: value.id }))
  }

  getObjectTypeValues(objectType) {
    if(objectType){
      if(objectType.internalTypeName === 'ErpSystem'){
        return this.objectMap(objectType?.objectValues)
      }else if(objectType.internalTypeName === 'FunctionalArea'){
        return objectType?.objectValues
      }else if(objectType.internalTypeName === 'OriginationMethodType'){
        return Object.keys(objectType?.objectValues).map((valueName) => ({value: {value: objectType?.objectValues[valueName]}, label: valueName}))
      }else{
        return objectType?.objectValues.map((value) => ({value: {value: value.id, name: value.name}, label: value.name}))
      }
    }
  }

  getPreselectedObjectTypeValues(objectType) {
    if(objectType){
      const values = this.props.preselectedObjectTypeValues
      if(objectType.internalTypeName === 'FunctionalArea'){
        return values
      }else if(objectType.internalTypeName === 'OriginationMethodType'){
        return Object.keys(values).map((valueName) => ({value: {value: objectType?.objectValues[valueName]}, label: valueName}))
      }else{
        return values.map((value) => ({value: {value: value.id, name: value.name}, label: value.name}))
      }
    }
  }

  getOperandsOptions(objectType) {
    if(objectType){
      return objectType?.operands.map((operand) => ({value: operand.internalOperandName, label: operand.humanizedOperandName }))
    }
  }

  onObjectTypeChange = (selectedType) => {
    if(selectedType){
      this.setState({
        selectedObjectType: selectedType,
        conditionType: selectedType.value.conditionType,
        operandOptions: this.getOperandsOptions(selectedType.value),
        objectTypeValueOptions: this.getObjectTypeValues(selectedType.value),
        selectedObjectTypeValues: [],
        selectedOperand: null
      });
    }else{
      this.setState({
        selectedObjectType: '',
        conditionType: '',
        selectedOperand: '',
        operandOptions: [],
        objectTypeValueOptions: [],
        selectedObjectTypeValues: []
      })
    }
  };

  onValueSelect = (selectedValue) => {
    this.setState({
      selectedObjectTypeValues: [selectedValue]
    })
  }

  onOperandChange = (selectedOperand) => {
    this.setState({ selectedOperand: selectedOperand});
    this.setState({
      selectedObjectTypeValues: this.props.editMode ? this.getPreselectedObjectTypeValues(this.findSelectedType()) : []
    })
  };

  getOperandSelector() {
    return(
      <Select
        value={this.state.selectedOperand || this.getSelectedOperand()}
        options={this.state.operandOptions}
        onChange={this.onOperandChange}
        isSearchable={false}
        styles={singleSelectorCustomStyles}
        components={{DropdownIndicator}}
      />
    )
  }

  renderOperandSelector() {
    if(this.state.selectedObjectType &&
       this.state.selectedObjectType !== '' &&
       this.state.operandOptions.length > 0) {
      return(this.getOperandSelector())
    }
  }

  renderObjectTypeSelector() {
    return(
      <Select
        value={this.state.selectedObjectType}
        options={this.state.objectTypeOptions}
        isClearable={true}
        isSearchable={false}
        onChange={this.onObjectTypeChange}
        styles={singleSelectorCustomStyles}
        components={{DropdownIndicator}}
      />
    )
  }

  renderMultipleFunctionalAreasSelector() {
    return(
      <FunctionalAreasDrillDown
        ref={this.multipleFunctionalAreasSelectorRef}
        functionalAreas={this.state.objectTypeValueOptions}
        selectedFunctionalAreas={this.getPreselectedObjectTypeValues(this.findSelectedType())}
        parentObjectType="settings"
        editable={true}
        removeIconSrc={this.props.removeIconSrc}
        labelTarget={''}
        selectedValueHandler={this.selectedObjectTypeValuesHandler}
        customSelectorStyle={true}
      />
    )
  }

  renderSingleFunctionalAreasSelector() {
    return(
      <SingleFunctionalAreaSelector
        ref={this.singleFunctionalAreasSelectorRef}
        functionalAreas={this.state.objectTypeValueOptions}
        preselectedFunctionalArea={this.getPreselectedObjectTypeValues(this.findSelectedType())}
        parentObjectType="settings"
        editable={true}
        removeIconSrc={this.props.removeIconSrc}
        labelTarget={''}
        selectedValueHandler={this.selectedObjectTypeValuesHandler}
      />
    )
  }

  renderObjectTypeValuesSelector(multipleValues) {
    if(multipleValues){
      return(
        <MultiSelector
          preselectedOptions={this.state.selectedObjectTypeValues || this.getPreselectedObjectTypeValues(this.findSelectedType())}
          optionsWithoutPreselected={this.state.objectTypeValueOptions}
          options={this.getObjectTypeValues(this.state.selectedObjectType.value)}
          selectedValueHandler={this.selectedObjectTypeValuesHandler}
          folded={this.state.selectedObjectType.value.internalTypeName == 'ErpSystem'}
        />
      )
    }else{
      return(
        <Select
          value={this.state.selectedObjectTypeValues}
          components={{DropdownIndicator}}
          hideSelectedOptions={true}
          isSearchable={false}
          closeMenuOnSelect={true}
          options={this.state.objectTypeValueOptions}
          onChange={this.onValueSelect}
          styles={singleSelectorCustomStyles}
        />
      )
    }
  }

  renderObjectTypeValueSelector() {
    if(this.state.selectedObjectType && this.state.selectedOperand){
      if(this.state.selectedOperand.value === 'in_list' &&
        this.state.selectedObjectType.value.internalTypeName === 'FunctionalArea'){
          return this.renderMultipleFunctionalAreasSelector()
      }else if(
        this.state.selectedOperand.value !== 'in_list' &&
        this.state.selectedOperand.value !== 'any' &&
        this.state.selectedObjectType.value.internalTypeName === 'FunctionalArea'){
          return this.renderSingleFunctionalAreasSelector()
      }else if(this.state.selectedOperand.value === 'in_list' &&
        this.state.selectedObjectType.value.internalOperandName !== 'FunctionalArea'){
          return this.renderObjectTypeValuesSelector(true)
      }else if(this.state.selectedOperand && this.state.selectedOperand.value !== 'any'){
        return this.renderObjectTypeValuesSelector(false)
      }
    }
  }

  getValuesToSubmit(values){
    if(this.state.selectedObjectType.value.internalTypeName === 'FunctionalArea'){
      return values.map(option => option.value).toString()
    }else{
      return values.map(option => option.value.value).toString()
    }
  }

  removeFormErrors() {
    this.setState({
      formErrors: null
    })
  }

  getFormErrors() {
    return(
      this.state.formErrors &&
        <div
          className={`${this.state.formErrors === null ? 'alert-hidden' : 'alert-shown'}`}
          onTransitionEnd={this.removeErrors()}
        >
          {this.state.formErrors}
        </div>
    )
  }

  submitForm = e => {
    e.preventDefault();
    $j.ajax({
      method: this.props.method,
      url: this.props.url,
      data: {
        workflow_condition: {
          object_type: this.state.selectedObjectType.value.internalTypeName,
          condition_type: this.state.selectedObjectType.value.conditionType,
          operand: this.state.selectedOperand?.value,
          object_value: this.getValuesToSubmit(this.state.selectedObjectTypeValues),
          object_attribute: 'id',
        },
        from_wf_definition_edit_page: true
      },
      success: (response) => {
        ReactRailsUJS.mountComponents();
        if(this.props.editMode && response.errors ){
          this.setState({
            formErrors: response.errors
          });
        }else{
          this.setState({
            selectedObjectType: '',
            selectedOperand: '',
            selectedObjectTypeValues: [],
            conditionType: '',
            operandOptions: [],
            objectTypeValueOptions: [],
            formErrors: response.errors
          });
        }
      }
    });
  }

  render() {
    return (
      this.state.isFormVisible &&
        [
          <div>
            <div
              className={`${this.state.formErrors ?  'alert-hidden' : 'alert-shown' }`}
              onTransitionEnd={() => removeFormErrors()}
            >
              {this.state.formErrors == null ? '' : <p className='notice'>{this.state.formErrors}</p>}
            </div>
          </div>,
          <form
            onSubmit={this.submitForm}
            className='workflow-def-new-condition-form'
          >
            {this.renderObjectTypeSelector()}
            <br/>
            {this.renderOperandSelector()}
            <br/>
            {this.renderObjectTypeValueSelector()}
            <br/>
            <br/>
            <button
              type="submit"
              className={`${this.props.editMode ? 'pretty-button mini green margin-right-10px' : 'pretty-button med'}`}
            >
              {this.props.editMode ? 'Update': 'Add'}
            </button>
            {this.props.editMode &&
              <button
                onClick={this.props.cancelEditFormHandler}
                className='pretty-button mini red'
              >
                Cancel
              </button>
            }
          </form>
        ]
    )
  }
}