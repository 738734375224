import OrderComponent from './OrderComponent';

class DataItem extends React.Component {
  getDataItemName() {
    const {
      dataItem: { objectLabel, term, dataModelObject }
    } = this.props;
    const termName = term ? term.name : null;
    const dataModelObjectName = dataModelObject ? dataModelObject.name : null;
    if (objectLabel && termName && dataModelObjectName) {
      return (
        [
          this.getObjectLabel(),
          this.getTermName(),
          this.getDataModelObjectName()
        ]
      );
    }
    if (objectLabel && termName) {
      if (objectLabel === termName)
        return this.getTermName();

      return (
        [
          this.getObjectLabel(),
          this.getTermName()
        ]
      );
    }
    if (objectLabel && dataModelObjectName) {
      if (objectLabel === dataModelObjectName)
        return this.getDataModelObjectName();

      return (
        [
          this.getObjectLabel(),
          this.getDataModelObjectName()
        ]
      );
    }
    return this.getObjectLabel();
  }

  getObjectLabel() {
    const { dataItem } = this.props;
    return (
      dataItem.objectLabel &&
      <div key={dataItem.objectLabel}>
        <img
          src={this.props.labelIconPath}
          alt="label"
          width="15px"
          height="15px"
          style={{ verticalAlign: 'text-bottom' }}
        />
        <div style={{ display: 'inline-block' }}>
          {dataItem.objectLabel}
        </div>
      </div>
    );
  }

  getTermName() {
    const { dataItem } = this.props;
    return (
      dataItem.termId &&
      <div key={dataItem.termId} title={dataItem.term.functionalDefinitionForHover}>
        <img
          src={this.props.termIconPath}
          alt="label"
          width="15px"
          height="15px"
          style={{ verticalAlign: 'text-bottom' }}
        />
        {!this.props.readOnly
          ? <a
            style={{ display: 'inline-block' }}
            href={`/institution/terms/${dataItem.termId}`}
          >
            {dataItem.term.name}
            </a>
          : <span style={{ display: 'inline-block' }}>
            {dataItem.term.name}
            </span>
        }
      </div>
    );
  }

  getDataModelObjectName() {
    const { dataItem } = this.props;
    return (
      dataItem.dataModelObjectId &&
      <div key={dataItem.dataModelObjectId}>
        <img
          src={this.props.dataModelIconPath}
          alt="label"
          width="15px"
          height="15px"
          style={{ verticalAlign: 'text-bottom' }}
        />
        {!this.props.readOnly
          ? <a
            style={{ display: 'inline-block' }}
            href={dataItem.dataModelObject.linkToObject}
          >
            {dataItem.dataModelObject.name}
            </a>
          : <span
            style={{ display: 'inline-block' }}
          >
            {dataItem.term.name}
            </span>}
      </div>
    );
  }

  getSourceDataSystem() {
    const { dataItem } = this.props;

    return (
      dataItem.erpSystemId && dataItem.erpSystem &&
        <div key={dataItem.erpSystemId}>
          {dataItem.erpSystem.dataApplication?.logoImageUrl ? <img src={dataItem.erpSystem.dataApplication.logoImageUrl} alt={dataItem.erpSystem.dataApplication.logoLabel} className='data-system-logo mapping' data-testid="data-source-erp-system-logo" /> : null}
          {dataItem.erpSystem.name}
        </div>
    )
  }

  getSortCriteria() {
    const { visibleTabs, dataItem } = this.props;
    const isTabVisible = visibleTabs.includes('Sort Criteria');
    return (isTabVisible && dataItem.reportSortCriteria.length > 0 &&
      <img
        src={this.props.sortIconPath}
        alt="label"
        width="15px"
        height="15px"
        style={{ verticalAlign: 'text-bottom' }}
      />);
  }

  getDisplay() {
    const { visibleTabs, dataItem, displayIconPath } = this.props;
    const isTabVisible = visibleTabs.includes('Display');
    return (isTabVisible && dataItem.reportDisplayItem &&
      <img
        src={displayIconPath}
        alt="label"
        width="15px"
        height="15px"
        style={{ verticalAlign: 'text-bottom' }}
      />);
  }

  getSelectCriteria() {
    const { visibleTabs, dataItem } = this.props;
    const isTabVisible = visibleTabs.includes('Selections');
    return (isTabVisible && dataItem.reportFilterCriteria.length > 0 &&
      <img
        src={this.props.selectIconPath}
        alt="label"
        width="15px"
        height="15px"
        style={{ verticalAlign: 'text-bottom' }}
      />);
  }

  getEditIcon() {
    return (
      <input
        type="button"
        title="edit"
        style={{
          width: '15px',
          height: '15px',
          verticalAlign: 'text-bottom',
          cursor: 'pointer',
          border: 'none',
          background: `url(${this.props.editIconPath}) no-repeat`
        }}
        onClick={() => this.handleDataItemEditIconClick(this.props.dataItem)}
      />
    );
  }

  getRemoveIcon() {
    return (
      <input
        type="button"
        title="remove"
        style={{
          width: '15px',
          height: '15px',
          verticalAlign: 'text-bottom',
          cursor: 'pointer',
          border: 'none',
          background: `url(${this.props.removeIconPath}) no-repeat`
        }}
        onClick={() => this.handleDataItemRemoveIconClick(this.props.dataItem)}
      />
    );
  }

  getSubmitOrderButton() {
    const { positionChanged } = this.props;
    return (
      <input
        type="button"
        title="submit"
        style={{
          width: '15px',
          height: '15px',
          verticalAlign: 'text-bottom',
          cursor: 'pointer',
          border: 'none',
          background: `url(${this.props.submitOrderIconPath}) no-repeat`
        }}
        onClick={positionChanged}
      />
    );
  }

  getHideOrderInputIcon() {
    const { toggleOrderFieldMode } = this.props;
    return (
      <input
        type="button"
        title="close"
        style={{
          width: '15px',
          height: '15px',
          verticalAlign: 'text-bottom',
          cursor: 'pointer',
          border: 'none',
          backgroundColor: 'white',
          fontWeight: 'bold'
        }}
        value="x"
        onClick={() => toggleOrderFieldMode(true)}
      />
    );
  }

  getEditButtons() {
    const {
      dataItem,
      orderFieldVisible,
      toggleOrderFieldMode,
      positionChanged,
      changePosition
    } = this.props;
    return (
      <div className="data-item-table__data-item-edit">
        <OrderComponent
          dataItemId={dataItem.id}
          value={dataItem.position}
          orderFieldVisible={orderFieldVisible}
          toggleOrderFieldMode={toggleOrderFieldMode}
          valueChanged={positionChanged}
          changePosition={changePosition}
          ariaLabel="Data Item order"
        />
        {
          orderFieldVisible
            ? [
              <span key="submit">{this.getSubmitOrderButton()}</span>,
              <span key="hide">{this.getHideOrderInputIcon()}</span>
              ]
            : [
              <span key="edit">{this.getEditIcon()}</span>,
              <span key="remove">{this.getRemoveIcon()}</span>
              ]
        }
      </div>
    );
  }

  handleDataItemEditIconClick(dataItem) {
    this.props.onDataItemModified(dataItem);
  }

  handleDataItemRemoveIconClick(dataItem) {
    this.props.onDataItemRemoved(dataItem, false);
  }

  render() {
    const { editable } = this.props;
    const { orderFieldVisible } = this.props.sharedProps;
    const dragHandleClassName = orderFieldVisible || !editable ? 'drag-handle-disabled' : 'drag-handle';
    return (
      <div key={this.getDataItemName()}>
        <div
          className={`data-item-table_cell data-item-table__zeroth-column ${dragHandleClassName}`}
        />
        <div className="data-item-table_cell data-item-table__first-column">
          <div style={{ float: 'left' }}>{this.getDataItemName()}</div>
          {editable && this.getEditButtons()}
        </div>
        <div className="data-item-table_cell data-item-table__second-column">
          {this.getSourceDataSystem()}
        </div>
        <div className="data-item-table_cell data-item-table__third-column">
          {this.getDisplay()}
        </div>
        <div className="data-item-table_cell data-item-table__forth-column">
          {this.getSelectCriteria()}
        </div>
        <div className="data-item-table_cell data-item-table__fifth-column">
          {this.getSortCriteria()}
        </div>
      </div>
    );
  }
}

DataItem.propTypes = {
  dataItem: PropTypes.object.isRequired,
  sharedProps: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  orderFieldVisible: PropTypes.bool.isRequired,
  onDataItemRemoved: PropTypes.func.isRequired,
  onDataItemModified: PropTypes.func.isRequired,
  changePosition: PropTypes.func.isRequired,
  toggleOrderFieldMode: PropTypes.func.isRequired,
  positionChanged: PropTypes.func.isRequired,
  submitOrderIconPath: PropTypes.string.isRequired,
  removeIconPath: PropTypes.string.isRequired,
  editIconPath: PropTypes.string.isRequired,
  selectIconPath: PropTypes.string.isRequired,
  displayIconPath: PropTypes.string.isRequired,
  sortIconPath: PropTypes.string.isRequired,
  dataModelIconPath: PropTypes.string.isRequired,
  termIconPath: PropTypes.string.isRequired,
  labelIconPath: PropTypes.string.isRequired,
  visibleTabs: PropTypes.arrayOf(Object).isRequired
};

export default DataItem;
