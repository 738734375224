export const dropdownRender = (function() {
  const searchNestedOptions = (values, searchCriteria) => {
    if (values.length == 0) return;

    const value = _.find(values, searchCriteria);
    if (value) return value;
    const children = _.flatMap(values, "children");

    return searchNestedOptions(children, searchCriteria);
  };

  const getNextIndex = (currentMenuValues, functionalAreas) => {
    if (!currentMenuValues.activeParentValue) return null;
    const activeParentValue = currentMenuValues.activeParentValue;
    const nestedOption = searchNestedOptions(functionalAreas, { id: activeParentValue });
    const options = nestedOption ? nestedOption.children : [];
    return _.findLastIndex(options, { id: currentMenuValues.activeValue });
  };

  const getItemsCount = (currentMenuValues, functionalAreas) => {
    const { activeParentValue } = currentMenuValues;
    if (!activeParentValue) {
      return 0;
    }

    const nestedOption = searchNestedOptions(functionalAreas, { id: activeParentValue });
    const options = nestedOption ? nestedOption.children : [];

    return options.size();
  };

  const calculateTopScroll = (container, currentMenuValues, functionalAreas) => {
    const containerHeight = container.clientHeight;
    const itemHeight = currentMenuValues.activeItem.clientHeight;
    const nextIndex = getNextIndex(currentMenuValues, functionalAreas);

    if (!nextIndex) {
      return 0;
    }

    const currenScrollList = (container.scrollTop / containerHeight).floor();
    const newScrollList = ((nextIndex * itemHeight) / containerHeight).floor();

    if (currenScrollList !== newScrollList && (container.scrollTop % itemHeight === 0)) {
      if (newScrollList > currenScrollList) {
        return nextIndex * itemHeight;
      }

      return newScrollList * containerHeight;
    }

    const itemsCount = getItemsCount(currentMenuValues, functionalAreas);
    const fullHeignt = itemsCount * itemHeight;
    const listItemsCount = containerHeight / itemHeight;
    const isTransitionFromLastList = nextIndex === (itemsCount - listItemsCount);

    if (isTransitionFromLastList) {
      const listsCount = (fullHeignt / containerHeight).floor();
      return (listsCount - 1) * containerHeight;
    }

    return container.scrollTop;
  };

  const getActiveMenuElements = () => {
    const element = _.find(document.getElementsByClassName("rc-cascader-menus rc-cascader-menus-placement-bottomLeft"),
      function(e){
        return !e.classList.contains("rc-cascader-menus-hidden");
    });

    if (!element) return [];

    return element.querySelectorAll(".rc-cascader-menu-item-active");
  };

  const addMenuItemAdditionalAttributes = (functionalAreas) => {
    _.each(document.getElementsByClassName("rc-cascader-menu-item"), (item) => {
      const
        title = item.getAttribute("title"),
        fa = searchNestedOptions(functionalAreas, { name: title });
      if (fa && !fa.available)
        item.setAttribute('style', "cursor: not-allowed;");
      
      if (fa && fa.description)
        item.setAttribute('title', fa.description);
    });
  }

  return (menus, functionalAreas) => {
    const next = menus.props;

    if (_.isEmpty(next))
      return <div>{menus}</div>;

    const activeValue = next.activeValue[next.activeValue.length - 1];
    const activeValueIndex = next.activeValue.lastIndexOf(activeValue);
    const activeParentValue = menus.props.activeValue[activeValueIndex - 1];
    const menuActiveElements = getActiveMenuElements();
    const activeItem = menuActiveElements[parseInt(activeValueIndex)];

    if (activeItem){
      const container = activeItem.parentElement;

      const currentMenuValues = {
        activeItem,
        activeValue,
        activeValueIndex,
        activeParentValue,
      };

      container.scrollTop = calculateTopScroll(container, currentMenuValues, functionalAreas);
    }

    setTimeout(() => {
      // hack. The Cascader lib doesn't provide a neat way to alter the dropdown once it's rendered
      // So simply wait for the component to render, and edit them after the fact
      addMenuItemAdditionalAttributes(functionalAreas);
    }, 250);

    return <div>{menus}</div>;
  };
}())

export const createOptions = (function() {
  const parseFunctionalArea = (fa, path) => {
    const itemPath = [path, fa.id];
    if (_.isEmpty(fa.children)) {
      return {
        value: fa.id,
        label: fa.name,
        description: fa.description,
        path: itemPath,
        disabled: !fa.availableToDrilldown,
        faAvailable: fa.available,
      };
    }
    return {
      value: fa.id,
      label: fa.name,
      description: fa.description,
      path: itemPath,
      disabled: !fa.availableToDrilldown,
      faAvailable: fa.available,
      children: fa.children.map((childArea) =>
        parseFunctionalArea(childArea, itemPath)
      ),
    };
  };

  return functionalAreas => functionalAreas.map(parseFunctionalArea);
}());

export const createFlattenOptions = (function() {
  const parseFlatFunctionalArea = (fa, path) => {
    let result = [];
    const itemPath = [...path, fa.id];

    if (fa.available === true) {
      result.push({
        value: fa.id,
        label: fa.name,
        description: fa.description,
        path: itemPath,
        faAvailable: fa.available
      });
    }

    if (!_.isEmpty(fa.children)) {
      return _.concat(result, _.flatMap(fa.children, (child) =>
        parseFlatFunctionalArea(child, itemPath)
      ));
    }

    return result;
  };

  return (functionalAreas) => {
    let result = _.flatMap(functionalAreas, fa => parseFlatFunctionalArea(fa, []));
    return _.uniqBy(result, "value");
  };
}());
