import {
  render,
  fireEvent,
  getNodeText,
  waitFor,
} from "@testing-library/react";
import _ from "lodash";

import DataSystemOrdering from "./DataSystemOrdering";

let nextDisplayOrder = 0

afterEach(() => {
  nextDisplayOrder = 0
})

describe("<DataSystemOrdering />", () => {
  it("changes the input back to a link after submitting the ordering", async () => {
    const orderedItems = [buildItem(), buildItem()];
    const component = exercise({ orderedItems });

    const orderLinks = await component.getOrderLinks();
    fireEvent.click(orderLinks[0]);
    const secondOrderLinks = await component.getOrderLinks();
    expect(secondOrderLinks.length).toEqual(1);

    const orderInputs = await component.getOrderInputs();
    fireEvent.change(orderInputs[0], { target: { value: "2" } });
    fireEvent.keyDown(orderInputs[0], { key: "Enter", code: "Enter" });
    const thirdOrderLinks = await component.getOrderLinks();
    expect(thirdOrderLinks.length).toBeGreaterThan(1);
  });

  it("renders items in the correct order", async () => {
    const orderedItems = [
      buildItem({ name: "Data System 1", display_order: 2 }),
      buildItem({ name: "Data System 2", display_order: 1 }),
    ];

    const component = exercise({ orderedItems });

    const newDataSystemOrder = await component.getList();
    expect(newDataSystemOrder).toEqual(["Data System 2", "Data System 1"]);
  });

  it("supports manual reordering via the enter key", async () => {
    const orderedItems = [
      buildItem({ name: "Data System 1", display_order: 1 }),
      buildItem({ name: "Data System 2", display_order: 2 }),
    ];
    const component = exercise({ orderedItems });

    const initialDataSystemOrder = await component.getList();
    expect(initialDataSystemOrder).toEqual(_.map(orderedItems, "name"));
    const orderLinks = await component.getOrderLinks();
    fireEvent.click(orderLinks[0]);
    const orderInputs = await component.getOrderInputs();
    fireEvent.change(orderInputs[0], { target: { value: "2" } });
    fireEvent.keyDown(orderInputs[0], { key: "Enter", code: "Enter" });

    await waitFor(async () => {
      const newDataSystemOrder = await component.getList();
      expect(newDataSystemOrder).toEqual(["Data System 2", "Data System 1"]);
    });
  });

  it("supports manual reordering via the submit button", async () => {
    const orderedItems = [
      buildItem({ name: "Data System 1", display_order: 1 }),
      buildItem({ name: "Data System 2", display_order: 2 }),
    ];
    const component = exercise({ orderedItems });

    const orderLinks = await component.getOrderLinks();
    fireEvent.click(orderLinks[0]);
    const secondOrderLinks = await component.getOrderLinks();
    expect(secondOrderLinks.length).toEqual(1);

    const orderInputs = await component.getOrderInputs();
    fireEvent.change(orderInputs[0], { target: { value: "2" } });
    fireEvent.click((await component.getSubmitButtons())[0]);
    await waitFor(async () => {
      const newDataSystemOrder = await component.getList();
      expect(newDataSystemOrder).toEqual(["Data System 2", "Data System 1"]);
    });
  });

  it("updates the order of other items in the list", async () => {
    const orderedItems = [buildItem(), buildItem()];
    const component = exercise({ orderedItems });

    const orderLinks = await component.getOrderLinks();
    fireEvent.click(orderLinks[0]);
    const secondOrderLinks = await component.getOrderLinks();
    expect(secondOrderLinks.length).toEqual(1);

    const orderInputs = await component.getOrderInputs();
    fireEvent.change(orderInputs[0], { target: { value: "2" } });
    fireEvent.keyDown(orderInputs[0], { key: "Enter", code: "Enter" });
    const thirdOrderLinks = await component.getOrderLinks();
    expect(thirdOrderLinks.length).toBeGreaterThan(1);
  })

  it("updates the numbers of each input", async () => {
    const orderedItems = [buildItem(), buildItem()]
    const component = exercise({ orderedItems })
    const orderLinks = await component.getOrderLinks()
    orderLinks[0].click()
    orderLinks[1].click()
    const orderInputs = await component.getOrderInputs()
    fireEvent.change(orderInputs[0], { target: { value: "2" } })
    fireEvent.keyDown(orderInputs[0], { key: "Enter", code: "Enter" });

    expect(orderInputs[1]).toHaveValue("1")
  })

  it("supports canceling the reorder", async () => {
    const component = exercise();

    const orderLinks = await component.getOrderLinks();
    fireEvent.click(orderLinks[0]);
    const orderInputs = await component.getOrderInputs();
    const cancelButtons = await component.getCancelButtons();
    fireEvent.click(cancelButtons[0]);

    expect(orderInputs[0]).not.toBeInTheDocument();
  });

  it("supports removing items from the order", async () => {
    const item = buildItem();
    const component = exercise({ orderedItems: [item] });

    expect(component.container).toHaveTextContent(item.name);
    const orderLinks = await component.getOrderLinks();
    fireEvent.click(orderLinks[0]);
    const removeLinks = await component.getRemoveLinks();
    fireEvent.click(removeLinks[0]);

    expect(await component.queryByTestId("order.listItem")).toBeNull();
  });

  it("supports adding items to the order", async () => {
    const item = buildItem();
    const firstUnorderedItem = buildItem({ displayOrder: 0 });
    const secondUnorderedItem = buildItem({ displayOrder: 0 });
    const component = exercise({
      orderedItems: [item],
      unorderedItems: [firstUnorderedItem, secondUnorderedItem],
    });

    expect(await component.getList()).toEqual([item.name]);
    const orderLinks = await component.getOrderLinks();
    fireEvent.click(orderLinks[0]);
    fireEvent.change(component.getByTestId("order.add"), {
      target: { value: firstUnorderedItem.id },
    });
    await waitFor(async () =>
      expect(await component.getList()).toEqual([
        item.name,
        firstUnorderedItem.name,
      ])
    );

    fireEvent.change(component.getByTestId("order.add"), {
      target: { value: secondUnorderedItem.id },
    });
    await waitFor(async () =>
      expect(await component.getList()).toEqual([
        item.name,
        firstUnorderedItem.name,
        secondUnorderedItem.name,
      ])
    );
  });

  it("supports adding items that have been removed", async () => {
    const item = buildItem();
    const component = exercise({
      orderedItems: [item],
    });

    const removeLinks = await component.getRemoveLinks();
    fireEvent.click(removeLinks[0]);
    expect(removeLinks[0]).not.toBeInTheDocument();
    fireEvent.change(component.getByTestId("order.add"), {
      target: { value: item.id },
    });
    await waitFor(async () =>
      expect(await component.getList()).toEqual([item.name])
    );
  });
});

const exercise = (
  { orderedItems, unorderedItems } = {
    orderedItems: [buildItem()],
    unorderedItems: [],
  }
) => {
  const component = render(
    <DataSystemOrdering ordered_items={orderedItems} unordered_items={unorderedItems} />
  );

  return {
    ...component,

    async getList() {
      const elements = await component.findAllByTestId("order.listItem");
      return elements.map(getNodeText);
    },

    async getOrderLinks() {
      return component.findAllByTestId("order.link");
    },

    async getOrderInputs() {
      return component.findAllByTestId("order.input");
    },

    async getSubmitButtons() {
      return component.findAllByTestId("order.submit");
    },

    async getCancelButtons() {
      return component.findAllByTestId("order.cancel");
    },

    async getRemoveLinks() {
      return component.findAllByTestId("order.remove");
    },
  };
};

const buildItem = (args = {}) => {
  const {
    id = Number(_.uniqueId()),
    name = `Data System ${id}`,
    displayOrder = getNextDisplayOrder(),
  } = args;

  return {
    id,
    name,
    display_order: displayOrder,
    ...args,
  };
};

const getNextDisplayOrder = () => {
  nextDisplayOrder += 1
  return nextDisplayOrder
}