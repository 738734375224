import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

const LimitList = 2,
  MenuList = props => (
    <components.MenuList {...props}>
      {Array.isArray(props.children) ? props.children.slice(0, LimitList) : props.children}
    </components.MenuList>
  ),
  DropdownIndicator = props => (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <span />
      </components.DropdownIndicator>
    )
  );

export default class SingleValueSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: this.prepareData(props.optionsForSelect),
      selectedOption: this.prepareData(props.selectedValue),
      openMenu: false,
      customSelectorClass: props.customSelectorClass,
      customHeight: props.customHeight,
      fieldName: props.fieldName 
    };
  }

  prepareData(data) {
    if (!data) return null;

    return Array.isArray(data) ? data.map(this.serializeOption)
      : this.serializeOption(data);
  }

  serializeOption(option) {
    return { value: option.id, label: option.name };
  }

  
  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  filterOption = (option, searchText) => {
    const { label } = option;

    if (
      label.toLowerCase().includes(searchText.toLowerCase())
    ) return true;

    return false;
  };

  handleInputChange = (query, { action }) => {
    if (action === 'input-change' && query.length >= 1)
      this.setState({ openMenu: true });
    else
      this.setState({ openMenu: false });
  };

  hideMenu = () => {
    this.setState({ openMenu: false });
  };

  onSelectorChange = (e) => {
    this.handleChange(e)
    this.hideMenu();
  }

  render() {
    const {
      fieldName,
      ariaLabel,
      isDisabled,
      inModal,
      isClearable,
      customSelectorClass,
      customHeight
    } = this.props

    const { 
      selectedOption, 
      options 
    } = this.state

    const menuPortalTarget = inModal ? document.body : null
    const controlStyle = {
      control: base => ({
        ...base, height: customHeight || 32, minHeight: customHeight || 32, maxHeight: 32,
      }),
    }
    const styles = inModal ? { ...controlStyle, menuPortal: base => ({ ...base, zIndex: 10001 }) } : controlStyle;

    return (
      <div className={customSelectorClass || "user-select"}>
        <Select
          name={fieldName}
          value={selectedOption}
          onChange={this.handleChange}
          maxMenuHeight={250}
          options={options}
          filterOption={this.filterOption}
          components={{DropdownIndicator}}
          placeholder='Type or Select'
          aria-label={ariaLabel}
          isDisabled={isDisabled}
          menuPortalTarget={menuPortalTarget}
          styles={styles}
          isClearable={isClearable}
          classNamePrefix="UserSelector"
        />
      </div>
    );
  }
}

SingleValueSelector.propTypes = {
  optionsForSelect: PropTypes.arrayOf(Object).isRequired,
  selectedValue: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  fieldName: PropTypes.string,
  ariaLabel: PropTypes.string,
  isDisabled: PropTypes.bool,
  inModal: PropTypes.bool,
  isClearable: PropTypes.bool,
};

SingleValueSelector.defaultProps = {
  selectedValue: null,
  fieldName: null,
  ariaLabel: null,
  isDisabled: false,
  inModal: false,
  isClearable: true,
};
