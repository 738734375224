import {
  render, fireEvent, within, screen
} from '@testing-library/react';
import JobsFilters from './JobsFilters';

describe('<JobsFilters />', () => {
  it('should render correct filters set', async () => {
    const component = exercise();
    const jobIdInput = await component.getJobIdInput();
    const selectors = await component.getSelectors();
    const dropdowns = await component.getDropdowns();
    const startInput = await component.getStartDateInput();
    const endInput = await component.getEndDateInput();
    const filterLabel = await component.getFilterLabel();
    const dateLabel = await component.getDateLabel();
    const fromLabel = await component.getFromLabel();
    const toLabel = await component.getToLabel();
    expect(startInput).toBeVisible();
    expect(endInput).toBeVisible();
    expect(filterLabel).toBeVisible();
    expect(dateLabel).toBeVisible();
    expect(fromLabel).toBeVisible();
    expect(toLabel).toBeVisible();
    expect(jobIdInput).toBeVisible();
    expect(jobIdInput).toHaveValue('');
    expect(selectors.length).toBe(2);
    expect(dropdowns.length).toBe(2);
  });

  it('should have reset button', async () => {
    const component = exercise();
    const jobIdInput = await component.getJobIdInput();
    fireEvent.change(jobIdInput, { target: { value: '223' } });
    expect(jobIdInput.value).toBe('223');
    const resetButton = await component.getResetButton();
    expect(resetButton).toBeVisible();
  });

  it('should have correct options for my jobs tab', async () => {
    const component = exercise();
    const selectors = await component.getSelectors();
    fireEvent.change(selectors[0], { target: { value: 'e' } });
    const { getByText } = within(screen.getByTestId('filtersContainer'));
    expect(getByText('Error')).toBeInTheDocument();
    expect(getByText('Success')).toBeInTheDocument();
    fireEvent.change(selectors[0], { target: { value: 'w' } });
    expect(getByText('Warning')).toBeInTheDocument();
    fireEvent.change(selectors[1], { target: { value: 'i' } });
    expect(getByText('Batch Community Copy')).toBeInTheDocument();
    expect(getByText('Data Item Import')).toBeInTheDocument();
    expect(getByText('Definition Import')).toBeInTheDocument();
    expect(getByText('Import Schema')).toBeInTheDocument();
    expect(getByText('Specification Import')).toBeInTheDocument();
  });

  it('should have correct options for api jobs tab', async () => {
    const component = exercise(true);
    const selectors = await component.getSelectors();
    fireEvent.change(selectors[1], { target: { value: 'i' } });
    const { getByText } = within(screen.getByTestId('filtersContainer'));
    expect(getByText('Import Schema')).toBeInTheDocument();
    expect(getByText('Specification API Import')).toBeInTheDocument();
  });

  it('should have serchable selectors', async () => {
    const component = exercise();
    const selectors = await component.getSelectors();
    fireEvent.change(selectors[1], { target: { value: 'Batch Community' } });
    const { getByText } = within(screen.getByTestId('filtersContainer'));
    expect(getByText('Batch Community Copy')).toBeInTheDocument();
    fireEvent.change(selectors[0], { target: { value: 'Warn' } });
    expect(getByText('Warning')).toBeInTheDocument();
  });
});

const exercise = (integrationJobs) => {
  const component = render(<JobsFilters
    updateUrl="users/user/background_jobs"
    integrationJobs={integrationJobs}
    selectedStartDate={new Date('11/11/2001')}
    selectedEndDate={new Date('11/11/2022')}
    dateFormat="MM/dd/yy"
    today={new Date()}
    initStartDate={new Date('11/11/2001')}
    initEndDate={new Date('11/11/2022')}
    helpPoints={{ point1: '', point2: '', point3: '', point4: '', point5: '' , point6: '' , point7: '' , point8: '' , point9: '' , point10: ''  }}
  />);

  return {
    ...component,

    async getJobIdInput() {
      return component.findByTitle('Job Id Filter Input');
    },

    async getResetButton() {
      return component.findByTitle('Reset Filters');
    },

    async getSelectors() {
      return component.findAllByRole('combobox');
    },

    async getDropdowns() {
      return component.findAllByTitle('Dropdown Indicator');
    },

    async getStartDateInput() {
      return component.findByTitle('Background Jobs Filter Start Date Calendar');
    },

    async getEndDateInput() {
      return component.findByTitle('Background Jobs Filter End Date Calendar');
    },

    async getFilterLabel() {
      return component.findByTitle('Filter By Label');
    },

    async getDateLabel() {
      return component.findByTitle('Date Label');
    },

    async getFromLabel() {
      return component.findByTitle('From Date Label');
    },

    async getToLabel() {
      return component.findByTitle('To Date Label');
    }
  };
};
