/**
 * DE1220 - Resize width of image containers on Edit Specification and Collection pages according to given image width.
 */
document.addEventListener('DOMContentLoaded', () => {
  const padding = 12; // padding for image container
  document.querySelectorAll('.img-attachment').forEach((el) => {
    const pool = setInterval(() => {
      if (el.naturalWidth) {
        clearInterval(pool);
        const span = el.closest('.attachment_link_icon');
        if (span) {
          span.style.width = `${(el.width + padding)}px`;
        }
      }
    });
  });
});
