import HelpPoint from "../../Helpers/HelpPoint";

export default class DDTDelimiter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
    };
  }

  outputDelimiterContent(props) {
    const { delimiter, delimiterEditMode } = props;
    let el;
    if (delimiterEditMode) {
      el = (
        <div>
          <textarea
            onChange={props.onDelimiterChange}
            value={delimiter || ''}
            id="delimiter"
          />
          <br />
          <button
            onClick={props.onDelimiterCancel}
            style={{
              float: 'initial',
              display: 'inline-block',
            }}
          >
            Cancel
          </button>
          <button
            onClick={props.onDelimiterSave}
            style={{
              float: 'initial',
              display: 'inline-block',
            }}
          >
            Save
          </button>
        </div>
      );
    } else {
      el = (
        <p>
          {
            delimiter || 'None'
          }
        </p>
      );
    }
    return el;
  }

  render() {
    const { props } = this;
    return (
      <div className="span-13">
        <div>
          <label style={{ display: 'inline-block' }} >Delimiter</label>
          {
            props.editable ? (<img
              src={props.imageEdit}
              style={{
                verticalAlign: 'middle',
                marginLeft: '5px',
              }}
              alt="Edit"
              onClick={this.props.toggleDelimiterEditMode}
            />) : ''
          }
          <HelpPoint helpPoint={this.props.helpPoints.point5} />
        </div>
        {this.outputDelimiterContent(props)}
      </div>
    );
  }
}
