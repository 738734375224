class DataItemsAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.handleReportItemsSelectChange = this.handleReportItemsSelectChange.bind(this);

    this.state = {
      ...props
    };
  }

  onUnload = (e) => {
    const selectedValue = this.state.createdReportItemId !== null && this.state.createdReportItemId !== '';
    if (selectedValue) {
      e.preventDefault();
      e.returnValue = '';
    }
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.onUnload);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload);
  }

  componentWillReceiveProps(nextProps) {
    const { createdReportItemId } = nextProps;
    if (createdReportItemId) {
      this.setState({
        createdReportItemId
      });
    }
  }

  handleReportItemsSelectChange(selectedOption) {
    const value = (selectedOption && selectedOption.value) || '';
    this.setState({
      createdReportItemId: value
    });
  }

  render() {
    const {
      reportItems,
      selectBoxName,
      selectBoxId
    } = this.props;
    let { createdReportItemId } = this.state;
    if (!createdReportItemId)
      createdReportItemId = '';

    const options = reportItems.map(reportItem => ({ value: reportItem.id, label: reportItem.objectLabel }));
    const selectedOption = options.filter(option => option.value === createdReportItemId);

    return (
      <div>
        <span className="report-items-select-span">
          <OptimizedSelect
            id={selectBoxId}
            name={selectBoxName}
            options={options}
            value={selectedOption}
            onChange={this.handleReportItemsSelectChange}
          />
        </span>
      </div>
    );
  }
}

export default DataItemsAutocomplete;
