function DMCLinkToRelatedObject(props) {
  const { node } = props;
  const { linkToRelatedObject, relatedObject } = node;
  let relatedObjectName;
  if (!linkToRelatedObject) return null;
  if (node.type === 'LineageTarget') {
    const { reportItem } = node;
    const { dataModelObject, dataModelObjectType, term } = reportItem;
    if (dataModelObjectType === 'Term' && term)
      relatedObjectName = term.name;
    else if (dataModelObject)
      relatedObjectName = dataModelObject.name;
  } else
    relatedObjectName = relatedObject.name;

  return (
    <a
      href={linkToRelatedObject}
      target="_blank"
    >
      {relatedObjectName}
    </a>
  );
}

export default DMCLinkToRelatedObject;
