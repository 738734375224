import HelpPoint from '../../Helpers/HelpPoint'
import DisplayModeSwitchForm from './DisplayModeSwitchForm'


export default class DisplayModeComponent extends React.Component {
  constructor(props) {
    super(props);
    this.showDisplayModeForm = this.showDisplayModeForm.bind(this);
    this.state = {
      showDisplayModeSwitchForm: false,
      ...props,
    };
    this.onModeSwitch = this.onModeSwitch.bind(this);
    this.onCloseForm = this.onCloseForm.bind(this);
  }

  showDisplayModeForm(e) {
    e.preventDefault();
    this.setState({
      showDisplayModeSwitchForm: true,
    });
    return false;
  }

  onModeSwitch(mode) {
    this.props.onModeSwitch(mode);
    this.setState({
      showDisplayModeSwitchForm: false,
      mode,
    });
  }

  onCloseForm() {
    this.setState({
      showDisplayModeSwitchForm: false,
    });
  }

  render() {
    const { showDisplayModeSwitchForm } = this.state;
    const { mode } = this.props;
    return (
      <span>
        <a href="#"
          onClick={e => this.showDisplayModeForm(e)}
        >
          Change Display Mode
          <HelpPoint helpPoint={this.props.helpPoint} />
        </a>
        <DisplayModeSwitchForm
          visible={showDisplayModeSwitchForm}
          onSwitch={this.onModeSwitch}
          onCancel={this.onCloseForm}
          mode={mode}
        />
      </span>
    );
  }
}
