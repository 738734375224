import DraggableDataItem from './DraggableDataItem';

class DataItemTableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.getDataItems = this.getDataItems.bind(this);
    this.getDataItemsWithDisplayType = this.getDataItemsWithDisplayType.bind(this);
    this.getDataItemsWithSortType = this.getDataItemsWithSortType.bind(this);
    this.getDataItemsWithSelectType = this.getDataItemsWithSelectType.bind(this);
    this.getDataItemsWithNoneType = this.getDataItemsWithNoneType.bind(this);
    this.reorder = this.reorder.bind(this);
    this.toggleOrderFieldMode = this.toggleOrderFieldMode.bind(this);
    this.positionChanged = this.positionChanged.bind(this);
    this.changePosition = this.changePosition.bind(this);
    this.state = {
      orderFieldVisible: false
    };
  }

  getDataItems(filterType) {
    const { dataItems } = this.props;
    switch (filterType) {
      case 'display':
        return this.getDataItemsWithDisplayType(dataItems);
      case 'select':
        return this.getDataItemsWithSelectType(dataItems);
      case 'sort':
        return this.getDataItemsWithSortType(dataItems);
      case 'none':
        return this.getDataItemsWithNoneType(dataItems);
      default:
        return dataItems;
    }
  }

  getDataItemsWithDisplayType(dataItems) {
    const dataItemsWithDisplayType = dataItems.map(dataItem => dataItem.reportDisplayItem && dataItem);
    return dataItemsWithDisplayType.filter(Boolean);
  }

  getDataItemsWithSelectType(dataItems) {
    const dataItemsWithSelectType = dataItems.map(dataItem => dataItem.reportFilterCriteria.length > 0 && dataItem);
    return dataItemsWithSelectType.filter(Boolean);
  }

  getDataItemsWithSortType(dataItems) {
    const dataItemsWithSortType = dataItems.map(dataItem => dataItem.reportSortCriteria.length > 0 && dataItem);
    return dataItemsWithSortType.filter(Boolean);
  }

  getDataItemsWithNoneType(dataItems) {
    const dataItemsWithNoneType = dataItems.map(dataItem => (
      _.isEmpty(dataItem.reportDisplayItem) &&
      _.isEmpty(dataItem.reportSortCriteria) &&
      _.isEmpty(dataItem.reportFilterCriteria) && dataItem
    ));
    return dataItemsWithNoneType.filter(Boolean);
  }

  positionChanged() {
    this.updatePositions();
  }

  reorder(_event, item, index, newIndex, list) {
    if (index === newIndex) return;

    // Calculates correct position if the numering not in order eg. [1,4,5,6]
    let calculatedElPos = newIndex > 0 ? list[newIndex - 1].position : 0;
    if (newIndex > 0 && newIndex > index)
      calculatedElPos = list[newIndex - 1].position;
    else if (newIndex > 0 && newIndex < index)
      calculatedElPos = list[newIndex - 1].position + 1;
    else
      calculatedElPos = 1;

    this.updatePositions(item.id, calculatedElPos);
  }

  validatePositions(dataItems) {
    return !dataItems.find(item => (
      item.position === '' || item.position === null || item.position === undefined
    ));
  }

  updatePositions(id, position) {
    const self = this;
    let dis;
    if (id && position)
      dis = [{ id, position }];
    else {
      const { dataItems } = this.props;
      if (!this.validatePositions(dataItems)) {
        jAlert('Error', 'Order field can not be blank.');
        return;
      }
      dis = dataItems.map(itm => (
        { id: itm.id, position: itm.position }
      ));
    }

    $j.ajax({
      url: `${this.props.controllerUrl}/sort.json`,
      method: 'PATCH',
      data: { data_items: dis },
      success: (response) => {
        const dataItems = response.report_items;
        self.props.refreshDataItems(dataItems);
      }
    }).error((xhr) => {
      location.reload();
    });
  }

  toggleOrderFieldMode(restoreOriginalOrder) {
    const { orderFieldVisible } = this.state;
    if (restoreOriginalOrder)
      this.props.restoreOriginalDataItems();

    this.setState({
      orderFieldVisible: !orderFieldVisible
    });
    if (orderFieldVisible)
      $j('.data-item-table__overlay').fadeOut({ duration: 500 });
    else
      $j('.data-item-table__overlay').fadeIn({ duration: 500 });

    this.props.setCanAddNewDataItem(orderFieldVisible);
  }

  reorderItems(dataItems, originalPosition, newPosition) {
    const originalPositionLessThanNew = originalPosition < newPosition;
    let items;
    if (originalPositionLessThanNew) {
      items = dataItems.findAll(item =>
        (item.position > originalPosition && item.position <= newPosition));
    } else {
      items = dataItems.findAll(item =>
        (item.position < originalPosition && item.position >= newPosition));
    }
    const orderedItems = items.sort((item1, item2) => (item1.position - item2.position));
    orderedItems.forEach((item) => {
      if (originalPositionLessThanNew)
        item.position -= 1;
      else
        item.position += 1;
    });
  }

  changePosition(dataItemId, position) {
    const { dataItems } = this.props;
    const changedItems = dataItems.slice(0);
    const editedItem = changedItems.find(item => (item.id === dataItemId));
    this.reorderItems(changedItems, editedItem.position, position);
    editedItem.position = position <= dataItems.length ? position : dataItems.length;
    this.props.refreshDataItems(changedItems, false, false);
  }

  render() {
    const { editable } = this.props;
    const dataItems = this.getDataItems(this.props.filterType);
    const { orderFieldVisible } = this.state;
    const sharedProps = {
      ...this.props,
      orderFieldVisible,
      toggleOrderFieldMode: this.toggleOrderFieldMode,
      positionChanged: this.positionChanged,
      changePosition: this.changePosition
    };

    return (
      <div>
        <div className="data-item-table data-item-table__overlayable">
          <div className="header-table data-item-table__table">
            <div className="data-item-table__header">
              <div className="data-item-table__row">
                <div className="data-item-table_head-cell data-item-table__zeroth-column" />
                <div className="data-item-table_head-cell data-item-table__first-column">
                  Data Item
                  <HelpPoint helpPoint={this.props.helpPoints.point5} />
                </div>
                <div className="data-item-table_head-cell data-item-table__second-column">
                  Source Data System
                  <HelpPoint helpPoint={this.props.helpPoints.point6} />
                </div>
                <div className="data-item-table_head-cell data-item-table__third-column">
                  Display
                  <HelpPoint helpPoint={this.props.helpPoints.point7} />
                </div>
                <div className="data-item-table_head-cell data-item-table__fifth-column" >
                  Select
                  <HelpPoint helpPoint={this.props.helpPoints.point8} />
                </div>
                <div className="data-item-table_head-cell data-item-table__sixth-column">
                  Sort
                  <HelpPoint helpPoint={this.props.helpPoints.point9} />
                </div>
              </div>
            </div>
          </div>
          <Reorder
            itemKey="id"
            // Lock horizontal to have a vertical list
            lock="horizontal"
            // The milliseconds to hold an item for before dragging begins
            holdTime="200"
            // The list to display
            list={dataItems}
            // A template to display for each list item
            template={DraggableDataItem}
            // Function that is called once a reorder has been performed
            callback={this.reorder}
            // Class to be applied to the outer list element
            listClass="data-item-table__body"
            // Class to be applied to each list item"s wrapper element
            itemClass="list-item"
            // A function to be called if a list item is clicked (before hold time is up)
            // itemClicked={this.itemClicked}
            // The item to be selected (adds "selected" class)
            // selected={this.state.selected}
            selected="selected"
            // The key to compare from the selected item object with each item object
            selectedKey="uuid"
            // Allows reordering to be disabled
            disableReorder={!editable || orderFieldVisible}
            sharedProps={sharedProps}
          />
        </div>
        <div
          className="data-item-table__overlay"
          onClick={() => { editable && orderFieldVisible && this.toggleOrderFieldMode(true); }}
        />
      </div>
    );
  }
}

export default DataItemTableComponent;
