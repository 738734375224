import { GlobalHotKeys, configure } from "react-hotkeys";
import React from "react";

function getModalTrigger(elementInFocusIsInsideModal, modal, elementInFocusIsInsideTechInfo, techInfoContainer) {
  if (elementInFocusIsInsideModal) {
    return document.querySelector(`[data-trigger-id='${modal.dataset.triggerButtonId}']`);
  }
  if (elementInFocusIsInsideTechInfo) {
    return techInfoContainer.querySelector("[data-browse-data-systems-modal-trigger='true']");
  }
  return document.querySelector(
    "[data-browse-data-systems-modal-trigger='true']:not(.disabled)"
  );
}

function getGrabbedData(key) {
  return sessionStorage.getItem(`data-grabbed-${key}`);
}

function pasteData(elementInFocus, dataText) {
  const schemaTableColumnContainer = elementInFocus.closest(".technical-quality-rule__column-placeholder");
  const nestedReportingColumn = elementInFocus.name && elementInFocus.name.includes("reporting_columns");
  const isTextArea = elementInFocus.type && elementInFocus.type === "textarea";

  if (schemaTableColumnContainer) return pasteInSchemaTableColumn(schemaTableColumnContainer);
  if (isTextArea) return elementInFocus.value = elementInFocus.value + dataText;
  if (nestedReportingColumn) return pasteInNestedReportingColumn(elementInFocus, dataText);
  elementInFocus.value = dataText; // regular text input
}

function pasteInSchemaTableColumn(container) {
  const objectTypesForSchemaTableColumn = ["Column", "Index"];
  if (objectTypesForSchemaTableColumn.include(getGrabbedData("object"))) {
    container.querySelector(".schema-name").value = getGrabbedData("schema");
    container.querySelector(".table-name").value = getGrabbedData("table");
    container.querySelector(".column-name").value = getGrabbedData("column");
  }
}

function pasteInNestedReportingColumn(elementInFocus, dataText) {
  // Fixes adding/removing Reporting Column fields causing pasted values to disappear
  const newInput = elementInFocus.cloneNode(true);
  newInput.value = dataText;
  elementInFocus.replaceWith(newInput);
}

export default class DataModelKeyShortcuts extends React.Component {
  constructor(props) {
    super(props);
    this.allowHotKeysInAllTags();
  }

  allowHotKeysInAllTags() {
    configure({ ignoreTags: [] });
  }

  switchModalFocus(e) {
    const modal = document.querySelector("[data-browse-data-systems-modal='true']");
    const elementInFocusIsCkeditor = e.id && e.id.include("cke_");
    const elementInFocus = elementInFocusIsCkeditor ? e.element.$ : e.target;
    const elementInFocusIsInsideModal = !!elementInFocus.closest(".ui-dialog");
    const modalIsCreatedAndOpen = !!(modal && modal.textContent.length > 0);
    const firstModalElement = modal && modal.parentElement.querySelector(".ui-button");
    const techInfoContainer = elementInFocus.closest("[data-tech-info-container='true']");
    const elementInFocusIsInsideTechInfo = !!techInfoContainer;
    const modalTrigger = getModalTrigger(elementInFocusIsInsideModal, modal, elementInFocusIsInsideTechInfo, techInfoContainer);

    if (modalIsCreatedAndOpen && elementInFocusIsInsideModal) return modalTrigger.focus();
    if (!elementInFocus.dataset.allowsReactHotkeys) return;
    if (modalIsCreatedAndOpen) return firstModalElement.focus();
    modalTrigger && modalTrigger.click();
  }

  pasteGrabbedData(e) {
    const elementInFocusIsCkeditor = e.id && e.id.include("cke_");
    const elementInFocus = elementInFocusIsCkeditor ? e.element.$ : e.target;
    const dataText = getGrabbedData("wikilink");

    if (!elementInFocus.dataset.allowsReactHotkeys) return;
    if (!dataText) return;
    if (elementInFocusIsCkeditor) return e.insertHtml(dataText);
    pasteData(elementInFocus, dataText);
  }

  componentDidMount() {
    CKEDITOR.on("instanceReady", (e) => {
      if (e.editor.element.$.id.include("technical_definitions")) {
        this.addCustomShortcutsToCkeditor(e.editor);
      }
    });
  }

  addCustomShortcutsToCkeditor(editor) {
    editor.addCommand("ckeSwitchModalFocus", {
      exec: (editor) => {
        this.switchModalFocus(editor);
      }
    });
    editor.addCommand("ckePasteGrabbedData", {
      exec: (editor) => {
        this.pasteGrabbedData(editor);
      }
    });
    const KEY_CODE_F = 70;
    const KEY_CODE_P = 80;
    editor.setKeystroke(CKEDITOR.CTRL + CKEDITOR.ALT + KEY_CODE_F, "ckeSwitchModalFocus");
    editor.setKeystroke(CKEDITOR.CTRL + CKEDITOR.ALT + KEY_CODE_P, "ckePasteGrabbedData");
  }

  render() {
    const keyMap = {
      shortcutToSwitchModalFocus: ["ctrl+option+f"],
      shortcutToPasteGrabbedData: ["ctrl+option+p"]
    };
    const handlers = {
      shortcutToSwitchModalFocus: this.switchModalFocus,
      shortcutToPasteGrabbedData: this.pasteGrabbedData
    };
    return(
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
    );
  }
}
