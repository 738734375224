import React from "react";
import PropTypes from "prop-types";
import { createOptions, createFlattenOptions, dropdownRender } from "./FunctionalAreasUtils.js";
import Cascader from 'rc-cascader';

export default class FunctionalAreasSingleSelect extends React.Component {
  constructor(props) {
    super(props);

    const
      { functionalAreas, selectedFunctionalArea } = this.props,
      options = createOptions(functionalAreas),
      flatOptions = createFlattenOptions(functionalAreas),
      currentSelected = selectedFunctionalArea ?
        _.find(flatOptions, { value: selectedFunctionalArea }) :
        {label: ""};

    this.state = {
      searchString: "",
      currentSelected,
      defaultSelected: this.getDefaultSelectedValue(options),
      visible: false
    };
  }

  /**
   * returns an array of values which is required to autoexpanding the first item in the list
   * @param options
   * @returns {Array}
   */
  getDefaultSelectedValue(options) {
    if (_.isEmpty(options)) {
      return [];
    }

    /**
     * options[0] is institution-wide Functional Area,
     * root node of all functional areas
     */

    return [_.first(options).value];
  }

  onFunctionalAreaChange = (value, selectedOptions) => {
    const
      selectedFunctionalArea = selectedOptions.last(),
      { callback } = this.props;

    this.updateDefaultSelected(value);

    if (selectedFunctionalArea.faAvailable === true) {
      this.setState({
        searchString: "",
        currentSelected: selectedFunctionalArea
      });

      if (callback)
        callback(selectedFunctionalArea.value);
    }
  };

  updateDefaultSelected(value) {
    const
      { functionalAreas } = this.props,
      options = createOptions(functionalAreas),
      { searchString } = this.state;

    if (!_.isEmpty(searchString))
      this.setState({
        defaulSelected: this.getDefaultSelectedValue(options)
      })
    else
      this.setState({
        defaulSelected: value
      });
  }

  getOptions = () => {
    const
      { functionalAreas } = this.props,
      { searchString } = this.state,
      options = createOptions(functionalAreas),
      flatOptions = createFlattenOptions(functionalAreas);

    if (searchString) {
      const searchLowCase = searchString.toLowerCase();

      return flatOptions.filter((option) =>
        option.label.toLowerCase().includes(searchLowCase)
      );
    }

    return options;
  };

  onSearchStringChange = (e) => {
    const searchString = e.target.value;
    this.setState({
      searchString,
      visible: true,
    });
  };

  onSearchKeyDown = (e) => {
    // Prevents the closing of the parent element (Cascader) by Backspace button
    switch (e.keyCode) {
      case KeyCode.BACKSPACE:
        e.stopPropagation();
        break;
      case KeyCode.ESC:
        this.setState({
          visible: false,
        });
        break;
      default:
        break;
    }
  };

  togglePopupVisibility = () => {
    const { visible } = this.state;
    this.setState({
      visible: !visible,
    });
  };

  onPopupVisibleChange = (visible) => {
    this.setState({
      visible
    });
  };

  render() {
    const {
      searchString,
      currentSelected,
      defaultSelected,
      visible
    } = this.state;
    const { labelTarget } = this.props;
    const opts = this.getOptions();

    return (
      <div className="cascader-container">
        <Cascader
          options={opts}
          expandIcon=''
          expandTrigger="hover"
          changeOnSelect
          onChange={this.onFunctionalAreaChange}
          onPopupVisibleChange={this.onPopupVisibleChange}
          popupVisible={visible}
          value={defaultSelected}
          dropdownRender={menus => dropdownRender(menus, this.props.functionalAreas)}
        >
          <span className="ui-combobox fa-selector-container">
            <input
              className="ui-widget-content ui-combobox-input ui-widget ui-corner-left"
              placeholder="-- Functional Area --"
              autoComplete="off"
              id={labelTarget || null}
              value={searchString || currentSelected.label}
              onChange={this.onSearchStringChange}
              onClick={this.togglePopupVisibility}
              onKeyDown={this.onSearchKeyDown}
              aria-label="Type or Select Functional Area"
            />
            <a
              tabIndex="-1"
              title="Show All Functional Areas"
              className="ui-button ui-widget ui-state-default ui-button-icon-only ui-corner-right ui-combobox-toggle fa-selector-btn"
              role="button"
            >
              <span className="ui-button-icon-primary ui-icon ui-icon-triangle-1-s" role="img" aria-label="Show all Functional Areas" />
              <span className="ui-button-text" />
            </a>
          </span>
        </Cascader>
      </div>
    );
  }
}

FunctionalAreasSingleSelect.propTypes = {
  functionalAreas: PropTypes.arrayOf(Object).isRequired,
  selectedFunctionalArea: PropTypes.number,
  callback: PropTypes.func
};
