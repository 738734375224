import React from 'react';
import PropTypes from 'prop-types';
import FunctionalAreasSingleSelect from './FunctionalAreasSingleSelect';

export default class FunctionalAreasSingleSelectContainer extends React.Component {
  constructor(props) {
    super(props);
    const { functionalAreas, clearFilterText } = this.props;

    functionalAreas.withClearFilterOption = function withClearFilterOption() {
      const optionToClearFilter = {
        id: -1,
        name: clearFilterText,
        children: [],
        description: '',
        available: true,
        availableToDrilldown: true,
      };

      this.unshift(optionToClearFilter);

      return this;
    };

    functionalAreas.withAllEnabled = function withAllEnabled() {
      /* This component is only used for filtering the browse pages
        so all options should be selectable */

      return this.map((fa) => {
        const faCopy = Object.assign({}, fa);

        faCopy.available = true;
        faCopy.availableToDrilldown = true;
        faCopy.children = withAllEnabled.call(faCopy.children);

        return faCopy;
      });
    };

    this.functionalAreas = functionalAreas
      .withClearFilterOption()
      .withAllEnabled();
  }

  render() {
    const { selectedFunctionalArea, formId } = this.props;

    return (
      <FunctionalAreasSingleSelect
        functionalAreas={this.functionalAreas}
        selectedFunctionalArea={parseInt(selectedFunctionalArea, 10)}
        callback={(functionalAreaId) => {
          const
            form = document.getElementById(formId),
            formField = form.functional_area_id;

          if (formField && formField.value !== functionalAreaId) {
            if (functionalAreaId === -1)
              formField.value = ''; // clear filter
            else
              formField.value = functionalAreaId;

            form.submit();
          }
        }}
      />
    );
  }
}

/* selectedFunctionalArea is ultimately cast to an int; the conversion is done
  here instead of the rails form to avoid casting an empty string (no filter
  applied) to 0 (a non-existent functional area id, thus returning 0 results) */
FunctionalAreasSingleSelectContainer.propTypes = {
  functionalAreas: PropTypes.arrayOf(Object).isRequired,
  selectedFunctionalArea: PropTypes.string,
  formId: PropTypes.string.isRequired,
  clearFilterText: PropTypes.string,
};

FunctionalAreasSingleSelectContainer.defaultProps = {
  selectedFunctionalArea: '',
  clearFilterText: '-- Functional Area --',
};
