import {
    render,
  } from '@testing-library/react';
  import DateFilter from './DateFilter';

  describe('<DateFilter />', () => {
    it('should render correct date filter', async () => {
      const component = exercise();
      const startInput = await component.getStartDateInput();
      const endInput = await component.getEndDateInput();
      const filterLabel = await component.getFilterLabel()
      const dateLabel = await component.getDateLabel()
      const fromLabel =  await component.getFromLabel()
      const toLabel =  await component.getToLabel()
      expect(startInput).toBeVisible();
      expect(endInput).toBeVisible();
      expect(filterLabel).toBeVisible();
      expect(dateLabel).toBeVisible();
      expect(fromLabel).toBeVisible();
      expect(toLabel).toBeVisible();
    });

    it('should render correct values', async () => {
      const component = exercise();
      const startInput = await component.getStartDateInput();
      const endInput = await component.getEndDateInput();
      expect(startInput).toHaveValue('11/11/2001')
      expect(endInput).toHaveValue('11/11/2022');
    });
  });

  const exercise = () => {
    const component = render(<DateFilter
      updateUrl={'users/user/background_jobs'}
      selectedStartDate={new Date('11/11/2001')}
      selectedEndDate={new Date('11/11/2022')}
      dateFormat={'MM/dd/yy'}
      today={new Date()}
      initStartDate={new Date('11/11/2001')}
      initEndDate={new Date('11/11/2022')}
      helpPoints={{ point1: '', point2: '', point3: '', point4: '' }}
    />);

    return {
      ...component,

      async getStartDateInput() {
        return component.findByTitle('Background Jobs Filter Start Date Calendar');
      },

      async getEndDateInput() {
        return component.findByTitle('Background Jobs Filter End Date Calendar');
      },

      async getFilterLabel() {
        return component.findByTitle('Filter By Label');
      },

      async getDateLabel() {
        return component.findByTitle('Date Label');
      },

      async getFromLabel() {
        return component.findByTitle('From Date Label');
      },

      async getToLabel() {
        return component.findByTitle('To Date Label');
      },
    }
  };