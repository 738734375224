import SpecCustomField from "./SpecCustomField";

export default class SpecCustomFields extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      onCustomFieldSave: this.onCustomFieldSave.bind(this),
      onCustomFieldCancel: this.onCustomFieldCancel.bind(this),
      onCustomFieldChange: this.onCustomFieldChange.bind(this),
      toggleCustomFieldEditMode: this.toggleCustomFieldEditMode.bind(this),
      customFieldEditMode: false,
      customFieldValue: null,
      contactAttributes: {},
      errors: []
    }
  }

  onCustomFieldSave(e, customField) {
    let { customFieldValue, contactAttributes } = this.state;
    const { report, reportVersion } = this.props;

    const url = customField.id ?
      `/institution/reports/${report.id}/versions/${reportVersion.id}/custom_fields/${customField.id}` :
      `/institution/reports/${report.id}/versions/${reportVersion.id}/custom_fields/`;

    const method = customField.id ?
      'PATCH' :
      'POST';

    const data = {
      custom_field: {
        id: customField.id,
        custom_field_definition_id: customField.customFieldDefinitionId,
        model_id: customField.modelId,
        mode_type: customField.modelType,
        value: customFieldValue,
        contact_attributes: contactAttributes
      }
    };

    let { customFields } = this.state;

    $j.ajax({
      url: url,
      method: method,
      data: data,
      success: (data) => {
        customFields = customFields.map((cf) =>
          cf.customFieldDefinition.id === data.customField?.customFieldDefinition?.id ? data.customField : cf
        );

        this.setState({
          customFields: customFields,
          customFieldEditMode: false
        })
      }
    }).error((xhr) => {
      if (xhr.responseJSON.errors)
        this.setState({ errors: xhr.responseJSON.errors });
      else
        location.reload();
    });
  }

  onCustomFieldChange(e, type, valuesArr = [], contactData = {}) {
    if (type === 'multiselect') {
      this.setState({
        customFieldValue: valuesArr.join('¦')
      })
    } else if (type === 'contact') {
      this.setState({
        contactAttributes: contactData
      })
    } else {
      let value = type === "checkbox" ? e.target.checked : e.target.value;

      this.setState({
        customFieldValue: value
      })
    }
  }

  onCustomFieldCancel(e) {
    e.preventDefault();
    this.setState({
      customFieldEditMode: false
    })
  }

  toggleCustomFieldEditMode(e, customField) {
    e.preventDefault();
    this.setState({
      customFieldValue: customField.displayValue,
      customFieldEditMode: customField.customFieldDefinition.id})
  }

  renderCustomFields() {
    const { customFieldDefinitionIds, name, allOptions, institution, helpPoints } = this.props;
    let   { customFields, errors } = this.state;

    if (customFieldDefinitionIds.length > 0) {
      return (
        customFields.map(
          (customField, fieldIndex )=>
            customFieldDefinitionIds.includes(customField.customFieldDefinitionId) && (
              <SpecCustomField
                key={`cf-${fieldIndex}`}
                childKey={`cf-${fieldIndex}`}
                customField={customField} 
                {...this.state}
                name={name}
                allOptions={allOptions}
                institution={institution}
                helpPoints={helpPoints}
                index={fieldIndex}
                errors={errors}
              />
            )
        )
      )
    } else {
      return ""
    }
  }

  render() {
    return(
      <div className="span-24">
        {this.renderCustomFields()}
      </div>
    )
  }
}
