import React from 'react'
import WorkflowConditionForm from './WorkflowConditionForm';
export default class WorkflowConditionItem extends React.Component {
  constructor(props) {
    super(props);

    this.cancelEditFormHandler = this.cancelEditFormHandler.bind(this)

    this.state = {
      objectType:           props.objectType,
      humanizedObjectType : props.humanizedObjectType,
      operand:              props.operand,
      humanizedOperand:     props.humanizedOperand,
      relatedObjects:       props.relatedObjects,
      manageButtonsVisible: props.manageButtonsVisible,
      editFormVisible:      false,
      preselectedValues:    this.props.preselectedValues
    }
  }

  cancelEditFormHandler() {
    this.setState({
      editFormVisible: false
    })
  }

  openEditForm() {
    this.setState({ editFormVisible: true })
  }

  removeItem() {
    $j.ajax({
      method: 'DELETE',
      url: this.props.removeUrl,
      data: {
        from_wf_definition_edit_page: true
      },
      success: () => {
        ReactRailsUJS.mountComponents();
      }
    })
  }

  renderRelatedObject() {
    if(this.state.operand === 'in_list'){
      const relatedObjects = this.state.relatedObjects.map((object) =>
        <li key={object.id.toString()}><b>{object.name}</b></li>
      );

      return(
        <ul className="wrkflw_condition_list">
          {relatedObjects}
        </ul>
      )
    }else if(this.state.operand !== 'any'){
      return(
       <b>
          {this.state.relatedObjects[0]?.name || 'Undefined'}
       </b>
      )
    }
  }

  renderOperand() {
    if(this.state.operand === 'in_list'){
      return(` is ${this.state.humanizedOperand}:`)
    }else{
      return(
        ` ${this.state.humanizedOperand} `
      )
    }
  }

  renderItemName() {
    return(
      <b>{this.state.humanizedObjectType}</b>
    )
  }

  renderOperandAndObject() {
    if(this.state.objectType !== 'WorkflowCondition::EmbeddedQualityRule'){
      return(
        [this.renderItemName(), this.renderOperand(), this.renderRelatedObject()]
      )
    }
  }

  render() {
    const {
      humanizedObjectType,
      manageButtonsVisible,
      editFormVisible
    } = this.state

    return(
      <fieldset className="workflow-condition-item">
        {manageButtonsVisible && !editFormVisible &&
          <div className="pt-action-header">
            <button
              onClick={() => this.openEditForm()}
              className='pretty-button mini ltgray workflow-warning margin-right-10px'
            >
              edit
            </button>


            <button
              onClick={() => this.removeItem()}
              className={`pretty-button mini ltgray
                          workflow-active-${this.props.workflowActive}`}
            >
              delete
            </button>
          </div>
        }

        <blockquote className="quiet">
          {!editFormVisible &&
            this.renderOperandAndObject()
          }

          {editFormVisible &&
            <div className='font_size_85'>
              <WorkflowConditionForm
                objectTypes={this.props.objectTypes}
                url={this.props.updateUrl}
                method={'PUT'}
                cancelEditFormHandler={this.cancelEditFormHandler}
                editMode={true}
                preselectedObjectTypeValues={this.props.preselectedObjectTypeValues}
                removeIconSrc={this.props.removeIconSrc}
              />
            </div>
          }
        </blockquote>
      </fieldset>
    )
  }
}