import React from 'react';
import Select, { components, createFilter } from 'react-select';
import { FixedSizeList as List } from 'react-window';

const MenuList = ({ options, children, getValue }) => {
  const OPTION_HEIGHT = 27;
  const ROWS = 6;
  const [value] = getValue();
  const initialOffset = (options.indexOf(value) !== -1 && Array.isArray(children) &&
    children.length >= ROWS && options.indexOf(value) >= ROWS)
    ? (options.indexOf(value) * OPTION_HEIGHT) - (OPTION_HEIGHT * 5)
    : 0;
  const height = (children.length >= ROWS ? OPTION_HEIGHT * ROWS : children.length * OPTION_HEIGHT) || 27;

  return (
    <List
      height={height}
      itemCount={children.length || 0}
      itemSize={OPTION_HEIGHT}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  )
};

const DropdownIndicator = () => (
  <a
    tabIndex="-1"
    title="Show All Options"
    className="ui-button ui-widget ui-state-default ui-button-icon-only ui-corner-right optimized-toggle"
    role="button"
    data-testid="primarySelectIcon"
  >
    <span className="ui-button-icon-primary ui-icon ui-icon-triangle-1-s" role="img" aria-label="Show all Options" />
    <span className="ui-button-text" />
  </a>
);

const formatOptionLabel = ({ label, isHeader }) => (
  <div className={isHeader ? 'optionHeader' : 'optionDefault'}>{label}</div>
);

export default function OptimizedSelect({ ...props }) {
  const wrapStyle = props.wrapStyle || { width: '214px' };

  return (
    <div className="optimizedSelectWrap" style={wrapStyle}>
      <Select
        filterOption={createFilter({ ignoreAccents: false })}
        components={{ MenuList, DropdownIndicator }}
        formatOptionLabel={formatOptionLabel}
        className="OptimizedSelect"
        classNamePrefix="OptimizedSelect"
        placeholder="Type or Select..."
        isClearable
        {...props}
      />
    </div>
  );
}