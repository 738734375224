import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { FixedSizeList as List } from 'react-window';
import DateFilter from './DateFilter';
import HelpPoint from '../Helpers/HelpPoint';

const JobsFilters = (props) => {
  const {
    integrationJobs,
    removeIconSrc,
    updateUrl,
    prevStatus,
    prevName,
    prevJobId,
    selectedStartDate,
    selectedEndDate,
    dateFormat,
    selectedDateFilter,
    helpPoints,
    today,
  } = props;

  const MenuList = ({ options, children, getValue }) => {
    const OPTION_HEIGHT = 27;
    const ROWS = 7;
    const [value] = getValue();
    const initialOffset = (options.indexOf(value) !== -1 && Array.isArray(children) &&
      children.length >= ROWS && options.indexOf(value) >= ROWS)
      ? (options.indexOf(value) * OPTION_HEIGHT) - (OPTION_HEIGHT * 5)
      : 0;
    const height = (children.length >= ROWS ? OPTION_HEIGHT * ROWS : children.length * OPTION_HEIGHT) || 27;

    return (
      <List
        height={height}
        itemCount={children.length || 0}
        itemSize={OPTION_HEIGHT}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  };

  const DropdownIndicator = props => (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <span title="Dropdown Indicator" />
      </components.DropdownIndicator>
    )
  );

  const statusOptions = [
    { label: '-- Status --', value: undefined },
    { label: 'Success', value: 'Success' },
    { label: 'Warning', value: 'Warning' },
    { label: 'Error', value: 'Error' }
  ];

  const myJobsNameOptions = [
    { label: '-- Name --', value: undefined },
    { label: 'Batch Community Copy', value: 'Batch Community Copy' },
    { label: 'Data Item Import', value: 'Data Item Import' },
    { label: 'Definition Import', value: 'Definition import' },
    { label: 'Import Schema', value: 'Import schema' },
    { label: 'Specification Import', value: 'Specification import' },
    { label: 'Lineage Import', value: 'Lineage Import' }
  ];

  const integrationNameOptions = [
    { label: '-- Name --', value: undefined },
    { label: 'Import Schema', value: 'Import schema' },
    { label: 'Specification API Import', value: 'Specification API import' }
  ];

  const now = new Date(today);
  const defaultDate = new Date(now.setDate(now.getDate() - 30));
  const initStartDate = selectedStartDate ? new Date(`${selectedStartDate}:`) : defaultDate;
  const initEndDate = selectedEndDate ? new Date(`${selectedEndDate}:`) : now;
  const nameOptions = integrationJobs ? integrationNameOptions : myJobsNameOptions;
  const defaultName = prevName ? nameOptions.find(o => o.value === prevName) : undefined;
  const defaultStatus = prevStatus ? statusOptions.find(o => o.value === prevStatus) : undefined;
  const defaultJobId = prevJobId || '';
  const [selectedNameFilter, setSelectedNameFilter] = useState(defaultName);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState(defaultStatus);
  const [selectedJobIdFilter, setSelectedJobIdFilter] = useState(defaultJobId);
  const [dataReset, setDataReset] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [dateFilter, setDateFilter] = useState(selectedDateFilter);
  const removeButtonPresent = selectedNameFilter?.value || selectedStatusFilter?.value || selectedJobIdFilter || dateFilter;

  useEffect(() => {
    if (mounted)
      sendRequest();
    else
      setMounted(true);
  }, [selectedNameFilter, selectedStatusFilter, dataReset]);

  const sendRequest = () => {
    $j.ajax({
      type: 'GET',
      url: updateUrl,
      data: {
        name_filter: dataReset ? undefined : selectedNameFilter?.value,
        job_id_filter: dataReset ? undefined : (selectedJobIdFilter || undefined),
        status_filter: dataReset ? undefined : selectedStatusFilter?.value,
        start_date: dataReset ? undefined : initStartDate,
        end_date: dataReset ? undefined : initEndDate,
        date_filter: dataReset ? undefined : dateFilter,
      },
      success: (response) => {
        window.location.href = response.redirect;
      },
      error: () => {
        window.location.reload();
      }
    });
  };

  const onFilterSelect = (value, filter) => {
    filter === 'name' ? setSelectedNameFilter(value) : setSelectedStatusFilter(value);
  };

  const onJobIdChange = (event) => {
    setSelectedJobIdFilter(event.target.value);
  };

  const searchJob = (event) => {
    if (event.key === 'Enter')
      sendRequest();
  };

  const resetFilters = () => {
    setDataReset(true);
  };

  return (
    <div>
      <DateFilter
        updateUrl={updateUrl}
        defaultDate={defaultDate}
        dateFormat={dateFormat}
        initStartDate={initStartDate}
        initEndDate={initEndDate}
        nameFilterValue={selectedNameFilter?.value}
        jobIdFilterValue={selectedJobIdFilter || undefined}
        statusFilterValue={selectedStatusFilter?.value}
        today={today}
        helpPoints={helpPoints}
        integrationJobs={integrationJobs}
        onDateChange={setDateFilter}
      />
      <div data-testid="filtersContainer" className="jobs-filters-container">
        <div className="optimizedSelectWrap job-id-filter-container">
          <input
            title="Job Id Filter Input"
            type="text"
            placeholder="-- Job ID --"
            aria-label="Job ID input"
            value={selectedJobIdFilter}
            onChange={onJobIdChange}
            className="job-id-input"
            onKeyDown={searchJob}
          />
        </div>
        <div className='help-point-container'>
          <HelpPoint helpPoint={integrationJobs ? helpPoints.point5 : helpPoints.point6} />
        </div>
        <div className="optimizedSelectWrap status-filter-container">
          <Select
            title="Status Filter Selector"
            options={statusOptions}
            components={{ MenuList, DropdownIndicator }}
            value={selectedStatusFilter}
            placeholder="-- Status --"
            className="OptimizedSelect"
            classNamePrefix="OptimizedSelect"
            onChange={value => onFilterSelect(value, 'status')}
          />
        </div>
        <div className='help-point-container'>
          <HelpPoint helpPoint={integrationJobs ? helpPoints.point7 : helpPoints.point8} />
        </div>
        <div className="optimizedSelectWrap name-filter-container">
          <Select
            title="Name Filter Selector"
            options={nameOptions}
            components={{ MenuList, DropdownIndicator }}
            value={selectedNameFilter}
            placeholder="-- Name --"
            className="OptimizedSelect"
            classNamePrefix="OptimizedSelect"
            onChange={value => onFilterSelect(value, 'name')}
          />
        </div>
        <div className='help-point-container'>
          <HelpPoint helpPoint={integrationJobs ? helpPoints.point9 : helpPoints.point10} />
        </div>
      </div>
      {removeButtonPresent &&
        <div>
          <button
            title="Reset Filters"
            name="button"
            type="button"
            label="Remove Filters"
            aria-label="Reset All Filters Button"
            onClick={resetFilters}
          >
            <img
              src={removeIconSrc}
              alt="Remove field name"
              tabIndex="0"
            />
            Remove Filters
          </button>
        </div>
      }
    </div>
  );
};

export default JobsFilters;
