export function Errors(props) {
  const { errors } = props;

  const content = error => (
    <div
      key={error}
      className="workflow-bar-alerts__alert workflow-bar-alerts__alert--error"
    >
      {error}
    </div>
  );

  if (errors && errors.length > 0) {
    return (
      <div className="workflow-bar-alerts">
        { Array.isArray(errors) ? errors.map(error => content(error)) : content(errors) }
      </div>
    );
  }
  return null;
}

Errors.propTypes = {
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired
};
