/* eslint-disable class-methods-use-this */
import ImportFilter from '../Import/ImportFilter';

export default class DataMappingSummaryTableComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      listItems: this.mapErrorsAndWarnings(),
      visibleErrors: true,
      visibleWarnings: true
    };

    this.onSearchKeywordChange = this.onSearchKeywordChange.bind(this);
    this.renderHtmlMessages = this.renderHtmlMessages.bind(this);
  }

  onSearchKeywordChange(event) {
    const filteredValue = event.target.value;

    if (filteredValue !== undefined && filteredValue.length > 0) {
      const toLowerCaseFilteredValue = filteredValue.toLowerCase();

      this.setState({
        listItems: this.state.listItems.filter(item => (
          item.source
            ? item.source.toLowerCase().includes(toLowerCaseFilteredValue)
            : false) ||
            (item.target
              ? item.target.toLowerCase().includes(toLowerCaseFilteredValue)
              : false
            )).map(filteredItem => (
          {
            row: filteredItem.row,
            source: filteredItem.source,
            target: filteredItem.target,
            errors: filteredItem.errors,
            warnings: filteredItem.warnings
          }
        ))
      });
    } else
      this.setState({ listItems: this.mapErrorsAndWarnings() });
  }

  mapErrorsAndWarnings = () => {
    const { lineages } = this.props;
    const { warningsList } = this.props;
    const { errorsList } = this.props;

    return lineages.map((lineage, index) => {
      const target = lineage.treeJson;

      return {
        row: index + 1,
        source: this.createSourceName(target),
        target: target ? target.name : '',
        errors: lineage.fullErrors,
        warnings: lineage.warnings,
        errorsList,
        warningsList
      };
    });
  };

  createSourceName = (target) => {
    const sources = target ? target.children : [];
    const sourceObj = sources.first();
    let sourceName = '';

    if (sourceObj) {
      if (sourceObj.children) {
        const sourceTerm = sourceObj.children.first()?.termName;
        sourceName = sourceTerm ? `${sourceTerm}(${sourceObj.name})` : sourceObj.name;
      } else
        sourceName = sourceObj.termName;
    }

    return sourceName;
  };

  renderTableHeader() {
    return ['Item', 'Source', 'Target', 'Messages'].map((key, index) => <th key={`key-${index}`}>{key.toUpperCase()}</th>);
  }

  renderTableData() {
    return this.state.listItems.map((item) => {
      const {
        row, source, target, errors, warnings, errorsList, warningsList
      } = item;
      if (errors.length > 0 ||
        warnings.length > 0 ||
        errorsList.length > 0 ||
        warningsList.length > 0) {
        return (
          <tr key={row}>
            <td>{row}</td>
            <td>{source}</td>
            <td>{target}</td>
            <td>
              {this.props.hasErrors &&
              this.state.visibleErrors &&
              (errors.length > 0 || errorsList.length > 0) &&
              (
                this.renderHtmlMessages(errors, errorsList, 'Errors')
              )}

              {this.props.hasWarnings &&
              this.state.visibleWarnings &&
              (warnings.length > 0 ||
                warningsList.length > 0) && (
                this.renderHtmlMessages(warnings, warningsList, 'Warnings')
              )}
            </td>
          </tr>
        );
      }
      return null;
    });
  }

  renderHtmlMessages(messages, messagesList, title) {
    const htmlText = (messages.concat(messagesList)).map((msn, index) => (
      <p key={`messege-${index}`} className="bottom">{msn}</p>
    ));

    return (
      <div>
        <h3>{title}</h3>
        {htmlText}
      </div>
    );
  }

  itemHasErrors = item => (item.errorsList.length > 0 || item.errors.length > 0) || ((item.errorsList.length > 0 || item.errors.length > 0) && (item.warningsList.length > 0 || item.warnings.length > 0));

  itemHasWarnings = item => (item.warningsList.length > 0 || item.warnings.length > 0) || ((item.warningsList.length > 0 || item.warnings.length > 0) && (item.errorsList.length > 0 || item.errors.length > 0));

  onChangeFilter = (filter) => {
    const filteredList = this.mapErrorsAndWarnings();

    if (!filter)
      this.setState({ listItems: filteredList });
    else
      this.setState({ listItems: filteredList.filter(item => (filter === 'errors' ? this.itemHasErrors(item) : this.itemHasWarnings(item))) });
  }

  render() {
    const { helpPoints } = this.props;

    return (
      <div>
        <div className="data-items-filter-container">
          <div className="data-items-filter-inner-container">
            <ImportFilter helpPoint={helpPoints.point1} dataItemsImport onChange={this.onChangeFilter} />
          </div>
          <div className="data-items-filter">
            <input type="text" size="50" name="search" placeholder="Search Keywords from your CSV" aria-label="Search the Data Mappings" onChange={this.onSearchKeywordChange} />
          </div>
        </div>
        <div className="border-default append-bottom">
          <table id="summary">
            <tbody>
              <tr>{this.renderTableHeader()}</tr>
              {this.renderTableData()}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
