function DMCNodeShowWrapper(WrappedComponent) {
  class Wrapper extends React.Component {
    constructor(props) {
      super(props);
      this.setModalContainer = this.setModalContainer.bind(this);
    }

    componentDidUpdate() {
      let opened;
      try {
        opened = $j(this.el).dialog('isOpen');
      } catch (_) {
      }

      if (this.props.node && !opened) {
        $j(this.el).dialog({
          dialogClass: 'dialog-shadow',
          width: 470,
          close: this.props.onCancel,
          open: function () {
            $j(this).parents('.ui-dialog').attr('tabindex', -1)[0].focus();
          }
        });
      }
    }

    UNSAFE_componentWillUpdate(nextProps) {
      if (!nextProps.node)
        $j(this.el).dialog('destroy');
    }

    setModalContainer(el) {
      this.el = el;
    }

    render() {
      return (
        <WrappedComponent {...this.props} setModalContainer={this.setModalContainer} />
      );
    }
  }

  Wrapper.displayName = `DMCNodeShowWrapper(${getDisplayName(WrappedComponent)})`;
  return Wrapper;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default DMCNodeShowWrapper;
