import PrimaryToolForm from './PrimaryToolForm';
import RelatedToolsSelect from './RelatedToolsSelect';

class RelatedToolsForm extends React.Component {
  constructor(props) {
    super(props);
    const primaryToolPresent = props.selectedPrimaryTool && props.selectedPrimaryTool.dataTool;

    this.state = {
      ...props,
      dataTools: this.filterDataTools(this.props),
      relatedToolsIdx: props.selectedRelatedTools ? props.selectedRelatedTools.map(tool => tool.dataTool.id) : [],
      primaryToolId: primaryToolPresent ? props.selectedPrimaryTool.dataTool.id : null
    };
  }

  onChangePrimaryTool = (tool) => {
    const { dataTools } = this.props;
    const { relatedToolsIdx } = this.state;

    let filteredOptions = dataTools.filter(opt => !relatedToolsIdx.includes(opt.id));
    if (tool)
      filteredOptions = filteredOptions.filter(opt => opt.id !== tool.value);

    this.setState({
      dataTools: filteredOptions,
      primaryToolId: tool ? tool.value : null
    });
  }

  onChangeRelatedTool = (tool, remove = false) => {
    const { dataTools } = this.props;
    const { relatedToolsIdx, primaryToolId } = this.state;
    let filteredOptions = primaryToolId ? dataTools.filter(opt => opt.id !== primaryToolId) : dataTools;
    let newRelatedToolsIdx = relatedToolsIdx;

    if (Array.isArray(tool)) {
      newRelatedToolsIdx = relatedToolsIdx.filter(id => id !== tool[1].value);
      newRelatedToolsIdx.push(tool[0].value);
    } else if (remove)
      newRelatedToolsIdx = relatedToolsIdx.filter(id => id !== tool.value);
    else
      newRelatedToolsIdx.push(tool.value);

    filteredOptions = filteredOptions.filter(opt => !newRelatedToolsIdx.includes(opt.id));
    this.setState({
      dataTools: filteredOptions,
      relatedToolsIdx: newRelatedToolsIdx
    });
  }

  filterDataTools = (props) => {
    let dataTools = props.dataTools;
    if(props.selectedPrimaryTool && props.selectedPrimaryTool.dataTool) {
      dataTools = dataTools.filter(opt => opt.id !== props.selectedPrimaryTool.dataTool.id);
    }
    if(props.selectedRelatedTools) {
      dataTools = dataTools.filter(opt => !props.selectedRelatedTools.map(tool => tool.dataTool.id).includes(opt.id));
    }
    return dataTools;
  }

  render() {
    const { dataTools, selectedPrimaryTool, selectedRelatedTools } = this.state;
    const { removeIconSrc, specName, helpPoints, relatedToolsVisible, reportCreation } = this.props;
    const formObjectName = reportCreation ? 'report[version_attributes]' : 'report_version';

    return (
      relatedToolsVisible ?
      <div>
        <PrimaryToolForm
          dataTools={dataTools}
          selectedPrimaryTool={selectedPrimaryTool}
          removeIconSrc={removeIconSrc}
          specName={specName}
          onChangeCallback={this.onChangePrimaryTool}
          helpPoints={helpPoints}
          reportCreation={reportCreation}
        />
        <RelatedToolsSelect
          formObjectName={formObjectName}
          dataTools={dataTools}
          selectedDataTools={selectedRelatedTools}
          removeIconSrc={removeIconSrc}
          onChangeCallback={this.onChangeRelatedTool}
          helpPoints={helpPoints}
          reportCreation={reportCreation}
        />
      </div> : null
    );
  }
}

export default RelatedToolsForm;
