import DMCNodeShowWrapper from './DMCNodeShowWrapper';
import DMCLinkToRelatedObject from './DMCLinkToRelatedObject';

let DMCLinioSourceShow = class extends React.Component {
  render() {
    const source = this.props.node;
    if (!source)
      return null;

    return (
      <div
        title="View Source Item"
        ref={el => this.props.setModalContainer(el)}
      >
        <h3>{source.name}</h3>
        <DMCLinkToRelatedObject node={source} />
        <div>
          <h5>Note: </h5>
          { source.note ? ` ${source.note}` : ' None'}
        </div>
      </div>
    );
  }
};
DMCLinioSourceShow.displayName = 'DMCLinioSourceShow';
DMCLinioSourceShow = DMCNodeShowWrapper(DMCLinioSourceShow);

export default DMCLinioSourceShow;
