import React, { useEffect, useState } from 'react';
import ObjectCollapsedPreview from './ObjectCollapsedPreview';
import ImportFilter from './ImportFilter';
import ImportOptions from './ImportOptions';
import HelpPoint from '../Helpers/HelpPoint';

const ImportPreviewControl = (props) => {
  const {
    tableTitle,
    objects,
    chevronIcons,
    objectType,
    importUrl,
    reportId,
    reportVersionId,
    userId,
    institutionId,
    notFoundMessage,
    emptyRowMessage,
    headerWarnings,
    headerErrors,
    unrecognizedErrors = [],
    userHasManagerPermission,
    importOptionsMessages,
    anyExistingObjects,
    noValidObjects,
    forceAutoApprove,
    invalidForAutoApprove,
    helpPoints
  } = props;

  const [objectsList, setObjectsList] = useState(objects);
  const [unrecognizedErrorsList, setUnrecognizedErrorsList] = useState(unrecognizedErrors);
  const [filteredObjectsList, setFilteredObjectsList] = useState(objects);
  const [
    filteredUnrecognizedErrorsList,
    setFilteredUnrecognizedErrorsList
  ] = useState(unrecognizedErrors);
  const [searchString, setSearchString] = useState('');
  const [autoAproveActive, setAutoApproveActive] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      searchObjects(filteredObjectsList, filteredUnrecognizedErrorsList);
    }, 500);
  }, [searchString]);

  const objectHasErrors = object => object.hasErrors || (object.hasErrors && object.hasWarnings);

  const objectHasWarnings = object => object.hasWarnings || (object.hasErrors && object.hasWarnings) || (autoAproveActive && object.approvalWarnings.length > 0);

  const searchObjects = (objects, unrecognizedErrors) => {
    if (searchString) {
      setObjectsList(objects.filter(object => object.objectValues.name.toLowerCase().includes(searchString)));
      setUnrecognizedErrorsList(unrecognizedErrors.filter(error => error.name.toLowerCase().includes(searchString)));
    } else {
      setObjectsList(objects);
      setUnrecognizedErrorsList(unrecognizedErrors);
    }
  };

  const onChangeFilter = (filter) => {
    if (!filter) {
      setFilteredObjectsList(objects);
      setFilteredUnrecognizedErrorsList(unrecognizedErrors);
      searchObjects(objects, unrecognizedErrors);
    } else {
      const filteredObjects = objects.filter(object => (filter === 'errors' ? objectHasErrors(object) : objectHasWarnings(object)));
      setFilteredObjectsList(filteredObjects);
      const filteredUnrecognizedErrors = unrecognizedErrors.filter(_error => (filter === 'errors'));
      setFilteredUnrecognizedErrorsList(filteredUnrecognizedErrors);
      searchObjects(filteredObjects, filteredUnrecognizedErrors);
    }
  };

  const getFilters = () => (
    <>
      <div className="import-filter-container">
        <div className="import-filter-selector-container">
          <ImportFilter helpPoint={helpPoints.point1} onChange={onChangeFilter} />
        </div>
        <div className="import-search-container">
          <input type="text" size="50" name="search" placeholder="Search Keywords from your CSV" aria-label="Search the imported objects" onChange={onSearchKeywordChange} />
          <HelpPoint helpPoint={helpPoints.point2} />
        </div>
      </div>
    </>
  );

  const onSearchKeywordChange = (event) => {
    const searchValue = event.target.value ? event.target.value.toLowerCase() : '';
    setSearchString(searchValue);
  };

  const getObjectCustomWarnings = (object) => {
    let curObject = objectCustomFieldWarnings.find(el => el.name == object.objectValues.name);
    return curObject?.warnings || [];
  };

  const mapObjectsPreview = () => (
    <>
      {unrecognizedErrorsList.map(object => (
        <div key={`error-object-preview-${object.name}`}>
          <ObjectCollapsedPreview
            chevronIcons={chevronIcons}
            objectType={objectType}
            statusMessage={object.statusMessage}
            actionMessage={object.actionMessage}
            hasErrors="true"
            errors={[object.unrecognizedReportError]}
            warnings={[]}
            objectValues={object}
            emptyRowMessage={emptyRowMessage}
            helpPoints={helpPoints}
            customFieldsHeaders={[]}
            customFieldsValues={[]}
          />
        </div>
      ))}
      {objectsList.map((object, index) => (
        <div key={`objectPreview-${index}`}>
          <ObjectCollapsedPreview
            chevronIcons={chevronIcons}
            autoAproveActive={autoAproveActive}
            approvalWarnings={object.approvalWarnings}
            objectType={objectType}
            statusMessage={object.statusMessage}
            approvalStatusMessage={object.approvalStatusMessage}
            actionMessage={object.actionMessage}
            hasErrors={object.hasErrors}
            hasWarnings={object.hasWarnings}
            errors={object.errors}
            warnings={object.warnings}
            customFieldsHeaders={object.customFieldsHeaders}
            customFieldsValues={object.customFieldsValues}
            objectValues={object.objectValues}
            emptyRowMessage={emptyRowMessage}
            helpPoints={helpPoints}
            objectIndex={index}
            objectCustomFieldWarnings={object?.customFieldsApprovalWarnings}
          />
        </div>
      ))}
    </>
  );

  return (
    <>
      <ImportOptions
        helpPoints={helpPoints}
        objectType={objectType}
        userId={userId}
        institutionId={institutionId}
        reportVersionId={reportVersionId}
        reportId={reportId}
        importUrl={importUrl}
        headerErrors={headerErrors}
        headerWarnings={headerWarnings}
        userHasManagerPermission={userHasManagerPermission}
        importOptionsMessages={importOptionsMessages}
        anyExistingObjects={anyExistingObjects}
        noValidObjects={noValidObjects}
        forceAutoApprove={forceAutoApprove}
        invalidForAutoApprove={invalidForAutoApprove}
        onCheckAutoApprove={setAutoApproveActive}
        objects={objectsList}
      />
      <div data-testid="previewSection">
        {getFilters()}
        <div>
          <h2 className="import-preview-table-title">{tableTitle}</h2>
          <HelpPoint helpPoint={helpPoints.point3} />
        </div>
        {(objectsList.length + unrecognizedErrorsList.length) > 0 ? mapObjectsPreview() : <div className="margin-top-15px"><p>{notFoundMessage}</p></div>}
      </div>
    </>
  );
};

export default ImportPreviewControl;
