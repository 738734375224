import DMCNodeShowWrapper from './DMCNodeShowWrapper';

let DMCProcessingStepShow = class extends React.Component {
  render() {
    const processingStep = this.props.node;
    if (!processingStep)
      return null;

    return (
      <div
        title="View Processing Step"
        ref={el => this.props.setModalContainer(el)}
      >
        <div>
          <h5>Processing Type:</h5>
          {` ${processingStep.name}`}
        </div>
        <div>
          <h5>Note:</h5>
          { processingStep.note ? ` ${processingStep.note}` : ' None'}
        </div>
      </div>
    );
  }
};
DMCProcessingStepShow.displayName = 'DMCProcessingStepShow';
DMCProcessingStepShow = DMCNodeShowWrapper(DMCProcessingStepShow);

export default DMCProcessingStepShow;
