import {
  render,
  within,
  screen,
} from '@testing-library/react';
import TermPreviewTable from './TermPreviewTable';

describe('<TermPreviewTable />', () => {
  it('should render definitions table', async () => {
    exerciseTermTable();
    const { getByText } = within(screen.getByTestId('termPreviewTable'));
    expect(getByText('Name')).toBeInTheDocument();
    expect(getByText('Definition')).toBeInTheDocument();
    expect(getByText('Definition Source')).toBeInTheDocument();
    expect(getByText('Classification')).toBeInTheDocument();
    expect(getByText('Synonyms')).toBeInTheDocument();
    expect(getByText('Functional Areas')).toBeInTheDocument();
    expect(getByText('Tags')).toBeInTheDocument();
    expect(getByText('Designated Requester')).toBeInTheDocument();
    expect(getByText('AdditionalAttribute1')).toBeInTheDocument();
  });

  it('should render table values', async () => {
    exerciseTermTable();
    const { getByText } = within(screen.getByTestId('termPreviewTable'));
    expect(getByText('TermName1')).toBeInTheDocument();
    expect(getByText('functionalDefinition1')).toBeInTheDocument();
    expect(getByText('tag1,tag2')).toBeInTheDocument();
    expect(getByText('termSource1')).toBeInTheDocument();
    expect(getByText('classificationCode1')).toBeInTheDocument();
    expect(getByText('technicalDefinition1')).toBeInTheDocument();
    expect(getByText('Data System:')).toBeInTheDocument();
    expect(getByText('dataSystem1')).toBeInTheDocument();
    expect(getByText('Time Context:')).toBeInTheDocument();
    expect(getByText('12/12/2025')).toBeInTheDocument();
    expect(getByText('synonym1')).toBeInTheDocument();
    expect(getByText('fa1')).toBeInTheDocument();
    expect(getByText('user1')).toBeInTheDocument();
    expect(getByText('AdditionalAttributeValue1')).toBeInTheDocument();
  });
});

const exerciseTermTable = () => {
  const termData = {
    name: 'TermName1',
    public: true,
    functionalDefinition: { value: 'functionalDefinition1', class: '' },
    tagList: { value: 'tag1,tag2', class: '' },
    termSource: { value: 'termSource1', class: '' },
    classificationCode: { value: 'classificationCode1', class: '' },
    technicalDefinitions: { values: [{ name: 'technicalDefinition1', erpSystem: 'dataSystem1', timeContext: '12/12/2025' }], class: '' },
    synonyms: { values: [{ name: 'synonym1' }], class: '' },
    functionalAreas: { values: [{ name: 'fa1' }], class: '' },
    designatedRequester: { value: { name: 'user1' }, class: '' }
  };

  const component = render(<TermPreviewTable
    objectValues={termData}
    customFieldsHeaders={[{ name: 'AdditionalAttribute1', required: true }]}
    customFieldsValues={['AdditionalAttributeValue1']}
    hasWarningsOrErrors
    emptyRowMessage="empty_row"
    helpPoints={{ point1: '', point2: '', point3: '', point4: '', point5: '', point6: '' }}
  />);

  return {
    ...component
  };
};
