import HelpPoint from "../../Helpers/HelpPoint";

export default class DDTSpecificationType extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const props = this.props;
    const {reportVersion} = props;
    const {specificationType} = props;
    return(
      <div>
        <div className="span-10">
          <label>Specification Type <HelpPoint helpPoint={this.props.helpPoints.point4} /></label>
          <p>
            { specificationType ?
              <a
                style={{display: 'inline-block'}}
                href={`/institution/output_types/${specificationType.id}`}>
                {specificationType.name}
              </a>
              :
              'Not specified.'
            }
          </p>
        </div>
      </div>
    )
  }
}
