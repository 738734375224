import { render, screen, fireEvent } from "@testing-library/react";

import FunctionalAreasSingleSelect from './FunctionalAreasSingleSelect';

mockDependencies();

describe('<FunctionalAreasSingleSelect />', () => {
  describe('Menu interaction', () => {
    it('Opens the dropdown menu when the icon is clicked', () => {
      const component = buildComponent();
      const dropdownIcon = component.getByTitle('Show All Functional Areas');

      fireEvent.click(dropdownIcon);

      const menuItems = screen.queryAllByRole('menuitem');
      expect(menuItems.length).toBeGreaterThan(0);
    });
  });
});

function buildComponent(props) {
  const sampleFunctionalAreas = getFunctionalAreas();

  return render(
    <FunctionalAreasSingleSelect
      functionalAreas={sampleFunctionalAreas}
      {...props}
    />
  );
}

function getFunctionalAreas() {
  return [{
    "id": 42,
    "name": "Institution-Wide",
    "children": [],
    "description": "This covers all aspects of gifts and donations given to the College, and the people who give the gifts. Types of gifts include endowments, cash donations, art, or land. The types of people who give include alumni, patients, parents of active students, employees, and foundations.",
    "available": true,
    "available_to_drilldown": false
  }];
}

function mockDependencies() {
  jest.mock('rc-cascader');
}
