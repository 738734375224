import React from 'react';
import Select, { components } from 'react-select';
import HelpPoint from '../../components/Helpers/HelpPoint';

const LimitList = 7,
  MenuList = props => (
    <components.MenuList {...props}>
      {Array.isArray(props.children) ? props.children.slice(0, LimitList) : props.children}
    </components.MenuList>
  )

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: this.prepareData(props.allOptions),
      institutionData: props.institution,
      selectedOption: this.prepareData(props.selectedOption),
      openMenu: false,
      customSelectorClass: props.customSelectorClass,
      customHeight: props.customHeight,
      id: props.contact?.id,
      name: props.contact?.name || '',
      email: props.contact?.email || '',
      phone: props.contact?.phone || '',
      title: props.contact?.title || '',
      organization: props.contact?.organization || '',
      website: props.contact?.url || '',
      notes: props.contact?.notes || '',
      userId: props.contact?.user_id || '',
      customFieldDefinitionId: props.type === 'customField' ? props.customFieldDefinitionId : null,
      onChangeContact: props.onChangeContact || null,
      formChecker: props.formChecker || false
    };

    this.onSelectChange = (props.onChange && this.handleMultiChange) || this.handleSingleChange;
  }

  componentDidMount() {
    this.state.formChecker && window.addEventListener("beforeunload", this.onUnload);
  }

  onUnload = e => {
    let nameChanged = this.state.name !== '';
    let emailChanged = this.state.email !== '';
    let phoneChanged = this.state.phone !== '';
    let titleChanged = this.state.title !== '';
    let organizationChanged = this.state.organization !== '';
    let websiteChanged = this.state.website !== '';
    let notesChanged = this.state.notes !== '';

    if (nameChanged || emailChanged || phoneChanged || titleChanged || organizationChanged || websiteChanged || notesChanged) {
      e.preventDefault();
      e.returnValue = '';
    }
  }

  prepareData(data) {
    if (!data) return null;

    return Array.isArray(data) ? data.map(this.serializeOption)
      : this.serializeOption(data);
  }

  serializeOption({
    id: value, name: label, email, login, phoneNumber, jobTitle
  } = {}) {
    if (!value) return { value: login, label, email, login, phoneNumber, jobTitle };

    return { value, label, email, login, phoneNumber, jobTitle };
  }

  handleSingleChange = (institutionData, selectedOption) => {
    this.setState({
      selectedOption: selectedOption || null,
      name: selectedOption?.label || '',
      email: selectedOption?.email || '',
      phone: selectedOption?.phoneNumber || '',
      title: selectedOption?.jobTitle || '',
      organization: selectedOption ? institutionData.name : '',
      website: selectedOption ? institutionData.web_site : '',
      userId: selectedOption?.value || '',
      notes: '',
    }, () => this.state.onChangeContact && this.state.onChangeContact(null, 'contact', null, {
        user_id: this.state.userId,
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        title: this.state.title,
        organization: this.state.organization,
        url: this.state.website,
        notes: this.state.notes,
        custom_field_definition_id: this.state.customFieldDefinitionId,
      })
    );
  };

  handleMultiChange = (selectedOption) => {
    this.props.onChange(selectedOption);

    const newOption = this.state.options.filter(option => option.value !== selectedOption.value);
    this.setState({ options: newOption });
  };

  filterOption = (option, searchText) => {
    const { label, data } = option;

    if (
      label.toLowerCase().includes(searchText.toLowerCase()) ||
      data.email.toLowerCase().includes(searchText.toLowerCase()) ||
      data.login.toLowerCase().includes(searchText.toLowerCase())
    ) return true;

    return false;
  };

  handleInputChange = (query, { action }) => {
    if (action === 'input-change' && query.length >= 1)
      this.setState({ openMenu: true });
    else
      this.setState({ openMenu: false });
  };

  hideMenu = () => {
    this.setState({ openMenu: false });
  };

  onSelectorChange = (institutionData, e) => {
    this.onSelectChange(institutionData, e);
    this.hideMenu();
  }

  handleChange = (field, value) => {
    if (field === 'name') {
      this.setState({
        [field]: value,
        selectedOption: null,
        userId: '',
        email: value === '' ? '' : this.state.email,
        phone: value === '' ? '' : this.state.phone,
        title: value === '' ? '' : this.state.title,
        organization: value === '' ? '' : this.state.organization,
        website: value === '' ? '' : this.state.website,
        notes: value === '' ? '' : this.state.notes,
      }, () => this.state.onChangeContact && this.state.onChangeContact(null, 'contact', null, {
          user_id: this.state.userId,
          name: this.state.name,
          email: this.state.email,
          phone: this.state.phone,
          title: this.state.title,
          organization: this.state.organization,
          url: this.state.website,
          notes: this.state.notes,
         })
      );
    } else {
      this.setState({ [field]: value },
        () => this.state.onChangeContact && this.state.onChangeContact(null, 'contact', null, {
          user_id: this.state.userId,
          name: this.state.name,
          email: this.state.email,
          phone: this.state.phone,
          title: this.state.title,
          organization: this.state.organization,
          url: this.state.website,
          notes: this.state.notes,
          custom_field_definition_id: this.state.customFieldDefinitionId,
        })
      );
    }
  }

  render() {
    const {
        name, ariaLabel, isDisabled, isClearable, helpPoints, customHeight, type
      } = this.props,
      { selectedOption, options, openMenu, institutionData } = this.state,

      controlStyle = {
        control: base => ({
          ...base, height: customHeight || 32, minHeight: customHeight || 32, maxHeight: 32,
        }),
      };
    const nameStyle = `contact_name_wrap ${type === 'erp_system_contact' ? 'margin-right-field' : ''}`;
    const anyContactData = this.state.userId || this.state.name || this.state.email || this.state.phone ||
                           this.state.title || this.state.organization || this.state.website || this.state.notes;

    return (
      <div className="user-select contact">
        <input type="hidden" name={`${name}[id]`} value={this.state.id} />
        { !anyContactData && <input type="hidden" name={`${name}[_destroy]`} value="1" /> }

        <div className='select-flex-container'>
          <label className="contact-select-label">Link to User (optional)<HelpPoint helpPoint={helpPoints.point1} /></label>
          <div className='select-container flex-select'>
            <Select
              name={`${name}[user_id]`}
              value={selectedOption}
              getOptionValue={option => option.value}
              options={options}
              filterOption={this.filterOption}
              components={{MenuList, DropdownIndicator: () => null}}
              placeholder={'Enter Name'}
              aria-label={ariaLabel}
              isDisabled={isDisabled}
              styles={controlStyle}
              isClearable={isClearable}
              classNamePrefix="UserSelector"
              onInputChange={this.handleInputChange}
              onChange={(e) => this.onSelectorChange(institutionData, e)}
              onBlur={this.hideMenu}
              menuIsOpen={openMenu}
            />
          </div>
        </div>
        <div className="field-flex-container">
          <label className="contact-label">Name<HelpPoint helpPoint={helpPoints.point2} /></label>
          <div className={nameStyle}>
            <input className='name-input' name={`${name}[name]`} type="text" value={this.state.name} onChange={(e) => this.handleChange('name', e.target.value)} />
            { ((this.state.name || this.state.email || this.state.phone || this.state.title ||
              this.state.organization || this.state.website || this.state.notes) && !this.state.userId) &&
              <span className="clear_btn" onClick={() => this.handleChange('name', '')} />
            }
          </div>
        </div>
        <div className='field-flex-container'>
          <label className="contact-label">Email Address<HelpPoint helpPoint={helpPoints.point3} /></label>
          <input name={`${name}[email]`} type="text" className='contact-text-input' value={this.state.email} onChange={(e) => this.handleChange('email', e.target.value)} />
        </div>
        <div className='field-flex-container'>
          <label className="contact-label">Phone Number<HelpPoint helpPoint={helpPoints.point4} /></label>
          <input name={`${name}[phone]`} type="text" className='contact-text-input' value={this.state.phone} onChange={(e) => this.handleChange('phone', e.target.value)} />
        </div>
        <div className='field-flex-container'>
          <label className="contact-label">Title<HelpPoint helpPoint={helpPoints.point5} /></label>
          <input name={`${name}[title]`} type="text" className='contact-text-input' value={this.state.title} onChange={(e) => this.handleChange('title', e.target.value)} />
        </div>
        <div className='field-flex-container'>
          <label className="contact-label">Organization<HelpPoint helpPoint={helpPoints.point6} /></label>
          <input name={`${name}[organization]`} type="text" className='contact-text-input' value={this.state.organization} onChange={(e) => this.handleChange('organization', e.target.value)} />
        </div>
        <div className='field-flex-container'>
          <label className="contact-label">Website<HelpPoint helpPoint={helpPoints.point7} /></label>
          <input name={`${name}[url]`} type="text" className='contact-text-input' value={this.state.website} onChange={(e) => this.handleChange('website', e.target.value)} />
        </div>
        <div className='contact-notes-container'>
          <label className="contact-label">Notes<HelpPoint helpPoint={helpPoints.point8} /></label>
          <textarea className='contact-notes-field resize-field' name={`${name}[notes]`} type="text" value={this.state.notes} onChange={(e) => this.handleChange('notes', e.target.value)} />
        </div>
        { type === 'customField' && <input name={`${name}[custom_field_definition_id]`} type="hidden" value={this.state.customFieldDefinitionId} />}
      </div>
    );
  }
}
