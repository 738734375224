import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

const LimitList = 7,
  MenuList = props => (
    <components.MenuList {...props}>
      {Array.isArray(props.children) ? props.children.slice(0, LimitList) : props.children}
    </components.MenuList>
  ),
  DropdownIndicator = props => (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <span />
      </components.DropdownIndicator>
    )
  );

export default class UserSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: this.prepareData(props.allOptions),
      selectedOption: this.prepareData(props.selectedOption),
      openMenu: false,
      customSelectorClass: props.customSelectorClass,
      customHeight: props.customHeight
    };

    this.onSelectChange = (props.onChange && this.handleMultiChange) || this.handleSingleChange;
  }

  prepareData(data) {
    if (!data) return null;

    return Array.isArray(data) ? data.map(this.serializeOption)
      : this.serializeOption(data);
  }

  serializeOption({
    id: value, name: label, email, login,
  } = {}) {
    if (!value) return { value: login, label, email, login };

    return { value, label, email, login };
  }

  handleSingleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  handleMultiChange = (selectedOption) => {
    this.props.onChange(selectedOption);

    const newOption = this.state.options.filter(option => option.value !== selectedOption.value);
    this.setState({ options: newOption });
  };

  filterOption = (option, searchText) => {
    const { label, data } = option;

    if (
      label.toLowerCase().includes(searchText.toLowerCase()) ||
      data.email.toLowerCase().includes(searchText.toLowerCase()) ||
      data.login.toLowerCase().includes(searchText.toLowerCase())
    ) return true;

    return false;
  };

  handleInputChange = (query, { action }) => {
    if (action === 'input-change' && query.length >= 1)
      this.setState({ openMenu: true });
    else
      this.setState({ openMenu: false });
  };

  hideMenu = () => {
    this.setState({ openMenu: false });
  };

  onSelectorChange = (e) => {
    this.onSelectChange(e);
    this.hideMenu();
  }

  render() {
    const {
        name, ariaLabel, isDisabled, inModal, placeholder, isClearable, useDropdown, customSelectorClass, customHeight
      } = this.props,
      { selectedOption, options, openMenu } = this.state,
      menuPortalTarget = inModal ? document.body : null,
      controlStyle = {
        control: base => ({
          ...base, height: customHeight || 32, minHeight: customHeight || 32, maxHeight: 32,
        }),
      },
      styles = inModal ? { ...controlStyle, menuPortal: base => ({ ...base, zIndex: 10001 }) } : controlStyle;

    return (
      <div className={customSelectorClass || "user-select"}>
        {useDropdown
          ? <Select
            name={name}
            value={selectedOption}
            onChange={this.onSelectChange}
            options={options}
            filterOption={this.filterOption}
            components={{MenuList, DropdownIndicator}}
            placeholder={placeholder}
            aria-label={ariaLabel}
            isDisabled={isDisabled}
            menuPortalTarget={menuPortalTarget}
            styles={styles}
            isClearable={isClearable}
            classNamePrefix="UserSelector"
          />
          : <Select
            name={name}
            value={selectedOption}
            options={options}
            filterOption={this.filterOption}
            components={{MenuList, DropdownIndicator: () => null}}
            placeholder={placeholder}
            aria-label={ariaLabel}
            isDisabled={isDisabled}
            menuPortalTarget={menuPortalTarget}
            styles={styles}
            isClearable={isClearable}
            classNamePrefix="UserSelector"
            onInputChange={this.handleInputChange}
            onChange={this.onSelectorChange}
            onBlur={this.hideMenu}
            menuIsOpen={openMenu}
          />
        }
      </div>
    );
  }
}

UserSelect.propTypes = {
  allOptions: PropTypes.arrayOf(Object).isRequired,
  selectedOption: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    login: PropTypes.string,
  }),
  name: PropTypes.string,
  ariaLabel: PropTypes.string,
  isDisabled: PropTypes.bool,
  inModal: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  isClearable: PropTypes.bool,
  useDropdown: PropTypes.bool,
};

UserSelect.defaultProps = {
  selectedOption: null,
  name: null,
  ariaLabel: null,
  isDisabled: false,
  inModal: false,
  placeholder: 'Enter Name',
  onChange: null,
  isClearable: false,
  useDropdown: false,
};
