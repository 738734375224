import React, { useState } from 'react';
import parse from 'html-react-parser';
import HelpPoint from '../Helpers/HelpPoint';
import ImportFileForm from './ImportFileForm';

const ImportOptions = (props) => {
  const {
    objectType,
    importUrl,
    userId,
    institutionId,
    userHasManagerPermission,
    importOptionsMessages,
    anyExistingObjects,
    noValidObjects,
    forceAutoApprove,
    invalidForAutoApprove,
    onCheckAutoApprove,
    helpPoints,
    headerWarnings = [],
    headerErrors = []
  } = props;

  const [autoApproveChecked, setAutoApprove] = useState(false);
  const [updExistingChecked, setUpdExisting] = useState(false);
  const objectTypes = {
    REPORT: 'specification',
    TERM: 'definition'
  };
  const fieldName = objectType === objectTypes.REPORT ? 'reports_import' : 'terms_import';

  const onChangeUpdExisting = () => {
    setUpdExisting(!updExistingChecked);
  };

  const onChangeAutoApprove = () => {
    setAutoApprove(!autoApproveChecked);
    onCheckAutoApprove(!autoApproveChecked);
  };

  const getFileSelectForm = () => (
    <div className="fileSelect">
      <div className="fileSelectInfo">{importOptionsMessages.selectFileInfo}</div>
      <ImportFileForm
        modelType={objectType}
        userId={userId}
        institutionId={institutionId}
        importUrl={importUrl}
        previewButton={importOptionsMessages.previewButton}
      />
    </div>
  );

  const getImportOptions = () => (
    <>
      <div>
        <div className="form_field" id="auto_approve">
          {userHasManagerPermission &&
            <>
              <div>
                {importOptionsMessages.autoApproveInfo}
              </div>
              <br />
            </>
          }
          <div className="form_field" id="auto_approve">
            {!invalidForAutoApprove &&
              forceAutoApprove ? <input hidden name={`${fieldName}[auto_approve]`} value="1" />
              : <>
                <input title="auto approve" name={`${fieldName}[auto_approve]`} className="auto-approve-checkbox" type="checkbox" disabled={!userHasManagerPermission} checked={autoApproveChecked} value={autoApproveChecked ? '1' : '0'} onChange={onChangeAutoApprove} />
                <label className={userHasManagerPermission ? '' : 'disabled-text'}>{importOptionsMessages.autoApproveCheckbox}</label>
                <HelpPoint helpPoint={helpPoints.point4} />

                {userHasManagerPermission
                  ? autoApproveChecked &&
                  <div className="reports-to-approve-count">
                    <div>{importOptionsMessages.willApproveCount}</div>
                    <div>{importOptionsMessages.willNotApproveCount}</div>
                  </div>
                  : <div>
                    {importOptionsMessages.optionsNotAvailable.message}
                    <a href={importOptionsMessages.optionsNotAvailable.link} target="_blank" title="go to user profile" >{importOptionsMessages.optionsNotAvailable.button}</a>
                    </div>
                  }
                </>
            }
            {anyExistingObjects &&
            <div className="form_field prepend-top">
              {importOptionsMessages.updateExistingInfo}
              <br />
              <div className="overwrite-info-block">
                <input title="overwrite" name={`${fieldName}[update_existing]`} className="overwrite-checkbox" type="checkbox" checked={updExistingChecked} value={updExistingChecked ? '1' : '0'} onChange={onChangeUpdExisting} />
                <label>{importOptionsMessages.updateExistingCheckboxName}</label>
                <HelpPoint helpPoint={helpPoints.point5} />
              </div>
            </div>
            }
          </div>
        </div>
        <strong>{parse(importOptionsMessages.reviewInfo)}</strong>
        <br />
        <input type="submit" name="commit" value={importOptionsMessages.importButton} data-disable-with="Accept and Import!" />
        {getFileSelectForm()}
      </div>
      <br />
    </>
  );

  const mapHeaderErrors = () => headerErrors.map((error, index) => (
    <div key={`headerError-${index}`} className="error">
      <p>{error}</p>
    </div>
  ));

  const mapHeaderWarnings = () => headerWarnings.map((warning, index) => (
    <div key={`headerWarning-${index}`} className="notice">
      <p>{warning}</p>
    </div>
  ));

  return (
    <>
      {noValidObjects ? getFileSelectForm() : getImportOptions()}
      {headerErrors.length > 0 && mapHeaderErrors()}
      {headerWarnings.length > 0 && mapHeaderWarnings()}
      <hr />
    </>
  );
};

export default ImportOptions;
