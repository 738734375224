import SpecReportDisplayItems from './SpecReportDisplayItems'

export default class FullTextDescriptionComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      isDisplayDataItemsExpanded: true,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      reportDisplayItems: nextProps.reportDisplayItems,
    });
  }

  render() {
    return (
      <SpecReportDisplayItems
        onRemoveDisplayDataItem={this.state.setDisplayDataItems}
        expandable={false}
        {...this.state}
      />
    );
  }
}
