import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { FixedSizeList as List } from 'react-window';
import HelpPoint from '../Helpers/HelpPoint';

const ImportFilter = (props) => {
  const { selected, helpPoint, onChange } = props;
  const options = [
    { label: 'All Records', value: null },
    { label: 'Records with Errors', value: 'errors' },
    { label: 'Records with Warnings', value: 'warnings' }
  ];
  const defaultValue = selected ? options.find(o => o.value === selected) : options[0];
  const [selectedFilter, setSelectedFilter] = useState(defaultValue);
  const [selectDone, setSelectDone] = useState(false);
  const wrapStyle = props.wrapStyle || { width: '212px' };

  useEffect(() => {
    if (selectDone) {
      onChange(selectedFilter.value);
    }
  }, [selectedFilter]);

  const MenuList = ({ options, children, getValue }) => {
    const OPTION_HEIGHT = 27;
    const ROWS = 6;
    const [value] = getValue();
    const initialOffset = (options.indexOf(value) !== -1 && Array.isArray(children) &&
      children.length >= ROWS && options.indexOf(value) >= ROWS)
      ? (options.indexOf(value) * OPTION_HEIGHT) - (OPTION_HEIGHT * 5)
      : 0;
    const height = (children.length >= ROWS ? OPTION_HEIGHT * ROWS : children.length * OPTION_HEIGHT) || 27;

    return (
      <List
        height={height}
        itemCount={children.length || 0}
        itemSize={OPTION_HEIGHT}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  };

  const DropdownIndicator = props => (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <span title="Dropdown Indicator" />
      </components.DropdownIndicator>
    )
  );

  const onFilterSelct = (value) => {
    setSelectDone(true);
    setSelectedFilter(value);
  };

  return (
    <div data-testid="filterSelector" title="Filter Input Selector" className="data-items-filter-inner-container">
      <div title="Filter By Label" className="label data-items-filter-inner-container">Filter by:<HelpPoint helpPoint={helpPoint} /></div>
      <div className="optimizedSelectWrap data-items-filter" style={wrapStyle}>
        <Select
          options={options}
          components={{ MenuList, DropdownIndicator }}
          value={selectedFilter}
          className="OptimizedSelect"
          classNamePrefix="OptimizedSelect"
          onChange={onFilterSelct}
        />
      </div>
    </div>
  );
};

export default ImportFilter;
