import {
  render,
  within,
  screen,
} from '@testing-library/react';
import ReportPreviewTable from './ReportPreviewTable';

describe('<ReportPreviewTable />', () => {
    it('should render report table headers', async () => {
      exerciseReportTable();
      const { getByText } = within(screen.getByTestId('reportPreviewTable'));
      expect(getByText('Name')).toBeInTheDocument();
      expect(getByText('Description')).toBeInTheDocument();
      expect(getByText('Purpose')).toBeInTheDocument();
      expect(getByText('Owner')).toBeInTheDocument();
      expect(getByText('Functional Areas')).toBeInTheDocument();
      expect(getByText('Specification Type')).toBeInTheDocument();
      expect(getByText('Source Data Systems')).toBeInTheDocument();
      expect(getByText('Access Details')).toBeInTheDocument();
      expect(getByText('Display Description')).toBeInTheDocument();
      expect(getByText('Output Format')).toBeInTheDocument();
      expect(getByText('Mapping Description')).toBeInTheDocument();
      expect(getByText('Selection Description')).toBeInTheDocument();
      expect(getByText('Sort Description')).toBeInTheDocument();
      expect(getByText('Target Data System')).toBeInTheDocument();
      expect(getByText('Tags')).toBeInTheDocument();
      expect(getByText('Related Tools')).toBeInTheDocument();
      expect(getByText('Primary Tool')).toBeInTheDocument();
      expect(getByText('External ID')).toBeInTheDocument();
      expect(getByText('Usage Note')).toBeInTheDocument();
      expect(getByText('Additional Details')).toBeInTheDocument();
      expect(getByText('Designated Requester')).toBeInTheDocument();
      expect(getByText('Technical Details')).toBeInTheDocument();
      expect(getByText('AdditionalAttribute1')).toBeInTheDocument();
    });

    it('should render report table values', async () => {
      exerciseReportTable();
      const { getByText } = within(screen.getByTestId('reportPreviewTable'));
      expect(getByText('SpecName1')).toBeInTheDocument();
      expect(getByText('description1')).toBeInTheDocument();
      expect(getByText('purpose1')).toBeInTheDocument();
      expect(getByText('Purpose')).toBeInTheDocument();
      expect(getByText('owner1')).toBeInTheDocument();
      expect(getByText('fa1')).toBeInTheDocument();
      expect(getByText('specificationType1')).toBeInTheDocument();
      expect(getByText('sourceDataSystems1')).toBeInTheDocument();
      expect(getByText('accessDetails1')).toBeInTheDocument();
      expect(getByText('displayDescription1')).toBeInTheDocument();
      expect(getByText('outputFormat1')).toBeInTheDocument();
      expect(getByText('mappingDescription1')).toBeInTheDocument();
      expect(getByText('selectionDescription1')).toBeInTheDocument();
      expect(getByText('sortDescription1')).toBeInTheDocument();
      expect(getByText('targetDataSystem1')).toBeInTheDocument();
      expect(getByText('tags1')).toBeInTheDocument();
      expect(getByText('tools1')).toBeInTheDocument();
      expect(getByText('primaryTool1')).toBeInTheDocument();
      expect(getByText('externalId1')).toBeInTheDocument();
      expect(getByText('usageNote1')).toBeInTheDocument();
      expect(getByText('additionalDetails1')).toBeInTheDocument();
      expect(getByText('designatedRequester1')).toBeInTheDocument();
      expect(getByText('technicalDetails1')).toBeInTheDocument();
    });
});

const exerciseReportTable = () => {
  const reportData = {
    name: 'SpecName1',
    description: 'description1',
    purpose: 'purpose1',
    owner: 'owner1',
    functionalAreas: 'fa1',
    specificationType: 'specificationType1',
    sourceDataSystems: 'sourceDataSystems1',
    accessDetails: 'accessDetails1',
    displayDescription: 'displayDescription1',
    outputFormat: 'outputFormat1',
    mappingDescription: 'mappingDescription1',
    selectionDescription: 'selectionDescription1',
    sortDescription: 'sortDescription1',
    targetDataSystem: 'targetDataSystem1',
    tags: 'tags1',
    relatedTools: 'tools1',
    primaryTool: 'primaryTool1',
    externalId: 'externalId1',
    usageNote: 'usageNote1',
    additionalDetails: 'additionalDetails1',
    designatedRequester: 'designatedRequester1',
    technicalDetails: 'technicalDetails1',
  }
  const component = render(<ReportPreviewTable
    objectValues={reportData}
    customFieldsHeaders={['AdditionalAttribute1']}
    customFieldsValues={['AdditionalAttribute1Value']}
  />);

  return {
    ...component,
  };
};
