import React from 'react';

const ReportPreviewTable = (props) => {
  const { customFieldsHeaders, customFieldsValues, objectValues, objectIndex, handleScroll } = props;

  const mapCustomFieldsHeaders = () => customFieldsHeaders.map((header, index) => <th key={`custFieldHead-${index}`}>{header}</th>);

  const mapCustomFieldsValues = () => customFieldsValues.map((value, index) => <td key={`custFieldVal-${index}`}>{value}</td>);
  return (
    <table data-testid="reportPreviewTable" className="import-preview-table bottomScrollContainer" key={`ReportPreviewTable-${objectIndex}`} id={`ReportPreviewTable-${objectIndex}`} onScroll={handleScroll} >
      <thead>
        <tr>
          <th>Name</th>
          <th>Description</th>
          <th>Purpose</th>
          <th>Owner</th>
          <th>Functional Areas</th>
          <th>Specification Type</th>
          <th>Source Data Systems</th>
          <th>Access Details</th>
          <th>Display Description</th>
          <th>Output Format</th>
          <th>Mapping Description</th>
          <th>Selection Description</th>
          <th>Sort Description</th>
          <th>Target Data System</th>
          <th>Tags</th>
          <th>{objectValues.tools ? 'Tools' : 'Related Tools'}</th>
          <th>Primary Tool</th>
          <th>External ID</th>
          <th>Usage Note</th>
          <th>Additional Details</th>
          <th>Designated Requester</th>
          <th>Technical Details</th>
          {mapCustomFieldsHeaders()}
        </tr>
      </thead>
      <tbody className='bottomScroll'>
        <tr>
          <td>{objectValues.name}</td>
          <td>{objectValues.description}</td>
          <td>{objectValues.purpose}</td>
          <td>{objectValues.owner}</td>
          <td>{objectValues.functionalAreas}</td>
          <td>{objectValues.specificationType}</td>
          <td>{objectValues.sourceDataSystems}</td>
          <td>{objectValues.accessDetails}</td>
          <td>{objectValues.displayDescription}</td>
          <td>{objectValues.outputFormat}</td>
          <td>{objectValues.mappingDescription}</td>
          <td>{objectValues.selectionDescription}</td>
          <td>{objectValues.sortDescription}</td>
          <td>{objectValues.targetDataSystem}</td>
          <td>{objectValues.tags}</td>
          <td>{objectValues.tools || objectValues.relatedTools}</td>
          <td>{objectValues.primaryTool}</td>
          <td>{objectValues.externalId}</td>
          <td>{objectValues.usageNote}</td>
          <td>{objectValues.additionalDetails}</td>
          <td>{objectValues.designatedRequester}</td>
          <td>{objectValues.technicalDetails}</td>
          {mapCustomFieldsValues()}
        </tr>
      </tbody>
    </table>
  );
};

export default ReportPreviewTable;
