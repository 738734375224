import ContactForm from "./ContactForm";
import CustomFieldMultiselect from "./CustomFieldMultiselect";

export default class SpecCustomField extends React.Component {
  constructor(props) {
    super(props);
    this.makeDatePicker = this.makeDatePicker.bind(this);
    this.state = {
      ...props,
      customFieldEditContent: this.customFieldEditContent.bind(this),
    }
  }

  makeDatePicker(element) {
    $j(element).datepicker({
      autoclose: true,
      dateFormat: $j('#js_date_format').val(),
      onSelect(selectedDate) {
        this.value = '';
        Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set.call(this, selectedDate);
        const event = createNewEvent('input', true);
        this.dispatchEvent(event);
      },
    });
  }

  customFieldEditContent(props) {
    const { customField, customFieldValue, imageRemoveIcon, allOptions, institution, helpPoints, name, errors } = props;
    const { customFieldDefinition } = customField;
    let el;

    el = (
      <div>
      {<Errors errors={errors} />}
      <label>
        {customFieldDefinition.fieldName.capitalize()}
      </label>

        {customFieldDefinition.fieldType === 'text' &&
        <textarea
          onChange={e => props.onCustomFieldChange(e, 'text')}
          value={customFieldValue || ''}
          id={customFieldDefinition.id}
        >
        </textarea>
        }

        {customFieldDefinition.fieldType === 'number' &&
        <input
          type="number"
          onChange={e => props.onCustomFieldChange(e, 'number')}
          value={customFieldValue || ''}
          id={customFieldDefinition.id}
        />
        }

        {customFieldDefinition.fieldType === 'datetime' &&
        <input
          type="text"
          onChange={e => props.onCustomFieldChange(e, 'datetime')}
          value={customFieldValue || ''}
          id={customFieldDefinition.id}
          ref={this.makeDatePicker}
        />
        }

        {customFieldDefinition.fieldType === 'checkbox' &&
        <input
          type="checkbox"
          onChange={e => props.onCustomFieldChange(e, 'checkbox')}
          value={customFieldValue}
          checked={customFieldValue}
          id={customFieldDefinition.id}
        />
        }

        {customFieldDefinition.fieldType === 'dropdown' &&
        <select
          onChange={e => props.onCustomFieldChange(e, 'dropdown')}
          defaultValue={customField.value}
          title={customField.customFieldDefinition.description}
        >
          {
            customFieldDefinition.customFieldDefinitionValues.map(function (cfdv) {
              return <option key={cfdv.id} value={cfdv.value}>{cfdv.field_name || cfdv.fieldName}</option>;
            })
          }
        </select>
        }

        {customFieldDefinition.fieldType === 'multiselect' &&
        <div>
          { <CustomFieldMultiselect
              customFieldDefinitionValues={customFieldDefinition.customFieldDefinitionValues}
              selectedCustomFieldDefinitionValues= {customField.value}
              parentObjectType= {customFieldDefinition.modelType}
              editable={true}
              removeIconSrc={imageRemoveIcon}
              onChange={props.onCustomFieldChange}
            />
          }
        </div>
        }

        {customFieldDefinition.fieldType === 'contact' &&
          <div>
            {
              <ContactForm
                selectedOption={customField.contact ? allOptions.find(user => user.id === customField.contact.user_id) : null}
                contact={customField.contact}
                customFieldDefinitionId={customField.customFieldDefinitionId}
                isClearable={true}
                useDropdown={false}
                ariaLabel='Contact link to User'
                name={name}
                allOptions={allOptions}
                institution={institution}
                helpPoints={helpPoints}
                onChangeContact={props.onCustomFieldChange}
            />
            }
          </div>
        }

        <br />
        <button
          onClick={props.onCustomFieldCancel}
          className="custom-field__button"
        >
          Cancel
        </button>

        <button
          onClick={e => props.onCustomFieldSave(e, customField)}
          className="custom-field__button"
        >
          Save
        </button>
      </div>
    );
    return el;
  }

  addManualLabel(userId, modelField, field) {
    if ((modelField === field) || !field || !userId) {
      return
    } else {
      return (
        <span>
          &ensp;
          <span className="modified_background" title="This contact attribute has been modified from the value in the user's profile.">(modified)</span>
        </span>
      );
    }
  }

  checkContactPresence(customField) {
    if (customField.contact?.name === '' && customField.contact?.email === '' &&
        customField.contact?.phone === '' && customField.contact?.title === '' &&
        customField.contact?.organization === '' && customField.contact?.url === '' &&
        customField.contact?.notes === '') {
          return true
        } else {
          return false
        };
  };

  getOptionDescription(displayValue, customField) {
    let value = customField.customFieldDefinition.customFieldDefinitionValues.find(value => (value.fieldName || value.field_name) == displayValue)
    return value?.description || 'No description available'
  }

  renderContactInfo(customField) {
    const { institution, allOptions } = this.props
    const profileLink = customField.contact?.userId ? <a href={`/users/${allOptions.find(user => user.id === customField.contact?.userId).login}`}>(User Profile)</a> : ''
    const user = customField.contact?.userId ? allOptions.find(user => user.id === customField.contact?.userId) : null
    const notSpecified = this.checkContactPresence(customField)

    return notSpecified ? (
        <div>
          <span>Not specified.</span>
        </div>
      ) : (
        <div>
          <div>
            {customField.contact?.name} {profileLink}
          </div>
          {customField.contact?.email &&
            <div>
              <a href={`mailto:${customField.contact?.email}`}>{customField.contact?.email}</a>
              {this.addManualLabel(customField.contact?.userId, user?.email, customField.contact?.email)}
            </div>
          }
          <div>
            {customField.contact?.phone}{this.addManualLabel(customField.contact?.userId, user?.phoneNumber, customField.contact?.phone)}
          </div>
          <div>
            {customField.contact?.title}{this.addManualLabel(customField.contact?.userId, user?.jobTitle, customField.contact?.title)}
          </div>
          <div>
            {customField.contact?.organization}{this.addManualLabel(customField.contact?.userId, institution.name, customField.contact?.organization)}
          </div>
          {customField.contact?.url &&
            <div>
              <a style={{display: 'inline-block'}} href={customField.contact.url} target="_blank">{customField.contact.url}</a>
              <div style={{display: 'inline-block'}}>{this.addManualLabel(customField.contact?.userId, institution.web_site, customField.contact.url)}</div>
            </div>
          }
          <div>
            {customField.contact?.notes}
          </div>
        </div>
      )
  }

  render() {
    let { customField, editable, imageEdit, customFieldEditMode, childKey } = this.props;

    return (
      <div>
        { customFieldEditMode != customField.customFieldDefinition.id ? (
            <div
              id={`custom-field-definition-${customField.customFieldDefinition.id}`}>
              <div>
                <label
                  style={{ display: 'inline-block' }}
                >
                  {customField.customFieldDefinition.fieldName.capitalize()}
                </label>
                {editable ? (<img
                  src={imageEdit}
                  style={{verticalAlign: 'middle', marginLeft: '5px'}}
                  onClick={e => this.state.toggleCustomFieldEditMode(e, customField)}
                  alt="Edit"
                />) : ''}
              </div>
              <blockquote className="specification-section">
                { customField.customFieldDefinition.fieldType === 'checkbox' ? (
                    <input
                      type="checkbox"
                      value={customField.value}
                      checked={customField.displayValue}
                      disabled
                    />
                  ) : (
                    customField.customFieldDefinition.fieldType === 'contact' ? (
                      this.renderContactInfo(customField)
                    ) : (
                    customField.value === null || _.isEmpty(customField.value)) ? (
                      <span>Not specified.</span>
                    ) : (
                      customField.customFieldDefinition.fieldType === 'multiselect' ? (
                        customField.displayValue.map((value, index) => {
                          return (
                            <div
                              key={`val-${index}-${childKey}`}
                              className="multiselect-options-list"
                              title={this.getOptionDescription(value, customField)}
                            >
                              {value}
                            </div>
                          )
                        })
                      ) : (
                        <span title={this.getOptionDescription(customField.displayValue, customField)}>{customField.displayValue}</span>
                      )
                    )
                  )
                }
              </blockquote>
            </div> ) :
          (
            <div>
              <div>
                {this.customFieldEditContent(this.props)}
              </div>
            </div>
          )
        }
      </div>
    )
  }
}
