import ShowValidValueListTableForm from "./ShowValidValueListTableForm";

export default function ShowNonMasterForms(props) {
  return (
    <div>
      {
        props.getNonMasterLists().map(list => (
          list &&
            <ShowValidValueListTableForm
              {...props}
              key={list.id}
              validValueList={list}
              validValueGroupVersion={props.validValueGroupVersion}
              valid_value_list_show_page={props.valid_value_list_show_page}
              quality_rule_show_page={props.quality_rule_show_page}
              has_igs_module={props.has_igs_module}
              svvltf_help_points={props.svvltf_help_points}
              allLists={props.allLists}
            />
        ))
      }
    </div>
  );
}
