import React from 'react';
import PropTypes from 'prop-types';
import { dropdownRender } from './FunctionalAreasUtils.js';

export default class FunctionalAreasDrillDown extends React.Component {
  constructor(props) {
    super(props);
    const options = this.createOptions(this.props.functionalAreas),
      flatOptions = this.createFlattenOptions(this.props.functionalAreas);
    this.state = {
      options,
      flatOptions,
      searchString: '',
      selectedFunctionalAreas: new Immutable.List(this.props.selectedFunctionalAreas),
      defaultSelected: this.getDefaultSelectedValue(options),
      visible: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const faIds = this.state.selectedFunctionalAreas
        .toArray()
        .map(fa => fa.value),
      prevFaIds = prevState.selectedFunctionalAreas
        .toArray()
        .map(fa => fa.value);

    if (!_.isEqual(faIds, prevFaIds))
      $j('.workflow-entry-points-form').submit();
  }

  /**
   * returns an array of values which is required to autoexpanding the first item in the list
   * @param options
   * @returns {Array}
   */
  getDefaultSelectedValue(options) {
    if (_.isEmpty(options))
      return [];


    /**
     * options[0] is institution-wide Functional Area,
     * root node of all functional areas
     */

    return [_.first(options).value];
  }

  createOptions = functionalAreas =>
    functionalAreas.map(fa => this.parseFunctionalArea(fa));

  parseFunctionalArea = (fa, path) => {
    const itemPath = [path, fa.id];
    if (_.isEmpty(fa.children)) {
      return {
        value: fa.id,
        label: fa.name,
        description: fa.description,
        path: itemPath,
        disabled: !fa.availableToDrilldown,
        faAvailable: fa.available,
      };
    }
    return {
      value: fa.id,
      label: fa.name,
      description: fa.description,
      path: itemPath,
      faAvailable: fa.available,
      children: fa.children.map(childArea =>
        this.parseFunctionalArea(childArea, itemPath)),
    };
  };

  parseFlatFunctionalArea = (fa, path) => {
    let result = [];
    path = path || [];
    const itemPath = [...path, fa.id];

    if (fa.available === true) {
      result.push({
        value: fa.id, label: fa.name, description: fa.description, path: itemPath, faAvailable: fa.available,
      });
    }


    if (!_.isEmpty(fa.children)) {
      for (let i = 0; i < fa.children.length; i += 1) {
        result = _.concat(
          result,
          this.parseFlatFunctionalArea(fa.children[parseInt(i)], itemPath),
        );
      }
    }
    return result;
  };

  createFlattenOptions = (functionalAreas) => {
    let result = [];
    for (let i = 0; i < functionalAreas.length; i += 1) {
      const fa = functionalAreas[i];
      result = _.concat(result, this.parseFlatFunctionalArea(fa));
    }
    return _.uniqBy(result, 'value');
  };

  onFunctionalAreaChange = (value, selectedOptions) => {
    const { selectedFunctionalAreas, searchString, options } = this.state,
      selectedFunctionalArea = selectedOptions.last();

    let defaultSelected = null;

    if (!_.isEmpty(searchString))
      defaultSelected = this.getDefaultSelectedValue(options);
    else
      defaultSelected = value;

    if (
      !selectedFunctionalAreas.find(fa => fa.value === selectedFunctionalArea.value)
    ) {
      if (selectedFunctionalArea.faAvailable === true) {
        this.setState({
          searchString: '',
          selectedFunctionalAreas: selectedFunctionalAreas.push(selectedFunctionalArea),
          defaultSelected,
        });

        if(this.props.selectedValueHandler){
          let selectedValues = this.state.selectedFunctionalAreas?._tail?.array || []
          this.props.selectedValueHandler(selectedValues.concat(selectedFunctionalArea))
        }
      } else {
        alert('Your user does not have access to this functional area.');
        return null;
      }
    }
  };

  onSearchStringChange = (e) => {
    const searchString = e.target.value;
    this.setState({
      searchString,
      visible: true,
    });
  };

  onRemoveClickHandler = (faIndex) => {
    const { selectedFunctionalAreas } = this.state;
    this.setState({
      selectedFunctionalAreas: selectedFunctionalAreas.delete(faIndex),
    });

    if(this.props.selectedValueHandler){
      let selectedValues = this.state.selectedFunctionalAreas.filter((elem, i) => i !== faIndex)?._tail?.array
      this.props.selectedValueHandler(selectedValues)
    }
  };

  onSearchKeyDown = (e) => {
    // Prevents the closing of the parent element (Cascader) by Backspase button
    switch (e.keyCode) {
      case KeyCode.BACKSPACE:
        e.stopPropagation();
        break;
      case KeyCode.ESC:
        this.setState({
          visible: false,
        });
        break;
      default:
        break;
    }
  };

  preventPageScroll = (e) => {
    const { searchInput } = this;
    switch (e.keyCode) {
      case KeyCode.UP:
      case KeyCode.DOWN:
      case KeyCode.LEFT:
      case KeyCode.RIGHT:
        e.preventDefault();
        break;
      case KeyCode.ENTER:
        break;
      case KeyCode.BACKSPACE:
      case KeyCode.ESC:
      default:
        searchInput.focus();
        break;
    }
  };

  togglePopupVisibility = () => {
    const { visible } = this.state;
    this.setState({
      visible: !visible,
    });
  };

  onPopupVisibleChange = (visible) => {
    this.setState({
      visible,
    });
  };

  getHiddenFieldName = (parentObjectType, idx) => {
    if (parentObjectType === 'term')
      return `term[term_functional_area_memberships_attributes][${idx}][functional_area_id]`;
    else if (parentObjectType === 'report')
      return 'report[functional_area_ids][]';
    else if (parentObjectType === 'quality_rule')
      return `quality_rule[quality_rule_functional_area_memberships_attributes][${idx}][functional_area_id]`;
    else if (parentObjectType === 'quality_issue')
      return `quality_issue[quality_issue_functional_area_memberships_attributes][${idx}][functional_area_id]`;
    else if (parentObjectType === 'research_data')
      return `research_data[research_data_functional_area_memberships_attributes][${idx}][functional_area_id]`;
    else if (parentObjectType === 'collection')
      return `project[project_functional_area_memberships_attributes][${idx}][functional_area_id]`;
    else if (parentObjectType === 'settings') {
      return this.props.rollUp
        ? `user[user_roll_up_functional_area_settings_attributes][${idx}][functional_area_id]`
        : `user[user_functional_area_settings_attributes][${idx}][functional_area_id]`;
    } else if (parentObjectType === 'global')
      return 'global[term_functional_area_memberships][]';
    else if (parentObjectType === 'valid_value_list_group')
      return `valid_value_list_group[valid_value_list_group_functional_area_memberships_attributes][${idx}][functional_area_id]`;
    else if (parentObjectType === 'system_of_record')
      return `erp_system[system_of_record_memberships_attributes][${idx}][functional_area_id]`;
    else if (parentObjectType === 'related_functional_area')
      return `erp_system[related_functional_area_memberships_attributes][${idx}][functional_area_id]`;

    console.error(`Undefined field name for: ${parentObjectType}`);
    return '';
  };

  getItemContainer = (fa, idx) => {
    const { parentObjectType } = this.props;
    return (
      <div className="item-container" key={`${fa.label}:${fa.value}`}>
        <div className="span-6 item-name">
          <a href={`/institution/functional_areas/${fa.value}`} target="blank" title={`${fa.description || 'No description available'}`}>
            {fa.label}
          </a>
        </div>
        <div className="span-2 btn-remove-item-container" style={{ borderRight: 0 }}>
          <input
            type="hidden"
            className="item-value"
            name={this.getHiddenFieldName(parentObjectType, idx)}
            value={fa.value}
          />
          {this.props.editable && (
            <button
              name="button"
              type="button"
              className="btn-remove-item"
              aria-label="remove item"
              onClick={() => this.onRemoveClickHandler(idx)}
            >
              <img
                src={this.props.removeIconSrc}
                alt="Remove field name"
                tabIndex="0"
              />
            </button>
          )}
        </div>
        <br />
      </div>
    );
  }

  getDeletedItemContainer = (fa, idx) => {
    const { parentObjectType } = this.props,
      index = idx + this.state.selectedFunctionalAreas.size;
    return (
      <div className="item-container" key={`${fa.label}:${fa.value}`}>
        <div className="span-2 btn-remove-item-container">
          <input
            type="hidden"
            className="item-value"
            name={
              this.props.rollUp
                ? `user[user_roll_up_functional_area_settings_attributes][${index}][id]`
                : `user[user_functional_area_settings_attributes][${index}][id]`
            }
            value={fa.id}
          />
          <input
            type="hidden"
            className="item-value"
            name={
              this.props.rollUp
                ? `user[user_roll_up_functional_area_settings_attributes][${index}][_destroy]`
                : `user[user_functional_area_settings_attributes][${index}][_destroy]`
            }
            value="1"
          />
        </div>
      </div>
    );
  };

  getOptions = () => {
    const {
      searchString,
      selectedFunctionalArea,
      options,
      flatOptions,
    } = this.state;
    if (searchString && !selectedFunctionalArea) {
      const searchLowCase = searchString.toLowerCase();
      return flatOptions.filter(option =>
        option.label.toLowerCase().include(searchLowCase));
    }
    return options;
  };

  render() {
    const {
        searchString,
        selectedFunctionalAreas,
        defaultSelected,
        visible,
      } = this.state,
      { editable, labelTarget } = this.props,
      opts = this.getOptions();

    return (
      <div className="cascader-container">
        {editable && (
          <Cascader
            options={opts}
            expandIcon=""
            expandTrigger="hover"
            changeOnSelect
            onChange={this.onFunctionalAreaChange}
            onPopupVisibleChange={this.onPopupVisibleChange}
            popupVisible={visible}
            value={defaultSelected}
            dropdownRender={menus => dropdownRender(menus, this.props.functionalAreas)}
          >
            <span className="ui-combobox">
              <input
                className={`ui-widget-content ui-combobox-input ui-widget ui-corner-left`}
                placeholder="Type or Select..."
                autoComplete="off"
                id={labelTarget || null}
                value={searchString}
                onChange={this.onSearchStringChange}
                onClick={this.togglePopupVisibility}
                onKeyDown={this.onSearchKeyDown}
                ref={el => (this.searchInput = el)}
                aria-label="Type or Select Functional Area"
                id={`${this.props.parentObjectType}_functional_areas`}
              />
              <a
                tabIndex="-1"
                title="Show All Functional Areas"
                className={`ui-button ui-widget ui-state-default
                            ui-button-icon-only ui-corner-right
                            ui-combobox-toggle`}
                role="button"
              >
                <span className="ui-button-icon-primary ui-icon ui-icon-triangle-1-s"
                      role="img"
                      aria-label="Show all Functional Areas" />
                <span className="ui-button-text" />
              </a>
            </span>
          </Cascader>
        )}
        <div id="functional-areas-selected-items-container" className="selected-items-container">
          {selectedFunctionalAreas.map((fa, idx) =>
            this.getItemContainer(fa, idx))}
          {this.props.parentObjectType === "settings" &&
            this.props.selectedFunctionalAreas &&
            this.props.selectedFunctionalAreas
              .filter(x => selectedFunctionalAreas.indexOf(x) < 0)
              .map((fa, idx) => this.getDeletedItemContainer(fa, idx))}
        </div>
      </div>
    );
  }
}

FunctionalAreasDrillDown.propTypes = {
  functionalAreas: PropTypes.arrayOf(Object).isRequired,
  parentObjectType: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  rollUp: PropTypes.bool,
};

FunctionalAreasDrillDown.defaultProps = {
  editable: false,
  rollUp: false,
};
