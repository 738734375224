/* eslint no-console:0 */
import "core-js/stable";
import "regenerator-runtime/runtime";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  registerables
} from "chart.js";
import ClipboardJS from 'clipboard';
import { Bar } from "react-chartjs-2";
import FunctionalAreasDrillDown from "../components/FunctionalAreas/FunctionalAreasDrillDown";
import FunctionalAreasSidebarDrillDown from "../components/FunctionalAreas/FunctionalAreasSidebarDrillDown";
import Cascader from "rc-cascader";
import KeyCode from "rc-util/lib/KeyCode";
import Immutable from "immutable";
import "../src/attachment_container_resize";
import ReferenceDataKeyShortcuts from "../components/DataModelSearch/ReferenceDataKeyShortcuts";
import UserSelect from "../components/shared/UserSelect";
import OptimizedSelect from "../components/shared/OptimizedSelect";
import AutomaticNamedRoleSelector from "../components/Workflow/AutomaticNamedRoleSelector";
import HTMLReactParser from 'html-react-parser';

window.FunctionalAreasDrillDown = FunctionalAreasDrillDown;
window.FunctionalAreasSidebarDrillDown = FunctionalAreasSidebarDrillDown;
window.Cascader = Cascader;
window.KeyCode = KeyCode;
window.Immutable = Immutable;
window.ReferenceDataKeyShortcuts = ReferenceDataKeyShortcuts;
window.UserSelect = UserSelect;
window.OptimizedSelect = OptimizedSelect;
window.AutomaticNamedRoleSelector = AutomaticNamedRoleSelector;
window.ChartJS = ChartJS;
window.CategoryScale = CategoryScale;
window.LinearScale = LinearScale;
window.BarElement = BarElement;
window.Title = Title;
window.Tooltip = Tooltip;
window.Legend = Legend;
window.Bar = Bar;
window.HTMLReactParser = HTMLReactParser;
window.ClipboardJS = ClipboardJS;
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
