import HelpPoint from '../Helpers/HelpPoint';

class DMCSourceErpSystems extends React.Component {
  constructor(props) {
    super(props);
  }

  getItemContainer = sErpSystem => (
    <div className="item-container" key={`${sErpSystem.id}:${sErpSystem.name}`}>
      <div className="span-2 btn-remove-item-container">
        <button
          name="button"
          type="submit"
          className="pretty-button red mini lowercase clear btn-remove-item"
          aria-label="remove item"
          onClick={e => this.props.onSourceSystemRemove(e, sErpSystem.id)}
        >
          Remove
        </button>
      </div>

      <div className="span-6 item-name">
        <a href={`/institution/data_systems/${sErpSystem.id}`} target="blank">
          {sErpSystem.name}
        </a>
      </div>
      <br />
    </div>
  );

  dataSystemsOptionRenderer = ({ focusedOption, focusOption, key, option, selectValue, style, valueArray }) => {
    const classNames = ['nameOption'];

    if (option.type === 'header') {
      classNames.push('nameHeader');

      return (
        <div
          className={classNames.join(' ')}
          key={key}
          style={style}
        >
          {option.label}
        </div>
      );
    }

    classNames.push('nameField');
    if (option === focusedOption) classNames.push('nameOptionFocused');
    if (valueArray.indexOf(option) >= 0) classNames.push('nameOptionSelected');

    return (
      <div
        className={classNames.join(' ')}
        key={key}
        onClick={() => selectValue(option)}
        onMouseEnter={() => focusOption(option)}
        style={style}
      >
        {option.label}
      </div>
    );
  };

  render() {
    let el,
      editLink,
      list;
    const optionsForDataSystemsSelect = this.props.erpSystems
      .filter(s => !this.props.sourceErpSystems.find(es => es.id === s.id))
      .filter(s => s.hideFromDropdown == false)
      .map((erpSystem) => {
        if (erpSystem.groupFlag) {
          const header = {
            label: erpSystem.name,
            value: '',
            isHeader: true,
            isDisabled: true
          };
          const systems = erpSystem.childSystems.filter(s => !this.props.sourceErpSystems.find(es => es.id === s.id))
            .filter(s => s.hideFromDropdown == false)
            .map(system => ({
              value: system.id,
              label: system.name
            }));

          return [header].concat(systems);
        }
        return {
          value: erpSystem.id,
          label: erpSystem.name
        };
      }).reduce((firstEl, secondEl) => firstEl.concat(secondEl), []);

    if (this.props.sourceSystemsEditMode) {
      list = (
        <div className="selected-items-container">
          {this.props.sourceErpSystems.map(sourceErpSystem => this.getItemContainer(sourceErpSystem))}
        </div>
      );

      el = (
        <div
          id="editable-source-data-systems-content-area"
          className="workflow-sidebar-section"
          ref={el => this.el = el}
        >
          <div className="virtualized-custom-wrap">
            <div className="virtualized-custom-style">
              <OptimizedSelect
                options={optionsForDataSystemsSelect}
                value=""
                onChange={selectedErpSystem => selectedErpSystem && this.props.onSourceSystemAdd(selectedErpSystem.value)}
                isClearable={false}
              />

              <button
                onClick={this.props.toggleSourceSystemsEditMode}
                className="toggle-expansion-button btn-done"
                aria-expanded={this.props.getSourceSystemsEditMode()}
                aria-controls="editable-source-data-systems-content-area"
              >
                Done
              </button>
            </div>

            {list}
          </div>
        </div>
      );
    } else {
      el = (
        <ul className="DMC-source-erp-system">
          {this.props.sourceErpSystems.map(erpSystem =>
            (<li key={erpSystem.id}>
              { erpSystem?.dataApplication?.logoImageUrl ? <img src={erpSystem.dataApplication.logoImageUrl} alt={erpSystem.dataApplication.logoLabel} className='data-system-logo mapping' data-testid="data-source-erp-system-logo mapping" /> : null }
              {erpSystem.name}
              {' '}
              {this.props.browseLink(erpSystem, 'Source Erp System')}
             </li>))}
        </ul>
      );

      editLink = (
        <img
          src={this.props.imageEdit}
          style={{ verticalAlign: 'middle' }}
          className="toggle-expansion-button"
          alt="Edit source data systems icon"
          role="button"
          aria-expanded={this.props.getSourceSystemsEditMode()}
          aria-controls="editable-source-data-systems-content-area"
          onClick={this.props.toggleSourceSystemsEditMode}
        />
      );
    }

    return (
      <div className="dmc-header__source-erp-systems span-9">
        <h5>Source Data System{this.props.onlyLinio ? '' : 's'}:</h5>
        {this.props.onlyLinio || !this.props.editable ? '' : editLink}
        <HelpPoint helpPoint={this.props.helpPoints.point2} />
        {el}
      </div>
    );
  }
}

export default DMCSourceErpSystems;
