/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";

export default class Multiselect extends React.Component {
  constructor(props) {
    super(props);
    const { options, selected } = this.createOptions(this.props.customFieldDefinitionValues, this.props.selectedCustomFieldDefinitionValues);
    const flatOptions = options;
    this.state = {
      options,
      flatOptions,
      searchString: "",
      selectedCustomFieldDefinitionValues: new Immutable.List(
        selected
      ),
      defaultSelected: this.getDefaultSelectedValue(options),
      visible: false,
    };
  }

  /**
   * @param options
   * @returns {Array}
   */
  getDefaultSelectedValue(options) {
    if (_.isEmpty(options)) {
      return [];
    }

    return [_.first(options).value];
  }

  createOptions = (customFieldDefinitionValues, selectedCustomFieldDefinitionValues) => {
    let prevOptions = customFieldDefinitionValues.map((cfdv) => this.parseData(cfdv));
    let options = []
    let selected = []
    if (selectedCustomFieldDefinitionValues && selectedCustomFieldDefinitionValues != undefined){
      selected = this.getSelectedFromProps(selectedCustomFieldDefinitionValues, prevOptions)
      options = prevOptions.filter( opt => !selected.includes(opt) )}
    else {
      options = prevOptions
    }
    return {options, selected}
  }

  getSelectedFromProps = (selectedCustomFieldDefinitionValues, options) => {
    let selectedValues = selectedCustomFieldDefinitionValues.split('¦');
    let filteredSelectedValues = []
    selectedValues.map((el) => {
      options.find((opt) => {
        if (opt.label === el) {
          filteredSelectedValues.push(opt)
        }
      })
    })
    return filteredSelectedValues
  }

  parseData = (customFieldDefinitionValue) => {
      return {
        value: customFieldDefinitionValue.id,
        label: customFieldDefinitionValue.fieldName,
        description: customFieldDefinitionValue.description,
        position: customFieldDefinitionValue.position,
      };
  };

  onSelectChange = (value, selectedOptions) => {
    const { selectedCustomFieldDefinitionValues, searchString, options } = this.state;
    const selectedCustomDefinition = selectedOptions.last();

    let defaultSelected = null;

    if (!_.isEmpty(searchString)) {
      defaultSelected = this.getDefaultSelectedValue(options);
    } else {
      defaultSelected = value;
    }
    if (
      !selectedCustomFieldDefinitionValues.find(
        (scfdv) => scfdv.value === selectedCustomDefinition.value
      )
    ) {
      if (selectedCustomDefinition) {
        this.setState({
          searchString: "",
          selectedCustomFieldDefinitionValues: selectedCustomFieldDefinitionValues.push(
            selectedCustomDefinition
          ),
          options: options.filter( opt => opt.value !== selectedCustomDefinition.value ),
          defaultSelected,
        });
      }
    }
  };

  onSearchStringChange = (e) => {
    const searchString = e.target.value;
    this.setState({
      searchString,
      visible: true,
    });
  };

  onRemoveClickHandler = (scfdvIndex) => {
    const { selectedCustomFieldDefinitionValues, options } = this.state;
    options.push(selectedCustomFieldDefinitionValues.get(scfdvIndex))
    selectedCustomFieldDefinitionValues.delete(scfdvIndex);
    this.setState({
        selectedCustomFieldDefinitionValues: selectedCustomFieldDefinitionValues.delete(scfdvIndex),
        options: options.sort((a,b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))
    });
  };

  onSearchKeyDown = (e) => {
    switch (e.keyCode) {
      case KeyCode.BACKSPACE:
        e.stopPropagation();
        break;
      case KeyCode.ESC:
        this.setState({
          visible: false,
        });
        break;
      default:
        break;
    }
  };

  preventPageScroll = (e) => {
    const { searchInput } = this;
    switch (e.keyCode) {
      case KeyCode.UP:
      case KeyCode.DOWN:
      case KeyCode.LEFT:
      case KeyCode.RIGHT:
        e.preventDefault();
        break;
      case KeyCode.ENTER:
        break;
      case KeyCode.BACKSPACE:
      case KeyCode.ESC:
      default:
        searchInput.focus();
        break;
    }
  };

  togglePopupVisibility = () => {
    const { visible } = this.state;
    this.setState({
      visible: !visible,
    });
  };

  onPopupVisibleChange = (visible) => {
    this.setState({
      visible,
    });
  };

  getItemContainer = (scfdv, idx, selectedCustomFieldDefinitionValues) => {
    const { formObjectName } = this.props;
    return (
      <div className="multi-item-container" key={`${scfdv.label}:${scfdv.value}`}>
        <div className="span-5 item-name">
          <p className="item-description" target="blank" title={`${scfdv.description || 'No description available'}`}>
            {scfdv.label}
          </p>
        </div>
        <div className="span-2 btn-remove-item-container" style={{borderRight:0}}>
          <input
            type="hidden"
            className="item-value"
            name={`${formObjectName}[value]`}
            value={(selectedCustomFieldDefinitionValues.map((scfdv) => scfdv.label)).join('¦')}
          />
          {this.props.editable && (
            <button
              name="button"
              type="button"
              className="btn-remove-item"
              aria-label="remove item multiselect item"
              onClick={() => this.onRemoveClickHandler(idx)}
            >
              <img
                src={this.props.removeIconSrc}
                alt="Remove field name"
                tabIndex="0"
              />
            </button>
          )}
        </div>
        <br />
      </div>
    );
  }

  getEmptyContainer = () => {
    const { formObjectName } = this.props;
    return (
      <div className="empty-item-container">
          <input
            type="hidden"
            className="item-value"
            name={`${formObjectName}[value]`}
            value={''}
          />
      </div>
    );
  }

  getOptions = () => {
    const {
      searchString,
      selectedCustomDefinition,
      options,
      flatOptions,
    } = this.state;
    if (searchString && !selectedCustomDefinition) {
      const searchLowCase = searchString.toLowerCase();
      return flatOptions.filter((option) =>
        option.label.toLowerCase().include(searchLowCase)
      );
    }
    return options;
  };

  render() {
    const {
      searchString,
      selectedCustomFieldDefinitionValues,
      defaultSelected,
      visible,
    } = this.state;
    const { editable } = this.props;
    const opts = this.getOptions();

    return (
      <div className="cascader-container">
        {editable && (
          <Cascader
            options={opts}
            expandIcon=''
            expandTrigger="hover"
            changeOnSelect
            onChange={this.onSelectChange}
            onPopupVisibleChange={this.onPopupVisibleChange}
            popupVisible={visible}
            value={defaultSelected}
          >
            <span className="ui-combobox">
              <input
                className="ui-widget-content ui-combobox-input ui-widget ui-corner-left"
                placeholder="Type or Select..."
                autoComplete="off"
                value={searchString}
                onChange={this.onSearchStringChange}
                onClick={this.togglePopupVisibility}
                onKeyDown={this.onSearchKeyDown}
                ref={(el) => (this.searchInput = el)}
                aria-label="Type or Select Multiselect Options"
                id={this.props.parentObjectType + "_multiselect"}
              />
              <a
                tabIndex="-1"
                title="Show All Multiselect Options"
                className="ui-button ui-widget ui-state-default ui-button-icon-only ui-corner-right ui-combobox-toggle"
                role="button"
              >
                <span className="ui-button-icon-primary ui-icon ui-icon-triangle-1-s" role="img" aria-label="Show all Multiselect Options" />
                <span className="ui-button-text" />
              </a>
            </span>
          </Cascader>
        )}
        <div className="selected-items-container">
          {selectedCustomFieldDefinitionValues.size > 0 ? selectedCustomFieldDefinitionValues.map((scfdv, idx) =>
            this.getItemContainer(scfdv, idx, selectedCustomFieldDefinitionValues)
          ) : this.getEmptyContainer()}
        </div>
      </div>
    );
  }
}

Multiselect.propTypes = {
  customFieldDefinitionValues: PropTypes.arrayOf(Object).isRequired,
  selectedCustomFieldDefinitionValues: PropTypes.string,
  formObjectName: PropTypes.string.isRequired,
  editable: PropTypes.bool,
};

Multiselect.defaultProps = {
  editable: false,
};
