import React from 'react';
import HelpPoint from '../Helpers/HelpPoint';

const TermPreviewTable = (props) => {
  const { customFieldsHeaders, customFieldsValues, objectValues, hasWarningsOrErrors, emptyRowMessage, helpPoints, objectIndex, handleScroll } = props;

  const mapCustomFieldsHeaders = () => customFieldsHeaders.map((header, index) => (
    <th key={`custFieldHead-${index}`} scope="col">
      {header.name}
      {header.required &&
        <span title="Required field" className="required-field"><i>*</i></span>
      }
    </th>
  ));

  const mapCustomFieldsValues = () => customFieldsValues.map((value, index) => <td key={`custFieldVal-${index}`} scope="col">{value}</td>);

  const mapTechnicalDefinitions = () => objectValues.technicalDefinitions.values.map((td, index) => (
    <span key={`techDef-${index}`}>
      <blockquote className="technical margin-top-10px">
        {td.name}
      </blockquote>
      {td.erpSystem &&
        <>
          <h5>Data System:<HelpPoint helpPoint={helpPoints.point6} /></h5>
          <div>{td.erpSystem}</div>
        </>
      }
      {td.timeContext &&
        <>
          <h5>Time Context:<HelpPoint helpPoint={helpPoints.point7} /></h5>
          <div>{td.timeContext}</div>
        </>
      }
    </span>
  ));

  const mapSynonyms = () => objectValues.synonyms.values.map((synonym, index) => {
    const comma = index !== objectValues.synonyms.values.length - 1 ? ', ' : '';

    return (
      <span key={`synonym-${index}`} className={synonym.valid || synonym.errors ? '' : 'invalid-value'}>
        {`${synonym.name}${comma}`}
      </span>
    );
  });

  const mapFunctionalAreas = () => objectValues.functionalAreas.values.map((fa, index) => {
    const comma = index !== objectValues.functionalAreas.values.length - 1 ? ', ' : '';

    return (
      <span key={`fa-${index}`} className={fa.errors ? 'invalid-value' : ''}>
        {`${fa.name}${comma}`}
      </span>
    );
  });

  return (
    <table data-testid="termPreviewTable" className="import-preview-table bottomScrollContainer" key={`TermPreviewTable-${objectIndex}`} id={`TermPreviewTable-${objectIndex}`} onScroll={handleScroll}>
      <thead>
        <tr>
          <th scope="col">Name<span title="Required field" className="required-field"><i>*</i></span></th>
          <th scope="col">Definition</th>
          <th scope="col">Definition Source</th>
          <th scope="col">Classification</th>
          <th scope="col">Synonyms</th>
          <th scope="col">Functional Areas</th>
          <th scope="col">Tags</th>
          <th scope="col">Designated Requester</th>
          {mapCustomFieldsHeaders()}
        </tr>
      </thead>
      <tbody className='bottomScroll'>
        {objectValues.blank
          ? <tr>
              <td colSpan="9"><p>{emptyRowMessage}</p></td>
            </tr>
          : <>
              <tr>
                <td>
                  {objectValues.name}
                  {objectValues.public && <p>(Public)</p>}
                </td>
                <td>
                  <div className={objectValues.functionalDefinition.class}>
                    <h4>Functional Definition:<HelpPoint helpPoint={helpPoints.point8} /></h4>
                    <blockquote>{objectValues.functionalDefinition.value}</blockquote>
                  </div>
                  <div className={objectValues.technicalDefinitions.class}>
                    <h4>Technical Definitions:<HelpPoint helpPoint={helpPoints.point9} /></h4>
                    {mapTechnicalDefinitions()}
                  </div>
                </td>
                <td>
                  <div className={objectValues.termSource.class}>
                    {objectValues.termSource.value}
                  </div>
                </td>
                <td>
                  <div className={objectValues.classificationCode.class}>
                    {objectValues.classificationCode.value}
                  </div>
                </td>
                <td>
                  <div className={objectValues.synonyms.class}>
                    {mapSynonyms()}
                  </div>
                </td>
                <td>
                  <div className={objectValues.functionalAreas.class}>
                    {mapFunctionalAreas()}
                  </div>
                </td>
                <td>
                  <div className={objectValues.tagList.class}>
                    {objectValues.tagList.value}
                  </div>
                </td>
                <td>
                  <div className={objectValues.designatedRequester.class}>
                    {objectValues.designatedRequester.value?.name}
                  </div>
                </td>
                {mapCustomFieldsValues()}
              </tr>
              {hasWarningsOrErrors &&
                <tr>
                  <td colSpan="8" className="invalid-value">
                    <p>
                      {objectValues.valid && <span className="valid-value">√ OK</span>}
                    </p>
                  </td>
                </tr>
              }
            </>
        }
      </tbody>
    </table>
  );
};

export default TermPreviewTable;
