import HelpPoint from '../../components/Helpers/HelpPoint';

export default class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      label: props.label || '',
      id: props.id || '',
      fieldName: props.name || '',
    };
  }
  
  render() {
    const { value, checked, onChange, helpPoint } = this.props
    const { label, id, fieldName } = this.state

    return (
      <label className='normal-label margin-top-5px'>
        <input type="checkbox" name={fieldName} id={id} value={value} checked={checked} onChange={onChange} />
        {label}
        {helpPoint && <HelpPoint helpPoint={helpPoint} />}
      </label>
    );
  };
}