import React from "react";

export default class HelpPoint extends React.Component {
  componentDidMount(element) {
    const helpPointElement = $j(".help-point--not-initialized").last();
    if (helpPointElement[0]) {
      HelpPointsHoverStyle.update(helpPointElement[0]);
      helpPointElement.removeClass("help-point--not-initialized");
      if (!inIframe()) {
        helpPointElement.on("click", e => {
          let helpPointKey = $j(e.target.closest("span")).data(
            "help-point-key"
          );
          $j.ajax({
            url: "/help_points/help_text",
            data: { help_point_key: helpPointKey },
            success: renderHelpPopup,
            dataType: "json"
          });
        });
      }
    }
  }

  render() {
    return <span dangerouslySetInnerHTML={{ __html: this.props.helpPoint }} />;
  }
}
