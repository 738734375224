import DMCNodeShowWrapper from './DMCNodeShowWrapper';
import DMCLinkToRelatedObject from './DMCLinkToRelatedObject';

let DMCLineageTargetShow = class extends React.Component {
  render() {
    const targetShow = this.props.node;
    if (!targetShow)
      return null;

    return (
      <div
        title="View Target Item"
        ref={el => this.props.setModalContainer(el)}
      >
        <h3>{targetShow.name}</h3>
        <DMCLinkToRelatedObject node={targetShow} />
        <div>
          <h5>Note: </h5>
          { targetShow.note ? ` ${targetShow.note}` : ' None'}
        </div>
      </div>
    );
  }
};
DMCLineageTargetShow.displayName = 'DMCLineageTargetShow';
DMCLineageTargetShow = DMCNodeShowWrapper(DMCLineageTargetShow);

export default DMCLineageTargetShow;
