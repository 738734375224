import { render, screen } from "@testing-library/react";

import DMCSourceErpSystems from './DMCSourceErpSystems';

describe('<DMCSourceErpSystems />', () => {
  describe('data system logo', () => {
    it('Shows logo', () => {
      buildComponent()
      expect(screen.getByAltText('test-logo').src).toEqual('http://localhost/test_url')
    });
  });
});

function buildComponent(props) {
  render(
    <DMCSourceErpSystems
      erpSystems={
        [{
          id: 1,
          name: 'Test ERP',
          institutionId: 1,
          systemType: 'test',
          hidenFromDropdown: false
        }]
      }
      helpPoints={
        {
          point2: 'test'
        }
      }
      sourceErpSystems={
        [{
          id: 2,
          name: 'Test ERP_2',
          institutionId: 1,
          systemType: 'test',
          hidenFromDropdown: false,
          dataApplication: {
            id: 1,
            logoImageUrl: 'http://localhost/test_url',
            name: 'test logo',
            logoLabel: 'test-logo'
          }
        }]
      }
      browseLink={jest.fn(() => {})}
      getSourceSystemsEditMode={jest.fn(() => {})}
    />
  );
}
