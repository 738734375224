import { fetch } from "whatwg-fetch";
import DraggableDataSystem from "./DraggableDataSystem";

export default class DataSystemOrdering extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderedItems: this.mapItemsFromServer(props.ordered_items),
      unorderedItems: this.mapItemsFromServer(props.unordered_items),
      addDataSystemOrderingSelectValue: "prompt",
    };
  }

  componentWillUnmount() {
    this.handleSuccess = () => { };
  }

  changePosition = (item, position) => {
    const { orderedItems } = this.state;
    const changedItems = orderedItems.slice(0);
    const editedItem = _.find(changedItems, { id: item.id });
    const originalPosition = editedItem.position;
    this.reorderItems(changedItems, originalPosition, position);
  };

  reorderItems(items, originalPosition, newPosition) {
    const originalIndex = this.convertPositionToIndex(originalPosition);
    const newConfinedPosition = this.confineNewPosition(newPosition);
    const newIndex = this.convertPositionToIndex(newConfinedPosition);
    const updatedItems = [...items];
    const originalItem = updatedItems[originalIndex];
    const newItem = updatedItems[newIndex];
    updatedItems[originalIndex] = {
      ...newItem,
      position: originalPosition,
    };
    updatedItems[newIndex] = {
      ...originalItem,
      position: newConfinedPosition,
    };
    this.setState({ orderedItems: updatedItems }, this.postUpdatedOrder);
  }

  postUpdatedOrder = () => {
    return this.request({
      url: "/institution/data_systems/sort.json",
      method: "POST",
      body: this.buildReorderRequestBody(),
    });
  };

  request = ({ url, method, body }) => {
    fetch(url, {
      method,
      headers: {
        "Content-type": "application/json",
        "X-CSRF-Token": this.props.authenticity_token,
      },
      body: JSON.stringify(body),
    }).catch((e) => console.error(e.stack));
  };

  buildReorderRequestBody = () => {
    return {
      data_system_ordering: _.chain(this.state.orderedItems)
        .orderBy(["position"])
        .map("id")
        .value(),
    };
  };

  confineNewPosition(newPosition) {
    const { orderedItems } = this.state;
    if (newPosition > orderedItems.length) return orderedItems.length;
    if (newPosition < 1) return 1;
    return newPosition;
  }

  convertPositionToIndex = (position) => position - 1;

  convertIndexToPosition = (index) => index + 1;

  mapItemsFromServer = (items) =>
    _.map(items, (item) => ({
      id: item.id,
      title: item.name,
      position: item.display_order,
    }));

  getItems = () => {
    return _.chain(this.state.orderedItems).orderBy(["position"]).compact().value();
  };

  remove = (item) => {
    const updatedItems = _.chain(this.state.orderedItems)
      .reject({ id: item.id })
      .map(this.updatePosition)
      .value();
    const updatedUnorderedItems = [
      ...this.state.unorderedItems,
      { ...item, position: 0 },
    ];
    this.setState(
      { orderedItems: updatedItems, unorderedItems: updatedUnorderedItems },
      this.postUpdatedOrder
    );
  };

  updatePosition = (item, index) => {
    return {
      ...item,
      position: this.convertIndexToPosition(index),
    };
  };

  add = (event) => {
    const id = Number(event.target.value);
    const itemToAdd = _.find(this.state.unorderedItems, {
      id,
    });
    const updatedItems = _.map(
      [...this.state.orderedItems, itemToAdd],
      this.updatePosition
    );
    const updateUnorderedItems = _.reject(this.state.unorderedItems, {
      id,
    });
    this.setState(
      {
        orderedItems: updatedItems,
        unorderedItems: updateUnorderedItems,
      },
      this.postUpdatedOrder
    );
  };

  renderAddOptions() {
    return _.map(this.state.unorderedItems, (item) => (
      <option key={item.id} value={item.id}>
        {item.title}
      </option>
    ));
  }

  handleMouseReorder = (event, item, oldIndex, newIndex) => {
    this.changePosition(item, this.convertIndexToPosition(newIndex));
  };

  render() {
    return (
      <div className="data-system-ordering">
        <Reorder
          itemKey="id"
          lock="horizontal"
          holdTime="200"
          reorderId="data-system-ordering"
          template={DraggableDataSystem}
          list={this.getItems()}
          sharedProps={{
            changePosition: this.changePosition,
            onRemove: this.remove,
            trashcanIconPath: this.props.trashcan_icon_path,
            moveIconPath: this.props.move_icon_path,
            controlPlayIconPath: this.props.control_play_icon_path
          }}
          callback={this.handleMouseReorder}
        />
        <select
          aria-label="Add Data System to ordering"
          data-testid="order.add"
          name="add_data_system_ordering"
          onChange={this.add}
          value={this.state.addDataSystemOrderingSelectValue}
          className="data-system-ordering_add"
        >
          <option value="prompt" disabled>
            Add Data System to ordering
          </option>
          {this.renderAddOptions()}
        </select>
      </div>
    );
  }
}
