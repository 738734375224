export default class DisplayDataItemMarker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props,
      selected: false,
      moving: false,
    };
    this.mouseDown = this.mouseDown.bind(this);
    this.mouseUp = this.mouseUp.bind(this);
    this.mouseMove = this.mouseMove.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      leftPos: nextProps.leftPos,
      topPos: nextProps.topPos,
      editable: nextProps.editable,
      dimensions: nextProps.dimensions,
      moving: nextProps.moving,
      item: nextProps.item,
    });
  }

  UNSAFE_componentWillUpdate(_nextProps, _nextState) {
    const img = $j('.display-data-mockup-container__img');
    img && img.off('mouseup');
    img && img.off('mousemove');
  }

  componentDidUpdate(_prevProps, _nextState) {
    const { editable } = this.state;
    if (editable) {
      const img = $j('.display-data-mockup-container__img');
      img && img.on('mouseup', this.mouseUp);
      img && img.on('mousemove', this.mouseMove);
    }
  }

  selectItem() {
    const { editable } = this.state;
    if (!editable) {
      const { item } = this.state;
      this.setState({
        item: Object.assign({}, item, { selected: !item.selected }),
      });
      this.state.onSelectMarker(item);
    }
  }

  markerWasMoved() {
    const { item } = this.state;
    const { leftPos, topPos } = this.state;
    return (item.leftPos !== leftPos || item.topPos !== topPos);
  }

  mouseDown(e) {
    const { editable } = this.state;
    if (editable) {
      e.preventDefault();
      this.setState({
        initialOffset: {
          pageX: e.pageX,
          pageY: e.pageY,
        },
        moving: true,
      });
    }
  }

  mouseUp(e) {
    if (e.target.readAttribute('alt') === 'Delete') {
      return;
    }
    const { moving } = this.state;
    if (moving) {
      if (this.markerWasMoved()) {
        e.preventDefault();
        this.setState({
          ...this.state,
          moving: false,
        });
        this.dragMarker();
      } else {
        const { item } = this.state;
        const { leftPos, topPos } = this.state;
        this.props.showSpecSelectDisplayDataItemForm(leftPos, topPos, item);
      }
    }
  }

  removeMarker(e, item) {
    e.preventDefault();
    const emptyItem = Object.assign({}, item, { leftPos: null, rightPos: null });
    this.setState({
      ...this.state,
      moving: false,
    });
    this.state.onDragMarker(emptyItem);
    return false;
  }

  mouseMove(e) {
    e.preventDefault();
    const { moving, initialOffset } = this.state;
    const { item } = this.state;
    if (moving) {
      const { pageX, pageY } = initialOffset;
      const leftPos = item.leftPos + (e.pageX - pageX);
      const topPos = item.topPos + (e.pageY - pageY);
      if (this.isPointInsideImage(leftPos, topPos)) {
        this.setState({
          leftPos,
          topPos,
        });
      }
    }
  }

  dragMarker() {
    if (this.props.editable) {
      const { item } = Object.assign({}, this.state);
      const { leftPos, topPos } = this.state;
      if (this.isPointInsideImage(leftPos, topPos)) {
        item.leftPos = leftPos;
        item.topPos = topPos;
        this.state.onDragMarker(item);
      }
    }
  }

  isPointInsideImage(leftPos, topPos) {
    const { dimensions } = this.state;
    return (leftPos >= 0 && topPos >= 0 && leftPos <= dimensions.width && topPos <= dimensions.height);
  }

  render() {
    const { item } = this.state;
    const { reportItem } = item;
    const title = reportItem && reportItem.objectLabel;
    const leftPos = this.state.leftPos - 16; // These indents are needed to align the icon according to icon's dimensions (32x32)
    const topPos = this.state.topPos - 32;
    const { highlighted } = item;
    const { removeIconPath } = this.props;
    const { editable } = this.state;
    return (
      <div title={title}
        style={{ position: 'absolute', left: leftPos, top: topPos, height: '32px', width: '32px', zIndex: 3 }}
        className="display-data-mockup-container__marker"
        onClick={() => this.selectItem()}
        onMouseDown={(e) => { this.mouseDown(e); }}
      >
        {editable && <img
          src={removeIconPath}
          style={{ position: 'absolute', left: '20px', top: '-10px' }}
          onClick={(e) => { this.removeMarker(e, item); e.preventDefault(); return false;}}
          alt="Delete"
        />}
        {highlighted ? <img src={this.props.markerBluePath} /> : <img src={this.props.markerGrayPath} /> }
      </div>
    );
  }
}
