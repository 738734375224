import OptimizedSelect from '../../shared/OptimizedSelect';

export default class SpecSelectDisplayDataItemForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props,
    };
    this.handleDisplayDataItemsSelectChange = this.handleDisplayDataItemsSelectChange.bind(this);
    this.handleCreateMarker = this.handleCreateMarker.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  componentDidUpdate(_prevProps, _prevState) {
    let opened = false;
    try {
      opened = $j(this.el).hasClass("ui-dialog-content") && $j(this.el).dialog('isOpen');
    } catch (_) {
    }

    if (!opened) {
      $j(this.el).dialog({
        modal: true,
        resizable: false,
        height: 320,
        width: 280,
        close: this.props.onCancel,
      });
    }
  }

  UNSAFE_componentWillUpdate(nextProps, _nextState) {
    const { displaySpecSelectDataItemForm } = nextProps;
    if (!displaySpecSelectDataItemForm) {
      $j(this.el).dialog('destroy');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      displaySpecSelectDataItemForm: nextProps.displaySpecSelectDataItemForm,
      newMarker: nextProps.newMarker,
    });
  }

  handleSelectChange(selectedOption) {
    const value = (selectedOption && selectedOption.value) || '';
    const fakeEvent = {
      target: {
        value,
      },
    };
    this.handleDisplayDataItemsSelectChange(fakeEvent);
  }

  handleDisplayDataItemsSelectChange(e) {
    const { reportDisplayItems } = this.props;
    const prevReportDisplayItem = this.props.reportDisplayItem;
    const reportDisplayItem = reportDisplayItems.find(ri => (ri.id == e.target.value));
    let newRdi = Object.assign({}, reportDisplayItem, {
      leftPos: this.state.newMarker.leftPos,
      topPos: this.state.newMarker.topPos,
    });
    this.setState({
      prevReportDisplayItem: prevReportDisplayItem,
      reportDisplayItem: newRdi,
    });
  }

  handleCreateMarker() {
    const { reportDisplayItem } = this.state;
    const { prevReportDisplayItem } = this.state;
    if (prevReportDisplayItem && prevReportDisplayItem.id !== reportDisplayItem.id) {
      if (prevReportDisplayItem.leftPos != null || prevReportDisplayItem.topPos != null) {
        const emptyItem = Object.assign({}, prevReportDisplayItem, { leftPos: null, topPos: null });
        this.props.onCreate(emptyItem);
      }
    }
    this.props.onCreate(reportDisplayItem);
  }

  render() {
    const { reportDisplayItems, reportDisplayItem } = this.props;
    const stateReportDisplayItem = this.state.reportDisplayItem;
    const selectValue = (stateReportDisplayItem && stateReportDisplayItem.id)
      || (reportDisplayItem && reportDisplayItem.id) || '';
    const { displaySpecSelectDataItemForm } = this.state;
    const filteredDisplayItems = reportDisplayItems.filter((item) => {
      return (!item.leftPos || !item.topPos)
        || (reportDisplayItem && reportDisplayItem.id === item.id);
    });

    const title = 'Select Display Data Item';
    if (!displaySpecSelectDataItemForm) {
      return null;
    }
    const options = filteredDisplayItems.map(rdi => ({ value: rdi.id, label: rdi.reportItem.objectLabel }));
    const selectedOption = options.filter(option => option.value === selectValue);

    return (
      <div
        title={title}
        ref={el => this.el = el}
      >
        <span id="report-display-items-span">
          <label>Display Data Item:</label>
          <OptimizedSelect
            options={options}
            value={selectedOption}
            onChange={this.handleSelectChange}
          />
        </span>
        <br />
        <span>
          <input
            className="f-l"
            type="submit"
            onClick={this.handleCreateMarker}
            value="Save"
          />
        </span>
      </div>
    );
  }
}
