import {
  render,
  within,
  screen
} from '@testing-library/react';
import ImportFilter from './ImportFilter';

describe('<ImportFilter />', () => {
  it('should render correct import filter', async () => {
    const component = exerciseWithSelectedValue();
    const dropdownIndicator = await component.getDropdownIndicator();
    const filterLabel = await component.getFilterLabel();
    expect(dropdownIndicator).toBeVisible();
    expect(filterLabel).toBeVisible();
  });

  it('should have correct selected value', async () => {
    exerciseWithSelectedValue();
    const { getByText } = within(screen.getByTestId('filterSelector'));
    expect(getByText('Records with Errors')).toBeInTheDocument();
  });

  it('should have correct selected value', async () => {
    exerciseWithoutSelectedValue();
    const { getByText } = within(screen.getByTestId('filterSelector'));
    expect(getByText('All Records')).toBeInTheDocument();
  });
});

const exerciseWithSelectedValue = () => {
  const component = render(<ImportFilter
    selected="errors"
    filterUrl="testUrl"
  />);

  return {
    ...component,

    async getDropdownIndicator() {
      return component.findByTitle('Dropdown Indicator');
    },

    async getFilterLabel() {
      return component.findByTitle('Filter By Label');
    },
  };
};

const exerciseWithoutSelectedValue = () => {
  const component = render(<ImportFilter />);

  return {
    ...component
  };
};
