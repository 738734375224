import PropTypes from "prop-types";

export default class SpecDisplayDataItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
    };
  }

  handleDisplayDataItemEditIconClick(displayDataItem) {
    this.props.onDisplayDataItemEdited(Object.assign({}, displayDataItem));
  }

  handleDisplayDataItemRemoveIconClick(displayDataItem) {
    this.props.onDisplayDataItemRemoved(displayDataItem);
  }

  getDisplayDataItemIcons() {
    const { reportDisplayItem: { reportItem: { term, objectLabel } } } = this.props;
    const termName = term ? term.name : null;
    if (objectLabel === termName) {
      return this.getTermIcon(true);
    }
    return ([
      this.getObjectLabelIcon(),
      this.getTermIcon(),
    ]);
  }

  getObjectLabelIcon() {
    const { reportDisplayItem: { reportItem } } = this.props;
    return (
      reportItem.objectLabel &&
      <div key={reportItem.objectLabel}>
        <img
          src={this.props.labelIconPath}
          alt="label"
          width="16px"
          height="16px"
          style={{ verticalAlign: 'text-bottom' }}
        />
      </div>
    );
  }

  getTermIcon(onlyTerm = false) {
    const { reportDisplayItem: { reportItem } } = this.props;
    const termClass = onlyTerm ?
      {
        verticalAlign: 'text-bottom',
        position: 'relative',
        top: '-5px',
      } :
      {
        verticalAlign: 'text-bottom',
      };
    return (
      reportItem.termId &&
      <div key={reportItem.termId}>
        { !this.props.readOnly ?
          <a
            href={`/institution/terms/${reportItem.termId}`}>
            <img
              src={this.props.termIconPath}
              alt="label"
              width="16px"
              height="16px"
              style={termClass}
            />
          </a> :
          <img
            src={this.props.termIconPath}
            alt="label"
            width="16px"
            height="16px"
            style={{ verticalAlign: 'text-bottom' }}
          />
        }
      </div>
    );
  }

  getDataItemName() {
    const { reportDisplayItem: { reportItem } } = this.props;
    const { objectLabel } = reportItem;
    const termName = reportItem.term ? reportItem.term.name : null;
    if (objectLabel === termName) {
      return this.getTermName();
    }
    return ([
      this.getObjectLabel(),
      this.getTermName(),
    ]);
  }

  getObjectLabel() {
    const { reportDisplayItem: { reportItem } } = this.props;
    return (
      reportItem.objectLabel &&
      <div key={reportItem.objectLabel}>
        <div
          style={{ fontSize: '13px' }}
          title={reportItem.objectLabel}
        >{reportItem.objectLabel}
        </div>
      </div>
    );
  }

  getTermName() {
    const { reportDisplayItem: { reportItem } } = this.props;
    return (
      reportItem.termId &&
      <div key={reportItem.termId}>
        { !this.props.readOnly ?
          <a
            style={{ fontSize: '13px' }}
            href={`/institution/terms/${reportItem.termId}`}
            title={reportItem.term.name}
          >
            {reportItem.term.name}
          </a> :
          <span
            style={{ fontSize: '13px' }}
            title={reportItem.term.name}
          >
            {reportItem.term.name}
          </span> }
      </div>
    );
  }

  getDataItemDisplayNotes() {
    const { notes } = this.props.reportDisplayItem;
    const truncatedNotes = this.truncateLines(notes, { lines: 4 });
    return (
      <div
        style={{ fontSize: '13px' }}
        title={notes}
      >
        {
          notes && truncatedNotes.split('\n').map((line, index) => (
            <div key={index} className="text-overflow-ellipsis">{line}</div>
          ))
        }
      </div>
    );
  }

  getDataItemLocation() {
    const { reportDisplayItem } = this.props;
    return (
      <div
        style={{ fontSize: '13px' }}
        title={reportDisplayItem.location}
      >
        {reportDisplayItem.location}
      </div>
    );
  }

  getDataItemAggregate = () => {
    const { reportDisplayItem } = this.props;
    return (
      <div
        style={{ fontSize: '13px' }}
        title={reportDisplayItem.aggregateNotes}
      >
        {reportDisplayItem.aggregateFlag ? 'Yes' : 'No'}
      </div>
    );
  };

  getDataItemAggregateNotes() {
    const { aggregateNotes: notes } = this.props.reportDisplayItem;
    const truncatedNotes = this.truncateLines(notes, { lines: 4 });
    return (
      <div
        style={{ fontSize: '13px' }}
        title={notes}
      >
        {
          notes && truncatedNotes.split('\n').map((line, index) => (
            <div key={index} className="text-overflow-ellipsis">{line}</div>
          ))
        }
      </div>
    );
  }


  isReportDisplayItemMarked() {
    const { reportDisplayItem } = this.props;
    return reportDisplayItem.leftPos && reportDisplayItem.topPos;
  }

  truncateLines = (text, { lines = 1, omission = '...' }) => {
    const linesArray = String(text).split(/\n/);
    if (linesArray.length > lines) {
      return linesArray.slice(0, lines).join('\n').concat(omission);
    }
    return String(text);
  };

  render() {
    const {
      locationIconSrc,
      locationBlueIconSrc,
      imageEdit,
      removeIconPath,
      reportDisplayItem,
      editable,
      isDisplayDataItemsExpanded,
    } = this.props;

    const locationIcon = this.isReportDisplayItemMarked() ? locationBlueIconSrc : locationIconSrc;
    let itemClassName = 'display-data-items__display-data-item';
    if (reportDisplayItem.highlighted) {
      itemClassName += ' highlighted';
    }

    const displayDataItemName = this.getDataItemName();

    return (
      <div className={itemClassName}>
        <div className="display-data-items__item-icons">
          <div>
            {
              editable &&
              <img
                src={imageEdit}
                style={{ verticalAlign: 'middle', marginLeft: '5px', width: '15px', height: '15px' }}
                onClick={() => this.handleDisplayDataItemEditIconClick(reportDisplayItem)}
                alt="Edit"
              />
            }
          </div>
          <div>
            {
              editable &&
              <img
                src={removeIconPath}
                style={{ verticalAlign: 'middle', marginLeft: '5px', width: '15px', height: '15px' }}
                onClick={() => this.handleDisplayDataItemRemoveIconClick(reportDisplayItem)}
                alt="Delete"
              />
            }
          </div>
        </div>
        <div className="display-data-items__icons">
          <div className="display-data-items__location">
            <img
              src={locationIcon}
              alt="label"
              width="18px"
              height="18px"
            />
          </div>
          <div className="display-data-items__related-item-icons">
            {this.getDisplayDataItemIcons()}
          </div>
        </div>
        { isDisplayDataItemsExpanded ? ([
          <div
            key={`ddiname:${displayDataItemName}`}
            className="display-data-items__description"
          >
            {displayDataItemName}
          </div>,
          <div
            key={`ddidisplaynotes:${displayDataItemName}`}
            className="display-data-items__info display-data-items__display-notes"
          >
            {this.getDataItemDisplayNotes()}
          </div>,
          <div
            key={`ddiaggregatenotes:${displayDataItemName}`}
            className="display-data-items__info display-data-items__aggregate"
          >
            {this.getDataItemAggregate()}
          </div>,
          <div
            key={`ddiaggregate:${displayDataItemName}`}
            className="display-data-items__info display-data-items__aggregate-notes"
          >
            {this.getDataItemAggregateNotes()}
          </div>,
          <div
            key={`ddilocationnotes:${displayDataItemName}`}
            className="display-data-items__info display-data-items__location-notes"
          >
            {this.getDataItemLocation()}
          </div>,
        ]) : (
          <div className="display-data-items__description">
            {this.getDataItemName()}
          </div>
        )}
      </div>
    );
  }
}

SpecDisplayDataItem.propTypes = {
  reportDisplayItem: PropTypes.object.isRequired,
  locationIconSrc: PropTypes.string.isRequired,
  locationBlueIconSrc: PropTypes.string.isRequired,
  imageEdit: PropTypes.string.isRequired,
  removeIconPath: PropTypes.string.isRequired,
  termIconPath: PropTypes.string.isRequired,
  labelIconPath: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  readOnly: PropTypes.bool,
  isDisplayDataItemsExpanded: PropTypes.bool,
  onDisplayDataItemEdited: PropTypes.func.isRequired,
  onDisplayDataItemRemoved: PropTypes.func.isRequired,
};

SpecDisplayDataItem.defaultProps = {
  editable: false,
  readOnly: false,
  isDisplayDataItemsExpanded: false,
};
