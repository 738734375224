export default class OrderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.toggleMode = this.toggleMode.bind(this);
    this.inputValueChange = this.inputValueChange.bind(this);
    this.keyDown = this.keyDown.bind(this);
    this.update = this.update.bind(this);
    this.state = {
      value: null,
      isEditing: false,
    };
    this.ref = null;
  }

  toggleMode() {
    this.setState({
      isEditing: !this.state.isEditing,
      value: null
    });
    return false;
  }

  inputValueChange(e) {
    const { value } = e.target;
    if (value !== "0" && /^-{0,1}\d+$/.test(value)) {
      // replacement for !isNaN function
      this.setState({
        value,
      });
    } else if (value === "") {
      this.setState({
        value: "",
      });
    }
  }

  update() {
    const { item } = this.props;
    const { value } = this.state;
    if (value !== "0" && /^-{0,1}\d+$/.test(value)) {
      // replacement for !isNaN function
      this.props.changePosition(item, parseInt(value, 10));
    } else if (value === "") {
      this.props.changePosition(item, "");
    }
    this.toggleMode();
    return false;
  }

  resetInnerStateValue() {
    this.setState({ value: null })
  }

  keyDown(e) {
    if (e.key === "Enter") {
      this.update();
      this.props.valueChanged && this.props.valueChanged();
    } else if (e.key === "Escape") {
      this.toggleMode();
    }
  }

  handleClick = (e) => {
    e.preventDefault();
    this.toggleMode();
    return false;
  };

  cancel = () => {
    this.toggleMode();
  }

  transferDragEventToReorderComponent = event => {
    event.preventDefault();
    return true;
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isEditing !== this.state.isEditing && this.state.isEditing) {
      this.ref.focus()
    }
  }

  getValue() {
    if (this.state.value !== null) {
      return this.state.value
    } else {
      return this.props.value
    }
  }

  renderForm() {
    const value = this.getValue()
    const invalidValueClass = parseInt(value, 10) >= 0 ? "" : "invalid-value";

    return (
      <div className="data-system-ordering_form">
        <input
          className={`data-system-ordering_input ${invalidValueClass}`}
          ref={(input) => this.ref = input}
          type="text"
          value={value}
          name="order"
          onChange={this.inputValueChange}
          onKeyDown={this.keyDown}
          aria-label={this.props.ariaLabel}
          data-testid="order.input"
        />
        <button
          onClick={this.update}
          data-testid="order.submit"
          className="img-button"
        >
          <img
            src={
              this.props.controlPlayIconPath
            }
            alt="submit manual order"
          />
        </button>
        <input
          type="button"
          title="close"
          value="x"
          className="data-system-ordering_cancel"
          data-testid="order.cancel"
          onClick={this.cancel}
        ></input>
      </div>
    );
  }

  renderLink() {
    return (
      <a href="" onClick={this.handleClick} data-testid="order.link">
        order
      </a>
    );
  }

  render() {
    const { isEditing: focused } = this.state;
    return (
      <div className="data-system-ordering_form-container">
        <img alt="reorder data system" src={this.props.moveIconPath} onMouseDown={this.transferDragEventToReorderComponent} className="data-system-ordering_move" />
        {focused ? this.renderForm() : this.renderLink()}
        <button
          data-testid="order.remove"
          onClick={() => this.props.onRemove(this.props.item)}
          className="img-button"
        >
          <img
            src={this.props.trashcanIconPath}
            alt="remove data system from order"
          />
        </button>
      </div>
    );
  }
}
