import React, { useRef, useEffect } from 'react'
import Select, { components } from 'react-select';

export default class MultiSelector extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      options: props.preselectedOptions.length > 0 ? this.filteredOptions(this.props.options, this.props.preselectedOptions, this.props.folded) : props.options,
      selectedOptions: props.preselectedOptions
    }
  }

  onValueSelect = (options) => {
    let optionsWithoutSelected = this.filteredOptions(this.state.options, options, this.props.folded)

    this.setState({
      selectedOptions: options,
      options: optionsWithoutSelected
    })
    this.props.selectedValueHandler(options)
  }

  removeSelectedOption(option) {
    const selectedWithoutRemovedOption =
      this.state.selectedOptions.filter(function(item){
        return item.label !== option.label
      })
    let allOptionsWithoutRemoved = this.filteredOptions(this.props.options, selectedWithoutRemovedOption, this.props.folded)

    this.setState({
      selectedOptions: selectedWithoutRemovedOption,
      options: allOptionsWithoutRemoved
    })
    this.props.selectedValueHandler(selectedWithoutRemovedOption)
  }

  filteredOptions(allOptions, selectedOptions, hasSubgroups) {
    const selectedOptionsNames = selectedOptions.map(option => option.label)

    if(hasSubgroups){
      return(
        allOptions.map(function({...group}) {
          group.options = group.options.filter(option => !selectedOptionsNames.includes(option.label))
          return group
        })
      )
    }else{
      return allOptions.filter(option => !selectedOptionsNames.includes(option.label))
    }
  }

  renderOption(option) {
    return(
      <div className={'workflow-condition-multi-selector-option'}>
        <div className={
          `workflow-condition-multi-selector-item
           workflow-condition-multi-selector-remove-button`
        }>
          <a
            className='pretty-button red mini lowercase clear'
            onClick={() => this.removeSelectedOption(option)}
          >
            remove
          </a>
        </div>

        <div className={
          `workflow-condition-multi-selector-item
          workflow-condition-multi-selector-delimeter`
        }>
          |
        </div>

        <div className={
          `workflow-condition-multi-selector-item
           workflow-condition-multi-selector-name`
        }>
          {option.label}
        </div>
      </div>
    )
  }

  renderSelectedOptions() {
    if(this.state.selectedOptions){
      return(
        <div className='workflow-condition-multi-selector-options'>
          {this.state.selectedOptions.map(option => this.renderOption(option))}
        </div>
      )
    }
  }

  render() {
    const DropdownIndicator = props => {
      return (
        <components.DropdownIndicator {...props}>
          <div className="Select-arrow" style={{position: 'absolute', marginLeft: '3px'}}></div>
        </components.DropdownIndicator>
      );
    };

    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        height: '26px',
        minHeight: '26px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        fontFamily: 'arial, sans-serif'
      }),

      container: (provided, state) => ({
        ...provided,
        width: '212px',
        height: '23px',
      }),

      valueContainer: (provided, state) => ({
        ...provided,
        backgroundColor: '#fff',
        borderColor: '#d9d9d9 #ccc #b3b3b3',
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
        color: '#333',
        cursor: 'default',
        verticalAlign: 'middle',
        height: '100%',
        position: 'unset',
        width: '85%'
      }),

      input: (provided, state) => ({
        ...provided,
        margin: 0,
        paddingTop: 0
      }),

      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '100%',
        width: '15%',
        backgroundColor: '#4b8ec6',
        borderRadius: '2px',
      }),

      indicatorSeparator: (provided, state) => ({
        ...provided,
        display: 'none'
      }),

      singleValue: (provided, state) => ({
        ...provided,
        width: '80%'
      })
    };

    return(
      [
      <Select
        isMulti
        closeMenuOnSelect={true}
        components={{DropdownIndicator}}
        options={this.state.options}
        onChange={this.onValueSelect}
        controlShouldRenderValue={false}
        isClearable={false}
        value={this.state.selectedOptions}
        styles={customStyles}
      />,
      this.renderSelectedOptions()
      ]
    )
  }
}

