import HelpPoint from "../../Helpers/HelpPoint";
import DisplayDataItemMarker from './DisplayDataItemMarker'


export default class SpecReportDisplayImageComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      markerMode: false,
      dimensions: {
        height: 0,
        width: 0,
      },
    };
    this.placeMarker = this.placeMarker.bind(this);
    this.onImgLoad = this.onImgLoad.bind(this);
  }

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    const { editable } = this.props;
    let imageContentHeight = $j('.display-data-mockup-container__overlayable').outerHeight();
    const marginBottom = editable ? $j('.new-display-data-item').outerHeight(true) : 0;
    const headerDataItemsHeight = $j('.display-data-items__header').outerHeight(true)
      + $j('.display-data-items__label').outerHeight(true);
    const minDataItemsHeight = parseInt($j('.display-data-items__scroll-items').css('min-height'), 10);
    let defaultDataItemsHeight;
    if (imageContentHeight > minDataItemsHeight) {
      defaultDataItemsHeight = imageContentHeight - headerDataItemsHeight
    } else {
      defaultDataItemsHeight = minDataItemsHeight
    }
    let displayDataItemsHeight = `${defaultDataItemsHeight - marginBottom}px`;
    $j('.display-data-items__scroll-items').css('max-height', displayDataItemsHeight);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      imageUrl: nextProps.imageUrl,
      reportDisplayItems: nextProps.reportDisplayItems,
    });
  }

  onImgLoad({ target: img }) {
    this.setState({
      dimensions: {
        height: img.offsetHeight,
        width: img.offsetWidth,
      },
    });
  }

  turnMarkerMode(markerMode) {
    this.setState({
      markerMode: markerMode,
    });
    if (markerMode) {
      $j('.display-data-mockup-container__overlay').fadeIn({ duration: 500 });
    } else {
      $j('.display-data-mockup-container__overlay').fadeOut({ duration: 500 });
    }
  }

  placeMarker(e) {
    const { markerMode } = this.state;
    if (markerMode) {
      let event = e.nativeEvent;
      let posX = event.offsetX ? (event.offsetX) : event.pageX - document.getElementById('pointer_div').offsetLeft;
      let posY = event.offsetY ? (event.offsetY) : event.pageY - document.getElementById('pointer_div').offsetTop;
      this.props.showSpecSelectDisplayDataItemForm(posX, posY);
    }
  }

  render() {
    const { imageUrl, editable } = this.state;
    const { markerMode, reportDisplayItems } = this.state;
    const { dimensions } = this.state;
    const { isDisplayDataItemsExpanded } = this.props;
    let displayDataMockupContainerStyle = 'display-data-mockup-container__img';
    if (markerMode) {
      displayDataMockupContainerStyle += ' marker_mode';
    }

    const displayDataMockupContainerWidth = `display-data-mockup-container
    ${isDisplayDataItemsExpanded ? 'display-data-mockup-container-collapsed' : ''}`;

    return (
      <div
        className={displayDataMockupContainerWidth}
      >
        <div className="display-data-mockup-container__overlayable">
          { !isDisplayDataItemsExpanded &&
            <div className="display-data-mockup-container__header">
              <div style={{textAlign: 'center'}}>
                <span
                  className="display-data-mockup-container__appearance">
                  Appearance <HelpPoint helpPoint={this.props.helpPoints.point10} />
                </span>
              </div>
              <div>
                { editable &&
                <a
                  className="pretty-button med f-r button-center"
                  onClick={this.props.onImageAdd}>Add Image
                </a>
                }
              </div>
              <div style={{textAlign: 'right'}}>
                {
                  imageUrl && editable &&
                  <img
                    style={{cursor: 'pointer'}}
                    src={this.props.markerGrayPath}
                    onClick={() => this.turnMarkerMode(!markerMode)}
                  />
                }
                <HelpPoint helpPoint={this.props.helpPoints.point11}/>
              </div>
            </div>
          }
          {
            imageUrl &&
            <div
              className={displayDataMockupContainerStyle}
              style={{
                maxWidth: '700px',
                maxHeight: '870px',
                overflow: 'auto',
                position: 'relative',
              }}
            >
              <div>
                {reportDisplayItems.filter(item => (item.leftPos && item.topPos)).map(item => (
                    <DisplayDataItemMarker
                      key={item.id + ':' + item.leftPos + ':' + item.rigthPos}
                      markerGrayPath={this.props.markerGrayPath}
                      markerBluePath={this.props.markerBluePath}
                      item={item}
                      leftPos={dimensions.width >= item.leftPos ? item.leftPos : dimensions.width}
                      topPos={dimensions.height >= item.topPos ? item.topPos : dimensions.height}
                      onSelectMarker={this.state.onSelectMarker}
                      editable={markerMode}
                      onDragMarker={this.state.onDragMarker}
                      dimensions={this.state.dimensions}
                      removeIconPath={this.state.removeIconPath}
                      showSpecSelectDisplayDataItemForm={this.props.showSpecSelectDisplayDataItemForm}
                    />
                  ),
                )}
                <img
                  ref={el => this.img = el}
                  className="display-data-mockup-container__img"
                  src={imageUrl}
                  alt={this.props.mockupImageDescription}
                  title={this.props.mockupImageDescription}
                  onClick={(e) => this.placeMarker(e)}
                  onLoad={this.onImgLoad}
                />
              </div>
            </div>
          }
        </div>
        <div
          className="display-data-mockup-container__overlay"
          onClick={() => this.turnMarkerMode(false)}
        />
      </div>
    );
  }
}
