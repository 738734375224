class DMCNewLinio extends React.Component {
  constructor(props) {
    super(props);
    this.getNewLineage = this.getNewLineage.bind(this);
    this.createLineage = this.createLineage.bind(this);
    this.state = {
      lineage_target: this.getNewLineage()
    };
  }

  getNewLineage(e) {
    return { related_object_id: this.props.primaryObjectId, related_object_type: this.props.primaryObjectType };
  }

  createLineage(e) {
    const { sourceErpSystems } = this.props;
    const lineageTarget = {
      ...this.state.lineage_target,
      object_label: this.props.termName
    };
    if (!_.isEmpty(sourceErpSystems))
      lineageTarget.erp_system_id = sourceErpSystems[0].id;

    $j.ajax({
      url: '/institution/lineages',
      method: 'POST',
      data: {
        lineage: this.state.lineage_target,
        lineage_target: lineageTarget
      },
      success: (data) => {
        this.setState({ lineage_label: '', lineage_target: this.state.lineage_target || {} });
        this.props.onCancel && this.props.onCancel();
        this.props.onLineageCreate && this.props.onLineageCreate(data);
      }
    }).error((xhr) => {
      if (xhr.responseJSON.errors)
        this.setState({ errors: xhr.responseJSON.errors });
      else
        location.reload();
    });
  }

  render() {
    const {
      plusImageSrc
    } = this.props;

    return (
      <div
        className="dmc-new-lineage"
      >
        <a
          onClick={this.createLineage}
          className="dmc-new-lineage__link"
        >
          <img
            src={plusImageSrc}
            className="dmc-new-lineage__plus-icon"
          />
          <span>Add a linio</span>
        </a>
      </div>
    );
  }
}

export default DMCNewLinio;
