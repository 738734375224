import React from 'react';
import Select, { components } from 'react-select';

export default class RelatedToolsSelector extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      options: props.selectedOptions.length > 0 ? this.filteredOptions(this.props.options, this.props.selectedOptions) : props.options,
      selectedOptions: props.selectedOptions
    }
  }

  onValueSelect = (options) => {
    let optionsWithoutSelected = this.filteredOptions(this.state.options, options)

    this.setState({
      selectedOptions: options,
      options: optionsWithoutSelected
    })
  }

  filteredOptions(allOptions, selectedOptions) {
    const selectedOptionsNames = selectedOptions.map(option => option.label)

    return allOptions.filter(option => !selectedOptionsNames.includes(option.label))
  }


  removeSelectedOption(option) {
    const selectedWithoutRemovedOption =
      this.state.selectedOptions.filter(function(item){
        return item.label !== option.label
      })
    let allOptionsWithoutSelected = this.filteredOptions(this.props.options, selectedWithoutRemovedOption)

    this.setState({
      selectedOptions: selectedWithoutRemovedOption,
      options: allOptionsWithoutSelected
    })
  }

  renderOption(option) {
    return(
      <div className={'related_tools_wrapper'}>
        <div>
          <a href={`/institution/tools/${option.value}`} target="blank">
            {option.label}
          </a>
        </div>
        <div>
          <img
            src={this.props.remove_icon_src}
            alt="Remove field name"
            tabIndex="0"
            onClick={() => this.removeSelectedOption(option)}
          />
        </div>

        <input
          type="hidden"
          className="item-value"
          name={'related_tools_ids'}
          value={JSON.stringify(this.state.selectedOptions.map(option => option.value))}
        />
      </div>
    )
  }

  renderSelectedOptions() {
    if(this.state.selectedOptions){
      return([
        <div className='width_30'>
          {this.state.selectedOptions.map(option => this.renderOption(option))}
        </div>,
        <br />]
      )
    }
  }

  render() {

    const DropdownIndicator = props => {
      return (
        <components.DropdownIndicator {...props}>
          <div className='ui-state-default no-border'>
            <div className='ui-button-icon-primary ui-icon ui-icon-triangle-1-s'></div>
          </div>
        </components.DropdownIndicator>
      );
    };

    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        height: '20px',
        minHeight: '22px',
        border: '1px solid #ccc',
        borderBottomRightRadius: '6px',
        borderTopRightRadius: '6px',
        borderRight: 'none',
        fontFamily: 'arial, sans-serif',
        boxShadow: 'none'
      }),

      container: (provided, state) => ({
        ...provided,
        width: '212px',
        height: '20px',
      }),

      valueContainer: (provided, state) => ({
        ...provided,
        backgroundColor: '#fff',
        borderColor: '#d9d9d9 #ccc #b3b3b3',
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
        color: '#333',
        cursor: 'default',
        verticalAlign: 'middle',
        height: '100%',
        position: 'unset',
        width: '85%'
      }),

      input: (provided, state) => ({
        ...provided,
        margin: 0,
        paddingTop: 0
      }),

      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '20px',
        width: '15%',
        backgroundColor: '#4b8ec6',
        borderBottomRightRadius: '5px',
        borderTopRightRadius: '5px',
        border: '1px solid',
      }),

      indicatorSeparator: (provided, state) => ({
        ...provided,
        display: 'none'
      }),

      singleValue: (provided, state) => ({
        ...provided,
        width: '80%'
      }),

      clearIndicator: (provided, state) => ({
        ...provided,
        display: 'none'
      })
    };

    return(
      [
        this.renderSelectedOptions(),
        <Select
          isMulti
          closeMenuOnSelect={true}
          components={{DropdownIndicator}}
          options={this.state.options}
          onChange={this.onValueSelect}
          controlShouldRenderValue={false}
          isClearable={false}
          value={this.state.selectedOptions}
          styles={customStyles}
          placeholder={'Type or Select...'}
        />
      ]
    )
  }
}