import {
  render,
  within,
  screen,
  fireEvent
} from '@testing-library/react';
import ImportPreviewControl from './ImportPreviewControl';

describe('<ImportPreviewControl />', () => {
  describe('when reports import', () => {
    it('should contain import filter block', async () => {
      const component = exerciseReportTable();
      const dropdownIndicator = await component.getDropdownIndicator();
      const filterLabel = await component.getFilterLabel();
      expect(dropdownIndicator).toBeVisible();
      expect(filterLabel).toBeVisible();
    });

    it('should contain search block', async () => {
      const component = exerciseReportTable();
      const searchInput = await component.getSearchInput();
      expect(searchInput).toBeVisible();
    });

    it('should have correct table title', async () => {
      exerciseReportTable();
      const { getByText } = within(screen.getByTestId('previewSection'));
      expect(getByText('Specifications Table')).toBeInTheDocument();
    });

    it('should render report table', async () => {
      exerciseReportTable();
      const { getAllByText, getByText } = within(screen.getByTestId('previewSection'));
      expect(getAllByText('SpecName1')).toHaveLength(2);
      expect(getByText('Name')).toBeInTheDocument();
      expect(getByText('Description')).toBeInTheDocument();
      expect(getByText('Purpose')).toBeInTheDocument();
      expect(getByText('Owner')).toBeInTheDocument();
      expect(getByText('Functional Areas')).toBeInTheDocument();
      expect(getByText('Specification Type')).toBeInTheDocument();
      expect(getByText('Source Data Systems')).toBeInTheDocument();
      expect(getByText('Access Details')).toBeInTheDocument();
      expect(getByText('Display Description')).toBeInTheDocument();
      expect(getByText('Output Format')).toBeInTheDocument();
      expect(getByText('Mapping Description')).toBeInTheDocument();
      expect(getByText('Selection Description')).toBeInTheDocument();
      expect(getByText('Sort Description')).toBeInTheDocument();
      expect(getByText('Target Data System')).toBeInTheDocument();
      expect(getByText('Tags')).toBeInTheDocument();
      expect(getByText('Primary Tool')).toBeInTheDocument();
      expect(getByText('External ID')).toBeInTheDocument();
      expect(getByText('Usage Note')).toBeInTheDocument();
      expect(getByText('Additional Details')).toBeInTheDocument();
      expect(getByText('Designated Requester')).toBeInTheDocument();
      expect(getByText('Technical Details')).toBeInTheDocument();
    });

    it('should render unrecognized error row', async () => {
      exerciseReportTable();
      const { getAllByText, getByText } = within(screen.getByTestId('previewSection'));
      expect(getAllByText('Some spec 1')).toHaveLength(1);
      const showLink = getAllByText('show')[0];
      expect(showLink).toBeInTheDocument();
      fireEvent.click(showLink);
      expect(getByText('Some error 1')).toBeInTheDocument();
    });

    it('should have correct status', async () => {
      exerciseReportTable();
      const { getByText } = within(screen.getByTestId('previewSection'));
      expect(getByText('Status: successMessage')).toBeInTheDocument();
      expect(getByText('Status: 1 error')).toBeInTheDocument();
    });

    it('should have correct action', async () => {
      exerciseReportTable();
      const { getByText } = within(screen.getByTestId('previewSection'));
      expect(getByText('Action: createNew')).toBeInTheDocument();
      expect(getByText('Action: Some act message')).toBeInTheDocument();
    });

    it('should have button show errors and warnings', async () => {
      exerciseReportTable();
      const { getAllByText } = within(screen.getByTestId('previewSection'));
      expect(getAllByText('show')[0]).toBeInTheDocument();
      expect(getAllByText('show')[1]).toBeInTheDocument();
    });

    it('should have show errors and warnings functionality', async () => {
      exerciseReportTable();
      const { getAllByText } = within(screen.getByTestId('previewSection'));
      const showLink = getAllByText('show')[0];
      expect(showLink).toBeInTheDocument();
      fireEvent.click(showLink);
      const hideLink = getAllByText('hide')[0];
      expect(hideLink).toBeInTheDocument();
      fireEvent.click(hideLink);
      expect(showLink).toBeInTheDocument();
    });

    it('should contain import options block', async () => {
      const component = exerciseReportTable();
      const autoApproveInfo = await component.findByText(messages.autoApproveInfo);
      const autoApproveCheckbox = await component.findByText(messages.autoApproveCheckbox);
      const updateExistingCheckboxName = await component.findByText(messages.updateExistingCheckboxName);
      const updateExistingInfo = await component.findByText(messages.updateExistingInfo);
      const reviewInfo = await component.findByText(messages.reviewInfo);
      const importButton = await component.findByText(messages.importButton);
      expect(autoApproveInfo).toBeVisible();
      expect(autoApproveCheckbox).toBeVisible();
      expect(updateExistingCheckboxName).toBeVisible();
      expect(updateExistingInfo).toBeVisible();
      expect(reviewInfo).toBeVisible();
      expect(importButton).toBeVisible();
    });
  });

  describe('when terms import', () => {
    it('should contain import filter block', async () => {
      const component = exerciseTermTable();
      const dropdownIndicator = await component.getDropdownIndicator();
      const filterLabel = await component.getFilterLabel();
      expect(dropdownIndicator).toBeVisible();
      expect(filterLabel).toBeVisible();
    });

    it('should contain search block', async () => {
      const component = exerciseTermTable();
      const searchInput = await component.getSearchInput();
      expect(searchInput).toBeVisible();
    });

    it('should have correct table title', async () => {
      exerciseTermTable();
      const { getByText } = within(screen.getByTestId('previewSection'));
      expect(getByText('Definitions Table')).toBeInTheDocument();
    });

    it('should render definitions table', async () => {
      exerciseTermTable();
      const { getAllByText, getByText } = within(screen.getByTestId('previewSection'));
      expect(getAllByText('TermName1')).toHaveLength(2);
      expect(getByText('Name')).toBeInTheDocument();
      expect(getByText('Definition')).toBeInTheDocument();
      expect(getByText('Definition Source')).toBeInTheDocument();
      expect(getByText('Classification')).toBeInTheDocument();
      expect(getByText('Synonyms')).toBeInTheDocument();
      expect(getByText('Functional Areas')).toBeInTheDocument();
      expect(getByText('Tags')).toBeInTheDocument();
      expect(getByText('Designated Requester')).toBeInTheDocument();
    });

    it('should render table values', async () => {
      exerciseTermTable();
      const { getAllByText, getByText } = within(screen.getByTestId('previewSection'));
      expect(getAllByText('TermName1')).toHaveLength(2);
      expect(getByText('functionalDefinition1')).toBeInTheDocument();
      expect(getByText('tag1,tag2')).toBeInTheDocument();
      expect(getByText('termSource1')).toBeInTheDocument();
      expect(getByText('classificationCode1')).toBeInTheDocument();
      expect(getByText('technicalDefinition1')).toBeInTheDocument();
      expect(getByText('dataSystem1')).toBeInTheDocument();
      expect(getByText('12/12/2025')).toBeInTheDocument();
      expect(getByText('synonym1')).toBeInTheDocument();
      expect(getByText('fa1')).toBeInTheDocument();
      expect(getByText('user1')).toBeInTheDocument();
    });

    it('should have correct status', async () => {
      exerciseTermTable();
      const { getByText } = within(screen.getByTestId('previewSection'));
      expect(getByText('Status: successMessage')).toBeInTheDocument();
    });

    it('should have correct action', async () => {
      exerciseTermTable();
      const { getByText } = within(screen.getByTestId('previewSection'));
      expect(getByText('Action: createNew')).toBeInTheDocument();
    });

    it('should have button show errors and warnings', async () => {
      exerciseTermTable();
      const { getByText } = within(screen.getByTestId('previewSection'));
      expect(getByText('show')).toBeInTheDocument();
    });

    it('should have show errors and warnings functionality', async () => {
      exerciseTermTable();
      const { getByText } = within(screen.getByTestId('previewSection'));
      const showLink = getByText('show');
      expect(showLink).toBeInTheDocument();
      fireEvent.click(showLink);
      const hideLink = getByText('hide');
      expect(hideLink).toBeInTheDocument();
      fireEvent.click(hideLink);
      expect(showLink).toBeInTheDocument();
    });

    it('should contain import options block', async () => {
      const component = exerciseTermTable();
      const autoApproveInfo = await component.findByText(messages.autoApproveInfo);
      const autoApproveCheckbox = await component.findByText(messages.autoApproveCheckbox);
      const updateExistingCheckboxName = await component.findByText(messages.updateExistingCheckboxName);
      const updateExistingInfo = await component.findByText(messages.updateExistingInfo);
      const reviewInfo = await component.findByText(messages.reviewInfo);
      const importButton = await component.findByText(messages.importButton);
      expect(autoApproveInfo).toBeVisible();
      expect(autoApproveCheckbox).toBeVisible();
      expect(updateExistingCheckboxName).toBeVisible();
      expect(updateExistingInfo).toBeVisible();
      expect(reviewInfo).toBeVisible();
      expect(importButton).toBeVisible();
    });
  });
});

const messages = {
  autoApproveInfo: 'auto_approve_info',
  autoApproveCheckbox: 'auto_approve_checkbox',
  willApproveCount: 'will_approve_count',
  willNotApproveCount: 'will_not_approve_count',
  optionsNotAvailable: { message: 'options_not_available', link: 'link' },
  updateExistingCheckboxName: 'update_existing_checkbox_name',
  updateExistingInfo: 'update_existing_info',
  reviewInfo: 'review_info',
  importButton: 'import_button'
};

const exerciseReportTable = () => {
  const component = render(<ImportPreviewControl
    tableTitle="Specifications Table"
    errorObjects={[]}
    objects={[{ objectValues: { name: 'SpecName1' }, warnings: ['warning1'], errors: ['error1'], hasWarnings: true, hasErrors: true, statusMessage: 'successMessage', actionMessage: 'createNew', customFieldsHeaders: [], customFieldsValues: [] }]}
    objectType="specification"
    notFoundMessage="not_found"
    emptyRowMessage="empty_row"
    chevronIcons={['/link1', '/link2']}
    importOptionsMessages={messages}
    headerErrors={[]}
    headerWarnings={[]}
    unrecognizedErrors={[{ name: 'Some spec 1', report: null, unrecognizedReportError: 'Some error 1', statusMessage: '1 error', actionMessage: 'Some act message' }]}
    userHasManagerPermission
    anyExistingObjects
    helpPoints={{ point1: '', point2: '', point3: '', point4: '', point5: '', point6: '' }}
  />);

  return {
    ...component,

    async getDropdownIndicator() {
      return component.findByTitle('Dropdown Indicator');
    },

    async getFilterLabel() {
      return component.findByTitle('Filter By Label');
    },

    async getSearchInput() {
      return component.findByPlaceholderText('Search Keywords from your CSV');
    }
  };
};

const exerciseTermTable = () => {
  const term = {
    objectValues: {
      name: 'TermName1',
      public: true,
      functionalDefinition: { value: 'functionalDefinition1', class: '' },
      tagList: { value: 'tag1,tag2', class: '' },
      termSource: { value: 'termSource1', class: '' },
      classificationCode: { value: 'classificationCode1', class: '' },
      technicalDefinitions: { values: [{ name: 'technicalDefinition1', erpSystem: 'dataSystem1', timeContext: '12/12/2025' }], class: '' },
      synonyms: { values: [{ name: 'synonym1' }], class: '' },
      functionalAreas: { values: [{ name: 'fa1' }], class: '' },
      designatedRequester: { value: { name: 'user1' }, class: '' }
    },
    warnings: ['warning1'],
    errors: ['error1'],
    hasWarnings: true,
    hasErrors: true,
    statusMessage: 'successMessage',
    actionMessage: 'createNew',
    customFieldsHeaders: [],
    customFieldsValues: []
  };
  const component = render(<ImportPreviewControl
    tableTitle="Definitions Table"
    errorObjects={[]}
    objects={[term]}
    objectType="definition"
    notFoundMessage="not_found"
    emptyRowMessage="empty_row"
    chevronIcons={['/link1', '/link2']}
    importOptionsMessages={messages}
    headerErrors={[]}
    headerWarnings={[]}
    unrecognizedErrors={[]}
    userHasManagerPermission
    anyExistingObjects
    helpPoints={{ point1: '', point2: '', point3: '', point4: '', point5: '', point6: '' }}
  />);

  return {
    ...component,

    async getDropdownIndicator() {
      return component.findByTitle('Dropdown Indicator');
    },

    async getFilterLabel() {
      return component.findByTitle('Filter By Label');
    },

    async getSearchInput() {
      return component.findByPlaceholderText('Search Keywords from your CSV');
    }
  };
};
