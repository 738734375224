import LinioComponent from './LinioComponent';
import TabSubjectDetailsDescription from '../ReportTemplateTabs/TabSubjectDetailsDescription';
import DMCLineageTargetShow from './DMCLineageTargetShow';
import DMCLinioSourceShow from './DMCLinioSourceShow';
import DMCProcessingStepForm from './DMCProcessingStepForm';
import DataMappingControlHeader from './DataMappingControlHeader';
import DMCNewLineageETL from './DMCNewLineageETL';
import LineageComponent from './LineageComponent';
import DMCNewLineage from './DMCNewLineage';
import DMCNewLinio from './DMCNewLinio';
import DMCProcessingStepShow from './DMCProcessingStepShow';

export default class DataMappingControlComponent extends React.Component {
  componentDidMount() {
    $j(this.el).sortable({
      handle: '.dmc__sortable-handle',
      placeholder: 'sortable-placeholder notice clear special-gray-box',
      start: (_event, ui) => {
        ui.placeholder.html(ui.placeholder.index());
        ui.placeholder.css({ 'font-size': ui.item.height() });
      },
      change: (_event, ui) => {
        const positionNumber = $j(this.el).children('div:visible').index(ui.placeholder) + 1;
        ui.placeholder.html(positionNumber);
      },
      helper: 'clone',
      update: (_event, ui) => {
        const lineageId = ui.item.data('id');
        const newOrder = ui.item.index() + 1;

        const lineage = {
          ...this.props.lineages.find(l => l.id === lineageId),
          newOrder
        };

        this.props.onOrderSubmit(null, lineage);
      },
      axis: 'y',
      cursor: 'move',
      opacity: 0.5,
      tolerance: 'pointer',
      forcePlaceholderSize: true
    });
  }

  getLineageComponent(props, lineage) {
    if (lineage.relatedObjectType === 'TechnicalDefinition')
      return <LinioComponent {...this.props} lineage={lineage} key={lineage.id} />;

    return <LineageComponent {...this.props} lineage={lineage} key={lineage.id} />;
  }

  getNewLineageComponent() {
    const { reportTemplate } = this.props;
    if (reportTemplate === 'ETL') {
      return (<DMCNewLineageETL
        key="1"
        {...this.props}
      />);
    }
    return null;
  }

  render() {
    const { editable, onlyLinio } = this.props;
    const showFields = [
      <DMCLineageTargetShow
        key="show1"
        node={this.props.targetShow}
        {...this.props}
      />,
      <DMCLinioSourceShow
        key="show2"
        node={this.props.sourceShow}
        {...this.props}
      />,
      <DMCProcessingStepShow
        key="show3"
        node={this.props.processingStepShow}
        {...this.props}
      />
    ];
    let editFields = '';
    if (editable) {
      editFields = onlyLinio
        ? (
            this.props.lineages.length === 0 && (
            <DMCNewLinio
              key="1"
              {...this.props}
            />
            )
          ) : (
            [
              <LineageForm
                key="2"
                lineage={this.props.lineage}
                lineageTarget={this.props.lineageTarget}
                sourceErpSystems={this.props.sourceErpSystems}
                {...this.props}
              />
            ]
          );

      editFields = [
        editFields,
        <LinioSourceForm
          key="3"
          linioSource={this.props.source}
          {...this.props}
        />,
        <DMCProcessingStepForm
          key="4"
          {...this.props}
        />
      ];
    }
    const detailsDescriptionEditor = <TabSubjectDetailsDescription {...this.props} />;

    const { lineages } = this.props;

    let filteredLineages;
    if (lineages.length === 1 && lineages[0].relatedObjectType === 'TechnicalDefinition')
      filteredLineages = lineages;
    else {
      filteredLineages = lineages.filter(l => (l.treeJson.reportItem))
        .sort((l1, l2) => (l1.treeJson.reportItem.position - l2.treeJson.reportItem.position));
    }

    const { reportTemplate, visibleFields } = this.props;
    const isMappingControlVisible = (visibleFields && visibleFields.includes('Data Mapping Control')) || onlyLinio;

    return (
      <div id={this.props.primaryId}>
        { visibleFields &&
          visibleFields.includes('Mapping Description') &&
          detailsDescriptionEditor }
        {
          visibleFields &&
          <DataMappingControlHeader {...this.props}>
            {
              editable &&
              !onlyLinio &&
              isMappingControlVisible &&
              (
                reportTemplate == 'ETL'
                  ? this.getNewLineageComponent()
                  : <DMCNewLineage
                    key="1"
                    {...this.props}
                  />
              )
            }
          </DataMappingControlHeader>
        }
        { editFields }
        { showFields }
        { isMappingControlVisible &&
          <div
            className="sortable"
            ref={el => this.el = el}
          >
            { filteredLineages.map(lineage =>
              (!onlyLinio || editable || (onlyLinio && lineage.treeJson.children.length > 0)
                ? (
                    this.getLineageComponent(this.props, lineage)
                  ) : (
                    null
                  )))}
          </div>
        }
      </div>
    );
  }
}
