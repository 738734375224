import {
  render,
  within,
  screen,
  fireEvent
} from '@testing-library/react';
import ObjectCollapsedPreview from './ObjectCollapsedPreview';

describe('<ObjectCollapsedPreview />', () => {
    it('should render report table', async () => {
      exercise();
      const { getAllByText, getByText } = within(screen.getByTestId('collapsePreview'));
      expect(getAllByText('SpecName1')).toHaveLength(2);
      expect(getByText('Name')).toBeInTheDocument();
      expect(getByText('Description')).toBeInTheDocument();
      expect(getByText('Purpose')).toBeInTheDocument();
      expect(getByText('Owner')).toBeInTheDocument();
      expect(getByText('Functional Areas')).toBeInTheDocument();
      expect(getByText('Specification Type')).toBeInTheDocument();
      expect(getByText('Source Data Systems')).toBeInTheDocument();
      expect(getByText('Access Details')).toBeInTheDocument();
      expect(getByText('Display Description')).toBeInTheDocument();
      expect(getByText('Output Format')).toBeInTheDocument();
      expect(getByText('Mapping Description')).toBeInTheDocument();
      expect(getByText('Selection Description')).toBeInTheDocument();
      expect(getByText('Sort Description')).toBeInTheDocument();
      expect(getByText('Target Data System')).toBeInTheDocument();
      expect(getByText('Tags')).toBeInTheDocument();
      expect(getByText('Primary Tool')).toBeInTheDocument();
      expect(getByText('External ID')).toBeInTheDocument();
      expect(getByText('Usage Note')).toBeInTheDocument();
      expect(getByText('Additional Details')).toBeInTheDocument();
      expect(getByText('Designated Requester')).toBeInTheDocument();
      expect(getByText('Technical Details')).toBeInTheDocument();
    });

    it('should have correct status', async () => {
      exercise();
      const { getByText } = within(screen.getByTestId('collapsePreview'));
      expect(getByText('Status: successMessage')).toBeInTheDocument();
    });

    it('should have correct action', async () => {
      exercise();
      const { getByText } = within(screen.getByTestId('collapsePreview'));
      expect(getByText('Action: createNew')).toBeInTheDocument();
    });

    it('should have button show errors and warnings', async () => {
      exercise();
      const { getByText } = within(screen.getByTestId('collapsePreview'));
      expect(getByText('show')).toBeInTheDocument();
    });

    it('should have show errors and warnings functionality', async () => {
      exercise();
      const { getByText } = within(screen.getByTestId('collapsePreview'));
      const showLink = getByText('show');
      expect(showLink).toBeInTheDocument();
      fireEvent.click(showLink);
      const hideLink = getByText('hide');
      expect(hideLink).toBeInTheDocument();
      fireEvent.click(hideLink);
      expect(showLink).toBeInTheDocument();
    });
});

const exercise = () => {
  const component = render(<ObjectCollapsedPreview
    objectValues={{ name: 'SpecName1' }}
    objectType="specification"
    hasWarnings={true}
    hasErrors={true}
    warnings={['warning1']}
    errors={['error1']}
    statusMessage='successMessage'
    actionMessage='createNew'
    customFieldsHeaders={[]}
    customFieldsValues={[]}
    notFoundMessage="not_found"
    emptyRowMessage="empty_row"
    chevronIcons={['/link1', '/link2']}
    helpPoints={{ point1: '', point2: '', point3: '', point4: '', point5: '', point6: '' }}
    objectCustomFieldWarnings={[]}
  />);

  return {
    ...component,
  };
};
