import HelpPoint from '../../Helpers/HelpPoint'
import SpecReportDisplayItems from './SpecReportDisplayItems'
import DisplayDetailsAttachmentUploadForm from './DisplayDetailsAttachmentUploadForm'


export default class SimpleAttachmentComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props,
      displayUploadForm: false,
      attachmentUrl: this.props.mockupImagePath,
      isDisplayDataItemsExpanded: true,
    };

    this.showUploadAttachmentForm = this.showUploadAttachmentForm.bind(this);
    this.hideUploadAttachmentForm = this.hideUploadAttachmentForm.bind(this);
    this.getControllerUrl = this.getControllerUrl.bind(this);
    this.onUploadAttachmentSuccess = this.onUploadAttachmentSuccess.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      reportDisplayItems: nextProps.reportDisplayItems,
    });
  }

  getControllerUrl() {
    let url = window.location.href;
    if (url.indexOf('/versions') === -1) {
      url = url.substr(0, url.indexOf('/display_details')) + '/versions/' + this.props.reportVersion.id + '/display_details_attachment';
    } else {
      url = 'display_details_attachment';
    }
    return url;
  }

  onUploadAttachmentSuccess(attachmentId) {
    const self = this;
    const url = this.getControllerUrl();

    $j.ajax({
      url: `${url}/${attachmentId}/show_link`,
      type: 'GET',
      dataType: 'JSON',
      success: (data) => {
        self.setState({
          attachmentUrl: data.img_src,
          attachedFileName: data.file_name,
          mockupImageDescription: data.description,
        });
      },
      error: (e) => {
        console.error(e);
      },
    });
  }

  showUploadAttachmentForm() {
    this.setState({
      displayUploadForm: true,
    });
  }

  hideUploadAttachmentForm() {
    this.setState({
      displayUploadForm: false,
    });
  }

  render() {
    const {
      editable,
      attachmentUrl,
      attachedFileName,
      mockupImageDescription,
      displayUploadForm,
    } = this.state;
    const controllerUrl = this.getControllerUrl();

    return (
      <div className="display-data-simple-attachment-container">
        <label
          style={{
            display: 'inline-block'
          }}
        >
          Display Sample
        </label>
        <HelpPoint helpPoint={this.props.simpleAttachmentHelpPoint} />
        <div>
          {
            editable &&
            <a
              className="pretty-button med"
              onClick={this.showUploadAttachmentForm}
            >
              Add Attachment
            </a>
          }
          {
            attachmentUrl &&
            <a
              style={{ marginLeft: '7px' }}
              target="_blank"
              href={attachmentUrl}
            >
              Download {mockupImageDescription || attachedFileName}
            </a>
          }
        </div>
        <SpecReportDisplayItems
          onRemoveDisplayDataItem={this.state.setDisplayDataItems}
          expandable={false}
          {...this.state}
        />
        <DisplayDetailsAttachmentUploadForm
          {...this.state}
          display={displayUploadForm}
          onUploadSuccess={this.onUploadAttachmentSuccess}
          onCancel={this.hideUploadAttachmentForm}
          controllerUrl={controllerUrl}
        />
      </div>
    );
  }
}
