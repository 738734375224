import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import HelpPoint from '../Helpers/HelpPoint';

import('react-datepicker/dist/react-datepicker.css');

const DateFilter = (props) => {
  const {
    updateUrl,
    dateFormat,
    defaultDate,
    initStartDate,
    initEndDate,
    nameFilterValue,
    jobIdFilterValue,
    statusFilterValue,
    helpPoints,
    integrationJobs,
    onDateChange,
    today,
  } = props;
  const now = new Date(today);
  const [startDate, setStartDate] = useState(initStartDate);
  const [endDate, setEndDate] = useState(initEndDate);
  const [dateChanged, setDateChanged] = useState(false);

  const filterRequest = () => {
    if (dateChanged) {
      $j.ajax({
        type: 'GET',
        url: updateUrl,
        data: {
          name_filter: nameFilterValue,
          job_id_filter: jobIdFilterValue,
          status_filter: statusFilterValue,
          start_date: startDate,
          end_date: endDate,
          date_filter: true,
        },
        success: (response) => {
          window.location.href = response.redirect;
        },
        error: () => {
          window.location.reload();
        }
      });
    }
  };

  const onChangeStartDate = (date) => {
    const newDate = !date || date > endDate ? defaultDate : date;
    setStartDate(newDate);
    onDateChange(true);
    setDateChanged(true);
  };

  const onChangeEndDate = (date) => {
    const newDate = !date || date < startDate ? now : date;
    setEndDate(newDate);
    onDateChange(true);
    setDateChanged(true);
  };

  const convertDateFormat = () => {
    const correctMonth = 'MM';
    const correctYear = '/yy';
    const formattedDate = dateFormat.replace('mm', correctMonth).replace('yy', 'yyyy');
    if (formattedDate.includes('-yy') || formattedDate.includes(correctYear))
      return formattedDate.includes(correctMonth) ? formattedDate : formattedDate.replace('M', correctMonth);
    else if (formattedDate.includes(','))
      return formattedDate.includes('MM') ? formattedDate.replace('MM', 'MMMM') : formattedDate.replace('M', 'MMM');

    return formattedDate.replace('-y', '-yy').replace('/y', correctYear);
  };

  return (
    <div>
      <div title="Filter By Label" className="label">Filter by:</div>
      <div className="date-inputs-container">
        <div title="Date Label" className="label date-label">Date Range</div>
        <div className="date-filter-container">
          <div className="date-filter-input">
            <div className="date-filter-prefix label" title="From Date Label">from<HelpPoint helpPoint={integrationJobs ? helpPoints.point1 : helpPoints.point2} /></div>
            <DatePicker
              title="Background Jobs Filter Start Date Calendar"
              previousYearAriaLabel="Previous Year Click"
              nextYearAriaLabel="Next Year Click"
              previousMonthAriaLabel="Previous Month Click"
              nextMonthAriaLabel="Next Month Click"
              className="startDateCalendar"
              selected={startDate}
              onChange={date => onChangeStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              maxDate={now}
              popperPlacement="bottom"
              dateFormat={convertDateFormat()}
              onCalendarClose={() => filterRequest()}
            />
          </div>
          <div className="date-filter-input">
            <div className="date-filter-prefix label" title="To Date Label">to<HelpPoint helpPoint={integrationJobs ? helpPoints.point3 : helpPoints.point4} /></div>
            <DatePicker
              title="Background Jobs Filter End Date Calendar"
              previousYearAriaLabel="Previous Year Click"
              nextYearAriaLabel="Next Year Click"
              previousMonthAriaLabel="Previous Month Click"
              nextMonthAriaLabel="Next Month Click"
              className="endDateCalendar"
              selected={endDate}
              onChange={date => onChangeEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              maxDate={now}
              minDate={startDate}
              popperPlacement="bottom"
              dateFormat={convertDateFormat()}
              onCalendarClose={() => filterRequest()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateFilter;
