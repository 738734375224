import OptimizedSelect from '../shared/OptimizedSelect';

class DMCTargetErpSystem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTargetErpSystem: props.targetErpSystem ? {
        value: props.targetErpSystem.id,
        label: props.targetErpSystem.name,
        type: 'name'
      } : null
    };
  }

  dataSystemsOptionRenderer = ({ focusedOption, focusOption, key, option, selectValue, style, valueArray }) => {
    const classNames = ['nameOption'];

    if (option.type === 'header') {
      classNames.push('nameHeader');

      return (
        <div
          className={classNames.join(' ')}
          key={key}
          style={style}
        >
          {option.label}
        </div>
      );
    }

    classNames.push('nameField');
    if (option === focusedOption) classNames.push('nameOptionFocused');
    if (valueArray.indexOf(option) >= 0) classNames.push('nameOptionSelected');

    return (
      <div
        className={classNames.join(' ')}
        key={key}
        onClick={() => selectValue(option)}
        onMouseEnter={() => focusOption(option)}
        style={style}
      >
        {option.label}
      </div>
    );
  };

  updateTargetErpSystem(selectedTargetErpSystem) {
    if (!selectedTargetErpSystem) return;

    this.setState({ selectedTargetErpSystem });
    this.props.onTargetSystemSave(selectedTargetErpSystem);
  }

  removeTargetErpSystem(e) {
    e.preventDefault();
    this.setState({ selectedTargetErpSystem: null });
    this.props.onTargetSystemSave({ value: '' });
  }

  render() {
    const { selectedTargetErpSystem } = this.state;
    const { targetErpSystem } = this.props;
    const targetErpSystemName = this.props.targetErpSystem && this.props.targetErpSystem.name;
    let editLink,
      el;

    if (this.props.targetSystemEditMode) {
      const optionsForDataSystemsSelect = this.props.erpSystems.filter(s => s.hideFromDropdown == false).map((erpSystem) => {
        if (erpSystem.groupFlag) {
          const header = {
            label: erpSystem.name,
            value: '',
            isHeader: true,
            isDisabled: true
          };

          const systems = erpSystem.childSystems.filter(s => s.hideFromDropdown == false).map(system => ({
            value: system.id,
            label: system.name
          }));
          return [header].concat(systems);
        }

        return {
          value: erpSystem.id,
          label: erpSystem.name
        };
      }).reduce((firstEl, secondEl) => firstEl.concat(secondEl), []);

      el = (
        <div className="virtualized-custom-wrap">
          <div className="virtualized-custom-style">
            <OptimizedSelect
              options={optionsForDataSystemsSelect}
              value={selectedTargetErpSystem}
              onChange={selectedTargetErpSystem => this.updateTargetErpSystem(selectedTargetErpSystem)}
              isClearable={false}
            />

            <button
              onClick={this.props.toggleTargetSystemEditMode}
              className="btn-done"
            >
              Done
            </button>
          </div>

          {selectedTargetErpSystem &&
          <button
            onClick={e => this.removeTargetErpSystem(e)}
            className="btn-remove"
          >
            Remove
          </button>}
        </div>
      );
    } else {
      editLink = (
        <img
          src={this.props.imageEdit}
          style={{ verticalAlign: 'middle' }}
          alt="Edit"
          onClick={this.props.toggleTargetSystemEditMode}
        />
      );

      el = (
        <label>
          {targetErpSystem?.dataApplication?.logoImageUrl ? <img src={targetErpSystem.dataApplication.logoImageUrl} alt={targetErpSystem.dataApplication.logoLabel} className='data-system-logo mapping' data-testid="data-source-erp-system-logo" /> : null }
          {targetErpSystemName}
          {' '}
          {targetErpSystemName && this.props.browseLink(this.props.targetErpSystem, 'Target Erp System')}
        </label>
      );
    }

    return (
      <div
        className="dmc-header__target-erp-system"
        style={this.props.withSourceErpSystem ? {} : { float: 'right' }}
      >
        <h5>Target Data System:</h5>
        {this.props.editable ? editLink : ''}
        <HelpPoint helpPoint={this.props.helpPoints.point3} />
        {el}
      </div>
    );
  }
}

export default DMCTargetErpSystem;
