import HelpPoint from "../Helpers/HelpPoint";

export default function ShowValidValueListTableForm(props) {
  const list = props.validValueList
  const allLists = props.allLists
  const limitValidValuesAmount = 20
  const rollUpsCount = list.roll_ups ? list.roll_ups.length : 0
  const allFlags = list.valid_values.map((validValue) => {return validValue.inactive_flag })
  const anyInactiveFlags = allFlags.find(function(flag) {return flag == true}) ? true : false
  const allDates = list.valid_values.map((validValue) => {return validValue.inactive_date })
  const anyInactiveDates = allDates.find(function(date) {return date !== null}) ? true : false
  const allMasterValues = list.valid_values.map((validValue) => {return validValue.master_value })
  const anyMasterValues = allMasterValues.find(function(value) {return value !== ''}) ? true : false

  const svvltfHelpPoints = props.svvltf_help_points
  const getValidValueListInformation = (validValue) => {
    let id,inactive_flag, inactive_date, master_value;
    ({id , inactive_flag, inactive_date, master_value} = validValue)
    return ([
      !props.master && (
        <td key={`master-${id}`}>
          {master_value}
        </td>
      ),
      rollUpsCount > 0 && (
        list.roll_ups.map(rollUp => {
          let validValueRollUp;
          if (validValue.valid_value_roll_ups) {
            validValueRollUp = validValue.valid_value_roll_ups.find(vvru => {return vvru.roll_up_index === rollUp.index})
          }
          let value = ''
          if (validValueRollUp) {
            value = validValueRollUp.value;
          }
          return <td key={`vvru-${rollUp.id}-${validValue.id}`}>{value}</td>
        })
      ),

      anyInactiveFlags && (<td key={`flag-${id}`} className="zoom-25 inactive-flag-column">
        <input type="checkbox" aria-label="Flag as active or inactive" disabled checked={inactive_flag}/>
      </td>),
       anyInactiveDates && (<td key={`date-${id}`}>
        {inactive_date}
      </td>)
    ])
  }

  const getBody = () => {
    return list.valid_values.map((validValue) => {
      if (props.showValidValueSectionFromList(list.id)) {
        let id, value, description
        ({id, value, description} = validValue)
        return (
          <tr key={`body:${validValue.id}:${validValue.value}`}>
            <td key={`code-${id}`}>{value}</td>
            <td key={`desc-${id}`}>{description}</td>
            {(props.valid_value_list_show_page || props.quality_rule_show_page) &&
              getValidValueListInformation(validValue)
            }
          </tr>
        )
      }
    })
  }

  const getValidValueListHeader = () => {
    let masterColumn;
    if (!props.master ) {
      masterColumn = <th key={`master-${list.id}`}  style={{width: getColumnWidth()}} className="zoom-25">Master Code</th>;
    }
    let rollUpColumns;
    if (rollUpsCount > 0) {
      rollUpColumns = list.roll_ups.map(rollUp => {
        return <th key={`vvru-${rollUp.id}`} style={{width: getColumnWidth()}}>{getRollUpLink(rollUp)}</th>;
      });
    }
    return ([
      masterColumn,
      rollUpColumns,
      anyInactiveFlags && (<th key={`flag-${list.id}`} className="zoom-25 inactive-flag-column">Inactive Flag</th>),
      anyInactiveDates && (<th key={`date-${list.id}`} style={{width: getColumnWidth()}} className="zoom-25">Inactive Date</th>)
    ])
  }

  const getRollUpLink = (rollUp) => {
    var rollUpName = rollUp.name
    var rollUpList = allLists && allLists.find(list => list.id === rollUp.valid_value_list_id )
    var masterStatus = rollUpList?.master ? ' - Master List' : ''
    var erpSystemName = rollUpList ?
                          rollUpList.erp_system ?
                            ` (${rollUpList.erp_system.name}) ` : ''
                        : ''
    return rollUpList ?
      <a href={`/institution/valid_value_list_groups/${rollUpList.valid_value_list_group_version
                                                                 .valid_value_list_group_id}`}
         title={`${rollUpName + erpSystemName + masterStatus}`}
      >
        {rollUpName}
      </a>
      : rollUpName
  }
  const getQuerySection = () => {
    return (
      <div>
        <h5>Query to Retrieve Values:</h5>
        <HelpPoint helpPoint={svvltfHelpPoints.point11} />
        <div>
        <textarea
          className="technical-text"
          name="retrieve_values_query"
          value={list.retrieve_values_query || ''}
          autoComplete="off"
          rows="7"
          cols="55"
          disabled
        />
        </div>
      </div>
    )
  }

  /* eslint-disable camelcase */
  const buildNullTechnicalRelationship = () => ({
    data_model_object: {
      name: "",
      link_to_object: "#",
      data_schema: { link_to_object: "#", name: "" },
      parent: { name: "" }
    }
  });

  const findTechnicalRelationship = dcbObjectSubtype => {
    const technicalRelationship = _.find(list.technical_relationships, {
      dcb_object_subtype: dcbObjectSubtype
    });
    if (!technicalRelationship) return buildNullTechnicalRelationship();
    return technicalRelationship;
  };
  /* eslint-enable camelcase */

  const getTableSection = () => {
    const technicalRelationshipCode = findTechnicalRelationship("Code");
    const technicalRelationshipDescription = findTechnicalRelationship(
      "Description"
    );
    const technicalRelationshipFlag = findTechnicalRelationship(
      "Inactive Flag"
    );
    const technicalRelationshipDate = findTechnicalRelationship(
      "Inactive Date"
    );
    return (
      <div>
        <div style={{ display: "table-row" }}>
          <div className="span-4">
            <h5>Schema:</h5>
            <HelpPoint helpPoint={svvltfHelpPoints.point19} />
          </div>
          { technicalRelationshipCode.data_model_object ? (
              <a
                href={
                  technicalRelationshipCode.data_model_object.data_schema
                    .link_to_object
                }
              >
                {
                  technicalRelationshipCode.data_model_object.data_schema.name
                }
              </a>
            ) : (
              <span>{ technicalRelationshipCode.data_schema_name }</span>
            )
          }
        </div>
        <div style={{ display: "table-row" }}>
          <div className="span-4">
            <h5>Table:</h5>
            <HelpPoint helpPoint={svvltfHelpPoints.point20} />
          </div>
          { technicalRelationshipCode.data_model_object ? (
              <a
                href={
                  technicalRelationshipCode.data_model_object.parent.link_to_object
                }
              >
                {technicalRelationshipCode.data_model_object.parent.name}
              </a>
            ) : (
              <span>{ technicalRelationshipCode.parent_name }</span>
            )
          }
        </div>
        <div style={{ display: "table-row" }}>
          <div className="span-4">
            <h5>Code:</h5>
            <HelpPoint helpPoint={svvltfHelpPoints.point1} />
          </div>
          { technicalRelationshipCode.data_model_object ? (
              <a href={technicalRelationshipCode.data_model_object.link_to_object}>
                {technicalRelationshipCode.data_model_object.name}
              </a>
            ) : (
              <span>{ technicalRelationshipCode.object_value }</span>
            )
          }
        </div>
        <div style={{ display: "table-row" }}>
          <div className="span-4">
            <h5>Description:</h5>
            <HelpPoint helpPoint={svvltfHelpPoints.point2} />
          </div>
          { technicalRelationshipDescription.data_model_object ? (
              <a
                href={
                  technicalRelationshipDescription.data_model_object.link_to_object
                }
              >
                {technicalRelationshipDescription.data_model_object.name}
              </a>
            ) : (
              <span>{ technicalRelationshipDescription.object_value }</span>
            )
          }
        </div>
        <div style={{ display: "table-row" }}>
          <div className="span-4">
            <h5>Inactive Flag:</h5>
            <HelpPoint helpPoint={svvltfHelpPoints.point3} />
          </div>
          { technicalRelationshipFlag.data_model_object ? (
              <a href={technicalRelationshipFlag.data_model_object.link_to_object}>
                {technicalRelationshipFlag.data_model_object.name}
              </a>
            ) : (
              <span>{ technicalRelationshipFlag.object_value }</span>
            )
          }
        </div>
        <div style={{ display: "table-row" }}>
          <div className="span-4">
            <h5>Inactive Date:</h5>
            <HelpPoint helpPoint={svvltfHelpPoints.point4} />
          </div>
          { technicalRelationshipDate.data_model_object ? (
              <a href={technicalRelationshipDate.data_model_object.link_to_object}>
                {technicalRelationshipDate.data_model_object.name}
              </a>
            ) : (
              <span>{ technicalRelationshipDate.object_value }</span>
            )
          }
        </div>
      </div>
    );
  };

  const getTechnicalInformation = () => {
    const colSpanWidth = props.master ? 4 : 5;
    const runDatetime = list.run_datetime ? list.run_datetime : 'n/a';
    const enableForIntegration = list.enabled_for_integration ? 'Yes' : 'No'
    return (
      <div className="borderBottomContainer">
        <tr>
          <td className="noBorderBottom" key={list.id} colSpan={colSpanWidth + rollUpsCount}>
            <div>
              <b>Technical Information</b>
              <HelpPoint helpPoint={svvltfHelpPoints.point22} />
              <a
                name="show-technical-information"
                href=''
                onClick={e => props.toggleTechnical(e, list.id)}
                style={{ marginLeft: '40px' }}
              >
                { props.visibleTechnical.includes(list.id) ? 'Hide' : 'View' } Details
              </a>
            </div>
            { props.showTechnicalSection(list.id) && list.query_mode === 'query' &&
              getQuerySection()
            }
            { props.showTechnicalSection(list.id) && list.query_mode !== 'query' &&
              getTableSection()
            }
            { props.showTechnicalSection(list.id) && props.has_igs_module &&
              [
                <label
                  key={`Last_run_${list.id}:${runDatetime}`}
                  className="inline"
                >
                  Last run:
                </label>,
                <HelpPoint
                  key={`Last_run_hp_${list.id}:${runDatetime}`}
                  helpPoint={svvltfHelpPoints.point10}
                />,
                <span
                  className="vvlmargin-left"
                  key={`Last_run_span_${list.id}:${runDatetime}`}
                >
                  {runDatetime}
                </span>,
                <br key={`Last_run_br_${list.id}:${runDatetime}`} />,
                <label
                  key={`Enable_for_integration_${list.id}`}
                  className="inline"
                >
                  Enabled for integration:
                </label>,
                <HelpPoint
                  key={`Enable_for_integration_hp_${list.id}`}
                  helpPoint={svvltfHelpPoints.point18}
                />,
                <span
                  className="vvlmargin-left"
                  key={`Enable_for_integration_span_${list.id}`}
                >
                  {enableForIntegration || ''}
                </span>,
                <br key={`Enabled_for_integration_br_${list.id}`} />,
                <label
                  key={`Frequency_${list.id}:${list.schedule || ''}`}
                  className="inline"
                >
                  Frequency:
                </label>,
                <HelpPoint
                  key={`Frequency_hp_${list.id}:${list.schedule || ''}`}
                  helpPoint={svvltfHelpPoints.point6}
                />,
                <span
                  className="vvlmargin-left"
                  key={`Frequency_span_${list.id}:${list.schedule || ''}`}
                >
                  {list.schedule || ''}
                </span>,
              ]
            }
          </td>
        </tr>
      </div>
    );
  };

  const getErpSystemName = () => {
    if (props.validValueList.erp_system_id) {
      return ' (' + props.getErpNameFromId(props.validValueList.erp_system_id) + ')'
    } else {
      return ''
    }
  };

  const checkPermission = () => {
    if (props.validValueList.erp_system_id) {
      let accessible_info = props.erpSystemsTechInfoAccesible.find((estia) => estia.id === props.validValueList.erp_system_id)
      if (accessible_info) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  const getErpSystem = () => {
    return props.erpSystems.find(s => s.id === list.erp_system_id);
  };

  const getDataApplication = () => {
    const erpSystem = getErpSystem();
    return (erpSystem && erpSystem.data_application) || null;
  };

  const getDataApplicationImageUrl = (dataApplication, type) => {
    if (type === 'logo') {
      return dataApplication && dataApplication.logo_image_url;
    }
    if (type === 'banner') {
      return dataApplication && dataApplication.banner_image_url;
    }
  };

  const getDataApplicationAltText = (dataApplication, type) => {
    if (type === 'logo') {
      return dataApplication && dataApplication.logo_label;
    }
    if (type === 'banner') {
      return dataApplication && dataApplication.banner_label;
    }
  };

  const getDataApplicationImage = ({ type, className }) => {
    const dataApplication = getDataApplication();
    const dataApplicationImageUrl = getDataApplicationImageUrl(dataApplication, type);
    const dataApplicationAltText = getDataApplicationAltText(dataApplication, type);

    if (dataApplicationImageUrl) {
      return (
        <img
          className={className}
          src={dataApplicationImageUrl}
          alt={dataApplicationAltText}
        />
      );
    }
  };

  const getListName = () => {
    if (list.name === ''){
      return props.validValueGroupVersion.name
    } else {
      return list.name
    }
  };

  const getTableHeight = (listLength) => {
    return listLength > 5 ? 238 : 'auto'
    // 238px - is the minimum height to fit 5 one-line rows
  };

  const getColumnWidth = () => {
    var numberOfBasicColumns = 1
    // Basic columns: code, description, master code, inactive date
    // Code, Inactive code, master code and inactive date
    // columns are excluded since their width is fixed (23/12/2021)
    return (100/(rollUpsCount + numberOfBasicColumns)) + '%'
  };

  const getTableHeader = () => {
    let text;
    if (props.master) {
      text = `${getListName()} ${getErpSystemName()} - Master List`;
    } else {
      text = `${getListName()} ${getErpSystemName()}`;
    }

    return (
      <h2>
        {
          getDataApplicationImage({
            type: 'logo',
            className: 'valid-value-list__data-application-logo',
          })
        }
        {text}
      </h2>
    );
  }

  return (
    <div>
      { !props.embedded_qr_page && getTableHeader() }
      <table className="valid-value-list-table zoom-table-font">
      <div style={{height: getTableHeight(list.valid_values.length)}} className="scroll-table-body">
        <tbody className='list-table-body'>
        <tr>
          <th key={`code-${list.id}`} style={{width: '30px'}} >Code</th>
          <th key={`desc-${list.id}`} style={{width: getColumnWidth()}} >Description</th>
          {(props.valid_value_list_show_page || props.quality_rule_show_page) &&
            getValidValueListHeader()
          }
        </tr>
        {getBody()}
        {list.valid_values.length > limitValidValuesAmount && !props.showValidValueSectionFromList(list.id) &&
          <tr>
            <td colSpan={4 + rollUpsCount}>
              <a name="show-all-master-valid-values" href='' onClick={e => props.toogleValidValueSection(e, list.id)}>
                <span>Hide some valid values</span>
              </a>
            </td>
          </tr>
        }
        </tbody>
      </div>
        {checkPermission() && props.validValueList.erp_system_id && props.valid_value_list_show_page &&
          getTechnicalInformation()
        }
      </table>
      <input type="hidden" />
    </div>
  )
}
