class DataItemFilterComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <div>
          <h5>Filter by usage: </h5>
          <select defaultValue="default" onChange={e => this.props.changeDataItemFilterValue(e)}>
            <option key="default" value="default">All</option>
            <option key="display" value="display">Display</option>
            <option key="select" value="select">Select</option>
            <option key="sort" value="sort">Sort</option>
            <option key="none" value="none">None</option>
          </select>
          <HelpPoint helpPoint={this.props.helpPoints.point1} />
        </div>
      </div>
    );
  }
}

export default DataItemFilterComponent;
